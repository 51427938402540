/* eslint-disable max-len */
import React from 'react';
import { IconModels } from '../icon.models';

const SVG = (props: IconModels.Props) => (
  <svg
    {...props}
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Eng" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][Eng][D]-Create-New-Article" transform="translate(-700.000000, -416.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 112.000000)">
          <g id="Upload-Photo" transform="translate(32.000000, 280.000000)">
            <g id="Icon" transform="translate(244.000000, 24.000000)">
              <g id="Upload">
                <rect id="#" opacity="0.287179129" x="0" y="0" width="48" height="48" />
                <rect id="Rectangle" fill="#F5F5F5" transform="translate(19.595918, 19.595918) rotate(-15.000000) translate(-19.595918, -19.595918) " x="3.59591794" y="3.59591794" width="32" height="32" rx="4" />
                <g id="Group" transform="translate(27.595918, 27.595918) rotate(15.000000) translate(-27.595918, -27.595918) translate(11.595918, 11.595918)">
                  <rect fill="#929292" id="Rectangle" x="0" y="0" width="32" height="32" rx="4" />
                  <circle id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="8.5" cy="8.5" r="2.5" />
                  <path d="M26,28 L6,28 C4.8954305,28 4,27.1045695 4,26 L4,23.1433981 C4,23.0496883 4.02633472,22.9578645 4.07600085,22.8783987 L7.3040034,17.7135946 C7.4649648,17.4560563 7.68246385,17.2385573 7.9400021,17.0775959 C8.8766752,16.4921752 10.1105759,16.7769215 10.6959966,17.7135946 L14,23 L19.1876337,11.8836421 C19.3863053,11.4579173 19.7285043,11.1157183 20.1542291,10.9170467 C21.1551713,10.4499403 22.3452599,10.8826998 22.8123663,11.8836421 L27.9530915,22.899482 C27.9839882,22.9656892 28,23.0378631 28,23.1109247 L28,26 C28,27.1045695 27.1045695,28 26,28 Z" id="Path" fill="#FFFFFF" fillRule="nonzero" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
