import React from 'react';
import styles from '../../ArticleContent.module.scss';
import { ArticleContentModels } from '../../article-content.models';

const Quote = ({ content }: ArticleContentModels.FactoryItemProps) => (
  <div className={styles['quote-wrapper']}>
    <p className={styles.quote}>{content}</p>
  </div>
);

export default Quote;
