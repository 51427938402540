import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import DescriptionTemplate from '../DescriptionTemplate';
import styles from './AdditionalDescription.module.scss';
import dataDescription from '../../../../../mockData/dataDescription.json';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import ServiceInfo from '../../serviceInfo/ServiceInfo';
import TRANSLATE_KEYS from '../../../../../i18n_keys';
import defaultAdditionalServices from './defaultDescriptionData';

const AdditionalDescription: FC = () => {
  const [translate] = useTranslation();

  return (
    <div className={styles.box}>
      {(dataDescription.additionalServices).map((info: ServicesPageModels.BaseMockDataDescription, index: number) => (
        <div key={info.paragraphFirst}>
          <DescriptionTemplate
            firstImg={info.bigImage}
            secondImg={info.smallImage}
            position={defaultAdditionalServices[index].position}
            extraClassName={defaultAdditionalServices[index].extraClassName}
          >
            <h2 className={styles.title}>{translate(TRANSLATE_KEYS[defaultAdditionalServices[index].title])}</h2>
            <div className={styles.text}>
              <ServiceInfo
                details={defaultAdditionalServices[index].details}
                info={info}
              />
            </div>
          </DescriptionTemplate>
        </div>
      ))}
    </div>
  );
};

export default withTranslation('translations')(AdditionalDescription);
