import { FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  ADMIN_FETCH_PETS_RECORDS,
  ADMIN_FETCH_PET,
  CLEAR_PETS,
  ADMIN_FETCH_ALL_PETS,
  DISPLAY_DELETE_MESSAGE,
  HIDE_DELETE_MESSAGE,
  DELETE_PET_SUCCESS,
  HIDE_OR_PUBLICH_PET_SUCCESS,
  CREATE_NEW_DOG,
  CLOTHE_CREATE_NEW_DOG,
  USER_DOG_FILTER_MALE,
  USER_FETCH_ALL_PETS,
  USER_DOG_FILTER_FEMALE,
  USER_DOG_FILTER_SEX_ALL,
  USER_DOG_FILTER_BEFORE_YEAR,
  USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS,
  USER_DOG_FILTER_FIVE_AND_MORE,
  USER_DOG_FILTER_SMALL_DOG,
  USER_DOG_FILTER_MEDIUM_DOG,
  USER_DOG_FILTER_BIG_DOG,
  USER_DOG_FILTER_AGE_ALL,
  USER_DOG_FILTER_SIZE_ALL,
  USER_DOG_FETCH_NEXT_ITEMS } from './types';

import { transformAndFilterPetDto,
  transformPetDto } from '../../../utils/transformPetDto';
import { getPetName, getPetTitle, getPetDescription, sortPetsById, filterPets } from './helpers';
import getDogAgeTranslateKey from '../../../utils/getDogAgeTranslateKey';

let pages = 0;

export const initialState = {
  pet: {},
  petsList: [],
  filteredPetsList: [],
  filtered: false,
  genderFilter: null,
  ageFilter: null,
  sizeFilter: null,
  items: 6,
  petsCount: 0,
  loading: false,
  error: null,
  pagesLoaded: 0,
  totalPages: 0,
  openModalCreatingDog: false,
  deleteMessageVisible: false,
};

export default function wantAFriendUserPagesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };

    case FETCH_END:
      return {
        ...state,
        loading: false,
      };

    case FETCH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_PETS:
      return {
        ...state,
        petsAllList: [],
        pet: {},
      };

    case ADMIN_FETCH_PETS_RECORDS:
      pages = state.pagesLoaded + 1;
      return {
        ...state,
        loading: false,
        petsList: sortPetsById(state.petsList.concat(action.payload.content)),
        petsCount: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pagesLoaded: pages,
      };

    case ADMIN_FETCH_ALL_PETS:
      return {
        ...state,
        loading: false,
        petsAllList: action.payload.data.map(el => transformPetDto(el, action.payload.selectedLanguage)),
      };

    case USER_FETCH_ALL_PETS:
      return {
        ...state,
        loading: false,
        petsAllList: action.payload.data.map(el => transformPetDto(el, action.payload.selectedLanguage)),
      };

    case ADMIN_FETCH_PET:
      return {
        ...state,
        loading: false,
        pet: transformAndFilterPetDto(action.payload.pet, action.payload.selectedLanguage),
        petName: getPetName(action.payload.pet),
        petTitle: getPetTitle(action.payload.pet),
        petDescription: getPetDescription(action.payload.pet),

      };

    case DISPLAY_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: true,
        petToDelete: action.payload,
      };

    case HIDE_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: false,
        petToDelete: null,
      };

    case DELETE_PET_SUCCESS:
      return {
        ...state,
        petsList: state.petsList.filter(pet => pet.id !== action.payload),
        petsCount: state.petsCount - 1,
      };

    case HIDE_OR_PUBLICH_PET_SUCCESS:
      return {
        ...state,
        pet: transformAndFilterPetDto(action.payload.data, action.payload.selectedLanguage),
        petsList: sortPetsById([...state.petsList.filter(pet => pet.id !== action.payload.oldId), action.payload.data]),
      };

    case CREATE_NEW_DOG:
      return {
        ...state,
        openModalCreatingDog: true,
      };

    case CLOTHE_CREATE_NEW_DOG:
      return {
        ...state,
        openModalCreatingDog: false,
      };
    case USER_DOG_FILTER_MALE:
      return {
        ...state,
        items: 6,
        genderFilter: 'male',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e.male === true), state, 'male'),
        petsList: filterPets(state.petsAllList.filter(e => e.male === true), state, 'male').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => e.male === true), state, 'male').length,
      };
    case USER_DOG_FILTER_FEMALE:
      return {
        ...state,
        items: 6,
        genderFilter: 'female',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e.male === false), state, 'female'),
        petsList: filterPets(state.petsAllList.filter(e => e.male === false), state, 'female').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => e.male === false), state, 'female').length,
      };

    case USER_DOG_FILTER_SEX_ALL:
      return {
        ...state,
        items: 6,
        genderFilter: null,
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e), state, 'allGender'),
        petsList: filterPets(state.petsAllList.filter(e => e), state, 'allGender').slice(0, 6),
        petsCount: filterPets(state.petsAllList, state, 'allGender').length,
      };

    case USER_DOG_FILTER_BEFORE_YEAR:
      return {
        ...state,
        items: 6,
        ageFilter: 'beforeYear',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
        || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear'), state, 'beforeYear'),
        petsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
        || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear'), state, 'beforeYear').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
        || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear'), state, 'beforeYear').length,
      };

    case USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS:
      return {
        ...state,
        items: 6,
        ageFilter: 'fromOneToFiveYears',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears'), state, 'fromOneToFiveYears'),
        petsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears'), state, 'fromOneToFiveYears').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears'), state, 'fromOneToFiveYears').length,
      };

    case USER_DOG_FILTER_FIVE_AND_MORE:
      return {
        ...state,
        items: 6,
        ageFilter: 'fiveAndMore',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
        || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears'), state, 'fiveAndMore'),
        petsList: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
        || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears'), state, 'fiveAndMore').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
        || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
        || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears'), state, 'fiveAndMore').length,
      };

    case USER_DOG_FILTER_AGE_ALL:
      return {
        ...state,
        items: 6,
        ageFilter: null,
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e), state, 'allAges'),
        petsList: filterPets(state.petsAllList.filter(e => e), state, 'allAges').slice(0, 6),
        petsCount: filterPets(state.petsAllList, state, 'allAges').length,
      };

    case USER_DOG_FILTER_SMALL_DOG:
      return {
        ...state,
        items: 6,
        sizeFilter: 'small',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e.sizeId === 1), state, 'small'),
        petsList: filterPets(state.petsAllList.filter(e => e.sizeId === 1), state, 'small').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => e.sizeId === 1), state, 'small').length,
      };

    case USER_DOG_FILTER_MEDIUM_DOG:
      return {
        ...state,
        items: 6,
        sizeFilter: 'medium',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e.sizeId === 2), state, 'medium'),
        petsList: filterPets(state.petsAllList.filter(e => e.sizeId === 2), state, 'medium').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => e.sizeId === 2), state, 'medium').length,
      };

    case USER_DOG_FILTER_BIG_DOG:
      return {
        ...state,
        items: 6,
        sizeFilter: 'big',
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e.sizeId === 3), state, 'big'),
        petsList: filterPets(state.petsAllList.filter(e => e.sizeId === 3), state, 'big').slice(0, 6),
        petsCount: filterPets(state.petsAllList.filter(e => e.sizeId === 3), state, 'big').length,
      };

    case USER_DOG_FILTER_SIZE_ALL:
      return {
        ...state,
        items: 6,
        sizeFilter: null,
        filtered: true,
        filteredPetsList: filterPets(state.petsAllList.filter(e => e), state, 'allSizes'),
        petsList: filterPets(state.petsAllList.filter(e => e), state, 'allSizes').slice(0, 6),
        petsCount: filterPets(state.petsAllList, state, 'allSizes').length,
      };

    case USER_DOG_FETCH_NEXT_ITEMS:
      return {
        ...state,
        petsList: state.filteredPetsList.slice(0, state.items + 6),
        items: state.items > state.petsList.length ? '' : state.items + 6,
      };

    default:
      return state;
  }
}
