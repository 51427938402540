import React, { FC, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import './edit-popup.scss';
import Icon from '../../../../../../../shared-ui/icon';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { ArticleFormatingTypes } from '../../../../../../../../models/blogPage.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

export interface SelectedTextValues {
  selectedText: string;
  selectedTextStart: number;
  selectedTextEnd: number;
}

export interface TextFormattingValues {
  isBold: boolean;
  isItalic: boolean;
  isUppercase: boolean;
  isQuote: boolean;
}

export interface LinkFormattingValues {
  isLink: boolean;
  isLinkInput: boolean;
  linkDeleteBtn: boolean;
  linkValue: string;
  linkInputWidth: string;
}

interface EditPopupProps {
  isTextSelected: boolean;
  editPopupY: number;
  editPopupX: number;
  textSelection: SelectedTextValues;
  textFormatting: TextFormattingValues;
  linkFormatting: LinkFormattingValues;
  handleLinkChange: (e) => void;
  toggleFormatting: (textSelection: SelectedTextValues, formatingType: ArticleFormatingTypes) => void;
  addEditLink: (e, textSelection: SelectedTextValues) => void;
  deleteLink: () => void;
  toggleLinkPopup: (textSelection: SelectedTextValues) => void;
  t: TranslateFn;
}

const EditPopup: FC<EditPopupProps> = ({
  isTextSelected,
  editPopupX,
  editPopupY,
  textSelection: {
    selectedText,
    selectedTextStart,
    selectedTextEnd,
  },
  textFormatting: {
    isBold,
    isItalic,
    isUppercase,
    isQuote,
  },
  linkFormatting: {
    isLink,
    isLinkInput,
    linkValue,
    linkInputWidth,
    linkDeleteBtn,
  },
  handleLinkChange,
  toggleFormatting,
  toggleLinkPopup,
  addEditLink,
  deleteLink,
  t: translate,
}) => (
  <>
    {(isTextSelected && !isLinkInput) && (
    <div
      className="edit__popup"
      style={{ top: `${editPopupY}px`, left: `${editPopupX}px` }}
    >
      <button
        className={`edit__icon ${isBold ? 'edit__icon--active' : ''}`}
        onClick={() => toggleFormatting(
          { selectedText, selectedTextStart, selectedTextEnd },
          ArticleFormatingTypes.BOLD,
        )}
      >
        <Icon name="text-bold" />
      </button>
      <button
        className={`edit__icon ${isItalic ? 'edit__icon--active' : ''}`}
        onClick={() => toggleFormatting(
          { selectedText, selectedTextStart, selectedTextEnd },
          ArticleFormatingTypes.ITALIC,
        )}
      >
        <Icon name="text-italic" />
      </button>
      <button
        className={`edit__icon edit__icon--link ${isLink ? 'edit__icon--active' : ''}`}
        onClick={() => toggleLinkPopup({ selectedText, selectedTextStart, selectedTextEnd })}
      >
        <Icon name="link" />
      </button>
      <button
        className={`edit__icon ${isUppercase ? 'edit__icon--active' : ''}`}
        onClick={() => toggleFormatting(
          { selectedText, selectedTextStart, selectedTextEnd },
          ArticleFormatingTypes.MARK,
        )}
      >
        <Icon name="text-size" />
      </button>
      <button
        className={`edit__icon ${isQuote ? 'edit__icon--active' : ''}`}
        onClick={() => toggleFormatting(
          { selectedText, selectedTextStart, selectedTextEnd },
          ArticleFormatingTypes.BLOCKQUOTE,
        )}
      >
        <Icon name="quote" />
      </button>
    </div>
    )}

    {isLinkInput && (
    <div
      className="link__popup"
      style={{ top: `${editPopupY}px`, left: `${editPopupX}px` }}
    >
      <input
        type="text"
        placeholder={translate(TRANSLATE_KEYS.enterLink) as string}
        className="link__input"
        value={linkValue}
        onChange={handleLinkChange}
        style={{ width: linkInputWidth }}
      />

      {(linkValue && !linkDeleteBtn) && (
        <button
          type="submit"
          className="link__edit link__submit"
          onClick={e => addEditLink(e, { selectedText, selectedTextStart, selectedTextEnd })}
        >
          <Icon name="arrow-next" />
        </button>
      )}

      {linkDeleteBtn && (
      <button
        type="submit"
        className="link__edit link__delete"
        onClick={deleteLink}
      >
        <Icon name="close" />
      </button>
      )}
    </div>
    )}
  </>
);

export default withTranslation('translations')(EditPopup);
