/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-" transform="translate(-540.000000, -1940.000000)">
        <g id="Paragraph" transform="translate(408.000000, 1744.000000)">
          <g id="DropDown" transform="translate(0.000000, 184.000000)">
            <g id="Icon" transform="translate(132.000000, 12.000000)" fill="#373737">
              <path d="M16,4 C16.5128358,4 16.9355072,4.38604019 16.9932723,4.88337887 L17,5 L17,7 C17,7.55228475 16.5522847,8 16,8 C15.4871642,8 15.0644928,7.61395981 15.0067277,7.11662113 L15,7 L15,6 L10,6 L10,18 L11,18 C11.5522847,18 12,18.4477153 12,19 C12,19.5128358 11.6139598,19.9355072 11.1166211,19.9932723 L11,20 L7,20 C6.44771525,20 6,19.5522847 6,19 C6,18.4871642 6.38604019,18.0644928 6.88337887,18.0067277 L7,18 L8,18 L8,6 L3,6 L3,7 C3,7.51283584 2.61395981,7.93550716 2.11662113,7.99327227 L2,8 C1.48716416,8 1.06449284,7.61395981 1.00672773,7.11662113 L1,7 L1,5 C1,4.48716416 1.38604019,4.06449284 1.88337887,4.00672773 L2,4 L16,4 Z M22,11 C22.5522847,11 23,11.4477153 23,12 C23,12.5128358 22.6139598,12.9355072 22.1166211,12.9932723 L22,13 L20,13 L20,19 C20,19.5522847 19.5522847,20 19,20 C18.4871642,20 18.0644928,19.6139598 18.0067277,19.1166211 L18,19 L18,13 L16,13 C15.4477153,13 15,12.5522847 15,12 C15,11.4871642 15.3860402,11.0644928 15.8833789,11.0067277 L16,11 L22,11 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
