/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Ru" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][D]-Create-New-Article-–-Dropdown-–-Edit-category-2" transform="translate(-928.000000, -424.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 112.000000)">
          <g id="Dropdown" transform="translate(32.000000, 264.000000)">
            <g id="Icon" transform="translate(472.000000, 48.000000)" fill="currentColor">
              <path d="M7.61289944,6.20970461 L7.70710678,6.29289322 L12,10.585 L16.2928932,6.29289322 C16.6834175,5.90236893 17.3165825,5.90236893 17.7071068,6.29289322 C18.0675907,6.65337718 18.0953203,7.22060824 17.7902954,7.61289944 L17.7071068,7.70710678 L13.415,12 L17.7071068,16.2928932 C18.0976311,16.6834175 18.0976311,17.3165825 17.7071068,17.7071068 C17.3466228,18.0675907 16.7793918,18.0953203 16.3871006,17.7902954 L16.2928932,17.7071068 L12,13.415 L7.70710678,17.7071068 C7.31658249,18.0976311 6.68341751,18.0976311 6.29289322,17.7071068 C5.93240926,17.3466228 5.90467972,16.7793918 6.20970461,16.3871006 L6.29289322,16.2928932 L10.585,12 L6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 C6.65337718,5.93240926 7.22060824,5.90467972 7.61289944,6.20970461 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
