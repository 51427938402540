import { Cookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router';
import { i18n } from 'i18next';
import { TranslatedText, TranslateFn } from '../../../../models/translate-function.models';
import { ProfileStoreModels } from './+store/profileStore.models';
import { LanguageData } from '../../../../models/language.model';

export const enum InputValues {
  Login = 'login',
  Email = 'email',
  PreferredLanguage = 'preferredLanguage'
}

export namespace ProfileFormModels {
  export interface UpdateRequestCredentials {
    userId: number;
    userData: ProfileStoreModels.UserData;
  }

  export interface Props extends RouteComponentProps {
    userData: ProfileStoreModels.UserData;
    isModalOpen: boolean;
    hideProfileAlert: boolean,
    selectedLanguage: LanguageData;
    cookies: Cookies;
    i18n: i18n;
    t: TranslateFn
    sendUpdateRequest: (creadentials: UpdateRequestCredentials, cookies: Cookies) => void;
    fetchAdminProfileData: (cookies: Cookies) => void;
    openPasswordModal: () => void;
    handleSelectLanguage: (value: string) => void;
  }

  export interface State {
    userId: number;
    imageLink: string;
    selectedImg: string;
    login: string;
    email: string;
    preferredLanguage: string;
    marker: boolean;
    isPopupShown: string;
    isPopupHidden: string;
    errors: Errors;
    disabled: boolean;
    isModalOpen: boolean;
  }

  export interface Errors {
    imageLink: TranslatedText;
    login: TranslatedText;
    email: TranslatedText;
    preferredLanguage: TranslatedText;
  }
}
