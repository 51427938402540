import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import PriceItem from './price-item/PriceItem';
import styles from './priceList.module.scss';
import { ServicesPageModels } from '../../models/servicesPage.models';
import TRANSLATE_KEYS from '../../../../i18n_keys';

function getValues(
    array: ServicesPageModels.MockDataPrice[],
    search: Partial<ServicesPageModels.PriceListProps>,
): ServicesPageModels.MockDataPrice[] {
  return array.filter(item => (
    (item.target === search.target)
    && (item.service === search.service)
    && (item.category === search.category)));
}

const PriceList: FC<ServicesPageModels.PriceListProps> = (props) => {
  const [translate] = useTranslation();
  const { data, ...search } = props;
  const price = getValues(data.price, search);
  const { categoryTitle, category } = price[0];

  return (
    <>
      <div className={styles.row} id={`#${category}`}>
        <div className={styles.service__wrapper}>
          <div className={styles.category}>
            <h3 className={styles.title}>{translate(categoryTitle)}</h3>
          </div>
          <div className={styles.list}>
            {
              price.map(item => (
                <PriceItem {...item} key={item.id} />
              ))
            }
          </div>
        </div>
        <div className={styles.service__wrapper}>
          <div className={styles.category} />
          <div className={styles.list}>
            <p className={styles['discount-info']}>{translate(TRANSLATE_KEYS.discountIsProvidedIndividually)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation('translations')(PriceList);
