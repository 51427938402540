import React, { FC, RefObject } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './promo.module.scss';
import { MAX_HEADER_HEIGHT } from '../../../shared-ui/header/Header';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const Promo: FC<{ tabsRef: RefObject<HTMLDivElement> }> = ({ tabsRef }) => {
  const [translate] = useTranslation();

  const handleGoToTabs = (): void => {
    window.scrollTo({
      top: tabsRef.current.offsetTop - MAX_HEADER_HEIGHT,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.box}>
      <div className={styles.wrapper}>
        <h1 className={styles.heading}>{translate(TRANSLATE_KEYS.services)}</h1>
        <p className={styles.text}>
          {translate(TRANSLATE_KEYS.sinceEachDog)}
        </p>
        <i className={styles.arrow} aria-hidden="true" onClick={handleGoToTabs} />
      </div>
    </div>
  );
};

export default withTranslation('translations')(Promo);
