import { AxiosResponse } from 'axios';
import { History } from 'history';
import { Cookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { WAFModels } from '../../../../../models/WAFPage.models';
import { WAFAction, WAFFetchPetsRecords } from './wantAFriendStore.models';

export namespace WAFActions {
  export type SimpleAction = () => WAFAction;
  export type ErrorHandler = (error: Error) => WAFAction;
  export type DisplayDeleteMessage = (pet: WAFModels.PetToDelete) => WAFAction;

  export type FetchPetSuccess = (pet: WAFModels.Pet) => WAFAction;
  export type FetchPetsRecordsSuccess = (records: WAFFetchPetsRecords) => WAFAction;
  export type FetchAllPetsSuccess = (pets: WAFModels.Pet[], selectedLanguage: string) => WAFAction;
  export type DeletePetSuccess = (id: number) => WAFAction;
  export type HideOrPublishPetSuccess = (pet: WAFModels.Pet, oldId: number) => WAFAction;

  export type FetchPetsRecords = (page: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchPet = (id: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchAllPets = (cookies: Cookies)=> (dispatch: Dispatch) => void;
  export type DeletePet = (
    cookies: Cookies,
    petToDelete: WAFModels.PetToDelete,
    history: History,
  ) => (dispatch: Dispatch) => void;
  export type PublishPet = (id: number, cookies: Cookies) => (dispatch: Dispatch) => Promise<WAFAction | AxiosResponse>;
  export type HidePet = (id: number, cookies: Cookies) => (dispatch: Dispatch) => Promise<WAFAction | AxiosResponse>;

}

export namespace WAFActionTypes {
  export const ADMIN_WAF_FETCH_START = 'ADMIN_WAF_FETCH_START';
  export const ADMIN_WAF_FETCH_END = 'ADMIN_WAF_FETCH_END';
  export const ADMIN_WAF_FETCH_ERROR = 'ADMIN_WAF_FETCH_ERROR';
  export const ADMIN_WAF_FETCH_PETS_RECORDS = 'ADMIN_WAF_FETCH_PETS_RECORDS';
  export const ADMIN_WAF_FETCH_PET = 'ADMIN_WAF_FETCH_PET';
  export const ADMIN_WAF_FETCH_ALL_PETS = 'ADMIN_WAF_FETCH_ALL_PETS';
  export const ADMIN_WAF_DISPLAY_DELETE_MESSAGE = 'ADMIN_WAF_DISPLAY_DELETE_MESSAGE';
  export const ADMIN_WAF_HIDE_DELETE_MESSAGE = 'ADMIN_WAF_HIDE_DELETE_MESSAGE';
  export const ADMIN_WAF_DELETE_PET_SUCCESS = 'ADMIN_WAF_DELETE_PET_SUCCESS';
  export const ADMIN_WAF_HIDE_OR_PUBLICH_PET_SUCCESS = 'ADMIN_WAF_HIDE_OR_PUBLICH_PET_SUCCESS';
  export const CLEAR_PETS = 'CLEAR_PETS';
  export const OPEN_DOG_POPUP_FORM = 'OPEN_DOG_POPUP_FORM';
  export const CLOSE_DOG_POPUP_FORM = 'CLOSE_DOG_POPUP_FORM';
  export const DOG_CREATED_SUCCESSFULLY = 'DOG_CREATED_SUCCESSFULLY';
  export const DOG_UPDATED_SUCCESSFULLY = 'DOG_UPDATED_SUCCESSFULLY';
}
