import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { formatDate } from '../../../../../../../../utils/formatDate';
import styles from './story.module.scss';

interface StoryProps {
  article: BlogPageModels.Article;
  articleTitle: string;
  articleCategory: string;
  t: TranslateFn;
}

const Story: FC<StoryProps> = ({
  article,
  articleTitle,
  articleCategory,
  t: translate,
}) => {
  const background = { backgroundImage: `url(${article.mainImgLink})` };
  return (
    <div className={styles.box} style={background}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p className={styles.category}>
            {articleCategory}
          </p>
          <h1 className={styles.heading}>
            {articleTitle}
          </h1>
          <p className={styles.date}>
            {translate(TRANSLATE_KEYS.added)}{` ${formatDate(article.date)}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Story);
