/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#373737"
      fillRule="evenodd"
      d="M11.5,16 C12.3284271,16 13,16.6715729 13,17.5 C13,18.3284271 12.3284271,19 11.5,19 C10.6715729,19
      10,18.3284271 10,17.5 C10,16.6715729 10.6715729,16 11.5,16 Z M11.5,10 C12.3284271,10 13,10.6715729
      13,11.5 C13,12.3284271 12.3284271,13 11.5,13 C10.6715729,13 10,12.3284271 10,11.5 C10,10.6715729
      10.6715729,10 11.5,10 Z M11.5,4 C12.3284271,4 13,4.67157288 13,5.5 C13,6.32842712 12.3284271,7 11.5,7
      C10.6715729,7 10,6.32842712 10,5.5 C10,4.67157288 10.6715729,4 11.5,4 Z"
    />
  </svg>
);

export default SVG;
