import { REQUEST_QUESTIONS_BEGIN,
  REQUEST_QUESTIONS_SUCCESS,
  REQUEST_QUESTIONS_FAILURE,
  CLEAR_QUESTIONS } from './types';

export const initialState = {
  items: [],
  loading: false,
  error: null,
};

export default function questionReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case REQUEST_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case REQUEST_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        items: [],
      };

    case CLEAR_QUESTIONS:
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
}
