/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useRef, useEffect, FC, MouseEvent, ChangeEvent } from 'react';
import { withTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { TranslatedText, TranslateFn } from '../../../../../../../../models/translate-function.models';
import Icon from '../../../../../../../shared-ui/icon';
import TooltipText from '../../../../../../../shared-ui/tooltip-text/tooltipText';
import style from './styles/article-category-select.module.scss';

const categoryNameMaxLength = 23;

interface ArticleCategoryInputProps {
  className: string;
  value: string;
  content: TranslatedText,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputSubmit: (e: MouseEvent) => void;
  t: TranslateFn;
}

const ArticleCategoryInput: FC<ArticleCategoryInputProps> = ({
  className,
  content,
  value,
  onInputSubmit,
  onChange,
  t: translate,
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef.current]);

  return (
    <li className={style['create-category']}>
      <TooltipText
        content={content}
        propModificator="ADD"
      />
      <input
        type="text"
        maxLength={categoryNameMaxLength}
        className={className}
        placeholder={`${translate(TRANSLATE_KEYS.createCategory)}`}
        onChange={onChange}
        ref={inputRef}
        value={value}
      />
      <button onClick={onInputSubmit}>
        <Icon name="add" />
      </button>
    </li>
  );
};

export default withTranslation('translations')(ArticleCategoryInput);
