import React from 'react';

/* eslint-disable max-len */
const SVG = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C4.13576 0 1.00318 3.13401 1.00318 7V11L0.403448 11.8C-0.584958 13.1185 0.355379 15 2.00272 15H13.9973C15.6446 15 16.585 13.1185 15.5966 11.8L14.9968 11V7C14.9968 3.13401 11.8642 0 8 0Z"
      fill="#797979"
    />
    <path d="M8 18C6.89543 18 6 17.1046 6 16H10C10 17.1046 9.10457 18 8 18Z" fill="#797979" />
  </svg>
);

export default SVG;
