import { WAFPopupStoreModels }
  from '../app/admin/components/wantAFriendPage/components/popupForm/+store/WAFPopupFormStore.models';
import { WAFModels } from '../models/WAFPage.models';

const buildDogPhotoList = (
  photos: WAFPopupStoreModels.DogPhoto[] | string[],
  oldDogPhotos: WAFModels.DogPhoto[] = [],
) => {
  const result = [];
  photos.forEach((photo, index) => {
    if (!photo.photo) return;
    result.push({
      id: oldDogPhotos[index]?.id || null,
      main: photo.main,
      photo: photo.photo,
    });
  });

  return result;
};

export default buildDogPhotoList;
