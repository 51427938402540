import React from 'react';

import { ArticleContentModels } from './article-content.models';

import Image from './components/image/Image';
import Paragraph from './components/paragraph/Paragraph';
import Quote from './components/quote/Quote';

const ArticleContentFactory = ({ type, content }: ArticleContentModels.FactoryProps): JSX.Element => {
  switch (type) {
    case 1: {
      return (<Paragraph content={content} />);
    }
    case 3: {
      return (<Image content={content} />);
    }
    case 2: {
      return (<Quote content={content} />);
    }
    default:
      return (null);
  }
}


export default ArticleContentFactory;
