import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '../shared-ui/slider';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';
import Articles from './components/articles';
import AboutUs from './components/about';
import Promo from './components/promo';
import Services from './components/services';
import WantAFriend from './components/wantAFriend';
import { HomeComponentProps } from './models/HomeComponentProps.model';

const HomeComponent = (props: HomeComponentProps) => {
    const { fetchArticles, articles, dogs, fetchDogs, history } = props;
    const { i18n } = useTranslation();
    const tabsRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        fetchArticles();
        fetchDogs();
    }, [i18n.language]);

    return (
      <>
        <Promo tabsRef={tabsRef} />
        <div ref={tabsRef}>
          <AboutUs />
        </div>
        <Services />
        <Slider />
        <WantAFriend dogs={dogs} history={history} />
        <Articles articles={articles} />
        <SignupForNewsContainer />
      </>
    );
};

export default HomeComponent;
