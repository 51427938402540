import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HomeComponent from './HomeComponent';
import { fetchArticles, fetchDogs } from './+store/actions';
import { HomeComponentProps } from './models/HomeComponentProps.model';
import { DispatchFn } from '../../types/dispatch.type';

const mapStateToProps = (state: { homeReducer: HomeComponentProps }) => (state.homeReducer);
const mapDispatchToProps = (dispatch: DispatchFn) => ({
  fetchArticles: () => fetchArticles()(dispatch),
  fetchDogs: () => fetchDogs()(dispatch),
});

const HomeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeComponent));

export default HomeContainer;
