import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { AdminBlogActions } from '../../+store/actions';
import styles from '../articleList/articleList.module.scss';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

interface PublishButtonProps {
  id: number;
  cookies: Cookies;
  onPublishArticle: (id: number, cookies: Cookies) => void;
  t: TranslateFn;
}

const PublishButton: FC<PublishButtonProps> = ({
  id,
  cookies,
  onPublishArticle,
  t: translate,
}) => {
  const onPublishArticleHandler = (e: MouseEvent) => {
    e.preventDefault();
    onPublishArticle(id, cookies);
  };

  return (
    <button
      className={styles['article-button--publish']}
      onClick={e => onPublishArticleHandler(e)}
    >
      {translate(TRANSLATE_KEYS.publishBtn)}
    </button>
  );
};

const mapDispatchToProps = dispatch => ({
  onPublishArticle: (
    id: number,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.publishArticle(id, cookies)),
});

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(withCookies(PublishButton)),
);
