import React, { Component, FormEvent } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import styles from './authentication.module.scss';
import Input from '../../../shared-ui/input';
import Button from '../../../shared-ui/button';
import { AuthPageActions } from './+store/actions';
import Toast from '../../../shared-ui/toast';
import { AuthComponentModels } from './auth.models';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { ButtonTypes } from '../../../shared-ui/button/button.models';

class AdminAuthentication extends Component<AuthComponentModels.Props, AuthComponentModels.State> {
  constructor(props: AuthComponentModels.Props) {
    super(props);
    this.state = { login: '', password: '', disabled: true, isAutorized: false };
  }

  componentDidMount() {
    const { cookies } = this.props;

    if (cookies.get('token')) this.setState({ isAutorized: true })
  }

  toggleSubmitDisable = (login: string, password: string): void => {
    this.setState({ disabled: !login || !password });
  };

  handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { login, password } = this.state;
    const { history, cookies, sendLoginRequestFn, t: translate } = this.props;
    const errorMessages: AuthComponentModels.ErrorMessages = {
      fourHundredOne: translate(TRANSLATE_KEYS.loginErrorMessage),
      other: translate(TRANSLATE_KEYS.somethingWentWrong),
    };
    sendLoginRequestFn({ login, password }, cookies, history, errorMessages);
  };

  handleLoginChange = (value: string): void => {
    const { handleInputsValidationError } = this.props;
    handleInputsValidationError('');
    const { password } = this.state;
    this.setState({ login: value });
    this.toggleSubmitDisable(value, password);
  };

  handlePasswordChange = (value: string): void => {
    const { handleInputsValidationError } = this.props;
    handleInputsValidationError('');
    const { login } = this.state;
    this.setState({ password: value });
    this.toggleSubmitDisable(login, value);
  };

  render() {
    const { loginError, t: translate } = this.props;
    const { disabled, isAutorized } = this.state;

    return isAutorized
      ? <Redirect to="admin/blog" />
      : (
        <main>
          <Toast
            iconName="exclamation-mark"
            text={translate(TRANSLATE_KEYS.accessErrorMessage)}
            start={loginError === translate(TRANSLATE_KEYS.somethingWentWrong)}
          />

          <div className={`${styles['form-container']} ${loginError && styles['form-error']}`}>
            <h1 className={styles.header}>DogHotel</h1>
            <h3 className={styles.subheader}>{translate('welcomeToAdminPage')}</h3>
            <form onSubmit={this.handleSubmit}>
              <Input
                id="name"
                placeholder={translate(TRANSLATE_KEYS.labelName)}
                label={translate(TRANSLATE_KEYS.labelName)}
                onChange={this.handleLoginChange}
                uiType="grayMarginTop"
              />
              <Input
                htmlType="password"
                id="password"
                placeholder={translate(TRANSLATE_KEYS.password)}
                label={translate(TRANSLATE_KEYS.password)}
                onChange={this.handlePasswordChange}
                uiType="grayMarginTop"
              />
              <p className={styles.message}>{ loginError || '' }</p>
              <div className={styles['button-wrapper']}>
                <Button
                  small
                  content={translate(TRANSLATE_KEYS.signIn)}
                  uiType={disabled ? 'sun-disabled' : 'sunMainBold'}
                  htmlType={ButtonTypes.Submit}
                  disabled={disabled}
                />
              </div>
            </form>
          </div>
        </main>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  sendLoginRequestFn: (credentials, cookies, history, errorMessages) => dispatch(
    AuthPageActions.adminLoginRequest(credentials, cookies, history, errorMessages),
  ),
  handleInputsValidationError: error => dispatch(AuthPageActions.adminLoginFailure(error)),
});

const mapStateToProps = state => ({
  hasLoggedIn: state.hasLoggedIn,
  loginError: state.adminLoginReducer.loginError,
  isAccessError: state.adminLoginReducer.isAccessError,
});

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(withCookies(AdminAuthentication)));
