import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import Icon from '../../../shared-ui/icon';
import styles from './header.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';

import HeaderContent from './components/headerContent/HeaderContent';

const WAF_LIST_HEADER_HEIGHT = 92;
const BLOG_LIST_HEADER_HEIGHT = 72;

const Header: FC = () => {
  const { t: translate } = useTranslation();
  const [locationPath, setLocationPath] = useState('');
  const adminPaths = ['/admin', '/admin/'];
  const wantAFriendPaths = ['/admin/want-a-friend', '/admin/want-a-friend/'];

  const useReactPath = () => {
    const [path, setPath] = useState(window.location.pathname);
    const listenToPathState = () => {
      const windowPath = window.location.pathname;
      setPath(windowPath);
    };

    useEffect(() => {
      listenToPathState();
    });

    return path;
  };

  const path = useReactPath();
  useEffect(() => {
    setLocationPath(path);
  }, [path]);

  return adminPaths.includes(path) ? (
    <header className={`${styles.box} ${styles['without-border']}`}>
      <div className={styles.wrapper}>
        <Link to="/home" className={styles['back-link']}>
          <Icon name="back" />
          {translate(TRANSLATE_KEYS.toTheMain)}
        </Link>
      </div>
    </header>
  ) : (
    <header className={`${styles.box} ${styles.box_collapse}`}>
      <HeaderContent
        locationPath={locationPath}
        collapseY={wantAFriendPaths.includes(path) ? WAF_LIST_HEADER_HEIGHT : BLOG_LIST_HEADER_HEIGHT}
      />
    </header>
  );
}

export default withTranslation('translations')(Header);
