import { LanguageData } from './language.model';

export namespace WAFModels {
  export interface Pet {
    id: number;
    birthDate: string;
    breedId: number;
    dogBreedValueDtoSet: DogDto[];
    dogDescriptionDtoSet: DogDto[];
    dogNameDtoSet: DogDto[];
    dogPhotoDtoList: DogPhoto[];
    sizeId: number;
    dogSizeValueDtoSet: DogDto[];
    dogStatus: string;
    hasGuardianship: boolean;
    male: boolean;
    sterilized: boolean;
    parentDogId: number;
    snapshotDogId: number;
    dateCreated: string;
    name?: string;
    sizeValue?: string;
    description?: string;
  }

  export interface DogDto {
    id: number;
    breedName?: string;
    description?: string;
    name?: string;
    sizeValue?: string;
    language: LanguageData;
  }

  export interface DogPhoto {
    id: number;
    main: boolean;
    photo: string;
  }

  export interface PetToDelete {
    id: number;
    deletePetId: number;
    petName: string;
  }

  export interface PetInfo {
    ua: string;
    en: string;
  }

  export const enum PetPublishStatuses {
    PUBLISHED = 'PUBLISHED',
    NOT_PUBLISHED = 'NOT_PUBLISHED'
  }
}
