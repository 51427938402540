import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ArticleButtons from './articleButtons';
import styles from './articleList.module.scss';
import getCategoryValueByLanguage from '../../../../../../utils/getCategoryValueByLanguage';
import { ArticleStatusIds, BlogPageModels } from '../../../../../../models/blogPage.models';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

interface ArticleItemProps {
  isMain: boolean;
  title: string;
  image: string;
  statusId: ArticleStatusIds;
  id: number;
  parentArticleId: number,
  categoryValueDtoSet: BlogPageModels.ArticleData[];
  language: string;
  t: TranslateFn;
}

const ArticleItem: FC<ArticleItemProps> = ({
  id,
  isMain,
  parentArticleId,
  statusId,
  title,
  categoryValueDtoSet,
  image,
  language,
  t: translate,
}) => {
  const category = getCategoryValueByLanguage(categoryValueDtoSet, language);
  const [isMouseOnArticleListButtons, setIsMouseOnArticleListButtons] = useState(false);

  return (
    <Link
      to={`/admin/blog/edit/article/${id}`}
      className={
        isMobile ? `${styles.article} ${styles.articleMobile}` : styles.article
      }
    >
      <article className={styles['article-item']}>
        <div className={styles['article-image-container']}>
          {isMain && (
            <span className={styles['article-main-label']}>{translate(TRANSLATE_KEYS.mainPage)}</span>
          )}
          <img alt={title} src={image} className={styles['article-image']} />
        </div>
        <div className={styles['article-head-wrap']}>
          <h2
            className={`${styles['article-head']} ${isMouseOnArticleListButtons ? styles['article-head-hover'] : ''}`}
          >
            {title}
          </h2>
          <h3 className={styles['article-category']}>{category}</h3>
        </div>
        <span
          onMouseOver={() => (setIsMouseOnArticleListButtons(true))}
          onMouseLeave={() => (setIsMouseOnArticleListButtons(false))}
        >
          <ArticleButtons statusId={statusId} id={id} title={title} parentArticleId={parentArticleId} />
        </span>
      </article>
    </Link>
  );
};

const mapStateToProps = state => ({ language: state.languageReducer.selectedLanguage.languageValue });

export default withTranslation('translations')(connect(mapStateToProps)(ArticleItem));
