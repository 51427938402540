import React from 'react';
import { PropTypes } from 'prop-types';
import styles from '../image-uploader.module.scss';

const ImageProgress = ({ isInProgress, selectedImg, imgProgressInKb, imgTotalInMb, progress }) => (
  <div className={styles['image-progress-container']}>
    <div className={styles['image-progressbar-info']}>
      <div className={styles['image-progressbar-name']}>
        {isInProgress ? `Загрузка изображения ${selectedImg.name}` : null}
      </div>
      <div className={styles['image-progressbar-status']}>
        {`${imgProgressInKb} kB / ${imgTotalInMb} MB`}
      </div>
    </div>

    <div className={styles['image-progressbar']}>
      <div
        className={styles['image-progress']}
        style={{ width: `${progress}%` }}
      />
    </div>
  </div>
);

ImageProgress.propTypes = {
  isInProgress: PropTypes.bool.isRequired,
  selectedImg: PropTypes.shape({ name: PropTypes.string.isRequired }),
  imgProgressInKb: PropTypes.number.isRequired,
  imgTotalInMb: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

ImageProgress.defaultProps = { selectedImg: null };

export default ImageProgress;
