import React, { FC } from 'react';
import SettingsButton from '../articleButtons/settingsButton';
import DisabledSettingsButton from '../articleButtons/disabledSettingsButton';
import DeleteButton from '../articleButtons/deleteButton';
import PublishButton from '../articleButtons/publishButton';
import HideButton from '../articleButtons/hideButton';
import UpdateButton from '../articleButtons/updateButton';
import styles from './articleList.module.scss';
import { ArticleStatusIds } from '../../../../../../models/blogPage.models';

interface ArticleButtonsProps {
  statusId: ArticleStatusIds;
  id: number;
  title: string;
  parentArticleId: number;
}

const ArticleButtons: FC<ArticleButtonsProps> = ({
  statusId,
  id,
  title,
  parentArticleId,
}) => (
  <div className={styles['article-button-wrapper']}>
    <DeleteButton id={id} title={title} parentArticleId={parentArticleId} />
    {statusId === ArticleStatusIds.HAS_CHANGES ? <DisabledSettingsButton /> : <SettingsButton id={id} />}
    {statusId === ArticleStatusIds.PUBLISHED && <HideButton id={id} />}
    {statusId === ArticleStatusIds.HIDDEN && <PublishButton id={id} />}
    {statusId === ArticleStatusIds.HAS_CHANGES && <UpdateButton id={id} />}
  </div>
);

export default ArticleButtons;
