import { SignUpForNewsActionTypes } from './signUpForNewsActions.models';
import { Action } from '../../../../types/action.interface';

interface State {
  email: string,
  loading: boolean,
  displayAlert: boolean,
}

const initialState: State = {
  email: '',
  loading: false,
  displayAlert: false,
};

export default function emailReducer(state: State = initialState, actions: Action<string>): State {
  switch (actions.type) {
    case SignUpForNewsActionTypes.GetInputValue:
      return {
        ...state,
        email: actions.payload as string,
      };
    case SignUpForNewsActionTypes.SendEmailStart:
      return {
        ...state,
        loading: true,
      };
    case SignUpForNewsActionTypes.SendEmailError:
      return {
        ...state,
        loading: false,
      };
    case SignUpForNewsActionTypes.SendEmailSuccess:
      return {
        ...state,
        loading: false,
        email: actions.payload as string,
        displayAlert: true,
      };
    case SignUpForNewsActionTypes.DisplayAlert:
      return {
        ...state,
        displayAlert: false,
      };
    default:
      return state;
  }
}
