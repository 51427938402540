import React, { useState, useEffect, useRef, FC } from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';

import TRANSLATE_KEYS from '../../../../i18n_keys';

import styles from './subheader.module.scss';

const isTouchDevice: boolean = /Mobi/i.test(window.navigator.userAgent);

const SubHeader: FC = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const navRef: React.MutableRefObject<HTMLElement> = useRef(null);
  const updateDimensions = (): void => setWidth(window.innerWidth);
  const [translate] = useTranslation();
  const isMobile: boolean = width < 340;

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
  });

  const onTouchHandler = (event: TouchEvent): void => {
    if (navRef.current && !navRef?.current?.contains(event?.target as HTMLElement)) {
      navRef.current.classList.remove(styles.navigationHover);
    } else {
      navRef.current.classList.add(styles.navigationHover);
    }
  }

  // Safari hover bug fix
  useEffect(() => {
    if (!isTouchDevice) return;

    document.addEventListener('touchstart', onTouchHandler);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('touchstart', onTouchHandler);
  }, []);

  return (
    <div className={styles.box}>
      <nav className={`${styles.navigation} ${styles.navigationHover}`} ref={navRef}>
        <NavLink to="/about/photo" className={styles.item} activeClassName={styles.active}>
          {translate(TRANSLATE_KEYS.photoGallery)}
        </NavLink>
        <NavLink to="/about/feedback" className={styles.item} activeClassName={styles.active}>
          {translate(TRANSLATE_KEYS.feedbacks)}
        </NavLink>
        <NavLink to="/about/ask" className={styles.item} activeClassName={styles.active}>
          {isMobile ? `${translate(TRANSLATE_KEYS.askAQuestionShort)}` : `${translate(TRANSLATE_KEYS.askAQuestion)}`}
        </NavLink>
      </nav>
    </div>
  );
};

export default withTranslation('translations')(SubHeader);
