import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { ArticelsListModels } from './articles-list.models';

import styles from './ArticlesList.module.scss';

const ArticlesListItem: FC<ArticelsListModels.ItemProps> = ({ id, image, title, category, from, isArticlePage }) => (
  <div className={`${styles.article} ${isArticlePage ? styles.article__articlePage : ''}`}>
    <Link
      to={{
        pathname: `/article/${id}`,
        state: { from },
      }}
    >
      <img src={image} alt="animal" className={`${styles['article__bg-image']}`} />
      <div className={styles['article__info-block']}>
        <h2 className={styles['article__card-title']}>{title}</h2>
        <span className={styles.category}>{category}</span>
      </div>
    </Link>
  </div>
);

export default memo(ArticlesListItem);
