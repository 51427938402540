import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import DogImageDropZone from './dogImageDropZone';
import { cloudDeleteFile } from '../../../../../../../../vendors/cloudApi';
import styles from './image-uploader.module.scss';
import DogImageProgress from './dogImageProgress';
import DogImagePreview from './dogImagePreview';
import DogImageSmallPreview from './dogImageSmallPreview';
import Icon from '../../../../../../../shared-ui/icon';
import { AdminWAFPopupActions } from '../../+store/actions';
import buildUpdatedImageList from '../../../../../../../../utils/buildUpdatedImageList';
import buildImagesListWithNewMainPhoto from '../../../../../../../../utils/buildImagesListWithNewMainPhoto';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { DogImageUploaderModels } from './imageUploader.models';
import { WAFPopupStoreModels } from '../../+store/WAFPopupFormStore.models';

import parseImageFilenameFromURL from '../../../../../../../../utils/parseImageFilenameFromURL';

const DogImageUploader = ({
  dogPhotos,
  onImgSelected,
  deleteDogPhoto,
  updateDogPhotos,
  t: translate,
}: DogImageUploaderModels.Props) => {
  const [src, setSrc] = useState<string | ArrayBuffer>('');
  const [selectedImg, setSelectedImg] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [photosList, setPhotosList] = useState(dogPhotos);
  const [currentClickedPhotoId, setCurrentClickedPhotoId] = useState(0);
  const [progressKB, setProgressKB] = useState(0);
  const [imgSize, setImgSize] = useState(0);

  const fileInput = useRef<HTMLInputElement>();

  useEffect(() => {
    setPhotosList(dogPhotos);
    setSrc(dogPhotos.filter(element => element.main === true).map(element => element.photo).join());
    setSelectedImg(dogPhotos.filter(element => element.main === true).map(element => ({ name: element.photo }))[0]);
  }, [dogPhotos]);

  const updateImageSource = () => {
    const updatedData = { selectedImg, isUploading, loadingProgress, source: src };
    const updatedImageList = buildUpdatedImageList(photosList, updatedData, currentClickedPhotoId);

    setPhotosList(updatedImageList);
  };

  const handleUpdateProgress = (event) => {
    const img = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(img);

    fileReader.onloadstart = (e) => {
      setProgressKB(e.loaded);
      setLoadingProgress(40);
      setImgSize(e.total);
      setIsUploading(true);
    };

    fileReader.onprogress = (e) => {
      if (e.lengthComputable) {
        const loadProgressPercent = (e.loaded / e.total) * 100;
        setLoadingProgress(loadProgressPercent);
        setProgressKB(e.loaded);
        setIsUploading(true);
      }
    };

    fileReader.onload = () => {
      setSrc(fileReader.result);
      updateImageSource();
    };

    fileReader.onloadend = (e) => {
      setLoadingProgress(100);
      setProgressKB(e.loaded);
      setIsUploading(false);
    };
    updateImageSource();
  };

  const handleSelectedImg = (event) => {
    handleUpdateProgress(event);

    const emptyMainImage = photosList.find((image) => image.main && !image.photo);
    const updatedData = {
      selectedImg: event.target.files[0],
      src: URL.createObjectURL(event.target.files[0]),
      imgSize: event.total,
    };

    if (emptyMainImage) {
      const imagesListWithNewMainPhoto = buildImagesListWithNewMainPhoto(photosList, currentClickedPhotoId);

      updateDogPhotos(imagesListWithNewMainPhoto);
    }

    setSelectedImg(updatedData.selectedImg);
    setSrc(updatedData.src);
    setImgSize(updatedData.imgSize);

    onImgSelected(event, currentClickedPhotoId);
    fileInput.current.value = '';
  };

  const handleRemoveImg = (event) => {
    event.preventDefault();

    const photoUrl = photosList[currentClickedPhotoId].photo;
    const photoName = parseImageFilenameFromURL(photoUrl);
    const updatedData = {
      photo: '',
      source: '',
      selectedImg: null,
      isUploading: false,
      progress: 0,
    };

    cloudDeleteFile(photoName, () => {
      deleteDogPhoto(currentClickedPhotoId);
    });

    const updatedImageList = buildUpdatedImageList(photosList, updatedData, currentClickedPhotoId);
    const imagesListWithNewMainPhoto = buildImagesListWithNewMainPhoto(updatedImageList, currentClickedPhotoId);
    const isClickedPhotoMain = photosList.find((image) => image.id === currentClickedPhotoId).main;
    const updatedDogPhotos = isClickedPhotoMain ? imagesListWithNewMainPhoto : updatedImageList;

    updateDogPhotos(updatedDogPhotos);
  };

  const handleDropImg = (e) => {
    handleUpdateProgress(e);
    setSelectedImg(e.dataTransfer.files[0]);
    setSrc(URL.createObjectURL(e.dataTransfer.files[0]));

    onImgSelected(e, currentClickedPhotoId);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fileInput.current.click();
  };

  const handleChangeCarouselImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const clickedPhotoId = Number(e.currentTarget.id);

    const isSomeImageIsLoading = photosList.find((image) => !image.photo && image.source);
    if (isSomeImageIsLoading) return;
    setSrc('');
    setSelectedImg(null);
    setIsUploading(false);
    setLoadingProgress(0);

    const newImagesList = photosList.map(photo => {
      if (photo.id === clickedPhotoId) {
        return {
          ...photo,
          activeImage: true,
        }
      }

      return {
        ...photo,
        activeImage: false,
      }
    });

    setCurrentClickedPhotoId(clickedPhotoId);
    setSrc(photosList[Number(e.currentTarget.id)].source);
    setSelectedImg(photosList[Number(e.currentTarget.id)].selectedImg);
    updateDogPhotos(newImagesList);
  };

  const handleMainImageChange = () => {
    const imagesListWithNewMainPhoto = buildImagesListWithNewMainPhoto(photosList, currentClickedPhotoId);

    updateDogPhotos(imagesListWithNewMainPhoto);
  };

  let imgLoaded = null;
  if (selectedImg !== null) {
    if (selectedImg.name) imgLoaded = selectedImg;
  }
  const imgProgressInKb = isUploading && imgLoaded
    ? Math.ceil((imgSize * 0.4) / 1024)
    : (progressKB / 1024).toFixed(0);
  const imgTotalInKb = (imgSize / 1024).toFixed(0);
  const currentPhotoForPreview = photosList[currentClickedPhotoId];

  const renderPhotoList = useMemo(() => photosList.map(photo => (
    <div
      className={
        photo.id === currentClickedPhotoId ? `${styles['image-dropzonegold']}` : `${styles['image-dropzone']}`
      }
      role="button"
      tabIndex={0}
      id={`${photo.id}`}
      key={photo.id}
      onClick={handleChangeCarouselImage}
    >
      {photo.main && photo.photo && (
        <div className={styles.star}>
          <Icon name="star-solid" />
          <span className={styles['tooltip-text-main-small-photo']}>
            {translate(TRANSLATE_KEYS.mainPhoto)}
          </span>
        </div>
      )}
      {photo.photo
        ? (
          <DogImageSmallPreview
            dogPhotoLink={photo.photo}
            key={photo.id}
          />
        ) : <Icon name="image-upload" />
      }
    </div>
  )), [photosList]);

  return (
    <div className={styles['image-uploader']}>
      <input
        type="file"
        name="articleFormImg"
        id="articleFormImg"
        onChange={handleSelectedImg}
        ref={(input) => {
          fileInput.current = input;
        }}
        hidden
        accept="image/*"
      />

      {!currentPhotoForPreview?.photo && (
        <DogImageDropZone
          handleDrop={handleDropImg}
          handleImageChange={handleImageChange}
        />
      )}
      {isUploading && (
        <DogImageProgress
          imgProgressInKb={+imgProgressInKb}
          imgTotalInKb={imgTotalInKb}
        />
      )}
      {currentPhotoForPreview?.photo && (
        <DogImagePreview
          dogPhotoLink={photosList[currentClickedPhotoId].photo}
          isClickedPhotoMain={photosList[currentClickedPhotoId].main}
          t={translate}
          handleImageChange={handleImageChange}
          handleRemoveImg={handleRemoveImg}
          handleMainImageChange={handleMainImageChange}
        />
      )}
      <div className={styles.carousel}>
        {renderPhotoList}
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  deleteDogPhoto: (index: number) => dispatch(AdminWAFPopupActions.dogPhotoDelete(index)),
  updateDogPhotos: (
    dogPhotos: WAFPopupStoreModels.DogPhoto[],
  ) => dispatch(AdminWAFPopupActions.dogPhotosChange(dogPhotos)),
});

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(withCookies(DogImageUploader)),
);
