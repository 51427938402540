import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import Button from '../button';
import styles from './main-article.module.scss';
import { MAX_HEADER_HEIGHT } from '../header/Header';

const MainArticle = (props) => {
  const { t } = useTranslation();
  const { tabsRef, article: { id, image, category, title, thesis } = {} } = props;
  const handleGoToTabs = () => {
    window.scrollTo({
      top: tabsRef.current.offsetTop - MAX_HEADER_HEIGHT,
      behavior: 'smooth',
    });
  };
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${image})` }}
      key={title}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p className={styles.category}>{category}</p>
          <h1 className={styles.heading}>{title}</h1>
          <LinesEllipsis
            className={styles.text}
            text={thesis || ''}
            maxLine={window.innerWidth < 600 ? 3 : 2}
            style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          />
          <div className={styles.button}>
            <Button content={t('moreDetails')} uiType="sunMain" href={`/article/${id}`} />
          </div>
          <i className={styles.arrow} aria-hidden="true" onClick={handleGoToTabs} />
        </div>
      </div>
    </div>
  );
};

MainArticle.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    category: PropTypes.string,
    title: PropTypes.string,
    thesis: PropTypes.string,
  }).isRequired,
  tabsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default withTranslation('translations')(MainArticle);
