import React from 'react';
import PropTypes from 'prop-types';
import styles from './Slide.module.scss';

const Slide = ({ review, image, name, info }) => (
  <div className={styles.review}>
    <div className={styles.review__text}>
      {review}
    </div>

    <div className={styles['owner-info']}>
      <img
        className={styles['owner-info__image']}
        src={image}
        alt="Person"
      />
      <div>
        <div className={styles['owner-info__name']}>{name}</div>
        <div className={styles['owner-info__description']}>{info}</div>
      </div>

    </div>

  </div>
);

Slide.propTypes = {
  review: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default Slide;
