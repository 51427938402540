/* export const URL_BLOG_ARTICLES = 'http://localhost:5505/doghotel-api/blogs/secondary-articles/';
export const URL_BLOG_CATEGORIES = 'http://localhost:5505/doghotel-api/blogs/categories';
export const URL_BLOG_ARTICLES_BY_CATEGORY = 'http://localhost:5505/doghotel-api/blogs/secondary-articles/';
export const URL_BLOG_MAIN_ARTICLE = 'http://localhost:5505/doghotel-api/blogs/main-articles';
export const URL_OPEN_ARTICLE = 'http://localhost:5505/doghotel-api/blogs/articles/';
export const URL_RECOMENDED_ARTICLE = 'http://localhost:5505/doghotel-api/blogs/'; */

const URL = process.env.REACT_APP_URL;

const BLOG_ARTICLES = '/doghotel-api/blogs/secondary-articles/';
const BLOG_CATEGORIES = '/doghotel-api/blogs/categories';
const BLOG_CREATE_CATEGORY = '/doghotel-api/admin/articles/categories/add';
const BLOG_UPDATE_CATEGORY = '/doghotel-api/admin/articles/categories';
const BLOG_ARTICLES_BY_CATEGORY = '/doghotel-api/blogs/secondary-articles';
const BLOG_MAIN_ARTICLE = '/doghotel-api/blogs/main-articles';
const BLOG_USER_SUBSCRIBE = '/doghotel-api/send-message';
const SEND_EMAIL_SEND_PULSE = '/doghotel-api/add-email';
const OPEN_ARTICLE = '/doghotel-api/blogs/articles/';
const RECOMMENDED_ARTICLE = '/doghotel-api/blogs/';
const ADMIN_CREATE_ARTICLE = '/doghotel-api/admin/articles/article';
const ADMIN_PUBLISH_ARTICLE = '/doghotel-api/admin/articles/publish/';
const ADMIN_UPDATE_ARTICLE = '/doghotel-api/admin/article/update/';
const ADMIN_HIDE_ARTICLE = '/doghotel-api/admin/articles/hide/';
const ADMIN_ARTICLE_SNAPSHOT = '/doghotel-api/admin/articles/snapshot';
const ADMIN_ARTICLES = '/doghotel-api/admin/articles/article/';
const ADMIN_ALL_ARTICLES_TITLES = '/doghotel-api/admin/articles/titles';
const ADMIN_SECONDARY_ARTICLES = '/doghotel-api/blogs/secondary-articles/';
const ADMIN_MAIN_ARTICLE = '/doghotel-api/admin/articles/article/main';
const ADMIN_LOGIN = '/doghotel-api/login';
const ADMIN_CATEGORIES = '/doghotel-api/admin/articles/categories/';
const ADMIN_UPLOAD_IMAGE = '/doghotel-api/admin/articles/image/upload';
const CLOUDINARY_IMAGE_UPLOAD = '/doghotel-api/admin/cloudinary/upload';
const ADMIN_FETCH_PROFILE_DATA = '/doghotel-api/admin/profile/login=';
const ADMIN_UPDATE_PROFILE = '/doghotel-api/admin/profile/update';
const ADMIN_WANT_A_FRIEND_CREATE = '/doghotel-api/admin/want-a-friend/dog';
const ADMIN_WANT_A_FRIEND_UPDATE = '/doghotel-api/admin/want-a-friend/dog';
const ADMIN_WANT_A_FRIEND_GET_ALL = '/doghotel-api/admin/want-a-friend/';
const ADMIN_PETS = '/doghotel-api/admin/want-a-friend/dog/';
const ADMIN_ALL_PETS = '/doghotel-api/admin/want-a-friend/dogs/';
const ADMIN_DELETE_PET = '/doghotel-api/admin/want-a-friend/dog/';
const ADMIN_HIDE_PET = '/doghotel-api/admin/want-a-friend/hide/';
const ADMIN_PUBLISH_PET = '/doghotel-api/admin/want-a-friend/publish/';
const ABOUT_ASK = '/doghotel-api/about/ask';
const USER_WANT_A_FRIEND_GET_ALL = '/doghotel-api/want-a-friend/';
const USER_WANT_A_FRIEND_GET_ONE = '/doghotel-api/want-a-friend/dog/';
const USER_WANT_A_FRIEND_DOGS = '/doghotel-api/want-a-friend/dogs';
const USER_WANT_A_FRIEND_SEND_NOTIFICATION = '/doghotel-api/send-notification';

export const URL_BLOG_ARTICLES = URL + BLOG_ARTICLES;
export const URL_BLOG_CATEGORIES = URL + BLOG_CATEGORIES;
export const URL_BLOG_CREATE_CATEGORY = URL + BLOG_CREATE_CATEGORY;
export const URL_BLOG_UPDATE_CATEGORY = URL + BLOG_UPDATE_CATEGORY;
export const URL_BLOG_ARTICLES_BY_CATEGORY = URL + BLOG_ARTICLES_BY_CATEGORY;
export const URL_BLOG_MAIN_ARTICLE = URL + BLOG_MAIN_ARTICLE;
export const URL_BLOG_USER_SUBSCRIBE = URL + BLOG_USER_SUBSCRIBE;
export const URL_SEND_EMAIL_SEND_PULSE = URL + SEND_EMAIL_SEND_PULSE;
export const URL_OPEN_ARTICLE = URL + OPEN_ARTICLE;
export const URL_RECOMMENDED_ARTICLE = URL + RECOMMENDED_ARTICLE;
export const URL_ADMIN_CREATE_ARTICLE = URL + ADMIN_CREATE_ARTICLE;
export const URL_ADMIN_PUBLISH_ARTICLE = URL + ADMIN_PUBLISH_ARTICLE;
export const URL_ADMIN_UPDATE_ARTICLE = URL + ADMIN_UPDATE_ARTICLE;
export const URL_ADMIN_HIDE_ARTICLE = URL + ADMIN_HIDE_ARTICLE;
export const URL_ADMIN_ARTICLE_SNAPSHOT = URL + ADMIN_ARTICLE_SNAPSHOT;
export const URL_ADMIN_ARTICLES = URL + ADMIN_ARTICLES;
export const URL_ADMIN_ALL_ARTICLES_TITLES = URL + ADMIN_ALL_ARTICLES_TITLES;
export const URL_ADMIN_SECONDARY_ARTICLES = URL + ADMIN_SECONDARY_ARTICLES;
export const URL_ADMIN_MAIN_ARTICLE = URL + ADMIN_MAIN_ARTICLE;
export const URL_ADMIN_LOGIN = URL + ADMIN_LOGIN;
export const URL_ADMIN_CATEGORIES = URL + ADMIN_CATEGORIES;
export const URL_ADMIN_UPLOAD_IMAGE = URL + ADMIN_UPLOAD_IMAGE;
export const URL_CLOUDINARY_IMAGE_UPLOAD = URL + CLOUDINARY_IMAGE_UPLOAD;
export const URL_ADMIN_FETCH_PROFILE_DATA = URL + ADMIN_FETCH_PROFILE_DATA;
export const URL_ADMIN_UPDATE_PROFILE = URL + ADMIN_UPDATE_PROFILE;
export const URL_WANT_A_FRIEND_CREATE = URL + ADMIN_WANT_A_FRIEND_CREATE;
export const URL_WANT_A_FRIEND_UPDATE = URL + ADMIN_WANT_A_FRIEND_UPDATE;
export const URL_WANT_A_FRIEND_GET_ALL = URL + ADMIN_WANT_A_FRIEND_GET_ALL;
export const URL_ADMIN_WANT_A_FRIEND_DOG = URL + ADMIN_PETS;
export const URL_ADMIN_ALL_PETS = URL + ADMIN_ALL_PETS;
export const URL_ADMIN_DELETE_PET = URL + ADMIN_DELETE_PET;
export const URL_ADMIN_HIDE_PET = URL + ADMIN_HIDE_PET;
export const URL_ADMIN_PUBLISH_PET = URL + ADMIN_PUBLISH_PET;
export const URL_ABOUT_ASK = URL + ABOUT_ASK;
export const URL_USER_WANT_A_FRIEND_GET_ALL = URL + USER_WANT_A_FRIEND_GET_ALL;
export const URL_USER_WANT_A_FRIEND_GET_ONE = URL + USER_WANT_A_FRIEND_GET_ONE;
export const URL_USER_WANT_A_FRIEND_DOGS = URL + USER_WANT_A_FRIEND_DOGS;
export const URL_USER_WANT_A_FRIEND_SEND_NOTIFICATION = URL + USER_WANT_A_FRIEND_SEND_NOTIFICATION;
