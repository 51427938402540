import { RootStateType } from '../../../types/store.models';
import DogDetailsState from
  '../../../app/wantAFriendUserPage/components/dogDetailsPage/models/userDogDetailsState.interface'

export namespace HeaderSelectors {
  export const Language = (state: RootStateType) => state.languageReducer.selectedLanguage.languageValue;

  export const AdminLanguage = (
    (state: RootStateType) => state.adminProfileReducer.userData.preferredLanguage.languageValue
  );

  export const SuccessMessage = (state: RootStateType) => state.messageReducer.success;

  export const SelectedLanguage = (state: RootStateType) => state.languageReducer.selectedLanguage.languageValue;

  export const PetName = (state: DogDetailsState) => state.wantAFriendUserPagesReducer.petName;
}
