import i18n from 'i18next';
import { LANGUAGES, DEFAULT_LANGUAGE } from './languages';
import SELECT_LANGUAGE from './types';

const currentLanguage = Object.values(LANGUAGES).find(item => item.languageValue === i18n.language);

export const initialState = { selectedLanguage: currentLanguage || DEFAULT_LANGUAGE };

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    default:
      return { ...state };
  }
}
