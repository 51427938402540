import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FAQListComponent from './FAQListComponent';
import { requestQuestions, clearQuestions } from './+store/actions';
import styles from './FAQList.module.scss';
import dotsLoader from '../../../shared-ui/dotsLoader/dotsLoader';

class FAQListContainer extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        faqValueSet: PropTypes.shape({
          answer: PropTypes.string,
          id: PropTypes.number,
          question: PropTypes.string,
        }),
      }),
    ).isRequired,
    fetchQuestions: PropTypes.func.isRequired,
    clearAllQuestions: PropTypes.func.isRequired,
  };

  static defaultProps = { error: false };

  constructor(props) {
    super(props);
    this.state = {};
    const { items } = this.props;
    items.forEach((question, index) => {
      this.state[`isOpenedQuestion${index}`] = false; // eslint-disable-line
    });
  }

  componentDidMount() {
    const { fetchQuestions } = this.props;
    fetchQuestions();
  }

  componentWillUnmount() {
    const { clearAllQuestions } = this.props;
    clearAllQuestions();
  }

  handleToggleResponse = (index) => {
    const isOpenedQuestion = `isOpenedQuestion${index}`;
    this.setState(prevState => ({ [isOpenedQuestion]: !prevState[isOpenedQuestion] }));
  };

  render() {
    const { error, loading, items, t } = this.props;
    const allFaq = items.map(index => index.faqValueSet).flat().filter(
      element => element.language.languageValue === `${t('locale')}`,
    );
    if (error) {
      return <div>{`Error: ${error.message}`}</div>;
    }

    if (loading) {
      return (
        <div className={styles.loader}>
          <div className={styles.dotsLoader}>{dotsLoader()}</div>
        </div>
      );
    }
    return (
      <section className={styles.box}>
        <div className={styles.wrapper}>
          <div className={styles.title}>{t('frequentlyAskedQuestions')}</div>
          <div className={styles.container}>
            {allFaq.map((question, index) => (
              <FAQListComponent
                index={index}/*eslint-disable*/
                isOpened={this.state[`isOpenedQuestion${index}`]}
                question={question}
                onToggleResponse={this.handleToggleResponse}
                key={question.id}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => state.questionReducer;

const mapDispatchToProps = dispatch => ({
  fetchQuestions: () => dispatch(requestQuestions()),
  clearAllQuestions: () => dispatch(clearQuestions())
});

FAQListContainer.propTypes = { t: PropTypes.func.isRequired };

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(FAQListContainer);
