import React from 'react';

const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Profile-Eng" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[4.1][D][Eng]-Profile-–-Language-–-Russian" transform="translate(-260.000000, -300.000000)">
        <g id="Avatar" transform="translate(216.000000, 200.000000)">
          <g id="Button" transform="translate(40.000000, 96.000000)">
            <g id="Edit-Icons-/-Edit" transform="translate(4.000000, 4.000000)" fill="currentColor">
              <path
                d="M18.7899815,5.2100185 C19.6022677,6.02230474 20.0055878,7.08869003
              19.9999415,8.15330906 C20.0055682,9.22894828 19.5979674,10.3065213
              18.7771403,11.1273484 L10.9044887,19 L5.98408145,19 C5.44058827,19
              5,18.5594117 5,18.0159185 L5,13.0955113 L12.8726516,5.22285965
              C13.6934787,4.40203257 14.7710517,3.99443183 15.8468651,4.00005744
              C16.91131,3.99441224 17.9776953,4.39773225 18.7899815,5.2100185 Z
              M12.36,8.517999 L6.96816291,13.9103307 L6.96816291,17.0318371
              L10.0877011,17.0318371 L15.481,11.638999 L12.36,8.517999 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
