import React from 'react';
import styles from '../../ArticleContent.module.scss';
import { ArticleContentModels } from '../../article-content.models';

const Image = ({ content }: ArticleContentModels.FactoryItemProps) => (
  <>
    {content && (
      <div className={styles['image-wrapper']}>
        <img src={content} alt="animal" />
      </div>
    )}
  </>
);

export default Image;
