import React, { FC } from 'react';

import styles from '../../Footer.module.scss';

interface DropdownIconProps {
  isActive: boolean
}

const DropdownIcon: FC<DropdownIconProps> = ({ isActive }) => (
  <i className={isActive ? `${styles['icon-arrow-up']}` : `${styles['icon-arrow-down']}`} />
);

export default DropdownIcon;
