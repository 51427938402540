import { AxiosError } from 'axios';
import { ArticleDto, ArticlePageData, MainArticle } from '../../../types/article.model';
import { Action } from '../../../types/action.interface';
import { ArticleModels } from '../models/article.models';

import ArticleActionTypes from './types';

import { transformArticleDto } from '../../../utils/transformArticleDto';
import { BlogPageModels } from '../../../models/blogPage.models';

export const initialState: ArticleModels.State = {
  openArticle: null,
  loading: false,
  error: null,
  recommendedArticles: [],
  hasMoreRecomendedArticles: false,
};

export default function openArticleReducer(state = initialState, action: Action<
    ArticleDto
    | AxiosError
    | ArticlePageData
    | null
  >): ArticleModels.State {
  switch (action.type) {
    case ArticleActionTypes.FETCH_START:
      return {
        ...state,
        loading: true,
      };

    case ArticleActionTypes.FETCH_OPEN_ARTICLE:
      return {
        ...state,
        loading: false,
        openArticle: {
          ...transformArticleDto(action.payload as BlogPageModels.Article),
          articleContent: (action.payload as MainArticle).articleContentDtoList.map(item => ({
            content: item.contentValueDtoSet[0].contentValue,
            orderOnPage: item.contentValueDtoSet[0].orderOnPage,
            type: item.contentValueDtoSet[0].contentTypeEnum.id,
          })).sort((a, b) => a.orderOnPage - b.orderOnPage),
        },
      };

    case ArticleActionTypes.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload as (AxiosError | null),
      };

    case ArticleActionTypes.FETCH_RECOMMENDED_ARTICLES:
      return {
        ...state,
        loading: false,
        recommendedArticles: (action.payload as {content: BlogPageModels.Article[]}).content.map(transformArticleDto),
        hasMoreRecomendedArticles: !(action.payload as ArticlePageData).last,
      };

    case ArticleActionTypes.FETCH_PAGINATION_RECOMMENDED_ARTICLES:
      return {
        ...state,
        loading: false,
        recommendedArticles: [
          ...state.recommendedArticles,
          ...(action.payload as {content: BlogPageModels.Article[]}).content.map(transformArticleDto),
        ],
        hasMoreRecomendedArticles: !(action.payload as ArticlePageData).last,
      };

    default:
      return state;
  }
}
