import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './cardsGrid.module.scss';
import Card from './Card';

const CardsGrid = ({
  petsList,
  translate,
  children,
  activeFilter,
  history,
}) => (
  <>
    <div
      className={activeFilter ? `${styles['cards-grid']} ${styles['cards-grid-mobile']}`
        : `${styles['cards-grid']}`}
    >
      {petsList.map(petData => (
        <Card
          petData={petData}
          translate={translate}
          key={petData.id}
          history={history}
        />
      ))}

      {children}
    </div>
  </>
);

CardsGrid.propTypes = {
  translate: PropTypes.func.isRequired,
  petsList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  activeFilter: PropTypes.bool.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

CardsGrid.defaultProps = { petsList: [], history: null };

const mapStateToProps = state => ({ deleteMessageVisible: state.wantAFriendPagesReducer.deleteMessageVisible });

export default connect(mapStateToProps, null)(CardsGrid);
