const createBirthDate = (monthsFromBirthday: number) => {
  const MILLISECONDS_IN_YEAR = 31557600000;
  const MONTHS_IN_YEAR = 12;

  const currentDate = Date.now();
  const msFromBirthday = MILLISECONDS_IN_YEAR * (monthsFromBirthday / MONTHS_IN_YEAR);
  const birthdayTime = currentDate - msFromBirthday;

  const formatDate = (date: Date) => {
    const MAX_SINGLE_DIGIT = 9;
    /*
      The getMonth() method returns the month in the specified date according to local time,
      as a zero-based value (where zero indicates the first month of the year)
    */
    const MONTH_SHIFT = 1;

    const formatDateValue = (value: number) => (value <= MAX_SINGLE_DIGIT ? `0${value}` : `${value}`);

    const year = date.getFullYear();
    const month = formatDateValue(date.getMonth() + MONTH_SHIFT);
    const day = formatDateValue(date.getDate());

    return [year, month, day].join('-');
  }

  return formatDate(new Date(birthdayTime));
};

export default createBirthDate;
