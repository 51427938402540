/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <title>Layer 1</title>
      <g fillRule="evenodd" fill="none" id="All-Articles-Ru">
        <g id="[2.0][D]-Delete-Article">
          <rect rx="8" height="232" width="600" y="0" x="0" id="path-1" />
          <g id="Dialog-Dialogs-/-Alert-dialog">
            <g id="Base">
              <use id="svg_1" x="-44" y="-36" xlinkHref="#path-1" filter="url(#filter-2)" fill="black" />
              <use id="svg_2" x="-44" y="-36" xlinkHref="#path-1" fillRule="evenodd" fill="#FFFFFF" />
            </g>
            <circle r="24" cy="12" cx="12" fill="#F97272" fillOpacity="0.1" id="Oval" />
            <g fill="#373737" id="Icon-Icons-/-Trash">
              <path id="Combined-Shape" d="m16.89501,10c0.55229,0 1,0.44772 1,1c0,0.03323 -0.00165,0.06644 -0.00496,0.0995l-0.70995,7.09951c-0.10224,1.0224 -0.96257,1.80099 -1.99008,1.80099l-6.38004,0c-1.02751,0 -1.88784,-0.77859 -1.99008,-1.80099l-0.70995,-7.09951c-0.05495,-0.54954 0.34599,-1.03958 0.89553,-1.09454c0.03307,-0.0033 0.06628,-0.00496 0.09951,-0.00496l9.79002,0zm-4.89501,-6c1.10457,0 2,0.89543 2,2l4,0c0.55228,0 1,0.44772 1,1c0,0.55228 -0.44772,1 -1,1l-12,0c-0.55228,0 -1,-0.44772 -1,-1c0,-0.55228 0.44772,-1 1,-1l4,0c0,-1.10457 0.89543,-2 2,-2z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
