import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ServicesPageComponent from './ServicesPageComponent';
import { addEmail, getInputValue } from '../shared-ui/sign-up-for-news/+store/actions';


const mapDispatchToProps = dispatch => ({
  onGetInputValue: value => dispatch(getInputValue(value)),
  onAddEmail: email => dispatch(addEmail(email)),
});

const mapStateToProps = state => ({
  email: state.emailReducer.email,
  isValid: state.emailReducer.isValid,
  isValidating: state.emailReducer.isValidating,
  error: state.emailReducer.error,
  placeholder: state.emailReducer.placeholder,
});

const ServicesPageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesPageComponent));

export default ServicesPageContainer;
