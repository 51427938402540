import React, { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from '../../../../../types/message.interface';
import { HeaderModels } from '../../header.models';

import styles from '../../Header.module.scss';
import TRANSLATE_KEYS from '../../../../../i18n_keys';

import { HeaderSelectors } from '../../selectors';
import { sendMessageWantDog, sendSuccessNull } from '../../../send-message/+store/actions';

import useScroll from '../../../../../hooks/useScroll';

import CardDogModalForm from
  '../../../../wantAFriendUserPage/components/dogDetailsPage/cardDogModalForm/cardDogModalForm';
import CardDogCareButton from
  '../../../../wantAFriendUserPage/components/dogDetailsPage/cardDogCareButton/cardDogCareButton';
import Button from '../../../button';

const PATH_TO_PAGE_WAF_DOG = '/want-a-friend/dog';
const HEADER_SCROLL_HEIGHT = 12;

const DogForm: FC<HeaderModels.DogFormProps> = ({ isMenuActive, isModalActive, setIsModalActive, handleCloseMenu }) => {
  const dispatch = useDispatch();
  const [translate] = useTranslation();
  const { scrollY } = useScroll();
  const { pathname } = useLocation();
  const success = useSelector(HeaderSelectors.SuccessMessage);
  const selectedLanguage = useSelector(HeaderSelectors.SelectedLanguage);
  const petName = useSelector(HeaderSelectors.PetName);
  const [formTitle, setFormTitle] = useState('');
  const dogUrl = window.location.href;

  const isShowDogForm = pathname.startsWith(PATH_TO_PAGE_WAF_DOG) && !isMenuActive;
  const isShowDogButtons = scrollY > HEADER_SCROLL_HEIGHT && isShowDogForm;
  const isMenuActiveClass = !isMenuActive
    ? `${styles.search} ${styles.searchWidth} ${styles.hoveredItem}`
    : `${styles['search-mobile']} ${styles.hoveredItem}`

  const handleSendMessage = async (message: Message) => {
    await dispatch(sendMessageWantDog(message));
  };

  const handleSendSuccessNull = () => {
    dispatch(sendSuccessNull());
  };

  return (
    <>
      <div className={isMenuActiveClass}>
        {/*
          * Unnecessary for current project version
          * <div className={`${styles.search__icon} ${styles.icon}`}>&#xe905;</div>
        */}
        {isShowDogButtons
          ? (
            <>
              <CardDogCareButton
                care={1}
                text={translate(TRANSLATE_KEYS.takeCare)}
                setActive={setIsModalActive}
                setFormTitle={setFormTitle}
              />
              <CardDogCareButton
                care={0}
                text={translate(TRANSLATE_KEYS.takeHome)}
                setActive={setIsModalActive}
                setFormTitle={setFormTitle}
              />
            </>
          )
          : (
            <Button
              content={translate(TRANSLATE_KEYS.signUp)}
              uiType="dark-rose-small"
              href="/contacts"
              onClick={handleCloseMenu}
            />
          )}
      </div>
      {
        isShowDogForm && (
          <CardDogModalForm
            onSendMessage={handleSendMessage}
            sendSuccessNull={handleSendSuccessNull}
            translate={translate}
            formTitle={formTitle}
            active={isModalActive}
            setActive={setIsModalActive}
            success={success}
            dogName={petName ? petName[selectedLanguage] : null}
            locale={selectedLanguage}
            dogUrl={dogUrl}
          />
        )
      }
    </>
  )
};

export default DogForm;
