import { AxiosError } from 'axios';
import { Article, MainArticle } from '../../../types/article.model';
import { Action, BlogPayload } from '../../../types/action.interface';
import BlogActionTypes from './types';
import { BlogModels } from '../models/blog.models';
import { BlogPageModels } from '../../../models/blogPage.models';

import { transformArticleDto } from '../../../utils/transformArticleDto';

export const initialState: BlogModels.State = {
  articles: [],
  mainArticle: null,
  categories: [],
  pagination: 1,
  error: null,
  isPagination: false,
  isLoading: false,
};

export default function blogReducer(
  state = initialState,
  action: Action<Article[] | BlogPageModels.CategoryDto[] | Article | number | AxiosError | null>,
): BlogModels.State {
  switch (action.type) {
    case BlogActionTypes.FETCH_START:
      return {
        ...state,
        isLoading: true,
      };

    case BlogActionTypes.FETCH_ARTICLES:
      // eslint-disable-next-line no-case-declarations
      const content = (action.payload as {content: BlogPageModels.Article[]})?.content
        .map(transformArticleDto) ?? [];
      return {
        ...state,
        isLoading: false,
        articles: content,
        isPagination: !(action.payload as BlogPayload).last,
      };

    case BlogActionTypes.FETCH_MAIN_ARTICLE:
      if ((action.payload as MainArticle)) {
        return {
          ...state,
          mainArticle: transformArticleDto((action.payload as BlogPageModels.Article)),
        };
      }
      return state

    case BlogActionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        isLoading: false,
        categories: (action.payload as BlogPageModels.CategoryDto[]).map((item) => ({
          id: item.id,
          name: item.categoryValueDtoSet[0].categoryValue,
        })),
      };

    case BlogActionTypes.FETCH_ARTICLES_BY_CATEGORY:
      return {
        ...state,
        isLoading: false,
        articles: (action.payload as {content: BlogPageModels.Article[]}).content.map(transformArticleDto),
        isPagination: !(action.payload as BlogPayload).last,
      };

    case BlogActionTypes.FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as AxiosError | null),
        isPagination: false,
      };

    case BlogActionTypes.CLEAR_ARTICLES:
      return {
        ...state,
        articles: [],
      };
    case BlogActionTypes.UPDATE_PAGINATION:
      return {
        ...state,
        pagination: (action.payload as number),
      };

    case BlogActionTypes.FETCH_PAGINATION:
      return {
        ...state,
        isLoading: false,
        articles: [
          ...state.articles,
          ...(action.payload as {content: BlogPageModels.Article[]}).content.map(transformArticleDto),
        ],
        isPagination: !(action.payload as BlogPayload).last,
      };

    default:
      return state;
  }
}
