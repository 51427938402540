import { useState, useLayoutEffect } from 'react';

import { ScreenModels } from '../types/screen.models';

const MEDIA_QUERIES: string[] = [
  '(min-width: 320px) and (max-width: 599px)',
  '(min-width: 600px) and (max-width: 959px)',
  '(min-width: 960px)',
];

const useMatchMedia = () => {
  const mediaQueryList = MEDIA_QUERIES.map((s) => window.matchMedia(s));
  const screenMatches = () => mediaQueryList.map((s) => s.matches);

  const [state, setState] = useState(screenMatches);

  useLayoutEffect(() => {
    const handler = () => setState(screenMatches);
    mediaQueryList.forEach((s) => s.addEventListener('change', handler));

    return () => mediaQueryList.forEach((s) => s.removeEventListener('change', handler));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Object.values(ScreenModels.Enum).reduce(
      (acc, s, idx) => ({ ...acc, [s]: state[idx] }),
      {},
    ) as ScreenModels.Types;
}

export default useMatchMedia
