/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-" transform="translate(-580.000000, -1940.000000)">
        <g id="Paragraph" transform="translate(408.000000, 1744.000000)">
          <g id="DropDown" transform="translate(0.000000, 184.000000)">
            <g id="Icon" transform="translate(172.000000, 12.000000)" fill="#373737">
              <path d="M22.7862595,6 L22.7862595,6.73192436 L22.7251908,6.73192436 C20.6183101,6.73192436 18.9440266,7.05376022 17.7022901,7.6974416 C16.4605536,8.34112298 15.8396947,9.19243075 15.8396947,10.2513904 C15.8396947,10.5732311 15.9109408,10.8120126 16.0534351,10.9677419 C16.1959295,11.1234713 16.4147568,11.2013348 16.7099237,11.2013348 C16.8727744,11.2013348 17.1959263,11.1364486 17.6793893,11.0066741 C18.1628523,10.8768996 18.5725174,10.8120133 18.9083969,10.8120133 C20.0279954,10.8120133 20.9898179,11.248049 21.7938931,12.1201335 C22.5979684,12.9922179 23,14.035589 23,15.2502781 C23,16.6726063 22.5470783,17.8197954 21.6412214,18.6918799 C20.7353644,19.5639643 19.5445366,20 18.0687023,20 C16.5114426,20 15.2773582,19.4835054 14.3664122,18.4505006 C13.4554662,17.4174958 13,16.013357 13,14.2380423 C13,11.611407 13.8345973,9.58176467 15.5038168,8.14905451 C17.1730363,6.71634434 19.5496029,6 22.6335878,6 L22.7862595,6 Z M10.7862595,4 L10.7862595,4.73192436 L10.7251908,4.73192436 C8.61831008,4.73192436 6.94402656,5.05376022 5.70229008,5.6974416 C4.46055359,6.34112298 3.83969466,7.19243075 3.83969466,8.25139043 C3.83969466,8.57323112 3.91094076,8.81201257 4.05343511,8.96774194 C4.19592947,9.1234713 4.41475679,9.20133482 4.70992366,9.20133482 C4.87277435,9.20133482 5.19592634,9.13644855 5.67938931,9.00667408 C6.16285229,8.87689961 6.5725174,8.81201335 6.90839695,8.81201335 C8.02799542,8.81201335 8.98981786,9.24804903 9.79389313,10.1201335 C10.5979684,10.9922179 11,12.035589 11,13.2502781 C11,14.6726063 10.5470783,15.8197954 9.64122137,16.6918799 C8.73536443,17.5639643 7.54453664,18 6.06870229,18 C4.5114426,18 3.27735824,17.4835054 2.36641221,16.4505006 C1.45546618,15.4174958 1,14.013357 1,12.2380423 C1,9.61140696 1.83459725,7.58176467 3.50381679,6.14905451 C5.17303634,4.71634434 7.5496029,4 10.6335878,4 L10.7862595,4 Z" id="Combined-Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
