import React from 'react';
import { connect } from 'react-redux';
import styles from './image-uploader.module.scss';
import Icon from '../../../../../../../shared-ui/icon';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { DogImagePreviewModels } from './imageUploader.models';

const DogImagePreview = ({
  imgIsLoaded,
  dogPhotoLink,
  isClickedPhotoMain,
  handleImageChange,
  handleRemoveImg,
  handleMainImageChange,
  t: translate,
}: DogImagePreviewModels.Props) => (
  <div className={styles['image-preview-container']}>
    <>
      {dogPhotoLink
        ? (
          <>
            <img
              src={dogPhotoLink}
              alt="Dog"
              className={styles.image}
            />
            <div className={styles['image-info']}>
              <div>
                <p className={styles['image-status']}>
                  {imgIsLoaded && translate(TRANSLATE_KEYS.isLoaded)}
                </p>
              </div>

              <div className={styles['image-btns']}>
                <button
                  onClick={handleMainImageChange}
                  className={styles.icon_main}
                >
                  <Icon name={isClickedPhotoMain ? 'star-solid' : 'star-regular'} />
                  <span className={styles['tooltip-text-main']}>
                    {translate(TRANSLATE_KEYS.mainPhoto)}
                  </span>
                </button>

                <button
                  onClick={handleImageChange}
                  className={styles.icon_replace}
                >
                  <Icon name="repeat" />
                  <span className={styles['tooltip-text-replace']}>
                    {translate(TRANSLATE_KEYS.replace)}
                  </span>
                </button>

                <button
                  onClick={handleRemoveImg}
                  className={styles.icon_remove}
                >
                  <Icon name="trash" />
                  <span className={styles['tooltip-text-delete']}>
                    {translate(TRANSLATE_KEYS.deleteBtn)}
                  </span>
                </button>

              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.image} />
            <div className={styles['image-info']}>
              <div>
                <p className={styles['image-status']}>{translate(TRANSLATE_KEYS.uploadingError)}</p>
                <div className={styles['image-name']}>
                  {translate(TRANSLATE_KEYS.uploadingErrorMessage)}
                </div>
              </div>
              <div className={styles.errorBtns}>
                <button
                  onClick={handleImageChange}
                  className={styles.errorIcon_replace}
                >
                  <Icon name="repeat" />
                </button>
                <button
                  onClick={handleRemoveImg}
                  className={styles.errorIcon_remove}
                >
                  <Icon name="trash" />
                </button>
              </div>
            </div>
          </>
        )
      }
    </>
  </div>
);

const mapStateToProps = state => ({ imgIsLoaded: state.adminArticleCreateFormReducer.imgIsLoaded });

export default connect(mapStateToProps)(DogImagePreview);
