import { Cookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { BlogPageModels } from '../../../../../models/blogPage.models';
import { ArticleIds, BlogAction } from './blogStore.models';

export namespace BlogActions {
  export type SimpleAction = () => BlogAction;
  export type ErrorHandler = (error: Error) => BlogAction;

  export type DeleteArticleSuccess = (id: number) => BlogAction;
  export type DisplayDeleteMessage = (article: BlogPageModels.ArticleDeleteData) => BlogAction;
  export type HideOrPublishArticleSuccess = (id: number, article: BlogPageModels.Article) => BlogAction;
  export type EditPost = (id: number) => BlogAction;
  export type ToggleChangeMainArticleMessage = (
    mainArticles: BlogPageModels.MainArticlesProps,
    browserPathCanUpdate: boolean,
  ) => BlogAction;

  export type FetchArticleSuccess = (article: BlogPageModels.Article) => BlogAction;
  export type FetchArticleListSuccess = (article: BlogPageModels.Article) => BlogAction;
  export type FetchArticlesSuccess = (
    articlesList: BlogPageModels.Article[],
    selectedLanguage: string,
  ) => BlogAction;
  export type FetchMainArticleSuccess = (
    mainArticle: BlogPageModels.Article,
    selectedLanguage: string
  ) => BlogAction;
  export type FetchAllArticlesTitlesSuccess = (articleTitles: BlogPageModels.ArticleTitles[]) => BlogAction;

  export type FetchArticle = (id: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchArticleList = (page: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchArticles = (page: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchMainArticle = (cookies: Cookies) => (dispatch: Dispatch) => void;
  export type FetchAllArticlesTitles = (cookies: Cookies) => (dispatch: Dispatch) => void;

  export type DeleteArticle = (articleIds: ArticleIds, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type PublishArticle = (id: number, cookies: Cookies) => (dispatch: Dispatch) => void;
  export type HideArticle = (id: number, cookies: Cookies) => (dispatch: Dispatch) => Promise<number| BlogAction>;

  export type CreateArticleSnapshot = (
    DTO: BlogPageModels.Article,
    cookies: Cookies
  ) => (dispatch: Dispatch) => Promise<number>;
  export type UpdateArticleSnapshot = (
    DTO: BlogPageModels.Article,
    cookies: Cookies
  ) => (dispatch: Dispatch) => void;
}

export namespace BlogActionsTypes {
  export const ADMIN_FETCH_ARTICLES = 'ADMIN_FETCH_ARTICLES';
  export const ADMIN_FETCH_ALL_ARTICLES = 'ADMIN_FETCH_ALL_ARTICLES';
  export const ADMIN_FETCH_ALL_ARTICLES_TITLES = 'ADMIN_FETCH_ALL_ARTICLES_TITLES';
  export const ADMIN_FETCH_ARTICLE = 'ADMIN_FETCH_ARTICLE';
  export const ADMIN_FETCH_ARTICLE_LIST = 'ADMIN_FETCH_ARTICLE_LIST';
  export const ADMIN_FETCH_MAIN_ARTICLE = 'ADMIN_FETCH_MAIN_ARTICLE';

  export const REQUEST_START = 'REQUEST_START';
  export const REQUEST_END = 'REQUEST_END';
  export const REQUEST_ERROR = 'REQUEST_ERROR';

  export const CREATE_NEW_POST = 'CREATE_NEW_POST';
  export const ARTICLE_CREATED_SUCCESSFULLY = 'ARTICLE_CREATED_SUCCESSFULLY';

  export const EDIT_POST = 'EDIT_POST';
  export const ARTICLE_UPDATED_SUCCESSFULLY = 'ARTICLE_UPDATED_SUCCESSFULLY';

  export const CLOSE_MODAL_WINDOW = 'CLOSE_MODAL_WINDOW';

  export const CLEAR_ARTICLES = 'CLEAR_ARTICLES';
  export const CLEAR_ALL_ARTICLES = 'CLEAR_ALL_ARTICLES';

  export const BLOG_DISPLAY_DELETE_MESSAGE = 'BLOG_DISPLAY_DELETE_MESSAGE';
  export const BLOG_HIDE_DELETE_MESSAGE = 'BLOG_HIDE_DELETE_MESSAGE';

  export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START';
  export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
  export const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR';

  export const PUBLISH_ARTICLE_START = 'PUBLISH_ARTICLE_START';
  export const PUBLISH_ARTICLE_SUCCESS = 'PUBLISH_ARTICLE_SUCCESS';
  export const PUBLISH_ARTICLE_ERROR = 'PUBLISH_ARTICLE_ERROR';

  export const HIDE_ARTICLE_START = 'HIDE_ARTICLE_START';
  export const HIDE_ARTICLE_SUCCESS = 'HIDE_ARTICLE_SUCCESS';
  export const HIDE_ARTICLE_ERROR = 'HIDE_ARTICLE_ERROR';

  export const TOGGLE_CHANGE_MAIN_ARTICLE_MESSAGE = 'TOGGLE_CHANGE_MAIN_ARTICLE_MESSAGE';
}
