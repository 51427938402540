/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Empty" transform="translate(-112.000000, -20.000000)">
        <g id="Navigation-Navigation-/-Admin-/-Article">
          <g id="Icon-ic_back" transform="translate(112.000000, 20.000000)" fill="currentColor">
            <path d="M8.48528137,3.70710678 C8.87580567,3.31658249 9.50897064,3.31658249 9.89949494,3.70710678 C10.2900192,4.09763107 10.2900192,4.73079605 9.89949494,5.12132034 L9.89949494,5.12132034 L4.02121356,10.9992136 L23,11 C23.5522847,11 24,11.4477153 24,12 C24,12.5522847 23.5522847,13 23,13 L3.63521356,12.9992136 L9.89949494,19.263456 C10.2599789,19.6239399 10.2877084,20.191171 9.98268354,20.5834622 L9.89949494,20.6776695 C9.50897064,21.0681938 8.87580567,21.0681938 8.48528137,20.6776695 L8.48528137,20.6776695 L0.707106781,12.8994949 C0.316582489,12.5089706 0.316582489,11.8758057 0.707106781,11.4852814 L0.707106781,11.4852814 Z" id="Combined-Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
