import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './Navigation.module.scss';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { NavigationProps } from './Navigation.models';

const Navigation = ({ t: translate }: NavigationProps) => (
  <nav
    className={styles.navigation}
  >
    <ul
      className={styles.list}
    >
      <li className={styles.item}>
        <NavLink
          to="/admin/blog"
          className={styles.link}
          activeClassName={styles['is-active']}
        >{translate(TRANSLATE_KEYS.blog)}
        </NavLink>
      </li>
      <li className={styles.item}>
        <NavLink
          to="/admin/want-a-friend"
          className={styles.link}
          activeClassName={styles['is-active']}
        >{translate(TRANSLATE_KEYS.wantAFriend)}
        </NavLink>
      </li>
    </ul>
  </nav>
);

export default withTranslation('translations')(Navigation);
