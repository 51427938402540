import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import homeReducer from '../app/home/+store/reducers';
import emailReducer from '../app/shared-ui/sign-up-for-news/+store/reducer';
import messageReducer from '../app/shared-ui/send-message/+store/reducer';
import feedbackReducer from '../app/about/components/feedback-list/+store/reducer';
import questionReducer from '../app/about/components/FAQList/+store/reducer';
import albumReducer from '../app/about/components/photo-gallery/+store/reducer';
import blogReducer from '../app/blog/+store/reducers';
import articlesPageReducer from '../app/admin/components/articlesPage/+store/reducers';
import adminArticleCreateFormReducer from '../app/admin/components/articlesPage/components/popupForm/+store/reducers';
import adminDogPopupFormReducer from '../app/admin/components/wantAFriendPage/components/popupForm/+store/reducers';
import openArticleReducer from '../app/article/+store/reducers';
import adminLoginReducer from '../app/admin/components/authentication/+store/reducers';
import adminProfileReducer from '../app/admin/components/profilePage/+store/reducers';
import languageReducer from '../app/language/+store/reducers';
import wantAFriendPagesReducer from '../app/admin/components/wantAFriendPage/+store/reducers';
import wantAFriendUserPagesReducer from '../app/wantAFriendUserPage/+store/reducers';

export default history => combineReducers({
  router: connectRouter(history),
  homeReducer,
  emailReducer,
  messageReducer,
  feedbackReducer,
  questionReducer,
  albumReducer,
  articlesPageReducer,
  blogReducer,
  openArticleReducer,
  adminLoginReducer,
  adminArticleCreateFormReducer,
  adminProfileReducer,
  languageReducer,
  wantAFriendPagesReducer,
  adminDogPopupFormReducer,
  wantAFriendUserPagesReducer,
});
