import i18next from 'i18next';
import { testIfNumberNeedsSpecialEnding, testIfNumberEndsWithOne } from './checkEnding';

const formatAnswer = (length) => {
  if (testIfNumberEndsWithOne(length)) return `${length} ${i18next.t('answer')}`;
  if (testIfNumberNeedsSpecialEnding(length)) return `${length} ${i18next.t('answerLength')}`;
  return `${length} ${i18next.t('answersLength')}`;
};

export default formatAnswer;
