import React from 'react';
import Icon from '../../../../../../shared-ui/icon';
import { getDropdownBtnClasses } from './helpers';
import styles from './styles/dog-parameter-select.module.scss';
import { DogParametersDropdownModels } from '../popupForm.models';

const DogParametersDropdown = ({
  selectedParameter,
  name,
  onClick,
}: DogParametersDropdownModels.Props) => {
  const renderParameter = selectedParameter || '';
  return (
    <button
      className={getDropdownBtnClasses(renderParameter)}
      onClick={() => onClick()}
    >
      <span className={styles.label}>
        {name}
      </span>
      {renderParameter && <span>{renderParameter}</span>}
      <Icon name="arrow-down" />
    </button>
  );
};

export default DogParametersDropdown;
