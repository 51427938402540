import { WAFActions, WAFActionTypes } from './wantAFriendActions.models';

import { URL_WANT_A_FRIEND_GET_ALL,
  URL_ADMIN_WANT_A_FRIEND_DOG,
  URL_ADMIN_ALL_PETS,
  URL_ADMIN_DELETE_PET,
  URL_ADMIN_HIDE_PET,
  URL_ADMIN_PUBLISH_PET } from '../../../../../endpoints';
import requestWithHeaders from '../../../../../utils/withHeaders';
import getSelectedLanguage from '../../../../../utils/getSelectedLanguage';

export namespace AdminWAFPageActions {
  export const fetchStart: WAFActions.SimpleAction = () => ({ type: WAFActionTypes.ADMIN_WAF_FETCH_START });

  export const fetchEnd: WAFActions.SimpleAction = () => ({ type: WAFActionTypes.ADMIN_WAF_FETCH_END });

  export const fetchError: WAFActions.ErrorHandler = error => ({
    type: WAFActionTypes.ADMIN_WAF_FETCH_ERROR,
    payload: { error },
  });

  export const clearPets: WAFActions.SimpleAction = () => ({ type: WAFActionTypes.CLEAR_PETS });

  export const openDogPopupForm: WAFActions.SimpleAction = () => ({ type: WAFActionTypes.OPEN_DOG_POPUP_FORM });

  export const closeDogPopupForm: WAFActions.SimpleAction = () => ({ type: WAFActionTypes.CLOSE_DOG_POPUP_FORM });

  export const displayDeleteMessage: WAFActions.DisplayDeleteMessage = pet => ({
    type: WAFActionTypes.ADMIN_WAF_DISPLAY_DELETE_MESSAGE,
    payload: { petToDelete: pet },
  });

  export const hideDeleteMessage: WAFActions.SimpleAction = () => (
    { type: WAFActionTypes.ADMIN_WAF_HIDE_DELETE_MESSAGE }
  );

  export const fetchPetSuccess: WAFActions.FetchPetSuccess = pet => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    return {
      type: WAFActionTypes.ADMIN_WAF_FETCH_PET,
      payload: { pet, selectedLanguage },
    };
  };

  export const fetchPet: WAFActions.FetchPet = (id, cookies) => (dispatch) => {
    dispatch(fetchStart());
    requestWithHeaders(
      { url: `${URL_ADMIN_WANT_A_FRIEND_DOG}${id}` },
      cookies,
    )
      .then(response => dispatch(fetchPetSuccess(response.data)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const fetchPetsRecordsSuccess: WAFActions.FetchPetsRecordsSuccess = records => ({
    type: WAFActionTypes.ADMIN_WAF_FETCH_PETS_RECORDS,
    payload: { records },
  });

  export const fetchPetsRecords: WAFActions.FetchPetsRecords = (page, cookies) => (dispatch) => {
    dispatch(fetchStart());
    requestWithHeaders({ url: `${URL_WANT_A_FRIEND_GET_ALL}${page}` }, cookies)
      .then(response => dispatch(fetchPetsRecordsSuccess(response.data)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const fetchAllPetsSuccess: WAFActions.FetchAllPetsSuccess = (pets, selectedLanguage) => ({
    type: WAFActionTypes.ADMIN_WAF_FETCH_ALL_PETS,
    payload: { pets, selectedLanguage },
  });

  export const fetchAllPets: WAFActions.FetchAllPets = cookies => (dispatch) => {
    const selectedLanguage = getSelectedLanguage().languageValue;
    dispatch(fetchStart());
    requestWithHeaders({ url: URL_ADMIN_ALL_PETS }, cookies)
      .then(response => dispatch(fetchAllPetsSuccess(response.data, selectedLanguage)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const deletePetSuccess: WAFActions.DeletePetSuccess = id => ({
    type: WAFActionTypes.ADMIN_WAF_DELETE_PET_SUCCESS,
    payload: { id },
  });

  export const deletePet: WAFActions.DeletePet = (cookies, petToDelete, history) => (dispatch) => {
    dispatch(fetchStart());
    return requestWithHeaders({
      method: 'DELETE',
      url: `${URL_ADMIN_DELETE_PET}${petToDelete.deletePetId}`,
    }, cookies)
      .then(() => {
        dispatch(deletePetSuccess(petToDelete.id));
        dispatch(fetchEnd());
        history.push('/admin/want-a-friend');
      })
      .catch(error => dispatch(fetchError(error)));
  };

  export const hideOrPublishPetSuccess: WAFActions.HideOrPublishPetSuccess = (pet, oldId) => {
    const selectedLanguage = getSelectedLanguage().languageValue;
    return {
      type: WAFActionTypes.ADMIN_WAF_HIDE_OR_PUBLICH_PET_SUCCESS,
      payload: { oldId, selectedLanguage, pet },
    };
  };

  export const publishPet: WAFActions.PublishPet = (id, cookies) => (dispatch) => {
    dispatch(fetchStart());
    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_PUBLISH_PET}${id}`,
    }, cookies)
      .then((response) => {
        dispatch(hideOrPublishPetSuccess(response.data, id));
        dispatch(fetchEnd());
        return response;
      })
      .catch(error => dispatch(fetchError(error)));
  };

  export const hidePet: WAFActions.HidePet = (id, cookies) => (dispatch) => {
    dispatch(fetchStart());
    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_HIDE_PET}${id}`,
    }, cookies)
      .then((response) => {
        dispatch(hideOrPublishPetSuccess(response.data, id));
        dispatch(fetchEnd());
        return response;
      })
      .catch(error => dispatch(fetchError(error)));
  };
}
