import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Story.module.scss';
import Icon from '../../../shared-ui/icon';

import TRANSLATE_KEYS from '../../../../i18n_keys';
import { ArticleBaseProperties } from '../../../../types/article.model';

import { formatDate } from '../../../../utils/formatDate';

const Story = ({ category, date, image, title }: ArticleBaseProperties) => {
  const [translate] = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.box} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.wrapper}>
        <nav className={styles['link-wrapper']}>
          <button className={styles.link} onClick={history.goBack}>
            <Icon name="back" />
          </button>
        </nav>
        <div className={styles.content}>
          <p className={styles.category}>
            {category}
          </p>
          <h1 className={styles.heading}>
            {title}
          </h1>
          <p className={styles.date}>
            {translate(TRANSLATE_KEYS.added)}{` ${formatDate(date)}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Story;
