const TRANSLATE_KEYS = {
  aboutUs: 'aboutUs',
  blog: 'blog',
  articles: 'articles',
  contacts: 'contacts',
  wantAFriend: 'wantAFriend',
  profile: 'profile',
  logOut: 'logOut',
  toTheMain: 'toTheMain',
  hasGuardianship: 'hasGuardianship',
  hideBtn: 'hideBtn',
  publishBtn: 'publishBtn',
  editBtn: 'editBtn',
  deleteBtn: 'deleteBtn',
  male: 'male',
  female: 'female',
  shareFacebookBtn: 'shareFacebookBtn',
  welcomeWantsFriend: 'welcomeWantsFriend',
  selectionListSex: 'selectionListSex',
  selectionListAge: 'selectionListAge',
  selectionListSize: 'selectionListSize',
  selectionListSterilized: 'selectionListSterilized',
  filters: 'filters',
  sexDog: 'sexDog',
  all: 'all',
  dogFilterBeforeYear: 'dogFilterBeforeYear',
  dogFilterFromOneToFive: 'dogFilterFromOneToFive',
  dogFilterFiveAndMore: 'dogFilterFiveAndMore',
  small: 'small',
  medium: 'medium',
  big: 'big',
  allAvailablePets: 'allAvailablePets',
  noFriends: 'noFriends',
  noFriendsTitle: 'noFriendsTitle',
  noFriendsTitleFilter: 'noFriendsTitleFilter',
  moreResults: 'moreResults',
  size: 'size',
  age: 'age',
  createArticle: 'createArticle',
  createRecord: 'createRecord',
  changesHaveBeenSaved: 'changesHaveBeenSaved',
  recordCreated: 'recordCreated',
  copyright: 'copyright',
  takeCare: 'takeCare',
  takeHome: 'takeHome',
  createBtn: 'createBtn',
  updateDogTitle: 'updateDogTitle',
  createDogTitle: 'createDogTitle',
  declineBtn: 'declineBtn',
  saveUpdate: 'saveUpdate',
  servicesAndPrices: 'servicesAndPrices',
  noAnimals: 'noAnimals',
  viewAll: 'viewAll',
  upToSixMonths: 'upToSixMonths',
  afterSixMonthsToOneYear: 'afterSixMonthsToOneYear',
  fromOneToTwoYears: 'fromOneToTwoYears',
  fromTwoToThreeYears: 'fromTwoToThreeYears',
  fromThreeToFourYears: 'fromThreeToFourYears',
  fromFourToFiveYears: 'fromFourToFiveYears',
  fromFiveToSixYears: 'fromFiveToSixYears',
  fromSixToEightYears: 'fromSixToEightYears',
  moreThanEightYears: 'moreThanEightYears',
  goToTheBlog: 'goToTheBlog',
  mainPhoto: 'mainPhoto',
  replace: 'replace',
  uploadingErrorMessage: 'uploadingErrorMessage',
  uploadingError: 'uploadingError',
  isLoaded: 'isLoaded',
  messageSent: 'messageSent',
  sendMessage: 'sendMessage',
  contactNumber: 'contactNumber',
  messageNotSent: 'messageNotSent',
  sendYourContact: 'sendYourContact',
  fieldIncorrect: 'fieldIncorrect',
  theNameMustBe: 'theNameMustBe',
  field: 'field',
  yourName: 'yourName',
  callUs: 'callUs',
  messageBioText: 'messageBioText',
  socialTheNetwork: 'socialTheNetwork',
  ourAddress: 'ourAddress',
  addressOtradny: 'addressOtradny',
  fieldNameNotEmpty: 'fieldNameNotEmpty',
  IncorrectName: 'IncorrectName',
  emailIncorrect: 'emailIncorrect',
  incorrectNumber: 'incorrectNumber',
  emptyNumber: 'emptyNumber',
  additionalServices: 'additionalServices',
  grooming: 'grooming',
  veterinaryCare: 'veterinaryCare',
  transportation: 'transportation',
  hotelForAnimalsInKiev: 'hotelForAnimalsInKiev',
  forMoreThan20Years: 'forMoreThan20Years',
  learnMore: 'learnMore',
  sinceEachDog: 'sinceEachDog',
  services: 'services',
  showMore: 'showMore',
  editTool: 'editTool',
  answering: 'answering',
  updateBtn: 'updateBtn',
  createArticleTitle: 'createArticleTitle',
  saving: 'saving',
  allArticles: 'allArticles',
  allRecords: 'allRecords',
  added: 'added',
  noArticleContent: 'noArticleContent',
  noArticleContentTitle: 'noArticleContentTitle',
  inputDogDescription: 'inputDogDescription',
  moreArticles: 'moreArticles',
  loginErrorMessage: 'loginErrorMessage',
  somethingWentWrong: 'somethingWentWrong',
  labelName: 'labelName',
  accessErrorMessage: 'accessErrorMessage',
  password: 'password',
  signIn: 'signIn',
  dropImgHere: 'dropImgHere',
  dropImgHereCaption: 'dropImgHereCaption',
  inputDescription: 'inputDescription',
  inputTitle: 'inputTitle',
  wrongCurrentPassword: 'wrongCurrentPassword',
  currentPasswordRequired: 'currentPasswordRequired',
  newPasswordRequired: 'newPasswordRequired',
  passwordValidation: 'passwordValidation',
  passwordNotMatch: 'passwordNotMatch',
  changePassword: 'changePassword',
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
  cancelBtn: 'cancelBtn',
  change: 'change',
  updateSuccessfullySaved: 'updateSuccessfullySaved',
  passwordChanged: 'passwordChanged',
  profileTitle: 'profileTitle',
  labelEmail: 'labelEmail',
  loginError: 'loginError',
  emailError: 'emailError',
  languageError: 'languageError',
  imageError: 'imageError',
  saveChanges: 'saveChanges',
  prefLang: 'prefLang',
  imgInfo: 'imgInfo',
  letGetToKnowEachOther: 'letGetToKnowEachOther',
  ourHotelIsOneOfTheFirstHotels: 'ourHotelIsOneOfTheFirstHotels',
  fromTheFirstDayWeMade: 'fromTheFirstDayWeMade',
  victoria: 'victoria',
  hostessAndNannyOfDogHotel: 'hostessAndNannyOfDogHotel',
  todayOurHotelIsOneOfTheFew: 'todayOurHotelIsOneOfTheFew',
  photoGallery: 'photoGallery',
  feedbacks: 'feedbacks',
  askAQuestionShort: 'askAQuestionShort',
  askAQuestion: 'askAQuestion',
  team: 'team',
  victoriaKishchenko: 'victoriaKishchenko',
  victoriaProf: 'victoriaProf',
  andreyMalyuti: 'andreyMalyuti',
  andreyProf: 'andreyProf',
  innaVasilkovskaya: 'innaVasilkovskaya',
  innaProf: 'innaProf',
  recommendedArticles: 'recommendedArticles',
  deletePetTitle: 'deletePetTitle',
  deletePetQuestion: 'deletePetQuestion',
  no: 'no',
  yes: 'yes',
  inputDogName: 'inputDogName',
  loadingImage: 'loadingImage',
  kilobyte: 'kilobyte',
  enterLink: 'enterLink',
  addImage: 'addImage',
  removeBlock: 'removeBlock',
  typeTextHere: 'typeTextHere',
  needPublish: 'needPublish',
  approved: 'approved',
  pending: 'pending',
  walkingTheDog: 'walkingTheDog',
  theFirstOption: 'theFirstOption',
  theSecondOption: 'theSecondOption',
  veterinaryServices: 'veterinaryServices',
  transportationOfAnimals: 'transportationOfAnimals',
  natural: 'natural',
  dryFood: 'dryFood',
  royalCanin: 'royalCanin',
  baskerville: 'baskerville',
  optiMeal: 'optiMeal',
  softFood: 'softFood',
  cannedBaskerville: 'cannedBaskerville',
  theCostOfLiving: 'theCostOfLiving',
  discountIsProvidedIndividually: 'discountIsProvidedIndividually',
  discount: 'discount',
  money: 'money',
  contact: 'contact',
  forDogs: 'forDogs',
  additionalServicesSmall: 'additionalServicesSmall',
  hotelForDogs: 'hotelForDogs',
  address: 'address',
  signUp: 'signUp',
  addText: 'addText',
  addContent: 'addContent',
  daysAgo: 'daysAgo',
  dayAgo: 'dayAgo',
  hourAgo: 'hourAgo',
  hoursAgo: 'hoursAgo',
  hAgo: 'hAgo',
  minAgo: 'minAgo',
  minsAgo: 'minsAgo',
  mAgo: 'mAgo',
  now: 'now',
  jan: 'jan',
  fab: 'fab',
  mar: 'mar',
  apr: 'apr',
  mai: 'mai',
  jun: 'jun',
  jul: 'jul',
  aug: 'aug',
  sep: 'sep',
  oct: 'oct',
  nov: 'nov',
  dec: 'dec',
  mainPage: 'mainPage',
  updateArticleTitle: 'updateArticleTitle',
  mainArticle: 'mainArticle',
  categoryName: 'categoryName',
  socTabInfo: 'socTabInfo',
  categoryNotSelected: 'categoryNotSelected',
  createCategory: 'createCategory',
  canNotBeDeleted: 'canNotBeDeleted',
  maxCategoriesAmount: 'maxCategoriesAmount',
  categoryExists: 'categoryExists',
  enterCategoryName: 'enterCategoryName',
  cancelWarning: 'cancelWarning',
  leaveBtn: 'leaveBtn',
  cancelQuestion: 'cancelQuestion',
  changeMainArticle: 'changeMainArticle',
  changeMainArticleWarningBegin: 'changeMainArticleWarningBegin',
  changeMainArticleQuestionEnd: 'changeMainArticleQuestionEnd',
  saveNewMainArticle: 'saveNewMainArticle',
  deleteArticleTitle: 'deleteArticleTitle',
  deleteQuestion: 'deleteQuestion',
  emailValidationError: 'emailValidationError',
  wantToKeepUpToDateWithNews: 'wantToKeepUpToDateWithNews',
  subscribeToOurNewsletter: 'subscribeToOurNewsletter',
  emailPlaceholderDefault: 'emailPlaceholderDefault',
  emailPlaceholderMobile: 'emailPlaceholderMobile',
  heading404Error: 'heading404Error',
  message404Error: 'message404Error',
  ReviewFromOlena: 'ReviewFromOlena',
  Olena: 'Olena',
  ZhuzhaChihuahua_AsyaCat: 'ZhuzhaChihuahua_AsyaCat',
  ReviewFromAlla: 'ReviewFromAlla',
  Alla: 'Alla',
  FrankAmericanCockerSpaniel: 'FrankAmericanCockerSpaniel',
  ReviewFromXavierAndLubov: 'ReviewFromXavierAndLubov',
  XavierAndLubov: 'XavierAndLubov',
  AprilBassetHound: 'AprilBassetHound',
  ReviewFromTanyaAndAlena: 'ReviewFromTanyaAndAlena',
  TanyaAndAlena: 'TanyaAndAlena',
  ZingerPembrokeWelshCorgi: 'ZingerPembrokeWelshCorgi',
  ReviewFromJuliaAndVladyslav: 'ReviewFromJuliaAndVladyslav',
  JuliaAndVladyslav: 'JuliaAndVladyslav',
  JohnnyWestHighlandWhiteTerrier: 'JohnnyWestHighlandWhiteTerrier',
  ReviewFromAndriiAndOlga: 'ReviewFromAndriiAndOlga',
  AndriiAndOlga: 'AndriiAndOlga',
  Waxa: 'Waxa',
  Evgenia: 'Evgenia',
  ReviewFromEvgenia: 'ReviewFromEvgenia',
  ArgitaDaudze: 'ArgitaDaudze',
  ReviewFromArgitaDaudze: 'ReviewFromArgitaDaudze',
  NinaFoxTerrier: 'NinaFoxTerrier',
  Olga: 'Olga',
  ReviewFromOlga: 'ReviewFromOlga',
  BeckyBichonFrize: 'BeckyBichonFrize',
  Leila: 'Leila',
  ReviewFromLeila: 'ReviewFromLeila',
  ArchieLabrador: 'ArchieLabrador',
};
export default TRANSLATE_KEYS;
