import { FETCH_START,
  ADMIN_FETCH_PETS_RECORDS,
  FETCH_END,
  FETCH_ERROR,
  DISPLAY_DELETE_PET,
  DISPLAY_DELETE_MESSAGE,
  ADMIN_FETCH_ALL_PETS,
  ADMIN_FETCH_PET_LIST,
  ADMIN_FETCH_PET,
  CLEAR_PETS,
  HIDE_DELETE_MESSAGE,
  DELETE_PET_SUCCESS,
  HIDE_OR_PUBLICH_PET_SUCCESS,
  CREATE_NEW_DOG,
  CLOTHE_CREATE_NEW_DOG,
  USER_DOG_FILTER_MALE,
  USER_DOG_FILTER_FEMALE,
  USER_FETCH_ALL_PETS,
  USER_DOG_FILTER_SEX_ALL,
  USER_DOG_FILTER_BEFORE_YEAR,
  USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS,
  USER_DOG_FILTER_FIVE_AND_MORE,
  USER_DOG_FILTER_SMALL_DOG,
  USER_DOG_FILTER_MEDIUM_DOG,
  USER_DOG_FILTER_BIG_DOG,
  USER_DOG_FILTER_AGE_ALL,
  USER_DOG_FILTER_SIZE_ALL,
  USER_DOG_FETCH_NEXT_ITEMS } from './types';

import { URL_USER_WANT_A_FRIEND_GET_ONE,
  URL_ADMIN_ALL_PETS,
  URL_ADMIN_DELETE_PET,
  URL_ADMIN_HIDE_PET,
  URL_ADMIN_PUBLISH_PET,
  URL_USER_WANT_A_FRIEND_GET_ALL,
  URL_USER_WANT_A_FRIEND_DOGS } from '../../../endpoints';
import requestWithHeaders from '../../../utils/withHeaders';
import getSelectedLanguage from '../../../utils/getSelectedLanguage';

export const fetchStart = () => ({ type: FETCH_START });

export const fetchEnd = () => ({ type: FETCH_END });

export const fetchError = error => ({
  type: FETCH_ERROR,
  payload: error,
});

export const fetchPetsRecordsSuccess = records => ({
  type: ADMIN_FETCH_PETS_RECORDS,
  payload: records,
});

export const fetchPetSuccess = (pet) => {
  const selectedLanguage = getSelectedLanguage().languageValue;

  return {
    type: ADMIN_FETCH_PET,
    payload: { pet, selectedLanguage },
  };
};

export const fetchArticleListSuccess = article => ({
  type: ADMIN_FETCH_PET_LIST,
  payload: article,
});

export const clearPets = () => ({ type: CLEAR_PETS });

export const fetchPetsRecords = (page, cookies) => (dispatch) => {
  dispatch(fetchStart());
  requestWithHeaders({ url: `${URL_USER_WANT_A_FRIEND_GET_ALL}${page}` }, cookies)
    .then(response => dispatch(fetchPetsRecordsSuccess(response.data)))
    .catch(error => dispatch(fetchError(error)));
};

export const maleDogFilter = data => ({ type: USER_DOG_FILTER_MALE, payload: data });
export const femaleDogFilter = data => ({ type: USER_DOG_FILTER_FEMALE, payload: data });
export const allSexDogFilter = data => ({ type: USER_DOG_FILTER_SEX_ALL, payload: data });
export const beforeYearDogFilter = data => ({ type: USER_DOG_FILTER_BEFORE_YEAR, payload: data });
export const fromOneToFiveYears = data => ({ type: USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS, payload: data });
export const fiveAndMore = data => ({ type: USER_DOG_FILTER_FIVE_AND_MORE, payload: data });
export const smallDogFilter = data => ({ type: USER_DOG_FILTER_SMALL_DOG, payload: data });
export const mediumDogFilter = data => ({ type: USER_DOG_FILTER_MEDIUM_DOG, payload: data });
export const bigDogFilter = data => ({ type: USER_DOG_FILTER_BIG_DOG, payload: data });
export const allAgeDogFilter = data => ({ type: USER_DOG_FILTER_AGE_ALL, payload: data });
export const allSizeDogFilter = data => ({ type: USER_DOG_FILTER_SIZE_ALL, payload: data });
export const fetchNextItems = data => ({ type: USER_DOG_FETCH_NEXT_ITEMS, payload: data });

export const displayDeletePet = pet => ({
  type: DISPLAY_DELETE_PET,
  payload: pet,
});

export const fetchAllPetsSuccess = (data, selectedLanguage) => ({
  type: ADMIN_FETCH_ALL_PETS,
  payload: { data, selectedLanguage },
});

export const fetchUserAllPetsSuccess = (data, selectedLanguage) => ({
  type: USER_FETCH_ALL_PETS,
  payload: { data, selectedLanguage },
});

export const fetchAllPets = cookies => (dispatch) => {
  const selectedLanguage = getSelectedLanguage().languageValue;
  dispatch(fetchStart());
  requestWithHeaders({ url: URL_ADMIN_ALL_PETS }, cookies)
    .then(response => dispatch(fetchUserAllPetsSuccess(response.data, selectedLanguage)))
    .catch(error => dispatch(fetchError(error)));
};

export const fetchUserPetsRecords = cookies => (dispatch) => {
  const selectedLanguage = getSelectedLanguage().languageValue;
  dispatch(fetchStart());
  requestWithHeaders({ url: URL_USER_WANT_A_FRIEND_DOGS }, cookies)
    .then(response => dispatch(fetchAllPetsSuccess(response.data, selectedLanguage)))
    .catch(error => dispatch(fetchError(error)));
};

export const fetchPet = (id, cookies) => (dispatch) => {
  dispatch(fetchStart());
  requestWithHeaders(
    { url: `${URL_USER_WANT_A_FRIEND_GET_ONE}${id}` },
    cookies,
  )
    .then(response => dispatch(fetchPetSuccess(response.data)))
    .catch(error => dispatch(fetchError(error)));
};

export const displayDeleteMessage = pet => ({
  type: DISPLAY_DELETE_MESSAGE,
  payload: pet,
});

export const deletePetSuccess = id => ({
  type: DELETE_PET_SUCCESS,
  payload: id,
});

export const hideOrPublishPetSuccess = (data, oldId) => {
  const selectedLanguage = getSelectedLanguage().languageValue;
  return {
    type: HIDE_OR_PUBLICH_PET_SUCCESS,
    payload: { data, oldId, selectedLanguage },
  };
};

export const deletePet = (cookies, petToDelete, history) => (dispatch) => {
  dispatch(fetchStart());
  return requestWithHeaders({
    method: 'DELETE',
    url: `${URL_ADMIN_DELETE_PET}${petToDelete.deletePetId}`,
  }, cookies).then(
    () => {
      dispatch(deletePetSuccess(petToDelete.id));
      dispatch(fetchEnd());
      history.push('/admin/want-a-friend');
    },
  ).catch(error => dispatch(fetchError(error)));
};

export const hideDeleteMessage = () => ({ type: HIDE_DELETE_MESSAGE });

export const publishPet = (cookies, id) => (dispatch) => {
  dispatch(fetchStart());
  return requestWithHeaders({
    method: 'PUT',
    url: `${URL_ADMIN_PUBLISH_PET}${id}`,
  }, cookies).then(
    (response) => {
      dispatch(hideOrPublishPetSuccess(response.data, id));
      dispatch(fetchEnd);
    },
  ).catch(error => dispatch(fetchError(error)));
};

export const hidePet = (cookies, id) => (dispatch) => {
  dispatch(fetchStart());
  return requestWithHeaders({
    method: 'PUT',
    url: `${URL_ADMIN_HIDE_PET}${id}`,
  }, cookies).then(
    (response) => {
      dispatch(hideOrPublishPetSuccess(response.data, id));
      dispatch(fetchEnd);
    },
  ).catch(error => dispatch(fetchError(error)));
};

export const createNewDog = () => ({ type: CREATE_NEW_DOG });
export const clotheCreateNewDog = () => ({ type: CLOTHE_CREATE_NEW_DOG });
