import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';
import grid from '../../../assets/styles/grid.module.scss';

import TRANSLATE_KEYS from '../../../i18n_keys';

import { FooterModels } from './models/footer.models';

import Link from './components/link/Link';
import HiddenLink from './components/hiddenLink/HiddenLink';
import Divider from './components/divider/Divider';
import DropdownIcon from './components/dropdownIcon/DropdownIcon';

const Footer: FC = () => {
  const [translate] = useTranslation();
  const [openMenu, setOpenMenu] = useState<FooterModels.DropdownMenuNames | ''>('');

  const isActiveDropdownSub1 = openMenu === FooterModels.DropdownMenuNames.Submenu1;
  const isActiveDropdownSub2 = openMenu === FooterModels.DropdownMenuNames.Submenu2;

  const handleChangeOpenMenu = (value: FooterModels.DropdownMenuNames) => () => {
    setOpenMenu((prev) => (value === prev ? '' : value));
  };

  const handlePricesClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={grid.row}>
          <div className={`${grid['col-4']} ${styles['hidden-elem']}`}>
            <div className={styles.logo}>
              <RouterLink to="/home" className={styles['logo-link']}>
                DogHotel
              </RouterLink>
            </div>
            <div className={styles['subtitle-main']}>{translate(TRANSLATE_KEYS.hotelForDogs)}</div>
          </div>
          <div
            className={`${grid['col-2']} ${styles['mobile-link']}`}
            onClick={handleChangeOpenMenu(FooterModels.DropdownMenuNames.Submenu1)}
            role="presentation"
          >
            <Link path="/prices" text={translate(TRANSLATE_KEYS.services)} isCaption />
            <DropdownIcon isActive={isActiveDropdownSub1} />
            <div className={styles.dropdown}>
              <div className={isActiveDropdownSub1 ? `${styles['is-visible-services']}` : `${styles['is-invisible']}`}>
                <Link
                  path="/prices"
                  text={translate(TRANSLATE_KEYS.forDogs)}
                  state={{ selectedTab: 0 }}
                  onClick={handlePricesClick}
                />
                <Link
                  path="/prices"
                  text={translate(TRANSLATE_KEYS.additionalServices)}
                  state={{ selectedTab: 1 }}
                  onClick={handlePricesClick}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div
            className={`${grid['col-2']} ${styles['mobile-link']}`}
            onClick={handleChangeOpenMenu(FooterModels.DropdownMenuNames.Submenu2)}
            role="presentation"
          >
            <Link path="/about" text={translate(TRANSLATE_KEYS.aboutUs)} isCaption />
            <DropdownIcon isActive={isActiveDropdownSub2} />
            <div className={styles.dropdown}>
              <div className={isActiveDropdownSub2 ? `${styles['is-visible-aboutus']}` : `${styles['is-invisible']}`}>
                <Link path="/about/photo" text={translate(TRANSLATE_KEYS.photoGallery)} />
                <Link path="/about/feedback" text={translate(TRANSLATE_KEYS.feedbacks)} />
                <Link path="/about/ask" text={translate(TRANSLATE_KEYS.askAQuestion)} />
              </div>
            </div>
          </div>
          <Divider />
          <div className={grid['col-2']}>
            <Link path="/blog/0" text={translate(TRANSLATE_KEYS.blog)} state={{ fromNav: true }} isCaption />
            <Divider />
            <div className={styles.dropdown}>
              <Link
                path="/want-a-friend"
                text={translate(TRANSLATE_KEYS.wantAFriend)}
                wrapperClasses={styles['dropdown-link']}
                isCaption
              />
            </div>
            <Divider />
          </div>
          <Divider />
          <div className={`${grid['col-2']} ${styles['footer-contacts']}`}>
            <Link path="/contacts" text={translate(TRANSLATE_KEYS.contacts)} isCaption />
            <HiddenLink
              path={`https://maps.google.com/maps?q=${translate(TRANSLATE_KEYS.address)}`}
              text={translate(TRANSLATE_KEYS.address)}
              target="_blank"
              rel="noopener noreferrer"
            />
            <HiddenLink
              path="tel:+380679179585"
              text="+380 67 917-95-85"
            />
            <HiddenLink
              path="tel:+380994594451"
              text="+380 99 459-44-51"
            />
            <HiddenLink
              path="mailto:ross-husky@ukr.net"
              text="ross-husky@ukr.net"
            />
          </div>
          <Divider />
        </div>
        <hr className={styles['hor-line']} />
        <div className={`${grid.row} ${styles['mobile-footer']}`}>
          <div className={`${grid['col-10']} ${styles.subtitle} ${styles.bottom} ${styles.copyright}`}>
            {translate(TRANSLATE_KEYS.copyright)}
          </div>
          <div className={`${grid['col-2']} ${styles.bottom} ${styles['bottom-soc']}`}>
            <div className={styles['soc-link']}>
              <a
                href="https://www.facebook.com/hoteldogsandcats"
                rel="noopener noreferrer"
                target="_blank"
                className={`${styles.subtitle} ${styles['link-subtitle']} ${styles['link-social']}`}
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
