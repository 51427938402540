/* eslint-disable max-len */
import React from 'react';
import { IconModels } from '../icon.models';

const SVG = (props: IconModels.Props) => (
  <svg
    {...props}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* <defs>
      <rect id="path-1" x="0" y="0" width="600" height="632" rx="8" />
      <filter x="-4.7%" y="-3.2%" width="109.3%" height="108.9%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs> */}
    <g id="Create/Edit-new-article-Ru" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][D]-Create-New-Article-–-Main-selected" transform="translate(-456.000000, -652.000000)">
        <g id="Overlay" fillOpacity="0.7">
          <rect x="0" y="0" width="1448" height="792" />
        </g>
        <g id="Dialog-Window" transform="translate(424.000000, 80.000000)">
          <g id="Dialog">
            <g id="Base">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
            </g>
          </g>
          <g id="Main" transform="translate(32.000000, 572.000000)">
            <g id="Checkbox">
              <rect id="Rectangle" fill="#ffffff" x="4" y="4" width="16" height="16" rx="2" />
              <polyline id="Path-2" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="8 12.5 10.5 15 16.5 9" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export default SVG;
