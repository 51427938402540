import React, { useState, useRef, KeyboardEvent } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './LanguageSelect.module.scss';
import Icon from '../../../../../shared-ui/icon';
import { LanguageSelectModels } from './languageSelect.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { KeyCodes } from '../../../../../../constants';

const LanguageSelect = ({ value, t: translate, onChange }: LanguageSelectModels.Props) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const options = [
    { label: 'Українська', value: 'uk' },
    { label: 'English', value: 'en' },
  ];

  const selectedOption = options.find(option => option.value === value);
  const displayedLabel = (selectedOption && selectedOption.label) || '';

  const handleInputKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === KeyCodes.Enter) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
    <div className={`${styles['form-item']} ${styles.lightGrayMarginBottom}`}>
      <input
        ref={inputRef}
        type="text"
        id="language"
        placeholder={translate(TRANSLATE_KEYS.prefLang) as string}
        value={displayedLabel}
        onFocus={() => setSelectOpen(true)}
        onBlur={() => setSelectOpen(false)}
        className={`${styles['form-input']} ${styles.lightGrayMarginBottom}`}
        readOnly
        onKeyDown={handleInputKeydown}
      />
      <label className={styles['form-label']} htmlFor="language">
        {translate(TRANSLATE_KEYS.prefLang)}
      </label>
      <Icon
        name={`arrow-${selectOpen ? 'up' : 'down'}`}
        onClick={() => inputRef.current.focus()}
        className={styles['select-arrow']}
      />
      {selectOpen ? (
        <div className={styles['select-menu']}>
          {options.map(option => (
            <button
              type="button"
              key={option.value}
              onMouseDown={() => {
                onChange(option.value);
              }}
              className={styles['select-option']}
            >
              {option.label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

LanguageSelect.defaultProps = { value: '' };

export default withTranslation('translations')(LanguageSelect);
