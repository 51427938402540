import React, { useState, useCallback, useEffect, FC } from 'react';
import { withTranslation } from 'react-i18next';
import { Cookies, withCookies } from 'react-cookie';
import './image-box.scss';
import ImageUploader from '../../../../../../../shared-ui/image-uploader';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import DeleteButton from '../../../articleButtons/deleteButton';
import adminUploadToCloudinaryRequest from '../../../../../../../../utils/postCloudinaryImage';

const albumName = 'blog';

interface ImageBoxProps {
  id: number;
  url: string,
  deleteBox: (id: number) => void;
  handleImageChange: (url: string, id: number) => void;
  t: TranslateFn;
  cookies: Cookies;
  activeLanguage: string;
}

const ImageBox: FC<ImageBoxProps> = ({
  id,
  url,
  deleteBox,
  handleImageChange,
  t: translate,
  cookies,
  activeLanguage,
}) => {
  const [imagePreview, setImagePreview] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState<string | ArrayBuffer | FormData>('');

  useEffect(() => {
    if (!url) return;
    const name = url.split('/').pop().split('.')[0];
    setImageUpload(true);
    setImageName(name);
    setImage(url);
  }, [url]);

  const getImg = (event) => {
    const { target, dataTransfer } = event;

    const img = target.files ? target.files[0] : dataTransfer.files[0];
    return [img, img.name.split('.')[0]]
  }

  const handleSelectedImg = useCallback((event) => {
    if (event) {
      event.preventDefault();
      const [img, name] = getImg(event);
      const imgWithPath = `${albumName}/${img.name}`;
      const formData = new FormData();
      formData.append('image', img, imgWithPath);
      setImagePreview(true);
      setImageName(name);
      //  TODO: weird part in code below (inherited from prev solution)
      setImage(formData);
    } else {
      setImagePreview(false);
    }
  }, [imagePreview, imageName, image]);

  const checkImageRemove = useCallback(() => {
    setImagePreview(false);
  }, [imagePreview]);

  const handleSubmit = useCallback(async () => {
    if (image) {
      const imageUrl = await adminUploadToCloudinaryRequest(cookies, image);
      setImageUpload(true);
      setImage(imageUrl);
      handleImageChange(imageUrl, id);
    }
  }, [imageUpload, image]);

  const handleDeleteBlock = useCallback(() => {
    deleteBox(id);
  }, [id, activeLanguage]);

  return (
    <div className="image-box">
      {imageUpload
        ? (
          <>
            <img
              className="image-box__article-image"
              src={image as string}
              alt={imageName}
            />
            <DeleteButton handleDeleteImg={handleDeleteBlock} />
          </>
        )
        : (
          <>
            <ImageUploader
              onImgSelected={handleSelectedImg}
              checkImageRemove={checkImageRemove}
            />
            <div className="image-box__preview-buttons-area">
              <button
                className="image-box__preview-button"
                onClick={handleSubmit}
                disabled={!imagePreview}
              >
                {translate(TRANSLATE_KEYS.addImage)}
              </button>
              <button
                className="image-box__preview-button"
                onClick={handleDeleteBlock}
                disabled={imagePreview}
              >
                {translate(TRANSLATE_KEYS.removeBlock)}
              </button>
            </div>
          </>
        )}
    </div>
  );
};

export default withTranslation('translations')(withCookies(ImageBox));
