import { AxiosError, AxiosResponse } from 'axios';
import { DispatchFn } from '../../../types/dispatch.type';
import { ArticleDto, ArticlePageData } from '../../../types/article.model';
import { GetStateType } from '../../../types/store.models';
import ArticleActionTypes from './types';
import { URL_OPEN_ARTICLE, URL_RECOMMENDED_ARTICLE } from '../../../endpoints';
import requestWithHeaders from '../../../utils/withHeaders';


export namespace ArticleActions {
  export const fetchStart = () => ({ type: ArticleActionTypes.FETCH_START });

  export const fetchError = (error: AxiosError | null | unknown) => ({
    type: ArticleActionTypes.FETCH_ERROR,
    payload: error,
  });

  export const fetchOpenSuccess = (data: ArticleDto) => ({
    type: ArticleActionTypes.FETCH_OPEN_ARTICLE,
    payload: data,
  });

  export const fetchOpen = (id: string) => async (dispatch: DispatchFn) => {
    dispatch(fetchStart());
    try {
      const articleRes: AxiosResponse<ArticleDto> = await requestWithHeaders({ url: `${URL_OPEN_ARTICLE}${id}` })
      const categoryRes: AxiosResponse<ArticlePageData> = (
        await requestWithHeaders({ url: `${URL_RECOMMENDED_ARTICLE}${articleRes.data.categoryId}/${id}/recommended/1` })
      )

      dispatch(fetchOpenSuccess(articleRes.data))
      dispatch(fetchRecommendedSuccess(categoryRes.data))
    } catch (error: unknown) {
      dispatch(fetchError(error))
    }
  };

  export const fetchRecommendedSuccess = (data: ArticlePageData) => ({
    type: ArticleActionTypes.FETCH_RECOMMENDED_ARTICLES,
    payload: data,
  });

  export const fetchPaginationRecommendedSuccess = (data: ArticlePageData) => ({
    type: ArticleActionTypes.FETCH_PAGINATION_RECOMMENDED_ARTICLES,
    payload: data,
  });

  export const fetchPaginationRecommended = (
    pagination: number,
  ) => async (dispatch: DispatchFn, getState: GetStateType): Promise<void> => {
    dispatch(fetchStart());
    try {
      const { id, categoryId } = getState().openArticleReducer.openArticle;

      const response: AxiosResponse<ArticlePageData> = (
        await requestWithHeaders({ url: `${URL_RECOMMENDED_ARTICLE}${categoryId}/${id}/recommended/${pagination}` })
      );
      dispatch(fetchPaginationRecommendedSuccess(response.data))
    } catch (error: unknown) {
      dispatch(fetchError(error))
    }
};
}

