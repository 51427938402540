export const testIfNumberNeedsSpecialEnding = (number: number) => {
  const numberEndsWith = number % 10;
  if (numberEndsWith === 2 || numberEndsWith === 3 || numberEndsWith === 4) {
    if (number !== 12 && number !== 13 && number !== 14) return true;
  }
  return false;
};

export const testIfNumberEndsWithOne = (number: number) => {
  const numberEndsWith = number % 10;
  if (numberEndsWith === 1 && number !== 11) return true;
  return false;
};
