/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';
// import moment from 'moment';
import { testIfNumberNeedsSpecialEnding, testIfNumberEndsWithOne } from './checkEnding';
import TRANSLATE_KEYS from '../i18n_keys';

const formatFullDate = (date: Date, options: { lng: string }) => {
  let month;
  switch (date.getMonth()) {
    case 0:
      month = i18next.t(TRANSLATE_KEYS.jan, options);
      break;
    case 1:
      month = i18next.t(TRANSLATE_KEYS.fab, options);
      break;
    case 2:
      month = i18next.t(TRANSLATE_KEYS.mar, options);
      break;
    case 3:
      month = i18next.t(TRANSLATE_KEYS.apr, options);
      break;
    case 4:
      month = i18next.t(TRANSLATE_KEYS.mai, options);
      break;
    case 5:
      month = i18next.t(TRANSLATE_KEYS.jun, options);
      break;
    case 6:
      month = i18next.t(TRANSLATE_KEYS.jul, options);
      break;
    case 7:
      month = i18next.t(TRANSLATE_KEYS.aug, options);
      break;
    case 8:
      month = i18next.t(TRANSLATE_KEYS.sep, options);
      break;
    case 9:
      month = i18next.t(TRANSLATE_KEYS.oct, options);
      break;
    case 10:
      month = i18next.t(TRANSLATE_KEYS.nov, options);
      break;
    case 11:
      month = i18next.t(TRANSLATE_KEYS.dec, options);
      break;
    default:
      break;
  }
  const formatedDate = `${date.getDate()} ${month} ${date.getFullYear()}`;
  return formatedDate;
};

export const formatDate = (date: string, options?) => {
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MIN = 60;

  // const dateToFormat = date instanceof Date ? date : moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
  const dateToFormat = new Date(date);
  const seconds = Math.floor((new Date().getTime() - dateToFormat.getTime()) / 1000);

  let interval = Math.floor(seconds / SECONDS_IN_DAY);
  if (interval > 3) return formatFullDate(dateToFormat, { lng: i18next.t('locale') });
  if (interval === 3 || interval === 2) return `${interval} ${i18next.t(TRANSLATE_KEYS.daysAgo, options)}`;
  if (interval === 1) return `${interval} ${i18next.t(TRANSLATE_KEYS.dayAgo, options)}`;

  interval = Math.floor(seconds / SECONDS_IN_HOUR);
  if (testIfNumberEndsWithOne(interval)) return `${interval} ${i18next.t(TRANSLATE_KEYS.hourAgo, options)}`;
  if (testIfNumberNeedsSpecialEnding(interval)) return `${interval} ${i18next.t(TRANSLATE_KEYS.hoursAgo, options)}`;

  if (interval > 1) return `${interval} ${i18next.t(TRANSLATE_KEYS.hAgo, options)}`;

  interval = Math.floor(seconds / SECONDS_IN_MIN);
  if (testIfNumberEndsWithOne(interval)) return `${interval} ${i18next.t(TRANSLATE_KEYS.minAgo, options)}`;
  if (testIfNumberNeedsSpecialEnding(interval)) return `${interval} ${i18next.t(TRANSLATE_KEYS.minsAgo, options)}`;

  if (interval > 1) return `${interval} ${i18next.t(TRANSLATE_KEYS.mAgo, options)}`;

  return i18next.t(TRANSLATE_KEYS.now, options);
};
