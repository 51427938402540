import TRANSLATE_KEYS from '../i18n_keys';

const getDogAgeTranslateKey = (date) => {
  const UNIX_TIME_STAMP = 1970;
  const MILLISECONDS_IN_DAY = 86400000;
  const DAYS_IN_SIX_MONTHS = 365 / 2;
  const dogBirthDate = new Date(date);
  const dogAgeMs = Date.now() - dogBirthDate.getTime();
  const dogAgeYears = Math.abs(new Date(dogAgeMs).getUTCFullYear() - UNIX_TIME_STAMP);

  if (dogAgeYears === 1) return TRANSLATE_KEYS.fromOneToTwoYears;
  if (dogAgeYears === 2) return TRANSLATE_KEYS.fromTwoToThreeYears;
  if (dogAgeYears === 3) return TRANSLATE_KEYS.fromThreeToFourYears;
  if (dogAgeYears === 4) return TRANSLATE_KEYS.fromFourToFiveYears;
  if (dogAgeYears === 5) return TRANSLATE_KEYS.fromFiveToSixYears;
  if (dogAgeYears === 6 || dogAgeYears === 7 || dogAgeYears === 8) return TRANSLATE_KEYS.fromSixToEightYears;
  if (dogAgeYears > 8) return TRANSLATE_KEYS.moreThanEightYears;

  const currentDate = Date.now();
  const daysFromBirthday = (currentDate - dogBirthDate.getTime()) / MILLISECONDS_IN_DAY;
  const isAgeLessThanSixMonths = daysFromBirthday < DAYS_IN_SIX_MONTHS;

  return isAgeLessThanSixMonths ? TRANSLATE_KEYS.upToSixMonths : TRANSLATE_KEYS.afterSixMonthsToOneYear;
};

export default getDogAgeTranslateKey;
