import { URL_BLOG_USER_SUBSCRIBE, URL_USER_WANT_A_FRIEND_SEND_NOTIFICATION } from '../../../../endpoints';
import requestWithHeaders from '../../../../utils/withHeaders';
import { DISPLAY_ALERT,
  SEND_MESSAGE_BEGIN,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  SEND_SUCCESS_NULL } from './types';

export const sendMessageBegin = () => ({ type: SEND_MESSAGE_BEGIN });

export const sendMessageSuccess = message => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { message },
});

export const sendMessageFailure = error => ({
  type: SEND_MESSAGE_FAILURE,
  payload: { error },
});

export const sendSuccessNull = () => ({ type: SEND_SUCCESS_NULL });

export const displayAlert = () => ({ type: DISPLAY_ALERT });

export function sendMessage(message) {
  return (dispatch) => {
    dispatch(sendMessageBegin());
    requestWithHeaders({
      method: 'POST',
      url: URL_BLOG_USER_SUBSCRIBE,
      data: message,
    })
      .then((response) => {
        dispatch(sendMessageSuccess(response.data));
        const interval = setInterval(() => {
          dispatch(displayAlert());
          clearInterval(interval);
        }, 1500);
      })
      .catch(error => dispatch(sendMessageFailure(error)));
  };
}

export function sendMessageWantDog(message) {
  return (dispatch) => {
    dispatch(sendMessageBegin());
    return requestWithHeaders({
      method: 'POST',
      url: URL_USER_WANT_A_FRIEND_SEND_NOTIFICATION,
      data: message,
    })
      .then((response) => {
        dispatch(sendMessageSuccess(response.data));
        const interval = setInterval(() => {
          dispatch(displayAlert());
          clearInterval(interval);
        }, 1500);
      })
      .catch(error => dispatch(sendMessageFailure(error)));
  };
}
