import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { AdminBlogActions } from '../../../../+store/actions';
import Icon from '../../../../../../../shared-ui/icon';
import style from './popupMessage.module.scss';
import Button from '../../../../../../../shared-ui/button';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { ArticleIds } from '../../../../+store/blogStore.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

interface PopupMessageDeleteProps {
  articleToDelete: BlogPageModels.ArticleDeleteData;
  cookies: Cookies;
  onHideDeleteMessage: () => void;
  onDeleteArticle: (articleIds: ArticleIds, cookies: Cookies,) => void;
  handleChangeRemoving: () => void;
  t: TranslateFn;
}

const PopupMessageDelete: FC<PopupMessageDeleteProps> = ({
  articleToDelete,
  cookies,
  onHideDeleteMessage,
  onDeleteArticle,
  handleChangeRemoving,
  t: translate,
}) => {
  const removeArticle = () => {
    handleChangeRemoving();
    onDeleteArticle(articleToDelete, cookies);
    onHideDeleteMessage();
  };

  const { title } = articleToDelete;
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.body}>
          <div className={`${style['warning-icon']} ${style['warning-delete']}`}>
            <Icon name="trash-popup" />
          </div>
          <div className={style['text-container']}>
            <header className={`${style.header} ${style['header-delete']}`}>
              {translate(TRANSLATE_KEYS.deleteArticleTitle)}
            </header>
            <p className={style['warning-text']}>
              {translate(TRANSLATE_KEYS.deleteQuestion)}
              {` "${title}" ?`}
            </p>
          </div>
        </div>
        <div className={style.buttons}>
          <Button
            content={translate(TRANSLATE_KEYS.cancelBtn)}
            uiType="dark-rose-unfilled"
            onClick={onHideDeleteMessage}
          />
          <Button
            content={translate(TRANSLATE_KEYS.deleteBtn)}
            uiType="dark-rose-filled"
            onClick={removeArticle}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ articleToDelete: state.articlesPageReducer.articleToDelete });

const mapDispatchToProps = dispatch => ({
  onHideDeleteMessage: () => dispatch(AdminBlogActions.hideDeleteMessage()),
  onDeleteArticle: (
    articleIds: ArticleIds,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.deleteArticle(articleIds, cookies)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(PopupMessageDelete)),
);
