import React, { FC } from 'react';
import Info from './components/info/index';
import LocationMap from './components/locationMap/index';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';

const ContactsComponent: FC = () => (
  <>
    <Info />
    <LocationMap />
    <SignupForNewsContainer />
  </>
);

export default ContactsComponent;
