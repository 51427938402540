import { withRouter } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import ArticlesPageComponent from './ArticlesPageComponent';

import { AdminBlogActions } from './+store/actions';
import { ProfilePageActions } from '../profilePage/+store/actions';

const mapStateToProps = state => ({
  article: state.articlesPageReducer.article,
  articles: state.articlesPageReducer.articles,
  mainArticle: state.articlesPageReducer.mainArticle,
  articlesCount: state.articlesPageReducer.articlesCount,
  openModalCreatingPost: state.articlesPageReducer.openModalCreatingPost,
  changeMainArticleMessageVisible: state.articlesPageReducer.changeMainArticleMessageVisible,
});
const mapDispatchToProps = dispatch => ({
  fetchArticles: (page: number, cookies: Cookies) => dispatch(AdminBlogActions.fetchArticles(page, cookies)),
  fetchAllArticlesTitles: (cookies: Cookies) => dispatch(AdminBlogActions.fetchAllArticlesTitles(cookies)),
  fetchMainArticle: (cookies: Cookies) => dispatch(AdminBlogActions.fetchMainArticle(cookies)),
  adminFetchProfileDataRequest: (
    cookies: Cookies,
  ) => dispatch(ProfilePageActions.adminFetchProfileDataRequest(cookies)),
  clearArticles: () => dispatch(AdminBlogActions.clearArticles()),
  onCreatePostHandler: () => dispatch(AdminBlogActions.createNewPost()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(ArticlesPageComponent)));
