import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './listHeader.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const PetsCount = ({ petsCount, articlesCount, translate }) => {
  const [path, setPath] = useState(window.location.pathname);
  const pathAdminBlog = '/admin/blog';
  const isAdminBlog = pathAdminBlog === path;

  useEffect(() => {
    setPath(window.location.pathname);
  }, [path]);

  return (
    <div className={`${styles['title-container-counter']}`}>
      <h2 className={styles.title}>
        {translate(TRANSLATE_KEYS.allAvailablePets)}
      </h2>
      <span
        className={styles['articles-count']}
      >
        {isAdminBlog ? articlesCount : petsCount}
      </span>
    </div>
  );
};

PetsCount.propTypes = {
  petsCount: PropTypes.number.isRequired,
  articlesCount: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  petsCount: state.wantAFriendUserPagesReducer.petsCount,
  articlesCount: state.articlesPageReducer.articlesCount,
});

export default withTranslation('translations')(
  connect(mapStateToProps)(PetsCount),
);
