import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import styles from './ProfileMenu.module.scss';
import browserStorage from '../../../../../../utils/localStorage';
import NewRecordBtn from '../../../../../shared-ui/adminListHeader/NewRecordBtn';
import useScroll from '../../../../../../hooks/useScroll';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { ProfileMenuProps } from './ProfileMenu.models';

const ProfileMenu = ({ imgUrl, isActive, collapseY, t: translate }: ProfileMenuProps) => {
  const node = useRef<HTMLDivElement>();
  const { scrollY } = useScroll();
  const path = window.location.pathname;
  const pathAdminBlog = '/admin/blog';
  const pathAdminWantAFriend = '/admin/want-a-friend';
  const [displayDropdown, setDropdownDisplay] = useState(false);
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdownDisplay(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const shouldDispalyNewRecordButton = Math.ceil(scrollY) > collapseY
    && (pathAdminBlog === path || pathAdminWantAFriend === path);

  const handleLogout = () => {
    browserStorage.removeKey('login');
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  return (
    <div
      ref={node}
      className={styles['profile-menu']}
    >
      {shouldDispalyNewRecordButton && <NewRecordBtn small />}
      {/* for Notification Center */}
      {/* <NotificationButton indicator /> */}
      <button
        type="button"
        className={
          isActive ? `${styles.button} ${styles['is-active']}` : styles.button
        }
        style={{ backgroundImage: `url(${imgUrl})` }}
        onClick={() => setDropdownDisplay(!displayDropdown)}
      />
      <div
        className={
          displayDropdown
            ? `${styles.content} ${styles['is-visible']}`
            : styles.content
        }
      >
        <ul className={styles.list}>
          <li className={styles.item}>
            <NavLink
              to="/admin/profile"
              className={styles.link}
              activeClassName={styles['is-active']}
              onClick={() => setDropdownDisplay(false)}
            >
              {translate(TRANSLATE_KEYS.profile)}
              <button
                type="button"
                className={styles['button-profile-burger']}
                style={{ backgroundImage: `url(${imgUrl})` }}
              />
            </NavLink>
          </li>
          <li className={styles.item}>
            <Link
              to="/admin"
              className={styles.link}
              onClick={() => handleLogout()}
            >
              {translate(TRANSLATE_KEYS.logOut)}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

ProfileMenu.defaultProps = {
  imgUrl: null,
  isActive: false,
  collapseY: 0,
};

export default withTranslation('translations')(ProfileMenu);
