import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedbackItem from '../feedback-item';
import formatAnswer from '../../../../../utils/formatAnswer';
import styles from './feedback-block.module.scss';

export default class FeedbackBlock extends Component {
  static propTypes = {
    feedback: PropTypes.shape({
      image: PropTypes.string.isRequired,
      review: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      petName: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string.isRequired,
          review: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          petName: PropTypes.string.isRequired,
          userId: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { answers } = props.feedback;
    this.state = { areAnswersClosed: answers ? answers.length >= 3 : false };
  }

  handleOpenAnswers = () => this.setState({ areAnswersClosed: false });

  render() {
    const { feedback } = this.props;
    const { answers } = feedback;
    const { areAnswersClosed } = this.state;
    const showCollapsedAnswers = !!answers && areAnswersClosed;
    const showOpenedAnswers = !!answers && !areAnswersClosed;

    return (
      <li>
        <FeedbackItem feedback={feedback} />
        {showCollapsedAnswers && (
          <div className={styles.answers}>
            <ul className={`${styles.list} ${styles['more-answers']}`}>
              {answers.map((answer, index) => (
                <li key={answer.review.substring(0, 20)}>
                  <div className={styles.images}>
                    <img
                      src={answer.image}
                      alt="avatar"
                      className={styles.img}
                      style={{ zIndex: answers.length - index }}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <button
              className={styles['more-answers-button']}
              onClick={this.handleOpenAnswers}
            >
              {`И ещё ${formatAnswer(answers.length)}`}
            </button>
          </div>
        )}

        {showOpenedAnswers && (
          <ul className={`${styles.list} ${styles['list-answers']}`}>
            {answers.map(answer => (
              <li key={answer.review.substring(0, 20)}>
                <FeedbackItem feedback={answer} />
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }
}
