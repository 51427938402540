import React, { FC, useState, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cookies, ReactCookieProps, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { History } from 'history';
import { AdminWAFPageActions } from '../../+store/actions';
import { CardsGridHelpers } from './helpers';
import styles from './card.module.scss';
import Icon from '../../../../../shared-ui/icon/index';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import DescriptionDetails from '../../../../../shared-ui/description-details/DescriptionDetails';
import { WAFModels } from '../../../../../../models/WAFPage.models';
import getDogDataForUpdate from '../../../../../../utils/getDogDataForUpdate';
import useOnOutsideClick from '../../../../../../hooks/useOnOutsideClick';
import { WAFPopupStoreModels } from '../popupForm/+store/WAFPopupFormStore.models';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import { AdminWAFPopupActions } from '../popupForm/+store/actions';

interface CardProps extends ReactCookieProps {
  petData: WAFModels.Pet
  language: string;
  cookies: Cookies;
  history: History;
  onUpdateDogHandler: (
    dogData: WAFPopupStoreModels.DogFormData,
    petBeforeUpdate: WAFModels.Pet,
  ) => void;
  handleCurrentDogCardIdUpdate: (event) => void;
  onDisplayDeleteMessage: (pet: WAFModels.PetToDelete) => void;
  onPublishPet: (petId: number, cookies: Cookies) => void;
  onHidePet: (petId: number, cookies: Cookies) => void;
  t: TranslateFn,
}

const Card: FC<CardProps> = ({
  petData,
  language,
  cookies,
  history = null,
  onDisplayDeleteMessage,
  onPublishPet,
  onHidePet,
  onUpdateDogHandler,
  handleCurrentDogCardIdUpdate,
  t: translate,
}) => {
  const [isBurgerMenuClicked, setIsBurgerMenuClicked] = useState(false);
  const [burgerMenuRef] = useOnOutsideClick<HTMLDivElement>({ handler: () => setIsBurgerMenuClicked(false) })

  const displayModalWindow = (e: MouseEvent) => {
    e.preventDefault();

    const deletePetId = petData.parentDogId || petData.id;
    onDisplayDeleteMessage({
      id: petData.id,
      deletePetId,
      petName: CardsGridHelpers.findNameByLanguage(petData.dogNameDtoSet, language),
    });
  };

  const publishPetHandler = (e: MouseEvent) => {
    e.preventDefault();

    onPublishPet(petData.id, cookies);
  };

  const hidePetHandler = (e: MouseEvent) => {
    e.preventDefault();

    onHidePet(petData.id, cookies);
  };

  const getMain = (dogPhotoDtoList) => {
    const main = dogPhotoDtoList.find(img => img.main);
    return main ? main.photo : undefined;
  };

  const petCardHandleClick = (e) => {
    handleCurrentDogCardIdUpdate(e);
    if (e.target.nodeName === 'IMG') {
      history.push(`/admin/want-a-friend/dog/${petData.id}`);
    }
  };

  return (
    <>
      <div
        className={styles['pet-card']}
        id={`${petData.id}`}
        onClick={petCardHandleClick}
        role="button"
        tabIndex={0}
      >
        { petData.hasGuardianship
          ? (
            <div className={styles['guardianship-lable']}>
              {translate(TRANSLATE_KEYS.hasGuardianship)}
            </div>
          )
          : null
        }
        { petData.dogStatus === 'NOT_PUBLISHED'
          ? (
            <div className={styles['hidden-lable']}>
              <Icon name="hide" />
            </div>
          )
          : null
        }
        <div
          className={styles['burger-menu']}
          ref={burgerMenuRef}
        >
          {isBurgerMenuClicked
            ? (
              <div
                className={styles['burger-menu__items-wrapper']}
                role="button"
                tabIndex={0}
              >
                {petData.dogStatus === WAFModels.PetPublishStatuses.PUBLISHED
                  ? (
                    <div
                      className={styles['burger-menu__item']}
                      onClick={e => hidePetHandler(e)}
                      role="button"
                      tabIndex={0}
                    >
                      {translate(TRANSLATE_KEYS.hideBtn)}
                    </div>
                  ) : (
                    <div
                      className={styles['burger-menu__item']}
                      onClick={e => publishPetHandler(e)}
                      role="button"
                      tabIndex={0}
                    >
                      {translate(TRANSLATE_KEYS.publishBtn)}
                    </div>
                  )}
                <div
                  className={`${styles['burger-menu__item']} ${styles['burger-menu__item_edit-btn']}`}
                  onClick={() => {
                    onUpdateDogHandler(getDogDataForUpdate(petData, translate), petData);
                    setIsBurgerMenuClicked(false);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {translate(TRANSLATE_KEYS.editBtn)}
                </div>
                <div
                  className={`${styles['burger-menu__item']} ${styles['burger-menu__item_red-btn']}`}
                  onClick={(e) => {
                    displayModalWindow(e);
                    setIsBurgerMenuClicked(false);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {translate(TRANSLATE_KEYS.deleteBtn)}
                </div>
              </div>
            ) : null }
          <div
            className={styles['burger-menu__dots']}
            onClick={() => (setIsBurgerMenuClicked(true))}
            role="button"
            tabIndex={0}
          >
            <Icon name="vertical-menu" />
          </div>
        </div>
        <div className={styles['image-container']}>
          <img
            className={styles['image-container__img']}
            src={getMain(petData.dogPhotoDtoList)}
            alt={`${CardsGridHelpers.findNameByLanguage(petData.dogNameDtoSet, language)}`}
          />
        </div>
        <div className={styles.description}>
          <Link to={`/admin/want-a-friend/dog/${petData.id}`}>
            <p className={styles.description__name}>
              {CardsGridHelpers.findNameByLanguage(petData.dogNameDtoSet, language)}
            </p>
          </Link>
          <DescriptionDetails styles={styles} translate={translate} petData={petData} />
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => setIsBurgerMenuClicked(false)}
        className={
          isBurgerMenuClicked
            ? `${styles.petCardOverlay} ${styles.isVisible}`
            : `${styles.petCardOverlay}`
          }
      />
    </>
  );
};

const mapStateToProps = state => ({ language: state.languageReducer.selectedLanguage.languageValue });

const mapDispatchToProps = dispatch => ({
  onDisplayDeleteMessage: (pet: WAFModels.PetToDelete) => dispatch(AdminWAFPageActions.displayDeleteMessage(pet)),
  onPublishPet: (petId: number, cookies: Cookies) => dispatch(AdminWAFPageActions.publishPet(petId, cookies)),
  onHidePet: (petId: number, cookies: Cookies) => dispatch(AdminWAFPageActions.hidePet(petId, cookies)),
  onUpdateDogHandler: (dogData: WAFPopupStoreModels.DogFormData, petBeforeUpdate: WAFModels.Pet) => {
    dispatch(AdminWAFPageActions.openDogPopupForm());
    dispatch(AdminWAFPopupActions.openPopupForDogUpdate(dogData, petBeforeUpdate));
  },
});

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(withCookies(Card)));
