import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styles from './listHeader.module.scss';
import Button from '../button';
import TRANSLATE_KEYS from '../../../i18n_keys';
import { AdminWAFPageActions } from '../../admin/components/wantAFriendPage/+store/actions';
import { AdminBlogActions } from '../../admin/components/articlesPage/+store/actions';
import { AdminWAFPopupActions } from '../../admin/components/wantAFriendPage/components/popupForm/+store/actions';
import { TranslateFn } from '../../../models/translate-function.models';

interface NewRecordBtnProps {
  small?: boolean;
  onCreatePostHandler: () => void;
  onCreateDogHandler: () => void;
  t: TranslateFn;
}

const NewRecordBtn: FC<NewRecordBtnProps> = ({
  small,
  onCreateDogHandler,
  onCreatePostHandler,
  t: translate,
}) => {
  const path = window.location.pathname;
  const pathAdminBlog = '/admin/blog';
  const isAdminBlog = pathAdminBlog === path;

  return (
    <div
      className={`${styles['create-button']} ${small && styles['create-button-collapse']}`}
      role="button"
      tabIndex={0}
    >
      <Button
        content={isAdminBlog ? translate(TRANSLATE_KEYS.createArticle) : translate(TRANSLATE_KEYS.createRecord)}
        uiType="sunShortBold"
        onClick={isAdminBlog ? onCreatePostHandler : onCreateDogHandler}
        small={small}
      />
    </div>
  );
}

NewRecordBtn.defaultProps = { small: false };

const mapDispatchToProps = dispatch => ({
  onCreatePostHandler: () => dispatch(AdminBlogActions.createNewPost()),
  onCreateDogHandler: () => {
    dispatch(AdminWAFPageActions.openDogPopupForm());
    dispatch(AdminWAFPopupActions.openPopupForDogCreate());
  },
});


export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(NewRecordBtn),
);
