import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import currentYear from './utils/getCurrentYear';
import currentHotelAge from './utils/currentHotelAge.ts';

i18n.use(LanguageDetector).init({
  detection: { order: ['localStorage', 'cookie', 'sessionStorage', 'htmlTag', 'path', 'subdomain', 'querystring'] },
  resources: {
    ua: {
      translations: {
        profileTitle: 'Профіль',
        imgInfo: 'Принаймні 256 x 256px PNG або JPG файл',
        labelName: 'Ім\'я користувача',
        password: 'Пароль',
        signIn: 'Увійти',
        enterNameAndPassword: 'Введіть ім\'я та пароль',
        toTheMain: 'На головну',
        labelEmail: 'Електронна адреса',
        prefLang: 'Бажана мова',
        titleSettings: 'Налаштування аккаунту',
        currentPsw: 'Поточний пароль',
        newPsw: 'Новий пароль',
        saveChanges: 'Зберегти зміни',
        editTool: 'Редагувати',

        allArticles: 'Усі статті',
        allRecords: 'Всі записи',
        allAvailablePets: 'Кількість тварин',
        wantAFriend: 'Хочу друга',
        takeHome: 'Взяти додому',
        takeCare: 'Доглядати',
        blog: 'Блог',
        profile: 'Профіль',
        logOut: 'Вийти',
        settings: 'Налаштування',
        createArticle: 'Створити статтю',
        photoGallery: 'Фотогалерея',

        mainPage: 'Головна',
        mainPhoto: 'Головне фото',
        deleteBtn: 'Видалити',
        hideBtn: 'Сховати',
        editBtn: 'Налаштування',
        publishBtn: 'Публікувати',
        updateBtn: 'Оновити',
        backBtn: 'Назад',
        shareFacebookBtn: 'Поділитися в Facebook',

        createArticleTitle: 'Створити статтю',
        createRecord: 'Створити запис',
        russian: 'Pосійська',
        ukrainian: 'Українська',
        english: 'Англійська',
        inputTitle: 'Назва статті*',
        inputDescription: 'Опис головної статті*',
        declineBtn: 'Відміна',
        createBtn: 'Створити',
        categoryName: 'Категорія*',
        categoryNotSelected: 'Категорія не обрана*',
        createCategory: 'Створити категорію',
        dropImgHere: 'Виберіть файл або перетягніть зображення сюди',
        dropImgHereCaption: 'Мінімальний розмір файлу - 256 x 256px PNG/JPG.\nМаксимальний розмір файлу - 10МБ.',
        isLoaded: 'Завантаження завершено!',
        mainArticle: 'Головна',

        cancelQuestion: 'Ви впевнені, що хочете залишити сторінку?',
        cancelWarning: 'Зверніть увагу, що якщо ви залишите поточну сторінку, всі не збережені дані будуть втрачені.',
        leaveBtn: 'Так, залишити',
        cancelBtn: 'Скасувати',

        deleteArticleTitle: 'Видалення статті',
        deleteQuestion: 'Ви впевнені, що хочете назавжди видалити статтю',

        updateArticleTitle: 'Налаштування статті',
        saveUpdate: 'Зберегти',

        changeMainArticle: 'Зміна головної статті',
        saveNewMainArticle: 'Змінити',
        changeMainArticleWarningBegin: 'Зверніть увагу, що головна стаття',
        changeMainArticleQuestionEnd: 'буде замінена на',

        moreArticles: 'Більше статей',
        moreResults: 'Більше результатів',

        canNotBeDeleted: 'Категорія не може бути видалена - в ній є статті',
        categoryExists: 'Категорія вже існує',
        maxCategoriesAmount: 'Максимальна кількість категорій - 6 вже додана',
        enterCategoryName: 'Введіть назву категорії',

        addImage: 'Додати зображення',
        removeBlock: 'Видалити блок',
        addText: 'Додати текст',
        typeTextHere: 'Введіть сюди текст статті...',
        addContent: 'Додати вміст',
        added: 'Додано',
        enterLink: 'Введіть посилання...',

        sendYourContact: 'Вкажіть ваші контактні дані і ми зв\'яжемося з вами',

        now: 'зараз',
        jan: 'січня',
        fab: 'лютого',
        mar: 'березня',
        apr: 'квітня',
        mai: 'травня',
        jun: 'червня',
        jul: 'липня',
        aug: 'серпня',
        sep: 'вересня',
        oct: 'жовтня',
        nov: 'листопада',
        dec: 'грудня',
        daysAgo: 'дні тому',
        dayAgo: 'день тому',
        hoursAgo: 'години тому',
        hourAgo: 'годину тому',
        hAgo: 'годин тому',
        minsAgo: 'хвилини тому',
        minAgo: 'хвилину тому',
        mAgo: 'хвилин тому',

        saving: 'Збереження',

        answer: 'відповідь',
        answerLength: 'відповіді',
        answersLength: 'відповідей',

        approved: 'перевірені',
        pending: 'в очікуванні',

        admin: 'Aдмін',
        updateSuccessfullySaved: 'Налаштування були оновлені',
        changesHaveBeenSaved: 'Зміни збережено',
        recordCreated: 'Запис створено',
        loginError: 'Будь ласка введіть логін',
        emailError: 'Будь ласка введіть коректну пошту. Пр.: email@gmail.com',
        languageError: 'Будь ласка введіть бажану мову',
        imageError: 'Будь ласка виберіть фото',
        photo: 'фото',

        changePassword: 'Змінити пароль',
        currentPassword: 'Ваш поточний пароль*',
        newPassword: 'Новий пароль*',
        confirmNewPassword: 'Підтвердіть новий пароль*',
        currentPasswordRequired: 'Поле поточний пароль обов\'язкове.',
        newPasswordRequired: 'Поле новий пароль обов\'язкове.',
        passwordValidation: 'Ваш пароль повинен містити принаймні: 8 символів, 1 велику літеру,'
            + ' 1 малу літеру, 1 цифру, 1 спеціальний символ.',
        passwordNotMatch: 'Введені вами паролі не збігаються.',
        wrongCurrentPassword: 'Ви ввели неправильний поточний пароль.',
        passwordChanged: 'Пароль змінено',
        change: 'Змінити',

        welcomeWantsFriend: 'Привіт, я',
        size: 'Розмір',
        sexDog: 'Стать',
        sterilization: 'Стерилізація',
        age: 'Вік',
        smallAge: 'До року',
        mediumAge: 'Від 1 року до 4 ',
        bigAge: 'Більше 5 років',
        male: 'Хлопчик',
        female: 'Дівчинка',
        yes: 'Так',
        no: 'Ні',
        myHistory: 'Моя історія',
        meet: 'Давай знайомитись',
        hasGuardianship: 'Під опікою',
        filters: 'Фільтри',
        dogFilterBeforeYear: 'До року',
        upToSixMonths: 'До 6 міс',
        afterSixMonthsToOneYear: '6 міс-1 рік',
        fromOneToTwoYears: '1-2 роки',
        fromTwoToThreeYears: '2-3 роки',
        fromThreeToFourYears: '3-4 роки',
        fromFourToFiveYears: '4-5 років',
        fromFiveToSixYears: '5-6 років',
        fromSixToEightYears: '6-8 років',
        dogFilterFiveAndMore: '5 і більше',
        dogFilterFromOneToFive: '1-5 років',
        moreThanEightYears: 'Більше 8 років',
        small: 'Маленький',
        medium: 'Середній',
        big: 'Великий',
        noAnimals: 'Тварин немає ...',
        noFriends: 'Що? І жодного друга?',
        noFriendsTitle: 'Поки що у нас немає собак, яких ми могли би дати доглядати',
        noFriendsTitleFilter: 'Наразі немає собачок по заданим параметрам',
        noArticles: 'Де ж нові статті?',
        noArticlesTitle: 'Статті, що підпадають під даний розділ, наразі відсутні',
        noArticleContent: 'Пробачте, ще не готово',
        noArticleContentTitle: 'Контент для цієї статті знаходиться в розробці',
        deletePetTitle: 'Видалення запису',
        deletePetQuestion: 'Ви впевнені, що хочете назавжди видалити запис',

        servicesAndPrices: 'Послуги та ціни',
        aboutUs: 'Про нас',
        contacts: 'Контакти',
        updateDogTitle: 'Редагувати запис',
        createDogTitle: 'Створити запис',
        inputDogName: "Ім'я*",
        selectionListSex: 'Стать',
        selectionListAge: 'Вік',
        selectionListSize: 'Розмір',
        selectionListSterilized: 'Стерилізація',
        inputDogDescription: 'Опис*',
        underCare: 'Під опікою',
        feedbacks: 'Відгуки',
        askAQuestion: 'Поставити запитання',
        askAQuestionShort: 'Зад. питання',
        signUp: 'Записатися',
        services: 'Послуги',
        hotelForAnimals: 'Готель для тварин',
        address: 'просп. Відрадний, 31, Київ',
        copyright: `©${currentYear} DogHotel. Всі права захищені`,
        newsSubscription: 'Підписка на новини',
        wantToKeepUpToDateWithNews: 'Хочете бути в курсі новин?',
        subscribeToOurNewsletter: 'Підписуйтесь на нашу розсилку новин.',
        messageSent: 'Повідомлення надіслано',
        messageNotSent: 'Повідомлення не надіслано',
        emailValidationError: 'Введіть, будь ласка, коректну адресу',
        emailPlaceholderDefault: 'Введіть Вашу адресу електронної пошти…',
        emailPlaceholderMobile: 'Введіть Ваш email...',
        enterYourEmailAddress: 'Вводьте Ваш адресу електронної пошти…',
        hotelForAnimalsInKiev: 'Готель для тварин в Києві',
        forMoreThan20Years: `За більше ніж ${currentHotelAge('uk')} ми зібрали групу професіоналів, котрі піклуються про Вашого`
          + ' вихованця на найвищому рівні.',
        learnMore: 'Дізнатись більше',
        boundlessLoveForAnimals: 'Безмежна любов до тварин наштовхнула нас на думку'
        + ' створити готель для собак.',
        weAreLocatedIn: `Ми знаходимось в приватному секторі в Києві. За більше ніж ${currentHotelAge('uk')} роботи ми`
          + ' навчились мати справу з різними за характером, віком та станом тваринами.',
        ourHotelIsOneOf: 'Наш готель - один з небагатьох з ветеринарною підтримкою. Ваш вихованець цілободово'
          + ' знаходиться під наглядом професіоналів.',
        additionalServices: 'Додаткові послуги',
        additionalServicesSmall: 'Дод. послуги',
        inAdditionToOverexposure: 'Окрім перетримки, ми професійно надаємо послуги з догляду та перевезення тварин.',
        veterinaryCare: 'Ветеринарна допомога',
        transportation: 'Перевезення',
        viewAll: 'Переглянути всі',
        articles: 'Статті',
        goToTheBlog: 'Перейти до блогу',

        sinceEachDog: 'Оскільки всі собаки унікальні, то й підхід до утримання кожного з них індивідуальний.',
        forDogs: 'Для собак',
        homeCare: 'Домашнє утримання',
        sofasPillowsToysChairs: 'Дивани, подушки, іграшки, крісла і телевізор. Всі собаки проживають вдома, на'
          + ' диванах у своїх “батьків”. Навіщо змінювати звичний стиль життя?',
        inOurKindergarden: 'В нашому “дитячому садку” або “скаутському таборі” можна продовжувати свій ритм життя.'
          + ' Звикли просто полежати на подушці або скрутитись в кріслі - будь ласка. Ввечері перегляд'
          + ' телевізора або ранній сніданок в компанії або нічний сон під ковдрою - та не питання.',
        comeAndSee: 'Приїжджайте та погляньте на все своїми очима.',

        aviaryContent: 'Вольєрне утримання',
        weHaveBothSummerAndWinterAviaries: 'У нас є і літні, і зимові вольєри. Вольєри створені для'
          + ' великих і середніх “серйозних” собак, які не можуть бути в “компанії”.',
        rottweilersAsianShepherdsAmericanAkitas: 'Ротвейлери, азіатські вівчарки,'
          + ' американські акіти, стаффордширські тер’єри, ердельр’єри,'
          + ' різеншнауцери. Вони всі чудові, красиві, розумні та добрі “діти”, коли вони знаходяться вдома з'
          + ' господарем, з родиною. Але в готелі для собак вони не перетинаються з іншими товаришами для уникнення'
          + ' бійок та травм. Вони гуляють окремо один від одного,'
          + ' харчуються, грають (за настроєм) з персоналом, обіймаються (якщо хочуть) з людьми. Але …'
          + ' окремо один від одного й інших собак. ',
        food: 'Харчування',
        natural: 'Натуральне:',
        riceLongOrBuckwheat: 'Рис (довгий) або гречка (ядро) з м’ясом та овочами відповідно до сезону. Якщо собака на'
          + ' дієті або погано харчується: курятина або індичка (ви вказуєте, що саме споживає Ваш малюк).',
        dryFood: 'Сухий корм',
        royalCanin: 'Royal Canin (Франція).',
        baskerville: 'Baskerville (Німеччина).',
        optiMeal: 'Opti Meal (Україна).',
        dryFoodIsProvidedIn: 'Сухі корми надаються в будь-якій позиції:'
        + ' і для щенят, для юніорів і для активних за породами, і для літніх собак.',
        softFood: 'М’який корм:',
        cannedBaskerville: 'Консерви Baskerville (широкий вибір).',
        ifTheDogIsMalnourished: 'Якщо собака погано харчується, бажано розповісти всі подробиці'
        + ' і всі улюблені “смаколики”, щоб знайти підхід до малюка.',
        theCostOfLiving: 'Вартість проживання',
        hotelForDogs: 'Готель для собак',
        withOurFood: 'З нашим харчуванням:',
        youCanSpecifyWhatFood: 'Ви можете вказати, який саме корм їсть і полюбляє “малюк” і ми'
        + ' замовимо цей корм для Вашого вихованця. Всі Ваші побажання виконуються.',
        withFoodOfTheOwner: 'З харчуванням господаря:',
        ifYourBabyIsOnAMedicalDiet: 'Якщо Ваш малюк на лікувальній дієті, Ви вказуєте корм, який споживає'
        + ' в даний момент Ваш собака, або можете самостійно його надати.',
        discount: 'Знижка',
        money: 'грн',
        contact: 'Зв\'язатися',
        discountIsProvidedIndividually: '*Знижка надається індивідуально.',
        walkingTheDog: 'Вигул собак',
        theFirstOption: 'Перший варіант:',
        weWalkTheDogOnALeashInThePark: 'На повідку в парку вигулюється стільки разів, скільки собака звик гуляти'
          + ' за тривалістю і кількістю разів. З повідку не відпускається, щоб не втік.',
        theSecondOption: 'Другий варіант:',
        weWalkTheDogInOurOwnPark: 'Гуляє в нашому власному парку, без повідка, стільки часу, скільки'
          + ' забажає. Якщо собака товариська, вона може бешкетувати, гратися, шкодити з іншими добрими'
          + ' собаками. Якщо собака не вміє чи не хоче грати з іншими родичами,'
          + ' вона гуляє сама, без інших собак, в присутності персоналу.',
        veterinaryServices: 'Ветеринарні послуги',
        theWonderfulDoctorAibolit: 'Чудовий лікар Айболит працює в готелі!'
          + ' А тому собаки обов’язково проходять регулярний ветеринарний огляд.',
        ifNecessaryTheFollowingMeasures: 'За необхідністю проводяться такі заходи:'
          + ' вакцинація, дегельмінтизація, пологова допомога,'
          + ' обробка ран та швів, складні порожнинні та офтальмологічні операції, кесарів розтин,'
          + ' стерелізація псів, сук.',
        grooming: 'Грумінг',
        yourDogOrCatHasNotSeen: 'Ваш собака давно не бачив гребінця, мила чи ванни? І не хоче, щоб ви його'
          + ' розчісували чи купали? Ще й до того ж хоче вас укусити? А вам набридло це неслухняне'
          + ' “дитя”, що схоже на сажотруса та має аромат не фрацузьких парфумів?',
        ourHairdresserWillBeHappy: 'Наш перукар із задоволенням зустріне'
          + ' Вас і Вашого вихованця. Розчеше, обробить ковтуни,'
          + ' обріже кігті, помиє та зробить будь-яку зачіску, що Ви б хотіли для свого вихованця.',
        transportationOfAnimals: 'Перевезення тварин',
        ifYouWantToTransportAnAnimal: 'Якщо Ви бажаєте перевезти тварину в готель,'
          + ' DogHotel з радістю все зробить за Вас.',
        weKnowHowToDeliver: 'Ми знаємо як доставити вихованця (тварину) в готель і зробити так, щоб переїзд став для'
          + ' Вашого друга максимально приємним.',
        weTransportOnly: 'Ми здійснюємо перевезення тільки собак - в салоні комфортабельного автобуса.',

        letGetToKnowEachOther: 'Давайте знайомитись!',
        ourHotelIsOneOfTheFirstHotels: 'Наш готель - один з перших готелів для собак, що створений та'
          + ` функціонує в Києві. Готель працює вже більше ${currentHotelAge('uk')}. Безмежна любов до тварин та глибокий`
          + ' професіоналізм спонукали нас створити такого роду сервіс. Я сама є розплідником Цвергшнауцерів,'
          + ' Сибірських Хаскі та Американської Акіти. Це приватний готель, в приватному секторі. ',
        fromTheFirstDayWeMade: 'З першого дня ми розробили для себе низку обов’язкових на нашу думку вимог: в штаті має'
          + ' бути лікар-ветеринар, обслуговуючий персонал повинен працювати цілодобово, всі тварини'
          + ' повинні жити в звичних умовах і годування має формуватися відповідно до замовлення господаря.',
        victoria: 'Вікторія',
        hostessAndNannyOfDogHotel: 'Господарка і “няня” готелю DogHotel',
        todayOurHotelIsOneOfTheFew: 'На сьогоднішній день наш готель - один з небагатьох, що має ветеринарну підтримку'
          + ' тварин. Ми приймаємо абсолютно різних за характером і віком собак. Цілодобово тварини'
          + ' знаходяться під наглядом обслуговуючого персоналу. ',
        team: 'Команда',
        victoriaKishchenko: 'Вікторія Кіщенко',
        victoriaProf: 'Головна няня',
        andreyMalyuti: 'Андрій Малюти',
        andreyProf: 'Головний нянь',
        innaVasilkovskaya: 'Інна Васильківська',
        innaProf: 'Ветеринар',

        content: 'Зміст',
        walking: 'Вигул',
        cats: 'Кішки',

        ourHotelIsConstantlyWorking: 'Наш готель постійно працює над розвитком та покращенням послуг.'
          + ' Для нас також дуже важливо, щоб клієнт завжди був задоволений, і ми слідкуємо за Вашими відгуками.',
        writeAFeedback: 'Написати відгук…',
        showMore: 'Показати більше',
        answering: 'Відповісти',
        showAllAnswers: 'Показати усі відповіді',
        loading: 'Завантаження',

        frequentlyAskedQuestions: 'Часті питання',

        writeToUs: 'Напишіть нам',
        ifForSomeReasonYouCouldNotContact: 'Якщо з будь-яких причин Ви не змогли з нами зв’язатися по'
          + ' телефону, скористуйтесь формою для відправлення повідомлення на E-mail:',
        yourName: 'Ваше ім’я *',
        contactNumber: 'Контактний телефон *',
        messageText: 'Текст повідомлення*',
        messageBioText: 'Напишіть кілька слів про себе',
        sendMessage: 'Відправити',
        ready: 'Готово',
        callUs: 'Зателефонуйте нам',
        socialTheNetwork: 'Соц. мережі',
        ourAddress: 'Наша адреса',
        addressOtradny: 'пр. Відрадний, 31, Київ',
        locale: 'uk',
        moreDetails: 'Детальніше',
        noPosts: 'Наразі публікацій немає',
        all: 'Всі',
        returnToListOfArticles: 'Повернутися до списку статей',
        recommendedArticles: 'Рекомендовані статті',
        welcomeToAdminPage: 'Ласкаво просимо на сторінку адміністратора!',
        askYourQuestions: 'Задайте ваші питання',
        didntFindTheAnswers: 'Не знайшли відповіді на питання? Задайте ваше питання нижче або зателефонуйте нам.',
        theNameMustBe: 'Ім\'я має складатися мінімум з двох літер',
        incorrectNumber: 'Номер телефону некоректний',
        emptyMessage: 'Текст повідомлення не повинен бути порожнім',
        field: 'Поле',
        fieldIncorrect: 'не коректно заповнено',
        emailIncorrect: 'Email некоректний',
        sendingMessage: 'Відправка',
        replace: 'Замінити',
        noContent: 'Наразі вміст тимчасово недоступний',
        loadingImage: 'Завантаження зображення',
        kilobyte: 'КБ',
        uploadingError: 'Помилка завантаження',
        uploadingErrorMessage: 'Під час завантаження зображення на сервер сталася помилка',
        loginErrorMessage: 'Неправильне ім\'я користувача або пароль',
        accessErrorMessage: 'Ваш акаунт тимчасово заблоковано',
        somethingWentWrong: 'Щось пішло не так',
        needPublish: 'Потрібно опублікувати статтю',
        fieldNameNotEmpty: 'Iм\'я не повинне бути пустим',
        IncorrectName: 'Ім\'я некоректне',
        emptyNumber: 'Номер телефону не повинен бути пустим',
        heading404Error: '404 Error',
        message404Error: 'Наші собачки з\'їли цю сторінку, тому її не існує',
        Olena: 'Олена',
        ReviewFromOlena: 'Чудове місце! Дуже рада, що доля звела нас із Вікторією, господаркою готелю. Вікторія разом '
          + 'зі своєю командою забезпечує ідеальні умови перебування тварин у готелі. Добрий персонал, правильно '
          + 'підібране харчування, чисті просторі вольєри для собачок різних розмірів. Доглянута територія, із зеленими'
          + ' кипарисами та туями, повністю закрита територія. Собачки протягом дня гуляють територією під наглядом '
          + 'персоналу, при цьому великі собачки не перетинаються з маленькими. Передбачено організацію ветеринарної '
          + 'допомоги, за потреби. Ми часто користуємося цим готелем та дуже задоволені умовами перебування наших '
          + 'хвостатих. Це просто чудово! Чистота, теплота, дбайливий колектив! Велику подяку хочу висловити Вікторії. '
          + 'Таких людей, як вона практично немає. Завжди увійде в становище, готова прийти на допомогу будь-якої '
          + 'хвилини. Людина з великої літери і величезним серцем, в якому вистачає місця кожному песику, що '
          + 'знаходиться в готелі. Для неї немає «чужих» собак – усіх любить, як своїх). Вирушаючи у відпустку, ми '
          + 'абсолютно спокійні за своїх вихованців. Тому що знаємо, вони в надійних, люблячих руках)). Рекомендую!!',
        ZhuzhaChihuahua_AsyaCat: 'Жужа (Чіхуахуа), Ася (Кішка)',
        Alla: 'Алла',
        ReviewFromAlla: 'Нашому вихованцю 14 років! І ми щасливі, що протягом усього життя кокер-спаніеля ФРЕНКА, у '
          + 'разі канікул та відряджень, на нього чекав теплий прийом і немов материнська турбота в готелі у Вікторії! '
          + 'Наш улюбленець був оточений по-справжньому домашніми умовами та трепетною турботою! Рекомендуємо всім '
          + 'своїм друзям завжди цей готель для чотириногих улюбленців! 🐶 ❤️',
        FrankAmericanCockerSpaniel: 'Френк (Американський кокер-спанієль)',
        XavierAndLubov: 'Ксав\'є та Любов',
        ReviewFromXavierAndLubov: '2015 року ми приїхали в Україну з Франції, і пошук перетримки для нашої Апріль став '
          + 'для нас реальною проблемою. Дуже хотілося знайти для нашої дівчинки сім\'ю, а не посадити її в клітку на '
          + 'час нашої відсутності. Нам пощастило, у цей момент ми знайшли Вікторію. Від однієї назви на сайті «дитячий'
          + ' садок» для ваших тварин стало тепло на душі  Але ми, все-таки, вирішили перевірити чи так це насправді. '
          + 'Реальність виявилася навіть кращою за наші очікування: затишно, чисто, облаштовано відповідно до потреб '
          + '«дітей», які приходять на якийсь час, але почуваються як удома. Натовп веселого народу зустрічав на порозі'
          + ' і проводив всюди. Все довкола дихало добром. З того часу ми регулярно звертаємося до Вікторії, а у нашої '
          + 'Апрільки тепер два будинки у Києві. Від\'їжджаючи, ми абсолютно спокійні, що нашій собачці добре, вона '
          + 'доглянута, нагодована і в класній компанії подружок та друзів. Дякую Вікторії та Андрію, вони професіонали'
          + ' у своїй справі, а ще, вони люди з величезною людською душею!',
        AprilBassetHound: 'Апріль (Бассет-хаунд)',
        TanyaAndAlena: 'Таня та Альона',
        ReviewFromTanyaAndAlena: 'Наш пес - Зінгер потрапив до цього «піонерського табору», коли йому було 4 місяці. Ми'
          + ' дуже переживали - це був наш перший подібний досвід. Дзвонили щодня, а Вікторія нас заспокоювала. З того '
          + 'часу ось уже минуло 6 років. І коли ми привозимо його на перетримку - буває за себе прикро 😊: хвіртка '
          + 'відкривається - хвіст задер і побіг. Жодних сумних поглядів, ніяких сліз розлуки. Звісно, це жарт. Але ми '
          + 'дуже раді, що познайомилися з такими людьми, які з такою любов\'ю ставляться до вихованців!',
        ZingerPembrokeWelshCorgi: 'Зінгер (Вельш-коргі пемброк)',
        JuliaAndVladyslav: 'Юлія та Владислав',
        ReviewFromJuliaAndVladyslav: 'Гарний та надійний готель для собак. Ми вже 6 років відправляємо нашого вестика '
          + 'Джонні до «дитсадка» до Вікторії і завжди спокійні за нього. Він нагодований, грає на свіжому повітрі і '
          + 'навіть не нудьгує за нами. Рекомендуємо!',
        JohnnyWestHighlandWhiteTerrier: 'Джонні (Вест-хайленд-уайт-тер\'єр)',
        AndriiAndOlga: 'Андрій та Ольга',
        ReviewFromAndriiAndOlga: 'Користуємося послугами Вікторії та Андрія вже кілька років. Завжди приємно мати з '
          + 'ними справу. Люди доброзичливі та завжди йдуть назустріч. Можуть допомогти в оформленні довідок та зводити'
          + ' до лікаря. Те саме можу сказати і про персонал. Тепер про головне – про собаку. Зрозуміло, що сказати - '
          + 'не скаже, але після цього готелю завжди виглядає дуже доглянуто, ситенько, та й, загалом, відчувається, що'
          + ' собака сповнена енергії та гарного настрою. Зважаючи на робочу діяльність часто доводиться їхати з країни'
          + ' і часом надовго. До Вікторії та Андрія свого собаку веземо, як до родичів уже (доброї бабусі). Ціна за '
          + 'таке дуже демократична. Я будь-яким господарям рекомендую цей готель.',
        Waxa: 'Вакса',
        Evgenia: 'Євгенія',
        ReviewFromEvgenia: 'Довірити собаку тим людям, у яких ти певен. Ти точно розумієш, що за собакою доглядають '
          + 'професіонали. Сподобалося, що в готелі були не агресивні собаки, а той собака, який агресивний, знаходився'
          + ' у вольєрі.',
        ArgitaDaudze: 'Аргіта Даудзе',
        ReviewFromArgitaDaudze: 'Як посол, я зайнята подорожами та іншими обов’язками, тому мені довелося шукати няню '
          + 'для мого собаки фокстер’єра. Я була рада знайти готель для собак Вікторії. Привітна, обізнана, добре '
          + 'організована людина. Моя собака в дуже хороших руках.',
        NinaFoxTerrier: 'Ніна (фокстер\'єр)',
        Olga: 'Ольга',
        ReviewFromOlga: 'Дуже вдячні Вікторії та Андрієві за добре налагоджений сервіс з обслуговування клієнтів із '
          + 'собаками. Дуже зручно, коли є можливість забрати собаку прямо з дому та привезти його назад. Отримувати '
          + 'відео та фото свого улюбленця в період нашої відсутності у місті. Крім того, якщо виникають проблеми зі '
          + 'здоров\'ям у вихованця під час перебування, вони мають ветеринара, який може оперативно надати допомогу. '
          + 'А також, можна замовити послугу грумера і отримати по приїзду красиво підстриженого, смачно пахучого і '
          + 'задоволеного собаку.',
        BeckyBichonFrize: 'Беккі (Бішон фризе)',
        Leila: 'Лейла',
        ReviewFromLeila: 'Готель для собак Вікторії нам порадила моя сестричка, яка вже кілька років відвозить свого '
          + 'улюбленого лабрадора і завжди у захваті. Наш Арчик завжди залишався або з друзями, або з родичами на '
          + 'короткий термін, і це не становило проблем. Але настав момент, коли собаку потрібно було залишити на '
          + 'тривалий час новорічної відпустки (2 тижні). Звичайно, без рекомендацій, ми не зважилися б відразу '
          + 'відвести улюбленця в незнайоме місце. Це був наш перший та дуже вдалий досвід. Розповім одразу про всі '
          + 'переваги, які ми відчули: коли за Арчиком приїхали і він настільки зрадів і відчув доброту, що він не мав '
          + 'ніякого стресу. Він з радістю заскочив у машину. Ми, щиро кажучи, не чекали від нього такого. За час його '
          + 'перебування жодних проблем не виникло. На завершення його канікул він приїхав у чудовій формі. Здавалося, '
          + 'що він побував на якомусь курорті. Веселий, із лисніючою шерстю, доглянутий - це, звичайно, дуже приємно і'
          + ' відчувається. Щодо корму: у нас перебування було трохи більше, ніж ми запланували, і це не виявилося '
          + 'проблемою. Вікторія спромоглася підібрати корм нашому алергіку і з цим теж немає проблем. Я дуже рада, що '
          + 'ми познайомилися, рада теплому, професійному ставленню до нашого улюбленця. Рада, що є люди, яким можна '
          + 'довіряти. Обов\'язково користуватимемося послугами. Спасибі вам велике!',
        ArchieLabrador: 'Арчі (Лабрадор)',
      },
    },
    en: {
      translations: {
        profileTitle: 'Profile',
        imgInfo: 'At least 256 x 256px PNG or JPG file',
        labelName: 'Username',
        password: 'Password',
        signIn: 'Sign In',
        enterNameAndPassword: 'Enter your name and password',
        toTheMain: 'To the Main',
        labelEmail: 'Email address',
        prefLang: 'Preferred language',
        titleSettings: 'Account settings',
        currentPsw: 'Current password',
        newPsw: 'New password',
        saveChanges: 'Save changes',
        editTool: 'Edit',

        allArticles: 'All articles',
        allRecords: 'All records',
        allAvailablePets: 'Available pets',
        wantAFriend: 'Want a friend',
        takeHome: 'Take home',
        takeCare: 'Take under care',
        blog: 'Blog',
        profile: 'Profile',
        logOut: 'Log out',
        settings: 'Settings',
        createArticle: 'Create article',
        photoGallery: 'Photogallery',

        mainPage: 'Main',
        mainPhoto: 'Main photo',
        deleteBtn: 'Delete',
        hideBtn: 'Hide',
        editBtn: 'Settings',
        publishBtn: 'Publish',
        updateBtn: 'Republish',
        backBtn: 'Back',
        shareFacebookBtn: 'Share on Facebook',

        createArticleTitle: 'Create article',
        createRecord: 'Create record',
        russian: 'Russian',
        ukrainian: 'Ukrainian',
        english: 'English',
        inputTitle: 'Title*',
        inputDescription: 'Description of the main article*',
        declineBtn: 'Cancel',
        createBtn: 'Create',
        categoryName: 'Category*',
        categoryNotSelected: 'Category*',
        createCategory: 'Create category',
        dropImgHere: 'Drag and drop files here or Choose file',
        dropImgHereCaption: 'The minimum file size is 256 x 256px PNG/JPG.\nThe maximum file size is 10MB.',
        isLoaded: 'Uploading is complete!',
        mainArticle: 'Main article',

        cancelQuestion: 'Are you sure you want to leave the page?',
        cancelWarning: 'Please note that if you leave the current page, all unsaved changes will be lost.',
        leaveBtn: 'Yes, leave',
        cancelBtn: 'Cancel',

        deleteArticleTitle: 'Delete article',
        deleteQuestion: 'Are you sure you want to permanently delete the article',

        updateArticleTitle: 'Article settings',
        saveUpdate: 'Save',

        changeMainArticle: 'Replacing the main article',
        saveNewMainArticle: 'Yes, replace',
        changeMainArticleWarningBegin: 'Pay attention that the main article',
        changeMainArticleQuestionEnd: 'will be replaced with the',

        moreArticles: 'More articles',
        moreResults: 'More results',

        canNotBeDeleted: 'Category can\'t be deleted - it contains articles',
        categoryExists: 'Category already exists',
        maxCategoriesAmount: 'Maximum number of categories - 6 is already added',
        enterCategoryName: 'Enter the category name',

        addImage: 'Add image',
        removeBlock: 'Remove block',
        addText: 'Add text',
        typeTextHere: 'Type text here...',
        addContent: 'Add content',
        added: 'Added',
        enterLink: 'Enter a link...',

        sendYourContact: 'Please provide us with your contact information and we\'ll get in touch with you',

        now: 'now',
        jan: 'January',
        fab: 'February',
        mar: 'March',
        apr: 'April',
        mai: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
        daysAgo: 'days ago',
        dayAgo: 'day ago',
        hoursAgo: 'hours ago',
        hourAgo: 'hour ago',
        hAgo: 'hours ago',
        minsAgo: 'minutes ago',
        minAgo: 'minute ago',
        mAgo: 'minutes ago',

        saving: 'Storage',

        answer: 'answer',
        answerLength: 'answer',
        answersLength: 'answers',


        approved: 'approved',
        pending: 'pending',

        admin: 'Admin',
        updateSuccessfullySaved: 'Settings have been updated',
        changesHaveBeenSaved: 'The changes have been saved',
        recordCreated: 'Record created',
        loginError: 'Please enter login',
        emailError: 'Please input valid email. Ex.: email@gmail.com',
        languageError: 'Please choose preferred language',
        imageError: 'Please choose profile picture',
        photo: 'photo(s)',

        changePassword: 'Change password',
        currentPassword: 'You current password*',
        newPassword: 'New password*',
        confirmNewPassword: 'Confirm new password*',
        currentPasswordRequired: 'The current password field is required.',
        newPasswordRequired: 'The new password field is required.',
        passwordValidation: 'Your password must be have at least: 8 characters long, 1 uppercase, 1 lowercase'
            + ' character, 1 number, 1 symbol.',
        passwordNotMatch: 'The passwords you entered do not match.',
        wrongCurrentPassword: 'You entered the wrong current password.',
        passwordChanged: 'Password has been changed',
        change: 'Change',

        welcomeWantsFriend: 'Hello, I am',
        size: 'Size',
        sexDog: 'Sex',
        sterilization: 'Sterilization',
        age: 'Age',
        smallAge: 'Up to a year',
        mediumAge: 'From 1 age till 4',
        bigAge: 'More than 5 years',
        male: 'Boy',
        female: 'Girl',
        yes: 'Yes',
        no: 'No',
        myHistory: 'My story',
        meet: 'Let\'s get acquainted',
        hasGuardianship: 'Under the care',
        filters: 'Filters',
        dogFilterBeforeYear: 'Less than 1 yr',
        upToSixMonths: 'up to 6 mon',
        afterSixMonthsToOneYear: '6 mon-1 year',
        fromOneToTwoYears: '1-2 yrs',
        fromTwoToThreeYears: '2-3 yrs',
        fromThreeToFourYears: '3-4 yrs',
        fromFourToFiveYears: '4-5 yrs',
        fromFiveToSixYears: '5-6 yrs',
        fromSixToEightYears: '6-8 yrs',
        dogFilterFiveAndMore: '5 and more',
        dogFilterFromOneToFive: '1-5 yr',
        moreThanEightYears: 'over 8 yrs',
        small: 'Small',
        medium: 'Medium',
        big: 'Large',
        noAnimals: 'No animals ...',
        noFriends: 'What? No friends?',
        noFriendsTitle: 'Currently, there are no dogs up for adoption',
        noFriendsTitleFilter: 'Currently we have no dogs with selected parameters',
        noArticles: 'Where are the new articles?',
        noArticlesTitle: 'Sorry, no articles here yet',
        noArticleContent: 'Sorry, this article is not finished',
        noArticleContentTitle: 'This content is still being written',
        deletePetTitle: 'Delete record',
        deletePetQuestion: 'Are you sure you want to permanently delete the record',

        servicesAndPrices: 'Prices and services',
        aboutUs: 'About us',
        contacts: 'Contacts',
        updateDogTitle: 'Update record',
        createDogTitle: 'Create record',
        inputDogName: 'Name*',
        selectionListSex: 'Sex',
        selectionListAge: 'Age',
        selectionListSize: 'Size',
        selectionListSterilized: 'Sterilized',
        inputDogDescription: 'Description*',
        underCare: 'Under care',
        feedbacks: 'Feedbacks',
        askAQuestion: 'Ask a question',
        askAQuestionShort: 'Asking',
        signUp: 'Sign up',
        services: 'Services',
        hotelForAnimals: 'Hotel for animals',
        address: 'Kyiv, Vidradnyi Avenue, 31',
        copyright: `©${currentYear} DogHotel. All rights reserved`,
        newsSubscription: 'News subscription',
        wantToKeepUpToDateWithNews: 'Want to keep up to date with news?',
        subscribeToOurNewsletter: 'Subscribe to our newsletter.',
        messageSent: 'Message sent',
        messageNotSent: 'Message not sent',
        emailValidationError: 'Please enter a valid address',
        emailPlaceholderDefault: 'Enter your email address…',
        emailPlaceholderMobile: 'Enter your email...',
        enterYourEmailAddress: 'Enter your email address…',
        hotelForAnimalsInKiev: 'Hotel for animals in Kyiv',
        forMoreThan20Years: `For more than ${currentHotelAge('en')} years, we have gathered a group of professionals who`
          + ' take care of your pet at the highest level.',
        learnMore: 'Learn more',
        boundlessLoveForAnimals: 'Boundless love for animals prompted us to create a hotel for dogs.',
        weAreLocatedIn: `We are located in the private sector in Kyiv. Over more than ${currentHotelAge('en')} years of`
          + ' work, we have learned to deal with animals of different nature, age and condition.',
        ourHotelIsOneOf: 'Our hotel is one of the few with veterinary support. Your pet is under'
          + ' the supervision of professionals around the clock.',
        additionalServices: 'Additional Services',
        additionalServicesSmall: 'Add. Services',
        inAdditionToOverexposure: 'In addition to overexposure, we'
        + ' provide professional care and transportation of animals.',
        veterinaryCare: 'Veterinary Care',
        transportation: 'Transportation',
        viewAll: 'View all',
        articles: 'Articles',
        goToTheBlog: 'Go to the blog',

        sinceEachDog: 'Since all dogs are unique, the approach to their care is just the same.',
        forDogs: 'For dogs',
        homeCare: 'Home care',
        sofasPillowsToysChairs: 'Sofas, pillows, toys, chairs and TV. All dogs live at home,'
          + ' on the couches of their "parents". Why change your usual way of life?',
        inOurKindergarden: 'In our "kindergarden" or "scout camp" you can continue your rhythm of life.'
          + ' Used to just lying on a pillow or curling up in a chair - welcome. Watch TV in the evening or'
          + ' have breakfast in the company or a night\'s sleep under a blanket is not an problem.',
        comeAndSee: 'Come and see everything with your own eyes.',
        aviaryContent: 'Aviary content',
        weHaveBothSummerAndWinterAviaries: 'We have both summer and winter aviaries. Aviaries are designed'
          + ' for large and medium "serious" dogs who can not be in the "company".',
        rottweilersAsianShepherdsAmericanAkitas: 'Rottweilers, Asian Shepherds, American Akitas, Staffordshire'
          + ' Terriers, Airedale Terriers, Giant Schnauzers. They are all wonderful, beautiful, smart and'
          + ' good "children". But that is when they are at home with the owner, with the family, but in hotels for'
          + ' dogs, they do not come across with other friends to avoid fights and injuries. They walk separately'
          + ' from each other, eat, play (when they like) with the staff, hug (if they want) with people.'
          + ' But… separately from each other and other dogs.',
        food: 'Food',
        natural: 'Natural:',
        riceLongOrBuckwheat: 'Rice (long) or buckwheat (kernel) with meat and vegetables according to the season.'
          + ' If the dog is on a diet or malnourished: chicken or turkey (you indicate what your baby eats).',
        dryFood: 'Dry food:',
        royalCanin: 'Royal Canin (France).',
        baskerville: 'Baskerville (Germany).',
        optiMeal: 'Opti Meal (Ukraine).',
        dryFoodIsProvidedIn: 'Dry food is provided in any position: for puppies,'
        + ' for juniors and for active breeds, and for older dogs.',
        softFood: 'Soft food:',
        cannedBaskerville: 'Canned Baskerville (wide selection range).',
        ifTheDogIsMalnourished: 'If the dog is malnourished, it is desirable to tell all the details'
        + ' and all the favorite "delicacies" to find an approach to the baby.',
        theCostOfLiving: 'The cost of living',
        hotelForDogs: 'Hotel for dogs',
        withOurFood: 'With our food:',
        youCanSpecifyWhatFood: 'You can specify what food your "baby" likes and we will order'
        + ' this food for your pet. All your wishes can be fulfilled.',
        withFoodOfTheOwner: 'With food of the owner:',
        ifYourBabyIsOnAMedicalDiet: 'If your baby is on a medical diet, inform us what food'
        + ' your dog is currently consuming, or you can provide it yourself.',
        discount: 'Discount',
        money: 'uah',
        contact: 'Contact',
        discountIsProvidedIndividually: '*Discount is provided individually.',
        walkingTheDog: 'Walking the dog',
        theFirstOption: 'The first option:',
        weWalkTheDogOnALeashInThePark: 'We walk the dog on a leash in the park as many times as the dog'
          + ' is used to during the day. We do not let it walk without a leash as it can run away.',
        theSecondOption: 'The second option:',
        weWalkTheDogInOurOwnPark: 'We walk the dog in our own park, without a leash, for as long as he or she wants.'
          + ' If the dog is sociable, it can play with other good dogs. If the dog does not want to play with'
          + '  other friends, it walks alone, without other dogs, in the presence of staff.',
        veterinaryServices: 'Veterinary services',
        theWonderfulDoctorAibolit: 'The wonderful doctor Aibolit works in the hotel!'
          + ' Therefore, dogs and cats must undergo regular veterinary examinations.',
        ifNecessaryTheFollowingMeasures: 'If necessary, the following measures are taken: vaccination,'
          + ' deworming, maternity care, treatment of wounds and sutures, complex cavitary and ophthalmic'
          + ' operations, cesarean section, sterilization of dogs, bitches, cats.',
        grooming: 'Grooming',
        yourDogOrCatHasNotSeen: 'Your dog or cat has not seen a comb, soap or bath for a long time?'
          + ' And they don\'t want you to comb or bathe them? Do they also want to bite you? Are you tired'
          + ' of this naughty "child" who looks like a chimney sweep and has a scent of non-French perfumes?',
        ourHairdresserWillBeHappy: 'Our hairdresser will be happy to meet you and your pet. Comb,'
          + ' trim hair, trim nails, wash, and make any hairstyle you would like for your pet.',
        transportationOfAnimals: 'Transportation of animals',
        ifYouWantToTransportAnAnimal: 'If you want to transport an animal to the DogHotel, we will be happy'
          + ' to do everything for you.',
        weKnowHowToDeliver: 'We know how to deliver a pet (animal) to the hotel and'
          + ' make the process as pleasant as possible for your friend.',
        weTransportOnly: 'We transport only dogs and cats - in the cabin of a comfortable bus.',

        letGetToKnowEachOther: 'Let\'s get to know each other!',
        ourHotelIsOneOfTheFirstHotels: 'Our hotel is one of the first hotels for dogs, created'
          + ` and operating in Kyiv. The hotel has been operating for over ${currentHotelAge('en')} years. Unlimited love for animals`
          + ' and deep professionalism gave us the idea to create such a service. I am a breeder of Miniature'
          + ' Schnauzers, Siberian Husky and American Akita. This is a private hotel in the private sector.',
        fromTheFirstDayWeMade: 'From the first day, we made a number of mandatory, from our point of view, requirements'
          + ' for ourselves: the staff must have our own veterinarian, the attendants must work around the clock,'
          + ' all animals must live in their usual conditions and the feeding must be formed by the owner\'s order.',
        victoria: 'Victoria',
        hostessAndNannyOfDogHotel: 'Hostess and caretaker of DogHotel',
        todayOurHotelIsOneOfTheFew: 'Today our hotel is one of the few that has Veterinary support for animals.'
          + ' We accept dogs of various characters and ages. The animals are under'
          + ' the supervision of the staff around the clock.',
        team: 'Team',
        victoriaKishchenko: 'Victoria Kishchenko',
        victoriaProf: 'Chief caretaker',
        andreyMalyuti: 'Andrey Malyuti',
        andreyProf: 'Chief caretaker',
        innaVasilkovskaya: 'Inna Vasilkovskaya',
        innaProf: 'Veterinarian',

        content: 'Content',
        walking: 'Walking',
        cats: 'Cats',

        ourHotelIsConstantlyWorking: 'Our hotel is constantly working on the development and improvement of services.'
          + ' It is also very important for us that the client is always satisfied, and we follow your feedback.',
        writeAFeedback: 'Write a feedback…',
        showMore: 'Show more',
        answering: 'Answer',
        showAllAnswers: 'Show All Answers',
        loading: 'Loading',

        frequentlyAskedQuestions: 'Frequently asked Questions',

        writeToUs: 'Write to us',
        ifForSomeReasonYouCouldNotContact: 'If for some reason you could not contact us by'
          + ' phone, use the form to send a message to E-mail:',
        yourName: 'Your name *',
        contactNumber: 'Contact number *',
        messageText: 'Message text *',
        messageBioText: 'Write a few words about yourself',
        sendMessage: 'Send message',
        ready: 'Ready',
        callUs: 'Call us',
        socialTheNetwork: 'Social Networks',
        ourAddress: 'Our address',
        addressOtradny: 'Kyiv, Vidradnyi Avenue, 31',
        locale: 'en',
        moreDetails: 'More details',
        noPosts: 'There are currently no posts',
        all: 'All',
        returnToListOfArticles: 'Return to the list of articles',
        recommendedArticles: 'Recommended articles',
        welcomeToAdminPage: 'Welcome to the Admin page!',
        askYourQuestions: 'Ask your questions',
        didntFindTheAnswers: 'Didn\'t find the answers to your questions? Ask your question below or give us a call.',
        theNameMustBe: 'The name should consist of at least two letters',
        incorrectNumber: 'The phone number is incorrect',
        emptyMessage: 'The text of the message should not be empty',
        field: 'The field',
        fieldIncorrect: 'is incorrect',
        emailIncorrect: 'The email is incorrect',
        sendingMessage: 'Sending Message',
        replace: 'Replace',
        noContent: 'Content is temporarily unavailable for now',
        loadingImage: 'Loading image',
        kilobyte: 'KB',
        uploadingError: 'Uploading Error',
        uploadingErrorMessage: 'An error occurred while uploading the image to the server',
        loginErrorMessage: 'Invalid username or password',
        accessErrorMessage: 'Your account is temporarily blocked',
        somethingWentWrong: 'Something went wrong',
        needPublish: 'You need to publish an article',
        fieldNameNotEmpty: 'The name should not be empty',
        IncorrectName: 'The name is incorrect',
        emptyNumber: 'The phone number should not be empty',
        heading404Error: '404 Error',
        message404Error: 'This page was eaten by our dogs so it doesn’t exist',
        Olena: 'Olena',
        ReviewFromOlena: 'Wonderful place! I am very glad that fate brought us together with Victoria, the owner of the'
          + ' hotel. Victoria, together with her team, provides ideal conditions for the animals stay in the hotel. '
          + 'Kind staff, properly selected food, and clean, spacious enclosures for dogs of different sizes. '
          + 'Well-groomed territory, with green cypresses and thujas, completely closed territory. Dogs during the day '
          + 'walk around the territory under the supervision of staff, while large dogs do not intersect with small '
          + 'ones. Provides veterinary assistance, if necessary. We often use this hotel and are very pleased with the '
          + 'conditions of our stay. It\'s just great! Cleanliness, warmth, and caring staff! I want to express my deep'
          + ' gratitude to Victoria. There are hardly any people like her. Always ready to help at any moment. A man '
          + 'with a capital letter and a huge heart, in which there is enough space for every dog that stays in the '
          + 'hotel. For her, there are no "stranger" dogs - she loves everyone as her own). On vacation, we are '
          + 'absolutely calm with our pets. Because we know they are in safe, loving hands)). I recommend this place!!',
        ZhuzhaChihuahua_AsyaCat: 'Zhuzha (Chihuahua), Asya (Cat)',
        Alla: 'Alla',
        ReviewFromAlla: 'Our pet is 14 years old! And we are happy that during the entire life of the cocker spaniel '
          + 'FRANK, in the case of vacations and business trips, he received a warm welcome and motherly care at the '
          + 'hotel in Victoria! Our pet was surrounded by truly homely conditions and awe-inspiring care! We always '
          + 'recommend this hotel for four-legged pets to all our friends! 🐶 ❤️',
        FrankAmericanCockerSpaniel: 'Frank (American Cocker Spaniel)',
        XavierAndLubov: 'Xavier and Lubov',
        ReviewFromXavierAndLubov: 'In 2015, we came to Ukraine from France, and finding an overstay for our April '
          + 'became a real problem for us. I really wanted to find a family for our girl, and not put her in a cage '
          + 'during our absence. We were lucky, at that moment we found Victoria. Just one name on the site '
          + '"kindergarten" for your animals made me feel warm  But we decided to check if it really is. The reality '
          + 'turned out to be even better than we expected: cozy, clean, equipped in accordance with the needs of '
          + '"children" who come for a while, but feel at home. A crowd of cheerful people met them at the threshold '
          + 'and escorted them everywhere. Everything around breathed goodness. Since then, we regularly contact '
          + 'Victoria, and our Aprilka now has two houses in Kyiv. When we leave, we are absolutely calm, that our dog '
          + 'is good, she is looked after, fed and in the great company of girlfriends and friends. Thanks to Victoria '
          + 'and Andrey, they are professionals in their field, and also, they are people with a huge human soul!',
        AprilBassetHound: 'April (Basset Hound)',
        TanyaAndAlena: 'Tanya and Alena',
        ReviewFromTanyaAndAlena: 'Our dog, Zinger, got to this "pioneer camp" when he was 4 months old. We were very '
          + 'worried; it was our first such experience. They called every day, and Victoria reassured us. Since then, 6'
          + ' years have already passed. And when we bring him on a visit, it’s a shame for ourselves 😊: the gate '
          + 'opens, the tail lifts up, and he runs away. No sad looks, no parting tears. Of course, this is a joke. But'
          + ' we are very glad that we met such people with such love for pets!',
        ZingerPembrokeWelshCorgi: 'Zinger (Pembroke Welsh Corgi)',
        JuliaAndVladyslav: 'Julia and Vladyslav',
        ReviewFromJuliaAndVladyslav: 'Nice and reliable hotel for dogs. We have been sending our little boy Johnny to '
          + '"kindergarten" in Victoria for 6 years and we are always happy for him. He is fed, plays in the fresh air '
          + 'and is not even bored with us. We recommend this place!',
        JohnnyWestHighlandWhiteTerrier: 'Johnny (West Highland White Terrier)',
        AndriiAndOlga: 'Andrii and Olga',
        ReviewFromAndriiAndOlga: 'We have been using the services of Victoria and Andrii for several years. It is '
          + 'always a pleasure to deal with them. These people are friendly and always go out of their way. They can '
          + 'help in drawing up certificates and lead to a doctor. I can say the same about the staff. Now about the '
          + 'main thing - about the dog. It\'s clear what to say - he won\'t say, but after this hotel it always looks '
          + 'very well-groomed, well-fed, and, in general, it feels like the dog is full of energy and in a good mood .'
          + ' Due to work activities, it is often necessary to leave the country, and sometimes for a long time. We '
          + 'take our dog to Viktoria and Andrii, as we already do to relatives (good grandmother). The price for this '
          + 'is very democratic. I recommend this hotel to any hosts.',
        Waxa: 'Waxa',
        Evgenia: 'Evgenia',
        ReviewFromEvgenia: 'Trust the dog to those people in whom you are confident. You definitely understand that '
          + 'professionals care of the dog. I liked that aggressive dogs were not in the hotel, and the aggressive dog '
          + 'was in the aviary.',
        ArgitaDaudze: 'Argita Daudze',
        ReviewFromArgitaDaudze: 'As ambassador occupied with traveling and other duties, I had to look for dog sitter '
          + 'for my fox terrier dog. I was delighted to find Victoria’s dog hotel. Welcoming, knowledgeable, well '
          + 'organized person. My dog is in very good hands.',
        NinaFoxTerrier: 'Nina (Fox terrier)',
        Olga: 'Olga',
        ReviewFromOlga: 'We are very grateful to Victoria and Andrey for the well-established customer service with '
          + 'dogs. It is very convenient when it is possible to pick up a dog directly from home and bring it back. To '
          + 'receive videos and photos of your pet during our absence from the city. In addition, if a pet has health '
          + 'problems during the stay, they have a veterinarian who can provide prompt assistance. And also, you can '
          + 'order the service of a groomer and get a beautifully trimmed, deliciously smelling and contented dog upon '
          + 'arrival.',
        BeckyBichonFrize: 'Becky (Bichon Frize)',
        Leila: 'Leila',
        ReviewFromLeila: 'The Victoria\'s hotel for dogs was recommended to us by my sister, who has been taking her '
          + 'beloved Labrador for several years and was always delighted. Our Archik always stayed either with friends '
          + 'or with relatives for a short time and this was not a problem. But the moment came when the dog had to be '
          + 'left for a long New Year\'s holidays (2 weeks). Of course, without recommendations, we would not dare to '
          + 'immediately take the pet to an unfamiliar place. It was our first and very successful experience. I’ll '
          + 'tell you right away about all the benefits that we felt: when they came for Archik and he was so happy and'
          + ' felt kindness that he didn’t have any stress. He happily jumped into the car. We honestly did not expect '
          + 'this from him. There were no problems during his stay. At the end of his vacation, he arrived in great '
          + 'shape. It looked like he was in some kind of resort. Cheerful, glossy wool, well-groomed - this, of '
          + 'course, is very pleasant and felt. Regarding the food: our stay was a bit longer than we planned and it '
          + 'didn\'t turn out to be a problem. Victoria was able to pick up food for our allergy sufferer and there are'
          + ' no problems with this either. I am very glad that we met, I am glad for the warm, professional attitude '
          + 'towards our pet. I\'m glad there are people you can trust. We will definitely use the services. Thank you '
          + 'very much!',
        ArchieLabrador: 'Archie (Labrador)',
      },
    },
  },
  fallbackLng: 'ua',
  lng: localStorage.getItem('i18nextLng') || 'ua',
  debug: true,

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },

  react: { wait: true },
});

export default i18n;
