import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './about.module.scss';
import grid from '../../../../assets/styles/grid.module.scss';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.box}>
      <section className={`${styles.container} ${grid.row}`}>
        <div className={`${styles.picture} ${grid['col-6']}`}>
          <img
            src={'https://res.cloudinary.com/dcx8ko4xm/image/upload/'
              + 'c_limit,h_393,q_auto:good,w_359/v1539857768/Home/pic_heart.png'}
            alt="love animals"
          />
        </div>
        <div className={`${styles.text} ${grid['col-6']}`}>
          <p>
            {t('boundlessLoveForAnimals')}
          </p>
          <p>
            {t('weAreLocatedIn')}
          </p>
          <p>
            {t('ourHotelIsOneOf')}
          </p>
        </div>
      </section>
    </div>
  );
};

export default withTranslation('translations')(AboutUs);
