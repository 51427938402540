import React from 'react';
import PropTypes from 'prop-types';

import Add from './icon-set/add';
import Close from './icon-set/close';
import CloseSmall from './icon-set/close-small';
import CheckMark from './icon-set/check-mark';
import Edit from './icon-set/edit';
import Text from './icon-set/text';
import TextBold from './icon-set/text-bold';
import TextItalic from './icon-set/text-italic';
import TextSize from './icon-set/text-size';
import TextQuote from './icon-set/text-quote';
import Link from './icon-set/link';
import ArrowNext from './icon-set/arrow-next';
import Quote from './icon-set/quote';
import Image from './icon-set/image';
import ImageUpload from './icon-set/image-upload';
import Back from './icon-set/back';
import BackDisabled from './icon-set/back-disabled';
import ArrowDown from './icon-set/arrow-down';
import ArrowUp from './icon-set/arrow-up';
import Pencil from './icon-set/pencil';
import Repeat from './icon-set/repeat';
import Trash from './icon-set/trash';
import TrashBlocked from './icon-set/trash-blocked';
import TrashPopup from './icon-set/trash-popup';
import Remove from './icon-set/remove';
import Checkbox from './icon-set/checkbox';
import ExclamationMark from './icon-set/exclamation-mark';
import VerticalMenu from './icon-set/vertical-menu';
import AppLogo from './icon-set/logo';
import Settings from './icon-set/settings';
import Hide from './icon-set/hide';
import Bell from './icon-set/bell';
import Show from './icon-set/show';
import StarSolid from './icon-set/star-solid';
import StarRegular from './icon-set/star-regular';
import { IconModels } from './icon.models';

const Icon = (props: IconModels.Props) => {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.name) {
    case 'add':
      return <Add />;
    case 'close':
      return <Close />;
    case 'close-small':
      return <CloseSmall />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'check-mark':
      return <CheckMark />;
    case 'edit':
      return <Edit />;
    case 'text':
      return <Text />;
    case 'text-bold':
      return <TextBold />;
    case 'text-italic':
      return <TextItalic />;
    case 'text-size':
      return <TextSize />;
    case 'text-quote':
      return <TextQuote />;
    case 'link':
      return <Link />;
    case 'quote':
      return <Quote />;
    case 'arrow-next':
      return <ArrowNext />;
    case 'image':
      return <Image />;
    case 'image-upload':
      return <ImageUpload {...props} />;
    case 'back':
      return <Back />;
    case 'back-disabled':
      return <BackDisabled />;
    case 'arrow-down':
      return <ArrowDown {...props} />;
    case 'arrow-up':
      return <ArrowUp {...props} />;
    case 'pencil':
      return <Pencil />;
    case 'repeat':
      return <Repeat />;
    case 'trash':
      return <Trash />;
    case 'trash-blocked':
      return <TrashBlocked />;
    case 'remove':
      return <Remove />;
    case 'exclamation-mark':
      return <ExclamationMark />;
    case 'trash-popup':
      return <TrashPopup />;
    case 'vertical-menu':
      return <VerticalMenu />;
    case 'app-logo':
      return <AppLogo />;
    case 'settings':
      return <Settings />;
    case 'hide':
      return <Hide />;
    case 'bell':
      return <Bell />;
    case 'show':
      return <Show />;
    case 'star-solid':
      return <StarSolid />
    case 'star-regular':
      return <StarRegular />
    default:
      return <Add />;
  }
};

Icon.propTypes = { name: PropTypes.string.isRequired };

export default Icon;
