import React from 'react';
import parse from 'html-react-parser';
import styles from '../../ArticleContent.module.scss';

import { ArticleContentModels } from '../../article-content.models';

const Paragraph = ({ content }: ArticleContentModels.FactoryItemProps) => (
  <p className={styles.text}>{parse(content)}</p>
);

export default Paragraph;
