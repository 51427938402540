import React, { FC } from 'react';
import navigationStyles from '../../RecommendedArticles.module.scss';
import { ArrowButtonDirection, SliderModels } from '../../recommended-articles.models';
import ArrowButton from '../ArrowButton';

const Navigation: FC<SliderModels.NavigationProps> = ({
  isDisabledLeftButton,
  isDisabledRightButton,
  handleClickToNextSlide,
  handleClickToPrevSlide,
}) => (
  <div className={navigationStyles.navigation}>
    <ArrowButton
      onClick={handleClickToPrevSlide}
      disabled={isDisabledLeftButton}
      direction={ArrowButtonDirection.Left}
    />
    <ArrowButton
      onClick={handleClickToNextSlide}
      disabled={isDisabledRightButton}
      direction={ArrowButtonDirection.Right}
    />
  </div>
  )

export default Navigation;
