const mockUsers = [
  {
    image: 'https://res.cloudinary.com/doghotelua/image/upload/v1579771695/feedback/dog-6_fuyrqf.jpg',
    review: 'ReviewFromJuliaAndVladyslav',
    name: 'JuliaAndVladyslav',
    info: 'JohnnyWestHighlandWhiteTerrier',
    id: 0,
    isActive: true,
  },
  {
    image: 'https://res.cloudinary.com/doghotelua/image/upload/v1578922556/feedback/dog-2_ryy5c8.jpg',
    review: 'ReviewFromAlla',
    name: 'Alla',
    info: 'FrankAmericanCockerSpaniel',
    id: 1,
    isActive: false,
  },
  {
    image: 'https://res.cloudinary.com/doghotelua/image/upload/v1578922556/feedback/dog-5_t9s5w8.jpg',
    review: 'ReviewFromTanyaAndAlena',
    name: 'TanyaAndAlena',
    info: 'ZingerPembrokeWelshCorgi',
    id: 2,
    isActive: false,
  },
  {
    image: 'https://res.cloudinary.com/doghotelua/image/upload/v1579771695/feedback/dog-8_c0uhbt.jpg',
    review: 'ReviewFromArgitaDaudze',
    name: 'ArgitaDaudze',
    info: 'NinaFoxTerrier',
    id: 3,
    isActive: false,
  },
];

export default mockUsers;
