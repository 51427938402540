import { ProfileActionTypes } from './profileActions.models';
import { ProfileStoreModels, ProfileAction } from './profileStore.models';

export const INITIAL_STATE: ProfileStoreModels.State = {
  userData: {
    userId: 0,
    imageLink: '',
    login: '',
    email: '',
    preferredLanguage: {
      languageId: 0,
      languageValue: '',
    },
  },
  isLoading: false,
  error: '',
  selectedImg: null,
  imgIsLoading: false,
  imgIsLoaded: false,
  isModalOpen: false,
  isPasswordChanged: false,
  displayAlert: false,
};

const adminProfileReducer = (
  state: ProfileStoreModels.State = INITIAL_STATE,
  action: ProfileAction): ProfileStoreModels.State => {
  switch (action.type) {
    case ProfileActionTypes.fetchProfileDataStart:
      return {
        ...state,
        isLoading: true,
      };

    case ProfileActionTypes.fetchProfileDataSuccess:
      return {
        ...state,
        isLoading: false,
        userData:
          {
            userId: action.payload.userId,
            imageLink: action.payload.imageLink,
            login: action.payload.login,
            email: action.payload.email,
            preferredLanguage: action.payload.preferredLanguage,
          },
      };

    case ProfileActionTypes.fetchProfileDataError:
      return {
        ...INITIAL_STATE,
        error: action.payload.error,
        isLoading: false,
      };

    case ProfileActionTypes.adminUpdateProfileRequest:
      return {
        ...state,
        isLoading: true,
      };

    case ProfileActionTypes.adminUpdateProfileSuccess:
      return {
        ...state,
        isLoading: false,
        userData:
          {
            userId: action.payload.userId,
            imageLink: action.payload.imageLink,
            login: action.payload.login,
            email: action.payload.email,
            preferredLanguage: action.payload.preferredLanguage,
          },
      };

    case ProfileActionTypes.adminUpdateProfileFailure:
      return {
        ...INITIAL_STATE,
        error: action.payload.error,
        isLoading: false,
      };

    case ProfileActionTypes.openChangePasswordModal:
      return {
        ...state,
        isModalOpen: true,
      };

    case ProfileActionTypes.closeChangePasswordModal:
      return {
        ...state,
        isModalOpen: false,
      };

    case ProfileActionTypes.adminUpdatePasswordStart:
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case ProfileActionTypes.adminUpdatePasswordSuccess:
      return {
        ...state,
        isModalOpen: false,
        displayAlert: true,
        isLoading: false,
      };

    case ProfileActionTypes.adminUpdatePasswordFailure:
      return {
        ...state,
        error: action.payload.error,
        isModalOpen: true,
        isLoading: false,
      };

    case ProfileActionTypes.hideProfileAlert:
      return {
        ...state,
        displayAlert: false,
      };

    default:
      return state;
  }
};

export default adminProfileReducer;
