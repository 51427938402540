import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withRouter } from 'react-router';
import { fetchPet, createNewDog, fetchError } from '../../+store/actions';
import userDogDetailsComponent from './userDogDetailsComponent';
import selectLanguage from '../../../language/+store/actions';
import { sendMessageWantDog, sendSuccessNull } from '../../../shared-ui/send-message/+store/actions';
import { DispatchFn } from '../../../../types/dispatch.type';
import { Message } from '../../../../types/message.interface';
import State from './models/userDogDetailsState.interface';

const mapStateToProps = (state: State) => ({
  pet: state.wantAFriendUserPagesReducer.pet,
  selectedLanguage: state.languageReducer.selectedLanguage.languageValue,
  petName: state.wantAFriendUserPagesReducer.petName,
  petTitle: state.wantAFriendUserPagesReducer.petTitle,
  petDescription: state.wantAFriendUserPagesReducer.petDescription,
  petsAllList: state.wantAFriendUserPagesReducer.petsAllList,
  error: state.wantAFriendUserPagesReducer.error,
  success: state.messageReducer.success,
});

const mapDispatchToProps = (dispatch: DispatchFn) => ({
  getPet: (id: string, cookies: Cookies) => fetchPet(id, cookies)(dispatch),
  handleSelectLanguage: (value: string) => dispatch(selectLanguage(value)),
  getUpdateDog: () => dispatch(createNewDog()),
  onSendMessage: (message: Message) => sendMessageWantDog(message)(dispatch),
  sendSuccessNull: () => dispatch(sendSuccessNull()),
  resetPetError: () => dispatch(fetchError(null)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(userDogDetailsComponent))),
);
