import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './services.module.scss';
import button from '../../../shared-ui/button/button.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const Services = () => {
  const { t: translate } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <h2 className={styles.title}>{translate(TRANSLATE_KEYS.additionalServices)}</h2>
        <div className={styles.text}>
          {translate('inAdditionToOverexposure')}
        </div>
      </div>
      <div className={`${styles.box} ${styles['img-box']}`}>
        <div className={styles.images}>
          <figure>
            <img
              className={styles.img}
              src="https://res.cloudinary.com/dcx8ko4xm/image/upload/v1540238973/Home/ic_grooming.svg"
              alt="cat and dog grooming"
            />
            <figcaption className={styles.figcaption}>{translate(TRANSLATE_KEYS.grooming)}</figcaption>
          </figure>
        </div>
        <div className={styles.images}>
          <figure>
            <img
              className={styles.img}
              src="https://res.cloudinary.com/dcx8ko4xm/image/upload/v1540239026/Home/ic_veterinary_care.svg"
              alt="veterinary care"
            />
            <figcaption className={styles.figcaption}>{translate(TRANSLATE_KEYS.veterinaryCare)}</figcaption>
          </figure>
        </div>
        <div className={styles.images}>
          <figure>
            <img
              className={styles.img}
              src="https://res.cloudinary.com/dcx8ko4xm/image/upload/v1540239008/Home/ic_transportation.svg"
              alt="transportation your pets"
            />
            <figcaption className={styles.figcaption}>{translate(TRANSLATE_KEYS.transportation)}</figcaption>
          </figure>
        </div>
      </div>
      <div className={styles.button}>
        <Link to="/prices" className={`${button.button} ${button.sun}`}>{translate(TRANSLATE_KEYS.viewAll)}</Link>
      </div>
    </section>
  );
};

export default withTranslation('translations')(Services);
