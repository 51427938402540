import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AdminWAFPageActions } from '../../../+store/actions';
import styles from './dogPageButtons.module.scss';
import Icon from '../../../../../../shared-ui/icon';
import { DeleteButtonModels } from '../dogDetailsPage.models';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { WAFModels } from '../../../../../../../models/WAFPage.models';
import { defaultHeaderHeight } from '../../../constants';

const DeleteButton: FC<DeleteButtonModels.Props> = ({
  pet,
  title,
  position = { top: defaultHeaderHeight },
  onDisplayDeleteMessage,
  t: translate,
}) => {
  const displayModalWindow = () => {
    const deletePetId = pet.parentDogId || pet.id;
    onDisplayDeleteMessage({
      id: pet.id,
      deletePetId,
      petName: title,
    });
  };

  return (
    <button
      className={styles['buttons-wrapper__delete']}
      onClick={displayModalWindow}
    >
      <Icon name="trash" />
      <span className={styles['tooltip-text']} style={position}>
        {translate(TRANSLATE_KEYS.deleteBtn)}
      </span>
    </button>
  );
};

const mapDispatchToProps = dispatch => (
  { onDisplayDeleteMessage: (pet: WAFModels.PetToDelete) => dispatch(AdminWAFPageActions.displayDeleteMessage(pet)) }
);

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(DeleteButton),
);
