import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import TRANSLATE_KEYS from '../../../../i18n_keys';

import styles from './story.module.scss';

const Story: FC = () => {
  const [translate] = useTranslation();

  return (
    <>
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={`${styles['block-image']} ${styles['block-image']}`}>
              <picture>
                <source
                  srcSet="https://res.cloudinary.com/doghotelua/image/upload/v1581003991/about-us/IMG_1228_xgimnu.jpg"
                  media="(max-width: 958px)"
                />
                <source
                  srcSet="https://res.cloudinary.com/doghotelua/image/upload/v1598970084/about-us/Villa_zougvh.jpg"
                />
                <img
                  className={styles['about-image']}
                  src="https://res.cloudinary.com/doghotelua/image/upload/v1598970084/about-us/Villa_zougvh.jpg"
                  alt="House"
                />
              </picture>
            </div>
            <div className={styles['block-wrapper']}>
              <div className={`${styles['block-text']} ${styles['block-text-main']}`}>
                <h1 className={styles.heading}>{translate(TRANSLATE_KEYS.letGetToKnowEachOther)}</h1>
                <p className={styles.text}>
                  {translate(TRANSLATE_KEYS.ourHotelIsOneOfTheFirstHotels)}
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.block} ${styles['block-above']}`}>
            <div className={`${styles['block-text']} ${styles['block-review-wrapper']}`}>
              <p className={styles.review}>
                {translate(TRANSLATE_KEYS.fromTheFirstDayWeMade)}
              </p>
              <div className={styles.owner}>
                <img
                  className={styles.avatar}
                  src="https://res.cloudinary.com/doghotelua/image/upload/v1581003990/about-us/IMG_1236_erkhg4.jpg"
                  alt="Person"
                />
                <div>
                  <p className={styles.name}>{translate(TRANSLATE_KEYS.victoria)}</p>
                  <p className={styles.description}>{translate(TRANSLATE_KEYS.hostessAndNannyOfDogHotel)}</p>
                </div>
              </div>
            </div>
            <div className={styles['block-image']}>
              <img
                className={styles['about-image']}
                src={
                  'https://res.cloudinary.com/doghotelua/image/upload/v1581003990/'
                  + 'about-us/2019-12-27_19.30.49_ixuuf2.jpg'
                }
                alt="Certificate"
              />
            </div>
          </div>
          <div className={styles['text-box']}>
            <p className={styles['text-description']}>
              {translate(TRANSLATE_KEYS.todayOurHotelIsOneOfTheFew)}
            </p>
          </div>
        </div>
      </div>
      <div className={styles['image-box']} />
    </>
  );
};

export default withTranslation('translations')(Story);
