const dogPageTranslations = {
  uk: {
    welcomeWantsFriend: 'Привіт, я',
    meet: 'Давай знайомитись',
    sexDog: 'Стать',
    male: 'Хлопчик',
    female: 'Дівчинка',
    age: 'Вік',
    smallAge: 'До року',
    mediumAge: 'Від 1 року до 4 ',
    bigAge: 'Більше 5 років',
    size: 'Розмір',
    sterilization: 'Стерилізація',
    yes: 'Так',
    no: 'Ні',
    myHistory: 'Моя історія',
    deletePetTitle: 'Видалення запису',
    deletePetQuestion: 'Ви впевнені, що хочете назавжди видалити запис',
    deleteBtn: 'Видалити',
    cancelBtn: 'Скасувати',
  },
  ru: {
    welcomeWantsFriend: 'Привет, я',
    meet: 'Давай знакомиться',
    sexDog: 'Пол',
    male: 'Мальчик',
    female: 'Девочка',
    age: 'Возраст',
    smallAge: 'До года',
    mediumAge: 'От 1 года до 4 лет',
    bigAge: 'Больше 5 лет',
    size: 'Размер',
    sterilization: 'Стерилизация',
    yes: 'Да',
    no: 'Нет',
    myHistory: 'Моя история',
    deletePetTitle: 'Удаление записи',
    deletePetQuestion: 'Вы уверены, что хотите навсегда удалить запись',
    deleteBtn: 'Удалить',
    cancelBtn: 'Отменить',
  },
  en: {
    welcomeWantsFriend: 'Hello, I am',
    meet: 'Let\'s get acquainted',
    sexDog: 'Sex',
    male: 'Boy',
    female: 'Girl',
    age: 'Age',
    smallAge: 'Up to a year',
    mediumAge: 'From 1 age till 4',
    bigAge: 'More than 5 years',
    size: 'Size',
    sterilization: 'Sterilization',
    yes: 'Yes',
    no: 'No',
    myHistory: 'My story',
    deletePetTitle: 'Delete record',
    deletePetQuestion: 'Are you sure you want to permanently delete the record',
    deleteBtn: 'Delete',
    cancelBtn: 'Cancel',
  },
};

export default dogPageTranslations;
