import { BlogActions, BlogActionsTypes } from './blogActions.models';
import { URL_ADMIN_ARTICLES,
  URL_ADMIN_MAIN_ARTICLE,
  URL_ADMIN_PUBLISH_ARTICLE,
  URL_ADMIN_HIDE_ARTICLE, URL_ADMIN_ARTICLE_SNAPSHOT, URL_ADMIN_ALL_ARTICLES_TITLES } from '../../../../../endpoints';
import requestWithHeaders from '../../../../../utils/withHeaders';
import getSelectedLanguage from '../../../../../utils/getSelectedLanguage';

export namespace AdminBlogActions {
  export const fetchStart: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.REQUEST_START });

  export const fetchEnd: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.REQUEST_END });

  export const fetchError: BlogActions.ErrorHandler = error => ({
    type: BlogActionsTypes.REQUEST_ERROR,
    payload: { error },
  });

  export const displayDeleteMessage: BlogActions.DisplayDeleteMessage = articleDeleteData => ({
    type: BlogActionsTypes.BLOG_DISPLAY_DELETE_MESSAGE,
    payload: { articleDeleteData },
  });

  export const hideDeleteMessage: BlogActions.SimpleAction = () => (
    { type: BlogActionsTypes.BLOG_HIDE_DELETE_MESSAGE }
  );

  export const clearArticles: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.CLEAR_ARTICLES });

  export const clearAllArticles: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.CLEAR_ALL_ARTICLES });

  export const createNewPost: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.CREATE_NEW_POST });

  export const editPost: BlogActions.EditPost = id => ({
    type: BlogActionsTypes.EDIT_POST,
    payload: { id },
  });

  export const toggleChangeMainArticleMessage: BlogActions.ToggleChangeMainArticleMessage = (
    mainArticles = {}, browserPathCanUpdate,
  ) => ({
    type: BlogActionsTypes.TOGGLE_CHANGE_MAIN_ARTICLE_MESSAGE,
    payload: { changeMainArticleMessageData: { ...mainArticles, browserPathCanUpdate } },
  });

  export const fetchMainArticleSuccess: BlogActions.FetchMainArticleSuccess = (mainArticle, selectedLanguage) => ({
    type: BlogActionsTypes.ADMIN_FETCH_MAIN_ARTICLE,
    payload: { mainArticle, selectedLanguage },
  });

  export const fetchMainArticle: BlogActions.FetchMainArticle = (cookies) => (dispatch) => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    dispatch(fetchStart());
    requestWithHeaders({ url: URL_ADMIN_MAIN_ARTICLE }, cookies)
      .then(response => dispatch(fetchMainArticleSuccess(response.data, selectedLanguage)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const fetchArticleSuccess: BlogActions.FetchArticleSuccess = article => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    return {
      type: BlogActionsTypes.ADMIN_FETCH_ARTICLE,
      payload: { article, selectedLanguage },
    };
  };

  export const fetchArticle: BlogActions.FetchArticle = (id, cookies) => dispatch => {
    dispatch(fetchStart());
    requestWithHeaders(
      { url: `${URL_ADMIN_ARTICLES}${id}` },
      cookies,
    )
      .then(response => dispatch(fetchArticleSuccess(response.data)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const fetchArticlesSuccess: BlogActions.FetchArticlesSuccess = (articlesList, selectedLanguage) => ({
    type: BlogActionsTypes.ADMIN_FETCH_ARTICLES,
    payload: {
      articlesList,
      selectedLanguage,
    },
  });

  export const fetchArticles: BlogActions.FetchArticles = (page, cookies) => (dispatch) => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    dispatch(fetchStart());
    requestWithHeaders({ url: `${URL_ADMIN_ARTICLES}page=${page}` }, cookies)
      .then(response => dispatch(fetchArticlesSuccess(response.data.content, selectedLanguage)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const fetchAllArticlesTitlesSuccess: BlogActions.FetchAllArticlesTitlesSuccess = articleTitles => ({
    type: BlogActionsTypes.ADMIN_FETCH_ALL_ARTICLES_TITLES,
    payload: { articleTitles },
  });

  export const fetchAllArticlesTitles: BlogActions.FetchAllArticlesTitles = (cookies) => (dispatch) => {
    dispatch(fetchStart());
    requestWithHeaders({ url: URL_ADMIN_ALL_ARTICLES_TITLES }, cookies)
      .then(response => dispatch(fetchAllArticlesTitlesSuccess(response.data)))
      .catch(error => dispatch(fetchError(error)));
  };

  export const deleteArticleStart: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.DELETE_ARTICLE_START });

  export const deleteArticleSuccess: BlogActions.DeleteArticleSuccess = id => ({
    type: BlogActionsTypes.DELETE_ARTICLE_SUCCESS,
    payload: { id },
  });

  export const deleteArticleError: BlogActions.ErrorHandler = error => ({
    type: BlogActionsTypes.DELETE_ARTICLE_ERROR,
    payload: { error },
  });

  export const deleteArticle: BlogActions.DeleteArticle = (articleIds, cookies) => (dispatch) => {
    const { id, parentArticleId } = articleIds;
    dispatch(deleteArticleStart());
    return requestWithHeaders({
      method: 'DELETE',
      url: `${URL_ADMIN_ARTICLES}${parentArticleId || id}`,
    }, cookies)
      .then(() => dispatch(deleteArticleSuccess(id)))
      .catch(error => dispatch(deleteArticleError(error)));
  };

  export const publishArticleStart: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.PUBLISH_ARTICLE_START });

  export const publishArticleSuccess: BlogActions.HideOrPublishArticleSuccess = (id, article) => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    return {
      type: BlogActionsTypes.PUBLISH_ARTICLE_SUCCESS,
      payload: { id, article, selectedLanguage },
    };
  };

  export const publishArticleError: BlogActions.ErrorHandler = error => ({
    type: BlogActionsTypes.PUBLISH_ARTICLE_ERROR,
    payload: { error },
  });

  export const publishArticle: BlogActions.PublishArticle = (id, cookies) => (dispatch) => {
    dispatch(publishArticleStart());
    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_PUBLISH_ARTICLE}${id}`,
    }, cookies)
      .then(({ data }) => {
        dispatch(publishArticleSuccess(id, data));
      })
      .catch(error => dispatch(publishArticleError(error)));
  };

  export const hideArticleStart: BlogActions.SimpleAction = () => ({ type: BlogActionsTypes.HIDE_ARTICLE_START });

  export const hideArticleSuccess: BlogActions.HideOrPublishArticleSuccess = (id, article) => {
    const selectedLanguage = getSelectedLanguage().languageValue;

    return {
      type: BlogActionsTypes.HIDE_ARTICLE_SUCCESS,
      payload: { id, article, selectedLanguage },
    };
  };

  export const hideArticleError: BlogActions.ErrorHandler = error => ({
    type: BlogActionsTypes.HIDE_ARTICLE_ERROR,
    payload: { error },
  });

  export const hideArticle: BlogActions.HideArticle = (id, cookies) => (dispatch) => {
    dispatch(hideArticleStart());
    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_HIDE_ARTICLE}${id}`,
    }, cookies)
      .then(({ data }) => {
        dispatch(hideArticleSuccess(id, data));
        return data.id;
      })
      .catch(error => dispatch(hideArticleError(error)));
  };

  export const createArticleSnapshot: BlogActions.CreateArticleSnapshot = (DTO, cookies) => (dispatch) => {
    dispatch(fetchStart());
    return requestWithHeaders({
      method: 'POST',
      url: `${URL_ADMIN_ARTICLE_SNAPSHOT}`,
      data: { ...DTO },
    }, cookies)
      .then(({ data }) => {
        dispatch((fetchArticleSuccess(data)));
        return data.id;
      })
      .catch(error => dispatch(fetchError(error)));
  };

  export const updateArticleSnapshot: BlogActions.UpdateArticleSnapshot = (DTO, cookies) => (dispatch) => {
    dispatch(fetchStart());
    requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_ARTICLE_SNAPSHOT}`,
      data: { ...DTO },
    }, cookies)
      .then(({ config: { data } }) => {
        const dataObject = JSON.parse(data);
        fetchArticle(dataObject.id, cookies)(dispatch);
        fetchEnd();
      })
      .catch(error => fetchError(error));
  };
}
