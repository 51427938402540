import requestWithHeaders from '../../../../../utils/withHeaders';
import { REQUEST_FEEDBACK_BEGIN,
  REQUEST_FEEDBACK_SUCCESS,
  REQUEST_FEEDBACK_FAILURE } from './types';

const QUANTITY_OF_FEEDBACK_TO_SHOW = 10;

export const requestFeedbackBegin = () => ({ type: REQUEST_FEEDBACK_BEGIN });

export const requestFeedbackSuccess = ({ feedback, hasMoreFeedback }) => ({
  type: REQUEST_FEEDBACK_SUCCESS,
  payload: {
    feedback,
    hasMoreFeedback,
  },
});

export const requestFeedbackFailure = error => ({
  type: REQUEST_FEEDBACK_FAILURE,
  payload: { error },
});

export function requestInitialFeedback() {
  return (dispatch) => {
    dispatch(requestFeedbackBegin());
    return requestWithHeaders({ url: '../mock-data/mock-feedback.json' })
      .then((response) => {
        const data = response.data.feedback;
        if (data.length > 10) {
          dispatch(requestFeedbackSuccess({
            feedback: data.slice(0, QUANTITY_OF_FEEDBACK_TO_SHOW),
            hasMoreFeedback: true,
          }));
          return {
            feedback: data.slice(0, QUANTITY_OF_FEEDBACK_TO_SHOW),
            hasMoreFeedback: true,
          };
        }

        dispatch(requestFeedbackSuccess({
          feedback: data,
          hasMoreFeedback: false,
        }));
        return {
          feedback: data,
          hasMoreFeedback: false,
        };
      })
      .catch(error => dispatch(requestFeedbackFailure(error)));
  };
}

export function requestMoreFeedback() {
  return (dispatch) => {
    dispatch(requestFeedbackBegin());
    return requestWithHeaders({ url: '../mock-data/mock-feedback.json' })
      .then((response) => {
        const data = response.data.feedback;
        dispatch(requestFeedbackSuccess({
          feedback: data,
          hasMoreFeedback: false,
        }));
        return {
          feedback: data,
          hasMoreFeedback: false,
        };
      })
      .catch(error => dispatch(requestFeedbackFailure(error)));
  };
}
