import { LanguageData } from './language.model';

export namespace BlogPageModels {
  export interface Article {
    id: number,
    categoryId: number,
    categoryValueDtoSet: ArticleData[],
    mainImgLink: string,
    articleContentDtoList: ArticleContent[],
    articleTabDtoList?: ArticleTab[],
    titleDtoSet: ArticleData[],
    thesisDtoSet: ArticleData[],
    dateTime: string,
    main: boolean,
    articleStatusName: string,
    parentArticleId?: number,
    snapshotId?: number,
    title?: string;
    date?: string;
    category?: string;
    image?: string;
    statusId?: ArticleStatusIds;
    thesis?: string;
  }
  export interface Category {
    id: number;
    name: string[];
    ids: number[];
    canBeDeleted?: boolean;
  }

  export interface ArticleContent {
    id: number,
    contentValueDtoSet: ArticleContentValue[],
  }

  export interface ArticleContentValue {
    id: number,
    contentValue: string,
    language: LanguageData,
    orderOnPage: number,
    contentTypeEnum: {
      id: number,
      contentTypeValue: ArticleContentTypes,
    },
  }

  export interface ArticleTab {
    id?: number,
    language?: LanguageData,
    isOriginal: boolean,
    hasChanged: boolean,
  }

  export interface ArticleData {
    id: number;
    language: LanguageData;
    categoryValue?: string,
    titleValue?: string;
    thesisValue?: string,
  }

  export interface ArticleTitles {
    id: number,
    titleDtoSet: ArticleData[],
  }

  export interface ArticlePageData {
    content: Article[],
    pageable: {
      sort: { sorted: boolean, unsorted: boolean },
      offset: number,
      pageNumber: number,
      pageSize: number,
      paged: boolean,
      unpaged: boolean,
    },
    totalPages: number,
    totalElements: number,
    last: boolean,
    size: number,
    number: number,
    sort: { sorted: boolean, unsorted: boolean },
    numberOfElements: number,
    first: boolean,
  }

  export interface MainArticlesProps {
    idArticleForEdit?: number | null,
    mainArticleTitles?: ArticleData[],
    newMainArticleTitles?: ArticleData[],
    parentArticleId?: number | null,
    snapshotId?: number | null,
    browserPathCanUpdate?: boolean,
    created?: boolean,
  }

  export interface NewCategoryInputData {
    id: number;
    name: {[index: number]: string};
  }

  export interface CategoryFetchData {
    maxNumberOfCategories: number;
    canBeDeleted: number[];
    currentCategories: CategoryDto[];
  }

  export interface CategoryDto {
    id: number,
    categoryId?: number;
    categoryValueDtoSet: ArticleData[]
  }

  export interface ArticleUpdateData {
    dataObject: Article;
    languageValue: string;
    id: number;
  }

  export interface CloseModalWindowData {
    saveArticleForEdit: boolean,
    saveArticleInForm: boolean,
  }

  export interface ArticleDeleteData {
    id: number;
    title: string;
    parentArticleId: number;
  }
}

export namespace ArticleTransformedData {
  export interface Tabs {
    uk: {
      id: number;
      isOriginal: boolean;
      hasChanged: boolean;
    },
    en: {
      id: number;
      isOriginal: boolean;
      hasChanged: boolean;
    }
  }

  export interface Content { uk: TransformedContent[], en: TransformedContent[] }

  export interface TransformedContent {
    type: ArticleContentTypes;
    content: string;
    id: number;
    articleContentSetId: number;
    contentTypeEnum: {
      id: number,
      contentTypeValue: ArticleContentTypes,
    },
    orderOnPage: number;
  }

  export interface FieldsMap {
    category: Field;
    title: Field;
    thesis: Field;
  }

  export interface Field {
    articleFieldName: 'categoryValueDtoSet' | 'titleDtoSet' | 'thesisDtoSet';
    itemValue: 'categoryValue' | 'titleValue' | 'thesisValue';
  }
}

export const enum ArticleContentTypes {
  TEXT = 'TEXT',
  QUOTE = 'QUOTE',
  IMG = 'IMG'
}

export const enum ArticleFormatingTypes {
  BOLD = 'b',
  ITALIC = 'i',
  MARK = 'mark',
  BLOCKQUOTE = 'blockquote'
}

export const enum ArticleStatusIds {
  PUBLISHED = 1,
  HIDDEN = 2,
  HAS_CHANGES = 3
}
