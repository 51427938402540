/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-" transform="translate(-500.000000, -1940.000000)">
        <g id="Paragraph" transform="translate(408.000000, 1744.000000)">
          <g id="DropDown" transform="translate(0.000000, 184.000000)">
            <g id="Icon" transform="translate(92.000000, 12.000000)" fill="#373737">
              <path d="M7.68300233,9.27769291 C8.04766818,9.64235876 8.07371574,10.217415 7.76114501,10.6121367 L7.68300233,10.6998472 L6.17457988,12.2082697 C4.62344662,13.7594029 4.62344662,16.2742869 6.17457988,17.8254201 C7.67724023,19.3280805 10.084358,19.3750386 11.6435469,17.9662945 L11.7917303,17.8254201 L13.3001528,16.3169977 C13.6928698,15.9242806 14.32959,15.9242806 14.7223071,16.3169977 C15.0869729,16.6816635 15.1130205,17.2567198 14.8004498,17.6514414 L14.7223071,17.739152 L13.2138846,19.2475744 C10.8773172,21.5841419 7.08899296,21.5841419 4.75242556,19.2475744 C2.46896195,16.9641108 2.41706505,13.2941294 4.59673486,10.9476423 L4.75242556,10.7861154 L6.26084801,9.27769291 C6.65356508,8.88497583 7.29028526,8.88497583 7.68300233,9.27769291 Z M15.7279221,8.27207794 C16.0904301,8.63458601 16.1183154,9.20500204 15.8115778,9.59949595 L15.7279221,9.69423226 L9.69423226,15.7279221 C9.30151519,16.1206391 8.66479501,16.1206391 8.27207794,15.7279221 C7.90956987,15.365414 7.88168464,14.794998 8.18842223,14.400504 L8.27207794,14.3057677 L14.3057677,8.27207794 C14.6984848,7.87936087 15.335205,7.87936087 15.7279221,8.27207794 Z M19.2475744,4.75242556 C21.531038,7.03588916 21.5829349,10.7058706 19.4032651,13.0523577 L19.2475744,13.2138846 L17.739152,14.7223071 C17.3464349,15.1150242 16.7097147,15.1150242 16.3169977,14.7223071 C15.9523318,14.3576412 15.9262843,13.782585 16.238855,13.3878633 L16.3169977,13.3001528 L17.8254201,11.7917303 C19.3765534,10.2405971 19.3765534,7.72571314 17.8254201,6.17457988 C16.3227598,4.67191953 13.915642,4.6249614 12.3564531,6.03370547 L12.2082697,6.17457988 L10.6998472,7.68300233 C10.3071302,8.0757194 9.67040998,8.0757194 9.27769291,7.68300233 C8.91302705,7.31833648 8.88697949,6.74328019 9.19955022,6.34855856 L9.27769291,6.26084801 L10.7861154,4.75242556 C13.1226828,2.41585815 16.911007,2.41585815 19.2475744,4.75242556 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
