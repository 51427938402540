import { HeaderModels } from './header.models'

export const isWAFLinkActive = (_, location: HeaderModels.UrlLocation) => (
  location.state?.prevUrl !== '/home' && location.pathname.includes('/want-a-friend')
)

export const isBlogLinkActive = (_, location: HeaderModels.UrlLocation) => (
  [/^\/blog\//, /^\/article\//].some(regexp => regexp.test(location.pathname))
  && location.state?.from !== '/home'
)
