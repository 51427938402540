import React, { FC } from 'react';
import styles from './ArrowButton.module.scss';
import Icon from '../../../../../shared-ui/icon';
import { SliderModels } from '../../recommended-articles.models';

const ArrowButton: FC<SliderModels.ArrowButtonProps> = ({ onClick, disabled, direction }) => (
  <button
    className={`${styles.arrow} ${styles[`arrow__${direction}`]}`}
    onClick={onClick}
    tabIndex={0}
    disabled={disabled}
    type="button"
  >
    <div className={styles.arrowBlock}>
      <Icon name={disabled ? 'back-disabled' : 'back'} />
    </div>
  </button>
);

export default ArrowButton;
