import { TranslatedText } from '../../../../../../../models/translate-function.models';
import dogParameterSelect from './styles/dog-parameter-select.module.scss';

export function getParameterSelectClasses(displayDropdown: boolean) {
  return `${dogParameterSelect['category-select']} ${
    displayDropdown ? dogParameterSelect['is-open'] : ''
  }`;
}

export function getDropdownBtnClasses(parameter: TranslatedText) {
  return `${dogParameterSelect['dropdown-button']} ${
    parameter ? dogParameterSelect['category-selected'] : ''
  }`;
}
