/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Eng" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][Eng][D]-Create-New-Article-–-Settings" transform="translate(-812.000000, -564.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 64.000000)">
          <g id="Image" transform="translate(32.000000, 376.000000)">
            <g id="Button" transform="translate(344.000000, 112.000000)">
              <g id="Icon" transform="translate(12.000000, 12.000000)" fill="currentColor">
                <path d="M16.8950124,10 C17.4472972,10 17.8950124,10.4477153 17.8950124,11 C17.8950124,11.0332298 17.8933561,11.0664389 17.8900496,11.0995037 L17.1800993,18.1990074 C17.0778589,19.221411 16.2175278,20 15.1900249,20 L8.80997512,20 C7.78247223,20 6.9221411,19.221411 6.81990074,18.1990074 L6.10995037,11.0995037 C6.05499599,10.5499599 6.45593998,10.0599172 7.00548384,10.0049628 C7.03854869,10.0016563 7.0717578,10 7.10498756,10 L16.8950124,10 Z M12,4 C13.1045695,4 14,4.8954305 14,6 L18,6 C18.5522847,6 19,6.44771525 19,7 C19,7.55228475 18.5522847,8 18,8 L6,8 C5.44771525,8 5,7.55228475 5,7 C5,6.44771525 5.44771525,6 6,6 L10,6 C10,4.8954305 10.8954305,4 12,4 Z" id="Combined-Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
