/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import axios from 'axios';
import requestWithHeaders from '../../../../../../../utils/withHeaders';
import { BlogPopupActions, BlogPopupActionsTypes } from './blogPopupActions.models';
import { URL_ADMIN_CREATE_ARTICLE,
  URL_ADMIN_CATEGORIES,
  URL_ADMIN_SECONDARY_ARTICLES,
  URL_BLOG_CREATE_CATEGORY,
  URL_BLOG_UPDATE_CATEGORY,
  URL_ADMIN_ARTICLE_SNAPSHOT } from '../../../../../../../endpoints';

import { BlogActionsTypes } from '../../../+store/blogActions.models';
import { LANGUAGES } from '../../../../../../language/+store/languages';
import { transformCategoryDto, transformCategoryDtoList } from '../../../../../../../utils/transformCategoryDto';
import { AdminBlogActions } from '../../../+store/actions';
import getArticleDTOfromArticleForm from '../../../../../../../utils/getArticleDTOfromArticleForm';
import { BlogPageModels } from '../../../../../../../models/blogPage.models';

export namespace AdminBlogPopupActions {
  export const fetchArticlesStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.FETCH_ARTICLES_START }
  );

  export const selectCategory: BlogPopupActions.SelectCategory = category => ({
    type: BlogPopupActionsTypes.SELECTED_CATEGORY_CHANGE,
    payload: { category },
  });

  export const articleTitleChange: BlogPopupActions.TitleOrDescriptionChange = (language, title) => ({
    type: BlogPopupActionsTypes.ARTICLE_TITLE_CHANGE,
    payload: {
      articleChangeData: {
        language,
        title,
      },
    },
  });

  export const articleDescriptionChange: BlogPopupActions.TitleOrDescriptionChange = (language, description) => ({
    type: BlogPopupActionsTypes.ARTICLE_DESCRIPTION_CHANGE,
    payload: {
      articleChangeData: {
        language,
        description,
      },
    },
  });

  export const enterNewCategory: BlogPopupActions.EnterNewCategory = newCategoryInputData => ({
    type: BlogPopupActionsTypes.ENTER_NEW_CATEGORY,
    payload: { newCategoryInputData },
  });

  export const categoryNameChange: BlogPopupActions.CategoryNameChange = category => ({
    type: BlogPopupActionsTypes.CATEGORY_NAME_CHANGE,
    payload: { category },
  });

  export const categoryLanguageChange: BlogPopupActions.CategoryLanguageChange = language => ({
    type: BlogPopupActionsTypes.CATEGORY_LANGUAGE_CHANGE,
    payload: { language },
  });

  export const categoryFetchStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.FETCH_CATEGORIES_START }
  );

  export const categoryFetchError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.FETCH_CATEGORIES_ERROR,
    payload: { error },
  });

  export const categoryFetchSuccess: BlogPopupActions.CategoryFetchSuccess = category => ({
    type: BlogPopupActionsTypes.FETCH_CATEGORIES_SUCCESS,
    payload: {
      categoryFetchData: {
        maxCategoriesAmount: category.maxNumberOfCategories,
        categories: transformCategoryDtoList(category.currentCategories),
        canBeDeleted: category.canBeDeleted,
      },
    },
  });

  export const categoryDeleteStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.DELETE_CATEGORIES_START }
  );

  export const categoryDeleteError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.DELETE_CATEGORIES_ERROR,
    payload: { error },
  });

  export const categoryDeleteEnd: BlogPopupActions.CategoryDeleteEnd = (index, selectedCategory) => ({
    type: BlogPopupActionsTypes.DELETE_CATEGORIES_END,
    payload: {
      index,
      category: selectedCategory,
    },
  });

  export const articlePostStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.POST_ARTICLE_START }
  );

  export const articlePostError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.POST_ARTICLE_ERROR,
    payload: { error },
  });

  export const articlePostSuccess: BlogPopupActions.ArticlePostSuccess = article => ({
    type: BlogActionsTypes.ARTICLE_CREATED_SUCCESSFULLY,
    payload: { article },
  });

  export const articleUpdateStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.UPDATE_ARTICLE_START }
  );

  export const articleUpdateError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.UPDATE_ARTICLE_ERROR,
    payload: { error },
  });

  export const articleUpdateSuccess: BlogPopupActions.ArticleUpdateSuccess = articleUpdateData => ({
    type: BlogActionsTypes.ARTICLE_UPDATED_SUCCESSFULLY,
    payload: { articleUpdateData },
  });

  export const categoryPostStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.POST_CATEGORY_START }
  );

  export const categoryPostError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.POST_CATEGORY_ERROR,
    payload: { error },
  });

  export const categoryPostSuccess: BlogPopupActions.CategoryPostSuccess = category => ({
    type: BlogPopupActionsTypes.POST_CATEGORY_SUCCESS,
    payload: { category: transformCategoryDto(category) },
  });

  export const categoryUpdateStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.UPDATE_CATEGORY_START }
  );

  export const categoryUpdateError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.UPDATE_CATEGORY_ERROR,
    payload: { error },
  });

  export const categoryUpdateSuccess: BlogPopupActions.CategoryUpdateSuccess = (category, language) => ({
    type: BlogPopupActionsTypes.UPDATE_CATEGORY_SUCCESS,
    payload: { category: transformCategoryDto(category), language },
  });

  export const editCategoryStart: BlogPopupActions.EditCategoryStart = categoryData => ({
    type: BlogPopupActionsTypes.EDIT_CATEGORY_START,
    payload: { category: categoryData },
  });

  export const editCategoryCancel: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.EDIT_CATEGORY_CANCEL }
  );

  export const fetchCategories: BlogPopupActions.FetchCategories = cookies => (dispatch) => {
    dispatch(categoryFetchStart());
    return requestWithHeaders({ url: URL_ADMIN_CATEGORIES }, cookies)
      .then(resp => dispatch(categoryFetchSuccess(resp.data)))
      .catch(err => dispatch(categoryFetchError(err)));
  };

  export const deleteCategory: BlogPopupActions.DeleteCategory = (
    id, index, selectedCategory, cookies,
  ) => (dispatch) => {
    dispatch(categoryDeleteStart());
    return requestWithHeaders({
      method: 'DELETE',
      url: `${URL_ADMIN_CATEGORIES}${id}`,
    }, cookies)
      .then(() => dispatch(categoryDeleteEnd(index, selectedCategory)))
      .catch(err => dispatch(categoryDeleteError(err)));
  };

  export const fetchArticlesError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.FETCH_ARTICLES_ERROR,
    payload: { error },
  });

  export const fetchArticlesSuccess: BlogPopupActions.FetchArticlesSuccess = articles => ({
    type: BlogPopupActionsTypes.FETCH_ARTICLES_SUCCESS,
    payload: { articles },
  });

  export const fetchArticles: BlogPopupActions.FetchArticles = () => (dispatch) => {
    dispatch(fetchArticlesStart());
    requestWithHeaders({ url: `${URL_ADMIN_SECONDARY_ARTICLES}1` })
      .then(response => dispatch(fetchArticlesSuccess(response.data.content)))
      .catch(error => dispatch(fetchArticlesError(error)));
  };

  export const displayErrorMessage: BlogPopupActions.DisplayErrorMessage = errorMessage => ({
    type: BlogPopupActionsTypes.DISPLAY_ERROR_MESSAGE,
    payload: { errorMessage },
  });

  export const setCategoryValidationError: BlogPopupActions.SetCategoryValidationError = validationError => ({
    type: BlogPopupActionsTypes.CATEGORY_VALIDATION_ERROR,
    payload: { validationError },
  });

  export const displayCancelMessage: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.DISPLAY_CANCEL_MESSAGE }
  );

  export const hideCancelMessage: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.HIDE_CANCEL_MESSAGE }
  );

  export const postArticle: BlogPopupActions.PostArticle = cookies => (dispatch, getState) => {
    const {
      activeLanguage, articleTitle, selectedCategory,
      mainImgLink, isMain, articleDescription,
    } = getState().adminArticleCreateFormReducer;
    const category = selectedCategory ? selectedCategory.name[activeLanguage.languageId] : '';
    const millisecondsPerMinute = 60000;
    const timeZoneOffSet = (new Date()).getTimezoneOffset() * millisecondsPerMinute;

    if (!category.length) {
      return dispatch(displayErrorMessage('Оберіть категорію статті'));
    }

    dispatch(articlePostStart());
    return requestWithHeaders({
      method: 'POST',
      url: URL_ADMIN_CREATE_ARTICLE,
      data: {
        articleContentDtoList: [],
        categoryId: `${selectedCategory.id}`,
        dateTime: (new Date(Date.now() - timeZoneOffSet)).toISOString().slice(0, 19).replace('T', ' '),
        mainImgLink: `${mainImgLink}`,
        articleStatusName: 'NOT_PUBLISHED',
        titleDtoSet: Object.values(LANGUAGES).map(language => ({
          language,
          titleValue: articleTitle[language.languageValue],
        })),
        thesisDtoSet: Object.values(LANGUAGES).map(language => ({
          language,
          thesisValue: articleDescription[language.languageValue],
        })),
        main: isMain,
      },
    }, cookies)
      .then(({ data }) => {
        dispatch(articlePostSuccess(data));
        return data;
      })
      .catch((error) => dispatch(articlePostError(error)));
  };

  export const createSnapshot: BlogPopupActions.CreateSnapshot = cookies => (dispatch, getState) => {
    const DTO = getArticleDTOfromArticleForm();
    const { id } = DTO;
    const { selectedLanguage: { languageValue } } = getState().languageReducer;
    const { dateTime } = getState().articlesPageReducer.article as BlogPageModels.Article;

    if (!DTO) {
      return dispatch(displayErrorMessage('Оберіть категорію статті'));
    }
    dispatch(articleUpdateStart());
    return requestWithHeaders({
      method: 'POST',
      url: `${URL_ADMIN_ARTICLE_SNAPSHOT}`,
      data: { ...DTO },
    }, cookies)
      .then(({ data }) => {
        const dataObject = JSON.parse(JSON.stringify(data));
        delete dataObject.dateTime;
        dataObject.dateTime = `${dateTime.replace(/ /g, 'T')}.000`;
        dispatch(articleUpdateSuccess({ dataObject, languageValue, id }));
        dispatch(AdminBlogActions.fetchArticleSuccess(dataObject));
        return dataObject.id;
      })
      .catch(error => dispatch(articleUpdateError(error)));
  };

  export const updateSnapshot: BlogPopupActions.UpdateSnapshot = cookies => (dispatch, getState) => {
    const DTO = getArticleDTOfromArticleForm();
    const { id } = DTO;
    const { selectedLanguage: { languageValue } } = getState().languageReducer;

    if (!DTO) {
      return dispatch(displayErrorMessage('Оберіть категорію статті'));
    }
    dispatch(articleUpdateStart());
    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_ADMIN_ARTICLE_SNAPSHOT}`,
      data: { ...DTO },
    }, cookies)
      .then(({ config: { data } }) => {
        const dataObject = JSON.parse(data);
        dispatch(articleUpdateSuccess({ dataObject, languageValue, id }));
        AdminBlogActions.fetchArticle(dataObject.id, cookies)(dispatch);
      })
      .catch(error => dispatch(articleUpdateError(error)));
  };

  export const postCategory: BlogPopupActions.PostCategory = cookies => (dispatch, getState) => {
    const { inputCategory, activeLanguage } = getState().adminArticleCreateFormReducer;
    dispatch(categoryPostStart());
    return requestWithHeaders({
      method: 'POST',
      url: URL_BLOG_CREATE_CATEGORY,
      data: {
        categoryValueDtoSet: Object.values(LANGUAGES).map(language => ({
          language,
          categoryValue: inputCategory.name[activeLanguage.languageId].replace(/\s+/g, ' ').trim(),
        })),
      },
    }, cookies)
      .then((response) => {
        dispatch(categoryPostSuccess(response.data));
        const category = { ...transformCategoryDto(response.data), canBeDeleted: true };
        dispatch(selectCategory(category));
      })
      .catch(error => dispatch(categoryPostError(error)));
  };


  export const updateCategory: BlogPopupActions.UpdateCategory = cookies => (dispatch, getState) => {
    const { activeLanguage, categoryInNameChange } = getState().adminArticleCreateFormReducer;
    dispatch(categoryUpdateStart());

    const putData = {
      id: categoryInNameChange.id,
      categoryValueDtoSet: [{
        language: activeLanguage,
        categoryValue: categoryInNameChange.name[activeLanguage.languageId].replace(/\s+/g, ' ').trim(),
        id: categoryInNameChange.ids[activeLanguage.languageId],
      }],
    };

    return requestWithHeaders({
      method: 'PUT',
      url: `${URL_BLOG_UPDATE_CATEGORY}/${categoryInNameChange.id}`,
      data: putData,
    }, cookies)
      .then(response => dispatch(categoryUpdateSuccess(response.data, activeLanguage)))
      .catch(error => dispatch(categoryUpdateError(error)));
  };

  export const articleIsMainCheckboxChange: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.ARTICLE_IS_MAIN_CHECKBOX_CHANGE }
  );

  export const postArticleImagePostStart: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.POST_ARTICLE_IMAGE_START }
  );

  export const postArticleImagePostSuccess: BlogPopupActions.PostArticleImagePostSuccess = imgLink => ({
    type: BlogPopupActionsTypes.POST_ARTICLE_IMAGE_SUCCESS,
    payload: { imgLink },
  });

  export const postArticleImagePostError: BlogPopupActions.ErrorHandler = error => ({
    type: BlogPopupActionsTypes.POST_ARTICLE_IMAGE_ERROR,
    payload: { error },
  });

  export const postArticleImage: BlogPopupActions.PostArticleImage = imgLink => (dispatch) => {
    dispatch(postArticleImagePostStart());
    return requestWithHeaders({
      method: 'GET',
      url: imgLink,
    })
      .then((response) => {
        dispatch(postArticleImagePostSuccess(response.config.url));
        return response;
      })
      .catch(error => dispatch(postArticleImagePostError(error)));
  };

  export const postArticleImageDelete: BlogPopupActions.SimpleAction = () => (
    { type: BlogPopupActionsTypes.POST_ARTICLE_IMAGE_DELETE }
  );

  // variables saveArticleForEdit and saveArticleInForm save article data in the store
  // ArticlesPageReducer and AdminArticleCreateFormReducer
  // because we need them in further execution of code
  // If you don't need to save this data, don't give any arguments for closeModalWindow action
  export const closeModalWindow: BlogPopupActions.CloseModalWindow = (saveArticleForEdit, saveArticleInForm) => ({
    type: BlogActionsTypes.CLOSE_MODAL_WINDOW,
    payload: {
      closeModalWindowData: {
        saveArticleForEdit,
        saveArticleInForm,
      },
    },
  });
}
