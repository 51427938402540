import getDogAgeTranslateKey from '../../../utils/getDogAgeTranslateKey';

const articleFieldMap = {
  name: {
    articleFieldName: 'dogNameDtoSet',
    itemValue: 'name',
  },
  sizeValue: {
    articleFieldName: 'dogSizeValueDtoSet',
    itemValue: 'sizeValue',
  },
  description: {
    articleFieldName: 'dogDescriptionDtoSet',
    itemValue: 'description',
  },
};

export const getArticleInfo = (article, articleField) => article[articleField.articleFieldName].reduce((res, item) => {
  const result = { ...res };
  if (item.language) {
    const language = item.language.languageValue;
    result[language] = item[articleField.itemValue];
  }
  return result;
}, { ru: undefined, uk: undefined, en: undefined });

export const sortPetsById = petsList => petsList.sort((pet, nextPet) => {
  const petId = pet.parentDogId || pet.id;
  const nextPetId = nextPet.parentDogId || nextPet.id;
  return nextPetId - petId;
});

export const filterBySize = (arr, size) => {
  const newArr = arr.filter(e => e.sizeId === size);
  return newArr;
};

export const filterPets = (filtered, state, way) => {
  const { ageFilter, sizeFilter, genderFilter } = state;
  let arr = [];

  if (!ageFilter && !sizeFilter && !genderFilter) {
    return filtered;
  }

  if (way === 'allGender' && !sizeFilter && !ageFilter) {
    arr = filtered.filter(e => e);
    return arr;
  }

  if (way === 'allAges' && !sizeFilter && !genderFilter) {
    arr = filtered.filter(e => e);
    return arr;
  }

  if (way === 'allSizes' && !ageFilter && !genderFilter) {
    arr = filtered.filter(e => e);
    return arr;
  }

  if ((way === 'allGender' && ageFilter === 'fiveAndMore' && sizeFilter === null)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears');
  }

  if ((way === 'allGender' && ageFilter === 'fiveAndMore' && sizeFilter === 'small')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 1);
  }

  if ((way === 'allGender' && ageFilter === 'fiveAndMore' && sizeFilter === 'medium')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 2);
  }

  if ((way === 'allGender' && ageFilter === 'fiveAndMore' && sizeFilter === 'big')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 3);
  }

  if ((way === 'allGender' && ageFilter === 'beforeYear' && sizeFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear'));
  }

  if ((way === 'allGender' && ageFilter === 'beforeYear' && sizeFilter === 'small')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 1);
  }

  if ((way === 'allGender' && ageFilter === 'beforeYear' && sizeFilter === 'medium')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 2);
  }

  if ((way === 'allGender' && ageFilter === 'beforeYear' && sizeFilter === 'big')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 3);
  }

  if ((way === 'allGender' && ageFilter === null && sizeFilter === 'small')) {
    arr = filtered.filter(e => e.sizeId === 1);
  }

  if ((way === 'allGender' && ageFilter === null && sizeFilter === 'medium')) {
    arr = filtered.filter(e => e.sizeId === 2);
  }

  if ((way === 'allGender' && ageFilter === null && sizeFilter === 'big')) {
    arr = filtered.filter(e => e.sizeId === 3);
  }

  if ((way === 'allGender' && ageFilter === 'fromOneToFiveYears' && sizeFilter === null)
  || (way === 'fromOneToFiveYears' && genderFilter === null && sizeFilter === null)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears');
  }

  if ((way === 'allGender' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'big')
  || (way === 'fromOneToFiveYears' && genderFilter === null && sizeFilter === 'big')
  || (way === 'big' && genderFilter === null && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 3);
  }

  if ((way === 'allGender' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'medium')
  || (way === 'fromOneToFiveYears' && genderFilter === null && sizeFilter === 'medium')
  || (way === 'medium' && genderFilter === null && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 2);
  }

  if ((way === 'allGender' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'small')
  || (way === 'fromOneToFiveYears' && genderFilter === null && sizeFilter === 'small')
  || (way === 'small' && genderFilter === null && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 1);
  }

  if ((way === 'allSizes' && ageFilter === 'beforeYear') || (way === 'beforeYear' && sizeFilter === null)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear');
  }

  if ((way === 'allSizes' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === false);
  }

  if ((way === 'allSizes' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true);
  }

  if ((way === 'small' && ageFilter === null && genderFilter === null)) {
    arr = filtered.filter(e => e.sizeId === 1);
  }

  if ((way === 'medium' && ageFilter === null && genderFilter === null)) {
    arr = filtered.filter(e => e.sizeId === 2);
  }

  if ((way === 'big' && ageFilter === null && genderFilter === null)) {
    arr = filtered.filter(e => e.sizeId === 3);
  }

  if ((way === 'small' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 1 && e.male === false);
  }

  if ((way === 'small' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 1 && e.male === true);
  }

  if ((way === 'medium' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 2 && e.male === false);
  }

  if ((way === 'medium' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 2 && e.male === true);
  }

  if ((way === 'big' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 3 && e.male === false);
  }

  if ((way === 'big' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 3 && e.male === true);
  }

  if ((way === 'allSizes' && ageFilter === 'fromOneToFiveYears' && genderFilter === null)
  || (way === 'fromOneToFiveYears' && ageFilter === 'fromOneToFiveYears' && genderFilter === null)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears');
  }

  if ((way === 'allSizes' && ageFilter === 'fiveAndMore' && genderFilter === null)
  || (way === 'fiveAndMore' && ageFilter === 'fiveAndMore' && genderFilter === null)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears');
  }

  if ((way === 'allSizes' && ageFilter === 'fiveAndMore' && genderFilter === 'male')
  || (way === 'fiveAndMore' && ageFilter === 'fiveAndMore' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === true);
  }

  if ((way === 'allSizes' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'male')
  || (way === 'fromOneToFiveYears' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true);
  }

  if ((way === 'allSizes' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'female')
  || (way === 'fromOneToFiveYears' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false);
  }

  if ((way === 'allSizes' && ageFilter === null && genderFilter === 'male')) {
    arr = filtered.filter(e => e.male === true);
  }

  if ((way === 'allSizes' && ageFilter === null && genderFilter === 'female')) {
    arr = filtered.filter(e => e.male === false);
  }

  if ((way === 'allAges' && sizeFilter === null && genderFilter === 'male')) {
    arr = filtered.filter(e => e.male === true);
  }

  if ((way === 'allAges' && sizeFilter === null && genderFilter === 'female')) {
    arr = filtered.filter(e => e.male === false);
  }

  if ((way === 'allAges' && sizeFilter === 'small' && genderFilter === null)) {
    arr = filterBySize(filtered, 1);
  }

  if ((way === 'allAges' && sizeFilter === 'medium' && genderFilter === null)) {
    arr = filterBySize(filtered, 2);
  }

  if ((way === 'allAges' && sizeFilter === 'big' && genderFilter === null)) {
    arr = filterBySize(filtered, 3);
  }

  if ((way === 'allAges' && sizeFilter === 'small' && genderFilter === 'male')
  || (way === 'small' && ageFilter === null && genderFilter === 'male')
  || (way === 'male' && sizeFilter === 'small' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === true && e.sizeId === 1);
  }

  if ((way === 'allAges' && sizeFilter === 'small' && genderFilter === 'female')
  || (way === 'small' && ageFilter === null && genderFilter === 'female')
  || (way === 'female' && sizeFilter === 'small' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === false && e.sizeId === 1);
  }

  if ((way === 'allAges' && sizeFilter === 'medium' && genderFilter === 'male')
  || (way === 'medium' && ageFilter === null && genderFilter === 'male')
  || (way === 'male' && sizeFilter === 'medium' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === true && e.sizeId === 2);
  }

  if ((way === 'allAges' && sizeFilter === 'medium' && genderFilter === 'female')
  || (way === 'medium' && ageFilter === null && genderFilter === 'female')
  || (way === 'female' && sizeFilter === 'medium' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === false && e.sizeId === 2);
  }

  if ((way === 'allAges' && sizeFilter === 'big' && genderFilter === 'male')
  || (way === 'big' && ageFilter === null && genderFilter === 'male')
  || (way === 'male' && sizeFilter === 'big' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === true && e.sizeId === 3);
  }

  if ((way === 'allAges' && sizeFilter === 'big' && genderFilter === 'female')
  || (way === 'big' && ageFilter === null && genderFilter === 'female')
  || (way === 'female' && sizeFilter === 'big' && ageFilter === null)) {
    arr = filtered.filter(e => e.male === false && e.sizeId === 3);
  }

  if (way === 'male' && !sizeFilter && !ageFilter) {
    arr = filtered.filter(e => e.male === true);
  }

  if (way === 'female' && !sizeFilter && !ageFilter) {
    arr = filtered.filter(e => e.male === false);
    return arr;
  }

  if ((way === 'male' && sizeFilter === null && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'male' && sizeFilter === null)
  || (way === 'allSizes' && genderFilter === 'male' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === true);
  }

  if ((way === 'male' && sizeFilter === 'small' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'male' && sizeFilter === 'small')
  || (way === 'small' && genderFilter === 'male' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === true && e.sizeId === 1);
  }

  if ((way === 'male' && sizeFilter === 'medium' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'male' && sizeFilter === 'medium')
  || (way === 'medium' && genderFilter === 'male' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === true && e.sizeId === 2);
  }

  if ((way === 'male' && sizeFilter === 'big' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'male' && sizeFilter === 'big')
  || (way === 'big' && genderFilter === 'male' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === true && e.sizeId === 3);
  }

  if ((way === 'female' && sizeFilter === 'small' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'female' && sizeFilter === 'small')
  || (way === 'small' && genderFilter === 'female' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === false && e.sizeId === 1);
  }

  if ((way === 'female' && sizeFilter === 'medium' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'female' && sizeFilter === 'medium')
  || (way === 'medium' && genderFilter === 'female' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === false && e.sizeId === 2);
  }

  if ((way === 'female' && sizeFilter === 'big' && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'female' && sizeFilter === 'big')
  || (way === 'big' && genderFilter === 'female' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === false && e.sizeId === 3);
  }

  if ((way === 'male' && sizeFilter === 'small' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === 'small')
  || (way === 'small' && genderFilter === 'male' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true && e.sizeId === 1);
  }

  if ((way === 'male' && sizeFilter === 'medium' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === 'medium')
  || (way === 'medium' && genderFilter === 'male' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true && e.sizeId === 2);
  }

  if ((way === 'male' && sizeFilter === 'big' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === 'big')
  || (way === 'big' && genderFilter === 'male' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true && e.sizeId === 3);
  }

  if ((way === 'female' && sizeFilter === null && ageFilter === 'fiveAndMore')
  || (way === 'fiveAndMore' && genderFilter === 'female' && sizeFilter === null)
  || (way === 'allSizes' && genderFilter === 'female' && ageFilter === 'fiveAndMore')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.male === false);
  }

  if ((way === 'female' && sizeFilter === 'small' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'female' && sizeFilter === 'small')
  || (way === 'small' && genderFilter === 'female' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false && e.sizeId === 1);
  }

  if ((way === 'female' && sizeFilter === 'medium' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'female' && sizeFilter === 'medium')
  || (way === 'medium' && genderFilter === 'female' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false && e.sizeId === 2);
  }

  if ((way === 'female' && sizeFilter === 'big' && ageFilter === 'fromOneToFiveYears')
  || (way === 'fromOneToFiveYears' && genderFilter === 'female' && sizeFilter === 'big')
  || (way === 'big' && genderFilter === 'female' && ageFilter === 'fromOneToFiveYears')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false && e.sizeId === 3);
  }

  if ((way === 'beforeYear' && sizeFilter === 'small' && genderFilter === null)
  || (way === 'small' && ageFilter === 'beforeYear' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 1);
  }

  if ((way === 'beforeYear' && sizeFilter === 'medium' && genderFilter === null)
  || (way === 'medium' && ageFilter === 'beforeYear' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 2);
  }

  if ((way === 'beforeYear' && sizeFilter === 'big' && genderFilter === null)
  || (way === 'big' && ageFilter === 'beforeYear' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.sizeId === 3);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === null)
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true);
  }

  if ((way === 'beforeYear' && genderFilter === 'female' && sizeFilter === null)
  || (way === 'female' && ageFilter === 'beforeYear' && sizeFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === false);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'small')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'small')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 1);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'medium')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'medium')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 2);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'big')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'big')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 3);
  }

  if ((way === 'beforeYear' && genderFilter === 'female' && sizeFilter === 'small')
  || (way === 'female' && ageFilter === 'beforeYear' && sizeFilter === 'small')
  || (way === 'small' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === false && e.sizeId === 1);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'small')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'small')
  || (way === 'small' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 1);
  }

  if ((way === 'beforeYear' && genderFilter === 'female' && sizeFilter === 'medium')
  || (way === 'female' && ageFilter === 'beforeYear' && sizeFilter === 'medium')
  || (way === 'medium' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === false && e.sizeId === 2);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'medium')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'medium')
  || (way === 'medium' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 2);
  }

  if ((way === 'beforeYear' && genderFilter === 'male' && sizeFilter === 'big')
  || (way === 'male' && ageFilter === 'beforeYear' && sizeFilter === 'big')
  || (way === 'big' && ageFilter === 'beforeYear' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === true && e.sizeId === 3);
  }

  if ((way === 'beforeYear' && genderFilter === 'female' && sizeFilter === 'big')
  || (way === 'female' && ageFilter === 'beforeYear' && sizeFilter === 'big')
  || (way === 'big' && ageFilter === 'beforeYear' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'upToSixMonths'
    || getDogAgeTranslateKey(e.birthDate) === 'afterSixMonthsToOneYear') && e.male === false && e.sizeId === 3);
  }

  if ((way === 'fromOneToFiveYears' && sizeFilter === 'small' && genderFilter === null)
  || (way === 'small' && ageFilter === 'fromOneToFiveYears' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 1);
  }

  if ((way === 'fromOneToFiveYears' && sizeFilter === 'medium' && genderFilter === null)
  || (way === 'medium' && ageFilter === 'fromOneToFiveYears' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 2);
  }

  if ((way === 'fromOneToFiveYears' && sizeFilter === 'big' && genderFilter === null)
  || (way === 'big' && ageFilter === 'fromOneToFiveYears' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.sizeId === 3);
  }

  if ((way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === null)
  || (way === 'male' && ageFilter === 'fromOneToFiveYears' && sizeFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true);
  }

  if ((way === 'fromOneToFiveYears' && genderFilter === 'female' && sizeFilter === null)
  || (way === 'female' && ageFilter === 'fromOneToFiveYears' && sizeFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false);
  }

  if ((way === 'fromOneToFiveYears' && genderFilter === 'female' && sizeFilter === 'small')
  || (way === 'female' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'small')
  || (way === 'small' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === false && e.sizeId === 1);
  }

  if ((way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === 'medium')
  || (way === 'male' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'medium')
  || (way === 'medium' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true && e.sizeId === 2);
  }

  if ((way === 'fromOneToFiveYears' && genderFilter === 'male' && sizeFilter === 'big')
  || (way === 'male' && ageFilter === 'fromOneToFiveYears' && sizeFilter === 'big')
  || (way === 'big' && ageFilter === 'fromOneToFiveYears' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromOneToTwoYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromTwoToThreeYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromThreeToFourYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromFourToFiveYears') && e.male === true && e.sizeId === 3);
  }


  if ((way === 'fiveAndMore' && sizeFilter === 'small' && genderFilter === null)
  || (way === 'small' && ageFilter === 'fiveAndMore' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 1);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'medium' && genderFilter === null)
  || (way === 'medium' && ageFilter === 'fiveAndMore' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 2);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'big' && genderFilter === null)
  || (way === 'big' && ageFilter === 'fiveAndMore' && genderFilter === null)) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 3);
  }

  if ((way === 'fiveAndMore' && !sizeFilter && !genderFilter)) {
    arr = filtered.filter(e => getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears');
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'small' && genderFilter === 'male')
  || (way === 'small' && ageFilter === 'fiveAndMore' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 1 && e.male === true);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'medium' && genderFilter === 'male')
  || (way === 'medium' && ageFilter === 'fiveAndMore' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 2 && e.male === true);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'big' && genderFilter === 'male')
  || (way === 'big' && ageFilter === 'fiveAndMore' && genderFilter === 'male')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 3 && e.male === true);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'small' && genderFilter === 'female')
  || (way === 'small' && ageFilter === 'fiveAndMore' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 1 && e.male === false);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'medium' && genderFilter === 'female')
  || (way === 'medium' && ageFilter === 'fiveAndMore' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 2 && e.male === false);
  }

  if ((way === 'fiveAndMore' && sizeFilter === 'big' && genderFilter === 'female')
  || (way === 'big' && ageFilter === 'fiveAndMore' && genderFilter === 'female')) {
    arr = filtered.filter(e => (getDogAgeTranslateKey(e.birthDate) === 'fromFiveToSixYears'
    || getDogAgeTranslateKey(e.birthDate) === 'fromSixToEightYears'
    || getDogAgeTranslateKey(e.birthDate) === 'moreThanEightYears') && e.sizeId === 3 && e.male === false);
  }

  return arr;
};

export const getPetName = article => getArticleInfo(article, articleFieldMap.name);
export const getPetTitle = article => getArticleInfo(article, articleFieldMap.sizeValue);
export const getPetDescription = article => getArticleInfo(article, articleFieldMap.description);
