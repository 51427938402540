import { ArticleTransformedData, BlogPageModels } from '../../../../models/blogPage.models';
import { CommonModels } from '../../../../models/common.models';

export const defaultArticle: BlogPageModels.Article = {
  articleContentDtoList: [],
  articleStatusName: '',
  articleTabDtoList: [],
  categoryId: 0,
  categoryValueDtoSet: [],
  dateTime: '',
  id: 0,
  main: false,
  mainImgLink: '',
  parentArticleId: 0,
  snapshotId: 0,
  thesisDtoSet: [],
  titleDtoSet: [],
  thesis: '',
}

export const defaultTranslation: CommonModels.RecordInfo = {
  uk: '',
  en: '',
};

export const defaultTabs: ArticleTransformedData.Tabs = {
  uk: {
    id: 0,
    isOriginal: false,
    hasChanged: false,
  },
  en: {
    id: 0,
    isOriginal: false,
    hasChanged: false,
  },
}

export const defaultContent: ArticleTransformedData.Content = {
  uk: [{
    type: null,
    content: '',
    id: 0,
    articleContentSetId: 0,
    contentTypeEnum: null,
    orderOnPage: 0,
  }],
  en: [{
    type: null,
    content: '',
    id: 0,
    articleContentSetId: 0,
    contentTypeEnum: null,
    orderOnPage: 0,
  }],
}

export const defaultIds = { uk: 1, en: 1 }

export const LANGUAGE_TABS = [
  {
    value: 'uk',
    label: 'Українська',
  },
  {
    value: 'en',
    label: 'English',
  },
];

