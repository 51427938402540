import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import style from './styles/dog-data.module.scss';
import { AdminWAFPopupActions } from '../+store/actions';
import Icon from '../../../../../../shared-ui/icon';
import { IsDogUnderCareModels } from '../popupForm.models';

const IsDogUnderCare = ({
  content = '',
  isUnderCare = false,
  changeIsUnderCare,
}: IsDogUnderCareModels.Props) => {
  useEffect(() => () => {
    changeIsUnderCare(false);
  }, []);

  const handleCheckBoxClick = (event) => {
    event.preventDefault();
    changeIsUnderCare();
  };

  return (
    <div className={style['article-form_main']}>
      <input
        type="checkbox"
        checked={isUnderCare}
        className={style['article-form_main-article']}
        id="mainArticle"
      />
      <div
        className={style['article-form_main-name']}
        onClick={handleCheckBoxClick}
        role="radio"
        tabIndex={0}
        aria-checked="false"
      >
        <Icon name="checkbox" />
        <span>{content}</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  changeIsUnderCare: (isUnderCare: boolean) => dispatch(
    AdminWAFPopupActions.isDogUnderCareChange(isUnderCare),
  ),
});

export default connect(null, mapDispatchToProps)(IsDogUnderCare);
