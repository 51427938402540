import requestWithHeaders from '../../../utils/withHeaders';
import { URL_BLOG_ARTICLES, URL_BLOG_MAIN_ARTICLE, URL_USER_WANT_A_FRIEND_GET_ALL } from '../../../endpoints';
import { HomeComponentActions } from './action-types';
import { Article } from '../../../types/article.model';
import { DispatchFn } from '../../../types/dispatch.type';
import { ActionPayloadContent } from '../../../types/action.interface';

const fetchMainArticle = () => requestWithHeaders({ url: URL_BLOG_MAIN_ARTICLE }).then(response => response.data)

export const fetchArticles = () => async (dispatch: DispatchFn) => {
  dispatch(HomeComponentActions.fetchStart());
  requestWithHeaders({ url: `${URL_BLOG_ARTICLES}1` })
    .then(async ({ data }) => {
      if (data.content.length < 3) {
        return { content: [await fetchMainArticle(), ...data.content] }
      }
      return { content: data.content };
    })
    .then((articles: ActionPayloadContent<Article[]>) => dispatch(HomeComponentActions.fetchArticlesSuccess(articles)))
    .catch((error: Error) => dispatch(HomeComponentActions.fetchError(error)))
};

export const fetchDogs = () => (dispatch: DispatchFn) => {
  dispatch(HomeComponentActions.fetchStart());
  requestWithHeaders({ url: `${URL_USER_WANT_A_FRIEND_GET_ALL}1` })
      .then(response => dispatch(HomeComponentActions.fetchDogsSuccess(response.data)))
      .catch((error: Error) => dispatch(HomeComponentActions.fetchError(error)))
}

