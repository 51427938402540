/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { BlogPageModels } from '../models/blogPage.models';
import { LanguageData } from '../models/language.model';

export const transformCategoryDto = (categoryDto: BlogPageModels.CategoryDto): BlogPageModels.Category => ({
  id: categoryDto.categoryId || categoryDto.id,
  name: categoryDto.categoryValueDtoSet.reduce((categoryNames, item) => ({
    ...categoryNames,
    [item.language.languageId]: item.categoryValue,
  }), []),
  ids: categoryDto.categoryValueDtoSet.reduce((categoryNames, item) => ({
    ...categoryNames,
    [item.language.languageId]: item.id,
  }), []),
});

// updates categories after editing
export const transformCategoryDtoAfterUpdate = (
  categoryDto: BlogPageModels.Category,
  updatedItem: BlogPageModels.Category,
  language: LanguageData,
) => {
  const categoryNameItem = categoryDto.name;
  categoryNameItem[language.languageId] = updatedItem.name[language.languageId];
  return ({
    id: categoryDto.id,
    name: categoryNameItem,
    ids: categoryDto.ids,
  });
};

export const transformCategoryDtoList = (
  categoryDtoList: BlogPageModels.CategoryDto[],
) => categoryDtoList.map(transformCategoryDto);
