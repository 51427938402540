export namespace ScreenModels {
  export interface Types {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  }

  export enum Enum {
    isMobile = 'isMobile',
    isTablet = 'isTablet',
    isDesktop = 'isDesktop',
  }
}
