import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import styles from './dogDetails.module.scss';
import CardDogHeader from './cardDogHeader/cardDogHeader';
import CardDogHistory from './cardDogHistory/cardDogHistory';
import CardDogParameters from './cardDogParameters/cardDogParameters';
import CardDogGallery from './CardDogGallery/cardDogGallery';
import CardDogCareButton from './cardDogCareButton/cardDogCareButton';
import SignupForNewsContainer from '../../../shared-ui/sign-up-for-news/signupForNewsContainer';
import CardDogModalForm from './cardDogModalForm/cardDogModalForm';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { UserDogDetailsProps, DtoSet, Position } from './models/userDogDetails.model';
import useScroll from '../../../../hooks/useScroll';
// import ShareFacebookButton from './shareFacebookButton/shareFacebookButton';

const UserDogDetailsComponent = (props: UserDogDetailsProps) => {
  const [redirect, setRedirect] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const { selectedLanguage } = props;
  const [dog, setPet] = useState({});
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [lineButton, setLineButton] = useState(true);
  const { scrollY } = useScroll();
  const startLanguage = selectedLanguage;
  const dogUrl = window.location.href;
  const {
    getPet,
    petName,
    petDescription,
    petTitle,
    pet,
    petsAllList = [],
    match,
    cookies,
    error,
    handleSelectLanguage,
    i18n,
    t: translate,
    onSendMessage,
    success,
    sendSuccessNull,
    resetPetError,
  } = props;

  useEffect(() => {
    if (error) {
      resetPetError();
      history.push('/404-not-found', { from: window.location.pathname });
    } else {
      getPet(match.params.id, cookies);
    }
  }, [match, error]);

  useEffect(() => {
    handleSelectLanguage(startLanguage);
    i18n.changeLanguage(startLanguage);
  }, [startLanguage]);

  useEffect(() => {
    if (!dog) return;
    setPet(pet);
  }, [dog]);

  useScrollPosition(({ prevPos, currPos }: { prevPos: Position, currPos: Position}) => {
    const isShow = currPos.y > prevPos.y;
    if (isShow !== hideOnScroll) {
      setHideOnScroll(isShow);
      setLineButton(isShow);
    }
  }, [hideOnScroll]);

  const history = useHistory()
  // TODO: Should be uncommented when ST-2586 will be implemented
  // const mainPhoto = () => {
  //   const photoObj = pet.dogPhotoDtoList.find(elem => elem.main);
  //   if (photoObj) return photoObj.photo;
  //   return pet.dogPhotoDtoList[0] ? pet.dogPhotoDtoList[0].photo : undefined;
  // }

  const isVisiblePage = petDescription !== undefined
    && pet !== undefined
    && pet.dogPhotoDtoList !== undefined
    && petsAllList !== undefined;
  return (
    <>
      {redirect ? <Redirect to="/want-a-friend" /> : ''}
      {(isVisiblePage
      ) ? (
        <>
          <main className={styles.box}>
            <div className={styles.wrapper}>
              <button onClick={history.goBack} className={styles['back-link']} />
              <CardDogHeader
                name={petName[selectedLanguage as keyof DtoSet]}
                activeLanguage={selectedLanguage}
              />
              <div
                className={hideOnScroll || lineButton
                  ? `${styles.buttonWrapper} ${styles.buttonWrapperVisible}`
                  : `${styles.buttonWrapper} ${styles.buttonWrapperInvisible}`}
              >
                <div
                  className={scrollY < 12
                  ? `${styles.scrolled}`
                  : `${styles.notScrolled}`}
                >
                  <CardDogCareButton
                    care={1}
                    text={translate(TRANSLATE_KEYS.takeCare)}
                    setActive={setModalActive}
                    setFormTitle={setFormTitle}
                  />
                  <CardDogCareButton
                    care={0}
                    text={translate(TRANSLATE_KEYS.takeHome)}
                    setActive={setModalActive}
                    setFormTitle={setFormTitle}
                  />
                </div>
                <button
                  className={styles.lineButton}
                  onClick={() => {
                    setLineButton(!lineButton);
                    setHideOnScroll(!hideOnScroll);
                  }}
                />
              </div>
              <CardDogModalForm
                onSendMessage={onSendMessage}
                sendSuccessNull={sendSuccessNull}
                translate={translate}
                formTitle={formTitle}
                active={modalActive}
                setActive={setModalActive}
                setRedirect={setRedirect}
                success={success}
                dogName={petName[selectedLanguage as keyof DtoSet]}
                locale={selectedLanguage}
                dogUrl={dogUrl}
              />
              <CardDogGallery
                photos={
                  pet.dogPhotoDtoList.length > 5 ? pet.dogPhotoDtoList.slice(0, 5) : pet.dogPhotoDtoList}
              />
              <CardDogParameters
                male={pet.male}
                sterilized={pet.sterilized!}
                size={petTitle[selectedLanguage as keyof DtoSet]}
                age={pet.birthDate}
                activeLanguage={selectedLanguage}
                translate={translate}
              />
              <CardDogHistory
                description={petDescription[selectedLanguage as keyof DtoSet]}
                activeLanguage={selectedLanguage}
              />
              {/* TODO: Should be returned on page when ST-2586 will be implemented */}
              {/* <ShareFacebookButton
                text={translate(TRANSLATE_KEYS.shareFacebookBtn)}
                title={`${translate(TRANSLATE_KEYS.welcomeWantsFriend)} ${petName[selectedLanguage]}!`}
                image={mainPhoto()}
               /> */}
            </div>
            <SignupForNewsContainer />
          </main>
        </>
        ) : null}
    </>
  );
};


export default withTranslation('translations')(UserDogDetailsComponent);
