export const enum ArrowButtonDirection {
  Right = 'Right',
  Left = 'Left'
}

export namespace SliderModels {
  export interface ArrowButtonProps {
    onClick: () => void,
    direction: ArrowButtonDirection,
    disabled: boolean,
  }

  export interface NavigationProps {
    handleClickToPrevSlide: () => void
    handleClickToNextSlide: () => void
    isDisabledRightButton: boolean
    isDisabledLeftButton: boolean
  }
}
