import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alerts from '../../../../../shared-ui/alert';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

const MessageWindow: FC<{isEditingDog: boolean}> = ({ isEditingDog }) => {
  const [translate] = useTranslation();
  const message = isEditingDog
      ? translate(TRANSLATE_KEYS.changesHaveBeenSaved)
      : translate(TRANSLATE_KEYS.recordCreated);
  return (
    <div>
      <Alerts messageType="update" iconType="update" message={message} />
    </div>
  );
};

MessageWindow.propTypes = { isEditingDog: PropTypes.bool.isRequired };

export default withTranslation('translations')(MessageWindow);
