/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2674 16.6316C14.5926 16.6316 16.4798 14.7453 16.4798 12.4211C16.4798 11.8737 16.3703 11.36 16.1765 10.88L18.6366 8.42105C19.9087 9.48211 20.9113 10.8547 21.5263 12.4211C20.0688 16.1179 16.4714 18.7368 12.2589 18.7368C11.0795 18.7368 9.95053 18.5263 8.90584 18.1474L10.7256 16.3284C11.2058 16.5221 11.7198 16.6316 12.2674 16.6316ZM4.37771 19.4655C4.08216 19.1701 4.08216 18.691 4.37771 18.3955L5.76336 17.0105L6.15091 16.6232C4.75238 15.5368 3.65714 14.0884 3 12.4211C4.4575 8.72421 8.05493 6.10526 12.2674 6.10526C13.5732 6.10526 14.8201 6.3579 15.9575 6.81263L16.3113 6.45895L18.2446 4.53312C18.5404 4.2385 19.0188 4.23891 19.3141 4.53404C19.6099 4.82971 19.6099 5.30924 19.3141 5.60491L5.44719 19.4655C5.15182 19.7607 4.67308 19.7607 4.37771 19.4655ZM8.50145 14.2737L9.8073 12.9684C9.76518 12.7916 9.73991 12.6063 9.73991 12.4211C9.73991 11.0232 10.8688 9.89474 12.2674 9.89474C12.4527 9.89474 12.6381 9.92 12.815 9.96211L14.1208 8.65684C13.5564 8.37895 12.9329 8.21053 12.2674 8.21053C9.9421 8.21053 8.05493 10.0968 8.05493 12.4211C8.05493 13.0863 8.22343 13.7095 8.50145 14.2737ZM12.1326 14.9305L14.7864 12.2779L14.8033 12.4126C14.8033 13.8105 13.6743 14.9389 12.2758 14.9389L12.1326 14.9305Z" fill="#373737" />
  </svg>
);

export default SVG;
