import React, { useState } from 'react';
import DogName from './DogName';
import DogParameterSelect from './DogParametersSelect';
import DogDescription from './DogDescription';
import DogImage from './DogImage';
import style from './styles/dog-data.module.scss';
import { getParametersList } from '../helpers';
import { DogDataModels } from '../popupForm.models';
import { DogParameterSelects } from '../../../constants';
import { WAFPopupStoreModels } from '../+store/WAFPopupFormStore.models';

const DogData = ({
  language,
  currentDog,
  isEditing = false,
  onImageSelected,
  t: translate,
}: DogDataModels.Props) => {
  const [initLanguage, setInitLanguage] = useState('');
  const [otherLanguages, setOtherLanguages] = useState([]);

  const modifyInitLang = (newState) => {
    setInitLanguage(newState.initLanguage);
    setOtherLanguages(newState.otherLanguages);
  };

  return (
    <div className={style['article-form']}>
      <DogName
        initLanguage={initLanguage}
        otherLanguages={otherLanguages}
        language={language}
        dogName={currentDog.dogName}
        isEditing={isEditing}
        modifyInitLang={modifyInitLang}
      />
      <div className={style['selects-wrapper']}>
        {
          DogParameterSelects.map(item => (
            <DogParameterSelect
              key={item.key}
              selectTitle={translate(item.name)}
              parameterValues={getParametersList(translate, item.itemsName)}
              dogParameters={currentDog.dogParameters}
              parameter={item.itemsName}
              addRequiredLabel={item.key !== 4}
            />
          ))
        }
      </div>
      <DogDescription
        initLanguage={initLanguage}
        modifyInitLang={modifyInitLang}
        otherLanguages={otherLanguages}
        language={language}
        dogDescription={currentDog.dogDescription}
        isEditing={isEditing}
      />
      <DogImage onImgUpload={onImageSelected} dogPhotos={currentDog.dogPhotos as WAFPopupStoreModels.DogPhoto[]} />
    </div>
  );
}

export default DogData;
