/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Ru" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][D]-Create-New-Article-–-Dropdown-–-Edit-category" transform="translate(-928.000000, -424.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 112.000000)">
          <g id="Dropdown" transform="translate(32.000000, 264.000000)">
            <g id="Icon" transform="translate(472.000000, 48.000000)" fill="currentColor">
              <path d="M17.0610196,10.013716 C17.5342963,10.0927904 17.8950124,10.5042703 17.8950124,11 L17.8900496,11.0995037 L17.1800993,18.1990074 C17.0778589,19.221411 16.2175278,20 15.1900249,20 L8.80997512,20 C8.32658172,20 7.88018801,19.8276769 7.53232318,19.5387137 L17.0610196,10.013716 Z M12,4 C13.1045695,4 14,4.8954305 14,6 L16.9344136,6 L18.402336,4.53473684 C18.6978366,4.23937052 19.1767954,4.23937052 19.472296,4.53473684 C19.7407751,4.8030939 19.7652722,5.2231188 19.5457247,5.51915269 L19.472296,5.60421053 L5.60494004,19.4652632 C5.3094394,19.7606295 4.83048065,19.7606295 4.53498001,19.4652632 C4.26650092,19.1969061 4.24200379,18.7768812 4.4615513,18.4808473 L4.53498001,18.3957895 L6.62841355,16.301 L6.10995037,11.0995037 C6.05499599,10.5499599 6.45593998,10.0599172 7.00548384,10.0049628 L7.10498756,10 L12.9334136,9.999 L14.9334136,8 L6,8 C5.44771525,8 5,7.55228475 5,7 C5,6.44771525 5.44771525,6 6,6 L10,6 C10,4.8954305 10.8954305,4 12,4 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
