import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import FeedbackBlock from './feedback-block';
import styles from './feedback-list.module.scss';

const FeedbackListComponent = ({ feedback }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.box}>
      <div className={styles.feedback}>
        <h1 className={styles.title}>{t('feedbacks')}</h1>
        <p className={styles.text}>
          {t('ourHotelIsConstantlyWorking')}
        </p>
      </div>
      <div className={styles['input-block']}>
        {/* TODO: This field should be returned on page after implementing functionality */}
        {/* <input
          className={styles.input}
          name="text"
          type="text"
          placeholder={t('writeAFeedback')}
        /> */}
      </div>
      <ul className={styles.list}>
        {feedback.map(feedbackItem => (
          <FeedbackBlock feedback={feedbackItem} key={feedbackItem.review.substring(0, 20)} />
        ))}
      </ul>
    </section>
  );
};

FeedbackListComponent.propTypes = {
  feedback: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      review: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      petName: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf.isRequired,
    }),
  ).isRequired,
};

export default withTranslation('translations')(FeedbackListComponent);
