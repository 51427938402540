import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { AdminWAFPageActions } from '../../+store/actions';
import DogPageComponent from './dogDetailsComponent';
import selectLanguage from '../../../../../language/+store/actions';
import { AdminWAFPopupActions } from '../popupForm/+store/actions';
import { WAFPopupStoreModels } from '../popupForm/+store/WAFPopupFormStore.models';
import { WAFModels } from '../../../../../../models/WAFPage.models';

const mapStateToProps = state => ({
  pet: state.wantAFriendPagesReducer.pet,
  selectedLanguage: state.languageReducer.selectedLanguage.languageValue,
  petName: state.wantAFriendPagesReducer.petName,
  petTitle: state.wantAFriendPagesReducer.petTitle,
  petDescription: state.wantAFriendPagesReducer.petDescription,
  petsAllList: state.wantAFriendPagesReducer.petsAllList,
  deleteMessageVisible: state.wantAFriendPagesReducer.deleteMessageVisible,
  openModalCreatingDog: state.wantAFriendPagesReducer.openDogPopupForm,
});

const mapDispatchToProps = dispatch => ({
  getPet: (id: number, cookies: Cookies) => dispatch(AdminWAFPageActions.fetchPet(id, cookies)),
  getAllPets: (cookies: Cookies) => dispatch(AdminWAFPageActions.fetchAllPets(cookies)),
  handleSelectLanguage: (value: string) => dispatch(selectLanguage(value)),
  onUpdateDogHandler: (dogData: WAFPopupStoreModels.DogFormData, petBeforeUpdate: WAFModels.Pet) => {
    dispatch(AdminWAFPageActions.openDogPopupForm());
    dispatch(AdminWAFPopupActions.openPopupForDogUpdate(dogData, petBeforeUpdate));
  },
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(DogPageComponent))),
);
