import { BlogActionsTypes } from './blogActions.models';
import { transformArticleDto, transformAndFilterArticleDto } from '../../../../../utils/transformArticleDto';
import { getArticleContent,
  getArticleTabs,
  getArticleTitle,
  getArticleThesis,
  getArticleCategory,
  getUpdatedStateForUpdatedArticle,
  getUpdatedStateForPublishedArticle,
  getArticle } from './helpers';
import { BlogAction, BlogStoreModels } from './blogStore.models';
import { BlogPageModels } from '../../../../../models/blogPage.models';
import { defaultArticle } from '../constants';

export const initialState: BlogStoreModels.State = {
  editing: false,
  createdEditing: false,
  article: defaultArticle,
  articles: [],
  articlesList: [],
  articlesCount: 0,
  articleToDelete: null,
  articleCategory: null,
  articlesTitles: [],
  articleTabs: null,
  articleContent: null,
  articleTitle: null,
  articleThesis: null,
  mainArticle: null,
  loading: false,
  error: null,
  openModalCreatingPost: false,
  deleteMessageVisible: false,
  changeMainArticleMessageVisible: false,
  articleTitlesToChangeMainArticle: null,
};


export default function articlesPagesReducer(state = initialState, action: BlogAction) {
  switch (action.type) {
    case BlogActionsTypes.REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case BlogActionsTypes.REQUEST_END:
      return {
        ...state,
        loading: false,
      };

    case BlogActionsTypes.ADMIN_FETCH_ARTICLE:
      return {
        ...state,
        loading: false,
        article: transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage),
        articleCategory: getArticleCategory(action.payload.article),
        articleTabs: getArticleTabs(action.payload.article),
        articleTitle: getArticleTitle(action.payload.article),
        articleThesis: getArticleThesis(action.payload.article),
        articleContent: getArticleContent(action.payload.article),
        articles: state.mainArticle && state.mainArticle.id === action.payload.article.id
          ? state.articles
          : [transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage),
            ...state.articles.filter((item: BlogPageModels.Article) => item.id !== action.payload.article.id),
          ],
      };

    case BlogActionsTypes.ADMIN_FETCH_ARTICLE_LIST:
      return {
        ...state,
        loading: false,
        articlesList: [...action.payload.articlesList.map(transformArticleDto)],
      };

    case BlogActionsTypes.ADMIN_FETCH_ARTICLES:
      return {
        ...state,
        loading: false,
        editing: false,
        articles: [
          ...state.articles,
          ...action.payload.articlesList.map(item => {
            const transformedItem = transformAndFilterArticleDto(item, action.payload.selectedLanguage);
            return { ...transformedItem, thesis: transformedItem.thesis ? transformedItem.thesis : '' };
          }) || [],
        ],
      };

    case BlogActionsTypes.ADMIN_FETCH_ALL_ARTICLES_TITLES:
      return {
        ...state,
        loading: false,
        articlesTitles: action.payload.articleTitles,
        articlesCount: action.payload.articleTitles.length,
      };

    case BlogActionsTypes.ADMIN_FETCH_MAIN_ARTICLE:
      return {
        ...state,
        mainArticle: action.payload.mainArticle
          ? transformAndFilterArticleDto(action.payload.mainArticle, action.payload.selectedLanguage)
          : null,
      };

    case BlogActionsTypes.CLEAR_ARTICLES:
      return {
        ...state,
        articles: [],
        articleCategory: null,
        articleTabs: null,
        articleTitle: null,
        articleThesis: null,
        articleContent: null,
      };

    case BlogActionsTypes.CLEAR_ALL_ARTICLES:
      return {
        ...state,
        allArticles: [],
        article: {},
      };

    case BlogActionsTypes.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        remain: false,
      };

    case BlogActionsTypes.CREATE_NEW_POST:
      return {
        ...state,
        openModalCreatingPost: true,
      };

    case BlogActionsTypes.EDIT_POST:
      if (!action.payload.id) {
        return {
          ...state,
          createdEditing: true,
          editing: false,
          openModalCreatingPost: true,
        };
      }
      return {
        ...state,
        editing: true,
        openModalCreatingPost: true,
        article: state.article.id && !state.articles.length
          ? state.article
          : getArticle(state, action.payload.id),
      };

    case BlogActionsTypes.CLOSE_MODAL_WINDOW:
      return {
        ...state,
        openModalCreatingPost: false,
        editing: false,
        createdEditing: false,
        article: action.payload.closeModalWindowData.saveArticleForEdit ? { ...state.article } : defaultArticle,
      };

    case BlogActionsTypes.ARTICLE_CREATED_SUCCESSFULLY:
      return {
        ...state,
        openModalCreatingPost: false,
        createdEditing: false,
        articlesCount: state.articlesCount + 1,
        article: defaultArticle,
        articles: [
          transformArticleDto(action.payload.article),
          ...state.articles,
        ],
      };

    case BlogActionsTypes.ARTICLE_UPDATED_SUCCESSFULLY:
      return getUpdatedStateForUpdatedArticle(state, action.payload.articleUpdateData);

    case BlogActionsTypes.BLOG_DISPLAY_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: true,
        articleToDelete: action.payload.articleDeleteData,
      };

    case BlogActionsTypes.BLOG_HIDE_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: false,
        idToDelete: null,
      };

    case BlogActionsTypes.DELETE_ARTICLE_START:
      return {
        ...state,
        loading: true,
      };

    case BlogActionsTypes.DELETE_ARTICLE_SUCCESS:
      if (
        state.mainArticle && state.mainArticle.id === action.payload.id
      ) return { ...state, mainArticle: null, loading: false };
      return {
        ...state,
        articles: state.articles.filter((article: BlogPageModels.Article) => article.id !== action.payload.id),
        articlesCount: state.articlesCount - 1,
        loading: false,
      };

    case BlogActionsTypes.DELETE_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case BlogActionsTypes.PUBLISH_ARTICLE_START:
      return {
        ...state,
        loading: true,
      };

    case BlogActionsTypes.PUBLISH_ARTICLE_SUCCESS:
      return getUpdatedStateForPublishedArticle(state, action.payload);

    case BlogActionsTypes.PUBLISH_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case BlogActionsTypes.HIDE_ARTICLE_START:
      return {
        ...state,
        loading: true,
      };

    case BlogActionsTypes.HIDE_ARTICLE_SUCCESS:
      if (!state.mainArticle) {
        return {
          ...state,
          article: state.article.id === action.payload.id
            ? transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage)
            : state.article,
          articles: state.articles.map(article => (article.id === action.payload.id
            ? transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage)
            : article)),
          mainArticle: null,
          loading: false,
        };
      }
      return {
        ...state,
        article: state.article.id === action.payload.id
          ? transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage)
          : state.article,
        articles: state.articles.map(article => (article.id === action.payload.id
          ? transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage)
          : article)),
        mainArticle: state.mainArticle.id === action.payload.id
          ? transformAndFilterArticleDto(action.payload.article, action.payload.selectedLanguage)
          : state.mainArticle,
        articleContent: getArticleContent(action.payload.article),
        articleTabs: getArticleTabs(action.payload.article),
        loading: false,
      };

    case BlogActionsTypes.HIDE_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case BlogActionsTypes.TOGGLE_CHANGE_MAIN_ARTICLE_MESSAGE:
      return {
        ...state,
        createdEditing: action.payload.changeMainArticleMessageData.created ? true : state.createdEditing,
        changeMainArticleMessageVisible: !state.changeMainArticleMessageVisible,
        articleTitlesToChangeMainArticle: action.payload.changeMainArticleMessageData.newMainArticleTitles?.length
          ? { ...action.payload.changeMainArticleMessageData }
          : null,
      };

    default:
      return state;
  }
}
