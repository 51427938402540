import React from 'react';
import styles from './dotsLoader.module.scss';

const dotsLoader = () => (
  <div className={styles['preloader-dots']}>
    <div className={styles.dot} />
    <div className={styles.dot} />
    <div className={styles.dot} />
  </div>
);


export default dotsLoader;
