import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import iconStyles from './image-change-icon.module.scss';
import Icon from '../../icon';


const ImageChangeIcon = ({ handleImageChange, t }) => {
  const [tooltipVisible, setTooltipVisibility] = useState(false);

  return (
    <>
      {tooltipVisible ? <span className={iconStyles['button-tooltip']}>{t('editTool')}</span> : null}
      <button
        className={iconStyles['pencil-button']}
        onClick={handleImageChange}
        onMouseEnter={() => setTooltipVisibility(!tooltipVisible)}
        onMouseLeave={() => setTooltipVisibility(!tooltipVisible)}
      >
        <Icon name="pencil" />
      </button>
    </>
  );
};

ImageChangeIcon.propTypes = {
  t: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};

export default ImageChangeIcon;
