import { LANGUAGES, DEFAULT_LANGUAGE } from './languages';
import SELECT_LANGUAGE from './types';

const selectLanguage = (value) => {
  const selectedLanguage = Object.values(LANGUAGES).find(language => value === language.languageValue);
  return {
    type: SELECT_LANGUAGE,
    payload: selectedLanguage || DEFAULT_LANGUAGE,
  };
};

export default selectLanguage;
