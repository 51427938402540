import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import ImageDropZone from './image-drop-zone';
import { cloudDeleteFile } from '../../../vendors/cloudApi';
import styles from './image-uploader.module.scss';
import { AdminBlogPopupActions } from '../../admin/components/articlesPage/components/popupForm/+store/actions';
import ImageInput from './image-input';
import ImageProgress from './image-progress';
import ImagePreview from './image-preview';
import ImageChangeIcon from './image-change-icon';
import parseImageFilenameFromURL from '../../../utils/parseImageFilenameFromURL';


class ImageUploader extends React.Component {
  fileInput = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      src: '',
      selectedImg: null,
      progress: 0,
      isInProgress: false,
    };

    this.handleSelectedImg = this.handleSelectedImg.bind(this);
    this.handleRemoveImg = this.handleRemoveImg.bind(this);
    this.handleDropImg = this.handleDropImg.bind(this);
    this.handleUpdateProgress = this.handleUpdateProgress.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleUpdateProgress = (event) => {
    const img = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(img);

    fileReader.onloadstart = (e) => {
      this.setState({
        progreskb: e.loaded,
        progress: 40,
        imgSize: e.total,
        isInProgress: true,
      });
    };

    fileReader.onprogress = (e) => {
      if (e.lengthComputable) {
        const progreskb = e.loaded;
        const loadProgressPercent = (e.loaded / e.total) * 100;
        this.setState({
          progress: loadProgressPercent,
          progreskb,
          isInProgress: true,
        });
      }
    };

    fileReader.onload = () => {
      this.setState({ src: fileReader.result });
    };

    fileReader.onloadend = (e) => {
      this.setState({
        progress: 100,
        progreskb: e.loaded,
        isInProgress: false,
      });
    };
  };

  handleSelectedImg = (event) => {
    this.handleUpdateProgress(event);

    const { onImgSelected } = this.props;
    this.setState({
      selectedImg: event.target.files[0],
      src: URL.createObjectURL(event.target.files[0]),
      imgSize: event.total,
    });

    onImgSelected(event);
    this.fileInput.current.value = '';
  };

  handleRemoveImg = (event) => {
    event.preventDefault();
    const { checkImageRemove, deleteMainImgLink, mainImgLink, onImgSelected } = this.props;
    if (mainImgLink) {
      const fileName = parseImageFilenameFromURL(mainImgLink);
      cloudDeleteFile(fileName, (result, error) => {
        // eslint-disable-next-line no-console
        console.log(result, error);
      });
    }

    this.setState({
      src: '',
      selectedImg: null,
      imgSize: 0,
    });

    onImgSelected(null);
    deleteMainImgLink();
    checkImageRemove();
  };

  handleDropImg = (e) => {
    const { onImgSelected } = this.props;
    this.handleUpdateProgress(e);
    this.setState({
      selectedImg: e.dataTransfer.files[0],
      src: URL.createObjectURL(e.dataTransfer.files[0]),
    });

    onImgSelected(e);
    this.fileInput.current.value = '';
  };

  handleImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.fileInput.current.click();
  };

  render() {
    const {
      src,
      selectedImg,
      isInProgress,
      progress,
      progreskb,
      imgSize,
    } = this.state;
    const { t, isProfilePage, mainImgLink } = this.props;
    const imgUploadedUrl = src || null;
    const imgLoaded = selectedImg || null;
    const imgProgressInKb = isInProgress && imgLoaded
      ? Math.ceil((imgSize * 0.4) / 1024)
      : (progreskb / 1024).toFixed(1);
    const imgTotalInMb = (imgSize / 1024 / 1024).toFixed(1);

    return (
      <div className={styles['image-uploader']}>

        <input
          type="file"
          name="articleFormImg"
          id="articleFormImg"
          onChange={this.handleSelectedImg}
          ref={(input) => {
            this.fileInput.current = input;
          }}
          hidden
          accept="image/*"
        />

        {!imgLoaded && !isInProgress ? (
          <ImageDropZone
            handleDrop={this.handleDropImg}
            isProfilePage={isProfilePage}
          >
            <ImageInput
              onChange={this.handleSelectedImg}
              handleImageChange={this.handleImageChange}
            />
          </ImageDropZone>
        ) : null}
        {isInProgress
          ? (
            <ImageProgress
              loadedkb={progreskb}
              imgTotal={imgSize}
              isInProgress={isInProgress}
              selectedImg={selectedImg}
              imgProgressInKb={imgProgressInKb}
              imgTotalInMb={imgTotalInMb}
              progress={progress}
            />
          ) : null}
        {!isInProgress && imgLoaded
          ? (
            <ImagePreview
              isProfilePage={isProfilePage}
              imgUploadedUrl={imgUploadedUrl}
              src={src}
              selectedImg={selectedImg}
              t={t}
              handleImageChange={this.handleImageChange}
              handleRemoveImg={this.handleRemoveImg}
              mainImgLink={mainImgLink}
            />
          ) : null}
        {isProfilePage
          ? (
            <ImageChangeIcon
              handleImageChange={this.handleImageChange}
              t={t}
            />
          ) : null}
      </div>
    );
  }
}

ImageUploader.propTypes = {
  onImgSelected: PropTypes.func.isRequired,
  checkImageRemove: PropTypes.func,
  t: PropTypes.func.isRequired,
  isProfilePage: PropTypes.bool,
  deleteMainImgLink: PropTypes.func.isRequired,
  mainImgLink: PropTypes.string,
};

ImageUploader.defaultProps = { checkImageRemove: result => result, isProfilePage: false, mainImgLink: null };

const mapStateToProps = state => ({
  src: state.adminArticleCreateFormReducer.src,
  mainImgLink: state.adminArticleCreateFormReducer.mainImgLink,
});

const mapDispatchToProps = dispatch => (
  { deleteMainImgLink: () => dispatch(AdminBlogPopupActions.postArticleImageDelete()) }
);

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(ImageUploader)),
);
