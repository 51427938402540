import { MouseEventHandler } from 'react';
import { TranslatedText } from '../../../models/translate-function.models';

export const enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export interface ButtonProps {
  content?: TranslatedText;
  uiType?: string;
  href?: string;
  htmlType?: ButtonTypes;
  small?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
}
