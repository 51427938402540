import React, { FC } from 'react';
import { AlertsComponentModels } from './alerts.models';
import styles from './alerts.module.scss';

const Alerts: FC<AlertsComponentModels.Props> = ({ messageType, iconType, message }) => (
  <div className={styles['warning-note']}>
    <i className={`${styles.icon} ${styles[`icon-${iconType}`]}`} />
    <p className={`${styles[`warning-note__message--${messageType}`]}`}>{message}</p>
  </div>
);

export default Alerts;
