import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import TRANSLATE_KEYS from '../../i18n_keys';
import { StatusCodes } from '../../models/common.models';
import { TranslateFn } from '../../models/translate-function.models';
import styles from './error-page.module.scss';
import AdminHeader from '../admin/components/header/header';
import UserHeader from '../shared-ui/header/Header';
import ErrorImage404 from '../../assets/images/no-content.svg';

interface ErrorPageProps {
  statusCode: StatusCodes
  t: TranslateFn,
}

const ADMIN_IN_URL_REGEXP = /\/admin/;

const ErrorPage: FC<ErrorPageProps> = ({ statusCode, t: translate }) => {
  const location = useLocation<{from: string}>();
  const prevPath = location.state.from;
  console.log(prevPath);

  const isAdmin = ADMIN_IN_URL_REGEXP.test(prevPath);
  const Header = isAdmin ? AdminHeader : UserHeader;
  const errorHeading = `heading${statusCode}Error`;
  const errorMessage = `message${statusCode}Error`;
  let errorImg: string;

  switch (statusCode) {
    case StatusCodes.NotFound:
      errorImg = ErrorImage404;
      break;
  }

  return (
    <>
      <Header />
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <div className={styles['error-block']}>
            <img className={styles['error-image']} src={errorImg} alt="Error icon" />
            <h1 className={styles['error-title']}>{translate(TRANSLATE_KEYS[errorHeading])}</h1>
            <h3 className={styles['error-subtitle']}>{translate(TRANSLATE_KEYS[errorMessage])}</h3>
          </div>
        </div>
      </div>
    </>
  )
};

export default withTranslation('translations')(ErrorPage);
