import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Cookies } from 'react-cookie';
import ArticleList from './components/articleList/articleList';
import styles from '../../../blog/components/articlesList/ArticlesList.module.scss';
import Button from '../../../shared-ui/button';
import PopupComponent from './components/popupForm/popupForm';
import FilterButtons from './components/filterButtons/filterButtons';
import PopupChangeMainArticle from './components/popupForm/components/popupMessages/changeMainArticle';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import ListHeader from '../../../shared-ui/adminListHeader/ListHeader';
import { BlogPageModels } from '../../../../models/blogPage.models';
import { TranslateFn } from '../../../../models/translate-function.models';
import scrollToElement from '../../../../utils/scrollToElement';

const START_PAGE = 1;
const criticalArticleNumber = 11;
const criticalArticlePage = 2;

interface ArticlesPageProps extends RouteComponentProps {
  article: BlogPageModels.Article;
  articles: BlogPageModels.Article[];
  mainArticle: BlogPageModels.Article;
  articlesCount: number;
  openModalCreatingPost: boolean;
  changeMainArticleMessageVisible: boolean;
  cookies: Cookies;
  fetchArticles: (page: number, cookies: Cookies) => void;
  fetchMainArticle: (cookies: Cookies) => void;
  fetchAllArticlesTitles: (cookies: Cookies) => void;
  clearArticles: () => void;
  adminFetchProfileDataRequest: (cookies: Cookies) => void;
  t: TranslateFn;
}

const ArticlesPage: FC<ArticlesPageProps> = ({
  article,
  articles,
  mainArticle,
  articlesCount,
  openModalCreatingPost,
  changeMainArticleMessageVisible,
  cookies,
  fetchArticles,
  fetchMainArticle,
  fetchAllArticlesTitles,
  clearArticles,
  adminFetchProfileDataRequest,
  t: translate,
}) => {
  const [page, setPage] = useState(START_PAGE);
  const [startRemoving, setStartRemoving] = useState(false);

  useEffect(() => {
    const { id: articleId } = article;

    if (articleId) {
      scrollToElement(`[href$='/article/${articleId}']`, 200);
    }
    fetchMainArticle(cookies);
    fetchArticles(page, cookies);
    fetchAllArticlesTitles(cookies);
    adminFetchProfileDataRequest(cookies);
    if (!mainArticle) {
      fetchNextPage();
    }
    // window.addEventListener('scroll', onScroll);

    return () => {
      clearArticles();
      // window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    if (!mainArticle) {
      fetchNextPage();
    }
    if (startRemoving && articlesCount === criticalArticleNumber) {
      fetchArticles(criticalArticlePage, cookies);
      handleChangeRemoving();
    }
  }, [mainArticle]);

  const handleChangeRemoving = () => {
    setStartRemoving(!startRemoving);
  };

  const fetchNextPage = () => {
    const newPage = page + 1;
    setPage(newPage);
    fetchArticles(newPage, cookies);
  }

  // TODO: EPMRDUADHP-4010 - Uncomment FilterButtonsFilterButtons after the Comments page is built.
  return (
    <>
      <ListHeader isBlogPage petsCount={articlesCount} />
      {/* <FilterButtons t={t} /> */}
      <ArticleList
        articles={articles}
        mainArticle={mainArticle}
        handleChangeRemoving={handleChangeRemoving}
      />
      {openModalCreatingPost && <PopupComponent />}
      {changeMainArticleMessageVisible && <PopupChangeMainArticle />}
      {articlesCount - articles.length > 1 && (
        <div className={styles['more-articles-button']}>
          <Button
            content={translate(TRANSLATE_KEYS.moreArticles)}
            uiType="grayWide"
            onClick={fetchNextPage}
          />
        </div>
      )}
    </>
  );
}

export default withTranslation('translations')(ArticlesPage);
