import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import TRANSLATE_KEYS from '../../../../i18n_keys'

import styles from '../image-uploader.module.scss';
import Icon from '../../icon';

class ImageDropZone extends Component {
  fileDropRef = React.createRef();

  constructor(props) {
    super(props);

    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    this.dragCounter = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
  };

  handleDrop = (e) => {
    const { handleDrop } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e);
      this.dragCounter = 0;
    }
  };

  render() {
    const { children, t: translate, isProfilePage } = this.props;
    return (
      <div
        className={!isProfilePage ? styles['image-dropzone'] : styles['image-dropzone_profile']}
        ref={this.fileDropRef}
        onDragEnter={this.handleDragIn}
        onDragLeave={this.handleDragOut}
        onDragOver={this.handleDrag}
        onDrop={this.handleDrop}
      >
        <Icon name="image-upload" />
        <p className={styles['image-dropzone-text']}>{translate(TRANSLATE_KEYS.dropImgHere)}</p>
        {isProfilePage ? null : (
          <span className={styles['image-dropzone-caption']}>
            {translate(TRANSLATE_KEYS.dropImgHereCaption)}
          </span>
        )}
        {children}
      </div>
    );
  }
}

ImageDropZone.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  t: PropTypes.func.isRequired,
  isProfilePage: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  src: state.adminArticleCreateFormReducer.src,
  mainImgLink: state.adminArticleCreateFormReducer.mainImgLink,
});

export default withTranslation('translations')(
  connect(mapStateToProps)(withCookies(ImageDropZone)),
);
