import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import styles from './button.module.scss';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import TRANSLATE_KEYS from '../../../../../i18n_keys';

const PriceListButton: FC<ServicesPageModels.ButtonProps> = ({ content }) => {
  const [translate] = useTranslation();
  const [buttonClassName, setButtonClassName] = useState<string>(styles.gray);
  const [buttonContent, setButtonContent] = useState<string>(content);

  const mouseOver = (): void => {
    setButtonClassName(`${styles.gray} ${styles.sun}`);
    setButtonContent(`${translate(TRANSLATE_KEYS.contacts)}`);
  };

  const mouseLeave = (): void => {
    setButtonClassName(`${styles.gray}`);
    setButtonContent(content);
  };

  return (
    <Link to="/contacts" onMouseOver={mouseOver} onMouseLeave={mouseLeave} className={buttonClassName}>
      {buttonContent} {buttonContent === `${translate(TRANSLATE_KEYS.contacts)}`
        ? null
        : `${translate(TRANSLATE_KEYS.money)}`}
    </Link>
  );
};

export default withTranslation('translations')(PriceListButton);
