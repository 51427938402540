import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticelsListModels } from './articles-list.models';

import styles from './ArticlesList.module.scss';

import Button from '../../../shared-ui/button';
import ArticlesListItem from './ArticlesListItem';

const ArticlesList: FC<ArticelsListModels.Props> = ({ articles, isPagination, from, handlePagination }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.box}>
      <div className={styles.articles}>
        {articles.map((item) => {
          const articleItemProps = {
            id: item.id,
            title: item.title,
            image: item.image,
            category: item.category,
          }
          return (
            <ArticlesListItem key={item.id} from={from} {...articleItemProps} />
          )
          })}
        {isPagination && (
          <div className={styles['more-articles-button']}>
            <Button content={translate('moreArticles')} uiType="gray" onClick={handlePagination} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ArticlesList);
