import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './listHeader.module.scss';
import NewRecordBtn from './NewRecordBtn';
import RecordsCount from './RecordsCount';
import useScroll from '../../../hooks/useScroll';
import TRANSLATE_KEYS from '../../../i18n_keys';
import { TranslateFn } from '../../../models/translate-function.models';


interface ListHeaderProps {
  petsCount: number;
  isBlogPage?: boolean;
  t: TranslateFn;
}

const WAF_LIST_HEADER_HEIGHT = 92;
const BLOG_LIST_HEADER_HEIGHT = 72;

const ListHeader: FC<ListHeaderProps> = ({
  petsCount,
  isBlogPage,
  t: translate,
}) => {
  const { scrollY } = useScroll();
  const yScrollDisplayThreshold = isBlogPage ? BLOG_LIST_HEADER_HEIGHT : WAF_LIST_HEADER_HEIGHT;
  const shouldDisplayNewRecordButton = Math.ceil(scrollY) < yScrollDisplayThreshold;

  return (
    <div className={`${styles.header} ${isBlogPage ? null : styles['header-rewrite']}`}>
      <div className={`${styles.wrapper} ${isBlogPage ? null : styles['wrapper-rewrite']}`}>
        <div className={`${styles['title-container']}`}>
          {petsCount !== 0 ? (
            <RecordsCount />
          ) : (
            <h2 className={styles.title_collapse}>
              {translate(TRANSLATE_KEYS.noAnimals)}
            </h2>
          )}
          { shouldDisplayNewRecordButton && <NewRecordBtn />}
        </div>
      </div>
    </div>
  );
};

ListHeader.defaultProps = { isBlogPage: false };

export default withTranslation('translations')(ListHeader);
