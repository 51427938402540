import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import Article from '../../../blog/components/articlesList/ArticlesListItem';
import styles from './articles.module.scss';
import button from '../../../shared-ui/button/button.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { Article as ArticleType } from '../../../../types/article.model';

const Articles = ({ articles }: { articles: ArticleType[] }) => {
  const { t: translate } = useTranslation();
  const location = useLocation();

  if (!articles.length) return null;

  return (
    <div className={styles.box}>
      <div className={styles.articles}>
        <div className={styles['title-row']}>
          <h1 className={styles.title}>{translate(TRANSLATE_KEYS.articles)}</h1>
          <Link
            to={{ pathname: '/blog/0', state: { fromNav: true } }}
            className={`${button.button} ${button.sun} ${styles.link} ${styles['tablet-hidden']}`}
          >
            {translate(TRANSLATE_KEYS.goToTheBlog)}
          </Link>
        </div>
        <div className={styles.cards}>
          {articles.map((article) => (
            <Article
              key={article.id}
              from={location.pathname}
              {...article}
            />
          ))}
        </div>
        <Link
          to={{ pathname: '/blog/0', state: { fromNav: true } }}
          className={`${button.button} ${button.sun} ${styles.link} ${styles['desktop-hidden']}`}
        >
          {translate(TRANSLATE_KEYS.goToTheBlog)}
        </Link>
      </div>
    </div>
  );
};


export default withTranslation('translations')(Articles);
