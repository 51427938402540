import { Cookies } from 'react-cookie';
import { TranslatedText, TranslateFn } from '../../../../../../models/translate-function.models';

export const enum InputValues {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  ConfirmNewPassword = 'confirmNewPassword'
}

export namespace ChangePasswordModels {
  export interface Props {
    requestError: string;
    cookies: Cookies;
    closeChangePasswordModal: () => void;
    sendUpdatePasswordRequest: (credentials: PasswordUpdateData, cookies: Cookies) => void;
    t: TranslateFn;
  }

  export interface State {
    currentPassword: string;
    newPassword: string
    confirmNewPassword: string;
    userId: number;
    errors: Errors;
  }

  export interface Errors {
    currentPassword: TranslatedText;
    newPassword: TranslatedText;
    confirmNewPassword: TranslatedText;
    validation: TranslatedText;
    requestError: TranslatedText;
  }

  export interface PasswordUpdateData {
    currentPassword: string;
    newPassword: string;
    userId: number;
  }
}
