import getDogAgeTranslateKey from './getDogAgeTranslateKey';
import { TranslateFn } from '../models/translate-function.models';
import { WAFModels } from '../models/WAFPage.models';
import { getParametersList } from '../app/admin/components/wantAFriendPage/components/popupForm/helpers';
import { WAFPopupStoreModels }
  from '../app/admin/components/wantAFriendPage/components/popupForm/+store/WAFPopupFormStore.models';

const getDogDataForUpdate = (petData: WAFModels.Pet, translate: TranslateFn): WAFPopupStoreModels.DogFormData => {
  const dogData = {
    dogId: petData.id,
    parentDogId: petData.parentDogId || 0,
    dogName: petData.dogNameDtoSet.reduce((acc, nameDto) => {
      acc[nameDto.language.languageValue] = nameDto.name;
      return acc;
    }, { uk: '', en: '' }),
    dogDescription: petData.dogDescriptionDtoSet.reduce((acc, desriptionDto) => {
      acc[desriptionDto.language.languageValue] = desriptionDto.description;
      return acc;
    }, { uk: '', en: '' }),
    dogParameters: {
      sex: petData.male ? 0 : 1,
      age: Object.values(getParametersList(translate, 'age'))
        .indexOf(translate(getDogAgeTranslateKey(petData.birthDate))),
      size: petData.sizeId - 1,
      sterilized: petData.sterilized ? 1 : 0,
    },
    dogPhotos: petData.dogPhotoDtoList
      .sort((a, b) => Number(b.main) - Number(a.main))
      .map(dogPhoto => dogPhoto.photo),
    isUnderCare: petData.hasGuardianship,
  };

  return dogData;
}

export default getDogDataForUpdate;
