import React, { FC } from 'react';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import styles from '../DescriptionTemplate.module.scss';
import grid from '../../../../../assets/styles/grid.module.scss';

const TemplateRight: FC<ServicesPageModels.DescriptionTemplateProps> = ({
  position,
  firstImg,
  children,
  extraClassName = '',
  secondImg = '',
}) => (
  <div className={`${styles.container}
    ${grid.row}
    ${position}
    ${styles['container-mobile']}
    ${styles[extraClassName]}`}
  >
    <div className={`${grid['col-4']} ${styles.text}`}>{children}</div>
    <div className={grid['col-8']}>
      <div className={styles['box-right']}>
        {secondImg && <div className={styles['small-image-right']}><img src={secondImg} alt="pet photos" /></div>}
        <div className={styles['big-image-right']}><img src={firstImg} alt="pet photos" /></div>
      </div>
    </div>
  </div>
);

export default TemplateRight;
