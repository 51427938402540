import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import WantAFriendUserPageComponent from './WantAFriendUserPageComponent';
import { fetchPetsRecords,
  fetchUserPetsRecords,
  maleDogFilter,
  femaleDogFilter,
  allSexDogFilter,
  beforeYearDogFilter,
  fromOneToFiveYears,
  fiveAndMore,
  smallDogFilter,
  mediumDogFilter,
  bigDogFilter,
  allAgeDogFilter,
  allSizeDogFilter,
  fetchNextItems } from './+store/actions';


const mapStateToProps = state => (state.wantAFriendUserPagesReducer);
const mapDispatchToProps = dispatch => (
  {
    fetchPetsRecords: (page, cookies) => dispatch(fetchPetsRecords(page, cookies)),
    fetchUserPetsRecords: cookies => dispatch(fetchUserPetsRecords(cookies)),
    maleDogFilter: data => dispatch(maleDogFilter(data)),
    femaleDogFilter: data => dispatch(femaleDogFilter(data)),
    allSexDogFilter: data => dispatch(allSexDogFilter(data)),
    beforeYearDogFilter: data => dispatch(beforeYearDogFilter(data)),
    fromOneToFiveYears: data => dispatch(fromOneToFiveYears(data)),
    fiveAndMore: data => dispatch(fiveAndMore(data)),
    smallDogFilter: data => dispatch(smallDogFilter(data)),
    mediumDogFilter: data => dispatch(mediumDogFilter(data)),
    bigDogFilter: data => dispatch(bigDogFilter(data)),
    allAgeDogFilter: data => dispatch(allAgeDogFilter(data)),
    allSizeDogFilter: data => dispatch(allSizeDogFilter(data)),
    fetchNextItems: data => dispatch(fetchNextItems(data)),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(WantAFriendUserPageComponent)));
