import React, { FC } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation, useTranslation } from 'react-i18next';
import SendMessage from '../../../shared-ui/send-message/sendMessageContainer';
import styles from './info.module.scss';
import grid from '../../../../assets/styles/grid.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';

interface InfoProps {
  displayAlert: boolean,
}

const Info: FC<InfoProps> = ({ displayAlert }) => {
  const { t: translate } = useTranslation();
  return (
    <section className={styles.box}>
      <div className={styles.alert}>
        <button style={displayAlert ? { display: 'flex' } : { display: 'none' }}>
          <i />
          <p>{translate(TRANSLATE_KEYS.messageSent)}</p>
        </button>
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{translate(TRANSLATE_KEYS.contacts)}</h2>
        <div className={grid.row}>
          <div className={grid['col-6']}>
            <div className={styles.block}>
              <h3 className={styles.subtitle}>{translate(TRANSLATE_KEYS.callUs)}</h3>
              <div>
                <a className={styles.tel} href="tel:+380444047642">+380 44 404-76-42</a>
              </div>
              <div>
                <a className={styles.tel} href="tel:+380679179585">+380 67 917-95-85</a>
              </div>
              <div>
                <a className={styles.tel} href="tel:+380994594451">+380 99 459-44-51</a>
              </div>
            </div>
            <div className={styles.block}>
              <h3 className={styles.subtitle}>{translate(TRANSLATE_KEYS.socialTheNetwork)}</h3>
              <div>
                <a
                  className={styles.socials}
                  href="https://www.facebook.com/hoteldogsandcats"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
              </div>
            </div>
            <div className={styles.block}>
              <h3 className={styles.subtitle}>{translate(TRANSLATE_KEYS.ourAddress)}</h3>
              <address className={styles.address}>{translate(TRANSLATE_KEYS.addressOtradny)}</address>
            </div>
          </div>
          <div className={grid['col-6']}>
            <SendMessage method="by phone" />
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = state => ({ displayAlert: state.messageReducer.displayAlert });

export default compose(withTranslation('translations'), connect(mapStateToProps, null))(Info);
