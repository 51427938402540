/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Add-Open-–-Hover" transform="translate(-668.000000, -676.000000)">
        <g id="Add-content" transform="translate(112.000000, 664.000000)">
          <g id="Menu" transform="translate(544.000000, 0.000000)">
            <g id="Icon" transform="translate(12.000000, 12.000000)" fill="currentColor">
              <path d="M19,4 C19.5128358,4 19.9355072,4.38604019 19.9932723,4.88337887 L20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 C18.4871642,8 18.0644928,7.61395981 18.0067277,7.11662113 L18,7 L18,6 L13,6 L13,18 L14,18 C14.5522847,18 15,18.4477153 15,19 C15,19.5128358 14.6139598,19.9355072 14.1166211,19.9932723 L14,20 L10,20 C9.44771525,20 9,19.5522847 9,19 C9,18.4871642 9.38604019,18.0644928 9.88337887,18.0067277 L10,18 L11,18 L11,6 L6,6 L6,7 C6,7.51283584 5.61395981,7.93550716 5.11662113,7.99327227 L5,8 C4.48716416,8 4.06449284,7.61395981 4.00672773,7.11662113 L4,7 L4,5 C4,4.48716416 4.38604019,4.06449284 4.88337887,4.00672773 L5,4 L19,4 Z" id="Combined-Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
