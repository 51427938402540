import React, { FC } from 'react';
import * as Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';
import { ButtonProps, ButtonTypes } from './button.models';

const Button: FC<ButtonProps> = ({
  content,
  uiType,
  href,
  htmlType = ButtonTypes.Button,
  onClick,
  small = false,
  disabled = false,
}) => {
  const LinkScroll = Scroll.Link;
  let button: JSX.Element;
  const classes = styles[uiType];

  const Btn = () => (
    <button
      className={`${classes} ${small && styles['btn-collapse']}`}
      type={htmlType}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );

  if (href) {
    button = href[0] === '#'
      ? <LinkScroll to={href} className={classes} smooth duration={500}>{content}</LinkScroll>
      : <Link to={href} className={classes} onClick={onClick}>{content}</Link>;
  } else {
    button = (
      <Btn />
    );
  }

  return button;
};

export default Button;
