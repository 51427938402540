import React from 'react';
import styles from './image-uploader.module.scss';

const DogImageSmallPreview = ({ dogPhotoLink }: { dogPhotoLink: string }) => (
  dogPhotoLink
    ? (
      <img
        src={dogPhotoLink}
        alt="Dog"
        className={styles.image_small}
      />
    ) : (
      <div className={styles.image_small} />
    )
);

export default DogImageSmallPreview;
