import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import createArticleStyle from './styles/dog-data.module.scss';
import { AdminWAFPopupActions } from '../+store/actions';
import { handleAutocomplete } from '../helpers';
import { DogNameAndDescriptionModels } from '../popupForm.models';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { defaultTranslation } from '../../../constants';

const NAME_MINIMUM_CHARACTER_LENGTH = 2;
const NAME_MAXIMUM_CHARACTER_LENGTH = 24;

const DogName = ({
  dogName = defaultTranslation,
  language,
  initLanguage,
  otherLanguages,
  isEditing,
  modifyInitLang,
  changeName,
  t: translate,
}: DogNameAndDescriptionModels.Props) => {
  useEffect(() => () => {
    changeName(language.languageValue, '');
  }, [])

  const nameChange = (event) => {
    const name = event.target.value;
    if (name.length > NAME_MAXIMUM_CHARACTER_LENGTH) return;
    changeName(language.languageValue, name);

    /* this feature will be deleted in future */
    handleAutocomplete({
      language,
      otherLanguages,
      initLanguage,
      isEditing,
      modifyInitLang,
    }, dogName, changeName, name);
  };

  return (
    <label className={createArticleStyle['article-form-name']} htmlFor="articleName">
      <input
        className={[
            createArticleStyle.input,
            otherLanguages.includes(language.languageValue) ? createArticleStyle.autocompleted : '',
          ].join(' ')
        }
        style={
          dogName[language.languageValue].length < NAME_MINIMUM_CHARACTER_LENGTH
            && dogName[language.languageValue].length !== 0
              ? { border: 'solid red 1px' }
              : { border: 'none' }
        }
        type="text"
        maxLength={NAME_MAXIMUM_CHARACTER_LENGTH}
        id="articleName"
        onChange={nameChange}
        value={dogName[language.languageValue]}
      />
      <span className={createArticleStyle.label}>{translate(TRANSLATE_KEYS.inputDogName)}</span>
      <span className={createArticleStyle.counter}>
        {NAME_MAXIMUM_CHARACTER_LENGTH - dogName[language.languageValue].length}
      </span>
    </label>
  );
}

const mapDispatchToProps = dispatch => ({
  changeName: (language: string, name: string) => dispatch(
    AdminWAFPopupActions.dogNameChange(language, name),
  ),
});

export default withTranslation('translations')(connect(null, mapDispatchToProps)(DogName));
