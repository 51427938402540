import React, { FC, memo } from 'react';

import { TabsModels } from './tabs.models';

import styles from '../../../shared-ui/tabs/tabs.module.scss';

import useMatchMedia from '../../../../hooks/useMatchMedia';

const TabsItem: FC<TabsModels.ItemProps> = ({ category, isActive, handleChangeCategoryTab, categories }) => {
  const { isDesktop } = useMatchMedia();
  const isUnvalidTabName = category.name.length > 10 && !isDesktop;

  return (
    <li
      role="presentation"
      style={categories.length > 5 ? { minWidth: '174.5px' } : null}
      className={`${styles.labelsBigItem} ${isActive ? styles.active : ''}`}
      onClick={() => handleChangeCategoryTab(category.id)}
    >
      {isUnvalidTabName ? (
        <>
          {`${category.name.slice(0, 10)}...`}
          <span className={styles['tooltip-text']}>{category.name}</span>
        </>
      ) : (
        category.name
      )}
    </li>
  );
};

export default memo(TabsItem);
