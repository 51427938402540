/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-" transform="translate(-460.000000, -1940.000000)">
        <g id="Paragraph" transform="translate(408.000000, 1744.000000)">
          <g id="DropDown" transform="translate(0.000000, 184.000000)">
            <g id="Icon" transform="translate(52.000000, 12.000000)" fill="#373737">
              <path d="M17,4 C17.5522847,4 18,4.44771525 18,5 C18,5.51283584 17.6139598,5.93550716 17.1166211,5.99327227 L17,6 L14.754,6 L11.325,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5128358 13.6139598,19.9355072 13.1166211,19.9932723 L13,20 L7,20 C6.44771525,20 6,19.5522847 6,19 C6,18.4871642 6.38604019,18.0644928 6.88337887,18.0067277 L7,18 L9.245,18 L12.674,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.48716416 10.3860402,4.06449284 10.8833789,4.00672773 L11,4 L17,4 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
