import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SubHeader from './components/subheader';
import PhotoGalleryContainer from './components/photo-gallery/photo-galleryContainer';
import Story from './components/story';
import FeedbackListContainer from './components/feedback-list/FeedbackListContainer';
import FAQListContainer from './components/FAQList/FAQListContainer';
import Team from './components/team';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';
import GalleryPreview from './components/gallery';

const AboutComponent: FC = () => (
  <>
    <Switch>
      <Route
        exact
        path="/about"
        render={() => (
          <>
            <SubHeader />
            <Story />
            <Team />
            <SignupForNewsContainer />
          </>
        )}
      />
      <Route
        exact
        path="/about/photo"
        render={() => (
          <>
            <SubHeader />
            <PhotoGalleryContainer />
            <GalleryPreview />
            <SignupForNewsContainer />
          </>
        )}
      />
      <Route
        exact
        path="/about/feedback"
        render={() => (
          <>
            <SubHeader />
            <FeedbackListContainer />
            <SignupForNewsContainer />
          </>
        )}
      />
      <Route
        exact
        path="/about/ask"
        render={() => (
          <>
            <SubHeader />
            <FAQListContainer />
            <SignupForNewsContainer />
          </>
        )}
      />
      <Route
        path="/about/*"
        render={() => <Redirect to={{ pathname: '/404-not-found', state: { from: window.location.pathname } }} />}
      />
    </Switch>
  </>
)

export default AboutComponent;
