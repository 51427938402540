import { WAFActionTypes } from './wantAFriendActions.models';

import { transformAndFilterPetDto, transformPetDto } from '../../../../../utils/transformPetDto';
import { getPetName, getPetTitle, getPetDescription, sortPetsByDateCreated } from './helpers';
import { WAFAction, WAFStoreModels } from './wantAFriendStore.models';

let pages = 0;

export const initialState: WAFStoreModels.State = {
  pet: {
    id: 0,
    birthDate: '',
    breedId: 0,
    dogBreedValueDtoSet: [],
    dogDescriptionDtoSet: [],
    dogNameDtoSet: [],
    dogPhotoDtoList: [],
    sizeId: 0,
    dogSizeValueDtoSet: [],
    dogStatus: '',
    hasGuardianship: false,
    male: false,
    sterilized: false,
    parentDogId: 0,
    snapshotDogId: 0,
    dateCreated: '',
    name: '',
    sizeValue: '',
    description: '',
  },
  petsList: [],
  petsCount: 0,
  loading: false,
  error: null,
  pagesLoaded: 0,
  totalPages: 0,
  openDogPopupForm: false,
  deleteMessageVisible: false,
};

export default function wantAFriendPagesReducer(
  state: WAFStoreModels.State = initialState,
  action: WAFAction,
) {
  switch (action.type) {
    case WAFActionTypes.ADMIN_WAF_FETCH_START:
      return {
        ...state,
        loading: true,
      };

    case WAFActionTypes.ADMIN_WAF_FETCH_END:
      return {
        ...state,
        loading: false,
      };

    case WAFActionTypes.ADMIN_WAF_FETCH_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case WAFActionTypes.CLEAR_PETS:
      return {
        ...state,
        petsAllList: [],
        pet: {},
      };

    case WAFActionTypes.ADMIN_WAF_DISPLAY_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: true,
        petToDelete: action.payload.petToDelete,
      };

    case WAFActionTypes.ADMIN_WAF_HIDE_DELETE_MESSAGE:
      return {
        ...state,
        deleteMessageVisible: false,
        petToDelete: null,
      };

    case WAFActionTypes.OPEN_DOG_POPUP_FORM:
      return {
        ...state,
        openDogPopupForm: true,
      };

    case WAFActionTypes.CLOSE_DOG_POPUP_FORM:
      return {
        ...state,
        openDogPopupForm: false,
      };

    case WAFActionTypes.ADMIN_WAF_FETCH_PET:
      return {
        ...state,
        loading: false,
        pet: transformAndFilterPetDto(action.payload.pet, action.payload.selectedLanguage),
        petName: getPetName(action.payload.pet),
        petTitle: getPetTitle(action.payload.pet),
        petDescription: getPetDescription(action.payload.pet),
      };

    case WAFActionTypes.ADMIN_WAF_FETCH_PETS_RECORDS:
      pages = state.pagesLoaded + 1;
      return {
        ...state,
        loading: false,
        petsList: sortPetsByDateCreated(state.petsList.concat(action.payload.records.content)),
        petsCount: action.payload.records.totalElements,
        totalPages: action.payload.records.totalPages,
        pagesLoaded: pages,
      };

    case WAFActionTypes.ADMIN_WAF_FETCH_ALL_PETS:
      return {
        ...state,
        loading: false,
        petsAllList: action.payload.pets.map(el => transformPetDto(el)),
      };

    case WAFActionTypes.ADMIN_WAF_DELETE_PET_SUCCESS:
      return {
        ...state,
        petsList: state.petsList.filter(pet => pet.id !== action.payload.id),
        petsCount: state.petsCount - 1,
      };

    case WAFActionTypes.ADMIN_WAF_HIDE_OR_PUBLICH_PET_SUCCESS:
      return {
        ...state,
        pet: transformAndFilterPetDto(action.payload.pet, action.payload.selectedLanguage),
        petsList: sortPetsByDateCreated(
          [...state.petsList.filter(pet => pet.id !== action.payload.oldId), action.payload.pet],
        ),
      };

    default:
      return state;
  }
}
