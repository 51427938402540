import React, { FC, useEffect, useState } from 'react';
import styles from './dogPageGallery.module.scss';
import { WAFModels } from '../../../../../../../models/WAFPage.models';
import { defaultDogPhoto } from '../../../constants';

const DogPageGallery: FC<{photos: WAFModels.DogPhoto[]}> = ({ photos = [] }) => {
  const [curPhoto, setCurPhoto] = useState<WAFModels.DogPhoto>(defaultDogPhoto);

  useEffect(() => {
    if (!photos.length) return;
    const newCurPhoto = photos.find(photo => photo.main) || photos[0];

    setCurPhoto(newCurPhoto);
  }, [photos]);

  const changePhoto = (photo: WAFModels.DogPhoto) => {
    setCurPhoto(photo);
  }

  return photos.length && (
    <div className={styles.galleryBlock}>
      <div className={styles.mainPhotoBlock}>
        <img className={styles.mainPhoto} src={curPhoto.photo} alt="dog" />
      </div>
      <div>
        <div className={styles.albums}>
          {photos.map(photo => (
            <div
              className={styles.image}
              key={photo.id}
              onClick={() => changePhoto(photo)}
              role="button"
              tabIndex={0}
            >
              <img
                src={photo.photo}
                alt="dog"
                className={photo.id === curPhoto?.id ? `${styles.dogImg} ${styles.active}` : styles.dogImg}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default DogPageGallery;
