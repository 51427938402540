import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardDogHeader.module.scss';
import dogPageTranslations from '../userDogPageTranslations';

const cardDogHeader = (props) => {
  const { name, activeLanguage } = props;
  return (
    <>
      <div className={styles.titleBlock}>
        <h4 className={styles.heading}>{dogPageTranslations[activeLanguage].welcomeWantsFriend} {name}!</h4>
      </div>
    </>
  );
};

cardDogHeader.propTypes = {
  name: PropTypes.string.isRequired,
  activeLanguage: PropTypes.string.isRequired,
};


export default cardDogHeader;
