import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './image-uploader.module.scss';
import dotsLoader from '../../../../../../../shared-ui/dotsLoader/dotsLoader';
import { DogImageProgressModels } from './imageUploader.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

const DogImageProgress = ({
  imgProgressInKb,
  imgTotalInKb,
}: DogImageProgressModels.Props) => {
  const { t: translate } = useTranslation();
  return (
    <div className={styles['image-progress-container']}>
      <div className={styles['image-progressbar-info']}>
        <div className={styles.loader}>
          <div className={styles.dotsLoader}>{dotsLoader()}</div>
        </div>
        <div>
          {translate(TRANSLATE_KEYS.loadingImage)}
        </div>
        <div>
          {`${imgProgressInKb}/${imgTotalInKb} ${translate(TRANSLATE_KEYS.kilobyte)}`}
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(DogImageProgress);
