import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import styles from './image-uploader.module.scss';
import Icon from '../../../../../../../shared-ui/icon';
import { DogImageDropZoneModels } from './imageUploader.models';

const DogImageDropZone = ({
  handleDrop,
  handleImageChange,
  t: translate,
}: DogImageDropZoneModels.Props) => {
  const [dragCounter, setDragCounter] = useState(0);
  const fileDropRef = useRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter - 1);
  };

  const handleDropEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e);
      setDragCounter(0);
    }
  };

  return (
    <div
      className={styles['image-dropzone']}
      ref={fileDropRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDropEvent}
    >
      <Icon name="image-upload" />
      <p className={styles['image-dropzone-text']}>{translate(TRANSLATE_KEYS.dropImgHere)}</p>
      <span className={styles['image-dropzone-caption']}>
        {translate(TRANSLATE_KEYS.dropImgHereCaption)}
      </span>
      <button
        name="articleFormImg"
        onClick={handleImageChange}
      />
    </div>
  );
}

export default withTranslation('translations')(DogImageDropZone);
