import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LanguageLabels, Languages } from '../../../../../models/language.model';

import styles from './LanguageForm.module.scss';

import { HeaderSelectors } from '../../selectors';
import LanguageAction from '../../../../language/+store/actions';

import Icon from '../../../icon';
import LanguageOption from './components/languageOption/LanguageOption';

const OPTIONS = [
  { label: LanguageLabels.UK, value: Languages.UK },
  { label: LanguageLabels.EN, value: Languages.EN },
];

const LanguageForm: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [, i18n] = useTranslation();
  const selectedLanguage = useSelector(HeaderSelectors.Language);
  const inputRef = useRef<HTMLInputElement>();

  const selectedOption = OPTIONS.find((o) => o.value === selectedLanguage);

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(LanguageAction(lang));
  };

  const handleChangeIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['form-container']}>
        <div className={styles['form-item']}>
          <input
            ref={inputRef}
            type="text"
            id="language"
            value={selectedOption.label}
            onFocus={handleChangeIsOpen}
            onBlur={handleChangeIsOpen}
            className={styles.form_input}
            readOnly
          />
          <Icon
            name="arrow-down"
            onClick={() => inputRef.current.focus()}
            className={styles[`select-${isOpen ? 'arrow-rotate' : 'arrow'}`]}
          />
          {
            isOpen && (
              <div className={styles['select-menu']}>
                {
                  OPTIONS.map((o) => (
                    <LanguageOption
                      key={`language-option-${o.value}`}
                      onChange={handleChangeLanguage}
                      option={o}
                      isSelected={selectedOption.value === o.value}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default LanguageForm;
