import { Cookies } from 'react-cookie';
import { History } from 'history';
import { AxiosError, AxiosResponse } from 'axios';
import requestWithHeaders from '../../../../../utils/withHeaders';
import { URL_ADMIN_LOGIN } from '../../../../../endpoints';
import { AuthComponentModels } from '../auth.models';
import browserStorage from '../../../../../utils/localStorage';
import { ProfilePageActions } from '../../profilePage/+store/actions';
import { AuthStoreModels } from './authStore.models';
import { AuthActions } from './auth.actions';
import { TranslatedText } from '../../../../../models/translate-function.models';
import { AuthAction } from '../../../../../types/action.interface';

const handleStatusCode = (
  status: number,
  errorMessages: AuthComponentModels.ErrorMessages): TranslatedText => {
    switch (status) {
      case 401:
        return errorMessages.fourHundredOne;

      default:
        return errorMessages.other;
    }
};

export namespace AuthPageActions {
  export const adminLoginFailure = (error: TranslatedText): AuthAction<TranslatedText> => ({
    type: AuthActions.adminLoginFailure,
    payload: error,
  });

  export const adminLoginSuccess = (): AuthAction => ({ type: AuthActions.adminLoginSuccess });

  export const adminLoginRequest = (
    credentials: AuthStoreModels.Credentials,
    cookies: Cookies,
    history: History,
    errorMessages: AuthComponentModels.ErrorMessages) => dispatch => requestWithHeaders({
      method: 'POST',
      url: URL_ADMIN_LOGIN,
      data: credentials,
    }, cookies)
      .then((response: AxiosResponse<string>) => {
        const token = response.headers.authorization.split(' ')[1];
        cookies.set('token', token, { path: '/' });
        dispatch(adminLoginSuccess());
        browserStorage.setKeyValue('login', credentials.login);
        ProfilePageActions.adminFetchProfileDataRequest(cookies)(dispatch);
        history.push('/admin/blog');
      })
      .catch((error: AxiosError) => {
        const { status } = error.response;
        const message = handleStatusCode(status, errorMessages);
        dispatch(adminLoginFailure(message));
      });
}
