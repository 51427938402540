import React, { useEffect, useState } from 'react';
import styles from './toast.module.scss';
import Icon from '../icon';
import { ToastProps } from './toast.models';

const Toast = ({ iconName, text, start }: ToastProps) => {
  const [k, setK] = useState(start);
  useEffect(() => {
    setK(start);
    start && setTimeout(() => setK(false), 5000);
  }, [start]);

  const wrapperStyle = `${styles.wrapper} ${k ? styles.active : ''}`;

  return (
    <div className={wrapperStyle}>
      <div className={styles.iconContainer}>
        <Icon name={iconName} />
      </div>
      {text}
    </div>
  );
};

Toast.defaultProps = { start: false };

export default Toast;
