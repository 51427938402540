import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './styles/dog-data.module.scss';
import { AdminWAFPopupActions } from '../+store/actions';
import calcTextareaHeight from '../../../../../../../utils/calcTextareaHeight';
import { handleAutocomplete } from '../helpers';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { DogNameAndDescriptionModels } from '../popupForm.models';
import { defaultTranslation } from '../../../constants';

const DESCRIPTION_SCROLL_QUANTITY_SYMBOLS = 179;
const DESCRIPTION_MAXIMUM_CHARACTER_LENGTH = 2500;

const DogDescription = ({
  dogDescription = defaultTranslation,
  initLanguage,
  otherLanguages,
  language,
  isEditing,
  changeDescription,
  modifyInitLang,
  t: translate,
}: DogNameAndDescriptionModels.Props) => {
  useEffect(() => () => {
    changeDescription(language.languageValue, '');
  }, []);

  const handleDescriptionChange = (e) => {
    if (e.target.value.includes('\n')) return;
    const description = e.target.value;
    changeDescription(language.languageValue, description);
    calcTextareaHeight(e);

    /* this feature will be deleted in future */
    handleAutocomplete({
      language,
      otherLanguages,
      initLanguage,
      isEditing,
      modifyInitLang,
    }, dogDescription, changeDescription, description);
  };

  const textareaStyles = dogDescription[language.languageValue]
    ? `${styles.textarea} ${styles['textarea-value']}`
    : styles.textarea;
  const labelStyles = dogDescription[language.languageValue]
    ? `${styles.label} ${styles['label-value']} ${styles.description}`
    : `${styles.label} ${styles.description}`;

  return (
    <label
      htmlFor="articleDescription"
      className={styles['article-form-description']}
    >
      <textarea
        id="articleDescription"
        value={dogDescription[language.languageValue]}
        maxLength={DESCRIPTION_MAXIMUM_CHARACTER_LENGTH}
        className={`${textareaStyles} 
          ${otherLanguages.includes(language.languageValue) ? styles.autocompleted : ''}`}
        onChange={handleDescriptionChange}
      />
      <span className={labelStyles}>{translate(TRANSLATE_KEYS.inputDogDescription)}</span>
    </label>
  );
}

const mapDispatchToProps = dispatch => ({
  changeDescription: (language: string, description: string) => dispatch(
    AdminWAFPopupActions.dogDescriptionChange(language, description),
  ),
});

export default withTranslation('translations')(connect(null, mapDispatchToProps)(DogDescription));
