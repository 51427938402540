import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AdminBlogActions } from '../../+store/actions';
import Icon from '../../../../../shared-ui/icon';
import styles from '../articleList/articleList.module.scss';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import { BlogPageModels } from '../../../../../../models/blogPage.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { CommonModels } from '../../../../../../models/common.models';

interface DeleteButtonProps {
  id?: number;
  title?: string;
  parentArticleId?: number;
  style?: CommonModels.Style;
  position?: { bottom: string };
  onDisplayDeleteMessage?: (article: BlogPageModels.ArticleDeleteData) => void;
  handleDeleteImg?: () => void;
  t: TranslateFn;
}

const DeleteButton: FC<DeleteButtonProps> = ({
  id,
  title,
  parentArticleId,
  style,
  position,
  onDisplayDeleteMessage,
  handleDeleteImg,
  t: translate,
}) => {
  const displayModalWindow = (e: MouseEvent) => {
    e.preventDefault();
    onDisplayDeleteMessage({ id, title, parentArticleId });
  };

  return (
    <button
      style={style}
      className={styles['article-button--delete']}
      onClick={handleDeleteImg || (e => displayModalWindow(e))}
    >
      <Icon name="trash" />
      <span className={styles['tooltip-text']} style={position}>
        {translate(TRANSLATE_KEYS.deleteBtn)}
      </span>
    </button>
  );
};

DeleteButton.defaultProps = {
  id: 0,
  title: '',
  parentArticleId: 0,
  style: {},
  position: { bottom: '63px' },
  handleDeleteImg: null,
  onDisplayDeleteMessage: null,
};

const mapDispatchToProps = dispatch => ({
  onDisplayDeleteMessage: (
    articleDeleteData: BlogPageModels.ArticleDeleteData,
  ) => dispatch(AdminBlogActions.displayDeleteMessage(articleDeleteData)),
});

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(DeleteButton),
);
