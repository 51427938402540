import { AuthStoreModels } from './authStore.models';
import { AuthActions } from './auth.actions';
import { AuthAction } from '../../../../../types/action.interface';
import { TranslatedText } from '../../../../../models/translate-function.models';

export const INITIAL_STATE: AuthStoreModels.State = {
  loggingIn: false,
  hasLoggedIn: false,
  loginError: '',
  isAccessError: false,
};

const adminLoginReducer = (
  state: AuthStoreModels.State = INITIAL_STATE,
  action: AuthAction<TranslatedText>): AuthStoreModels.State => {
    switch (action.type) {
      case AuthActions.adminLoginRequest:
        return {
          ...state,
          loginError: '',
          loggingIn: true,
        };
      case AuthActions.adminLoginSuccess:
        return {
          ...state,
          loggingIn: false,
          hasLoggedIn: true,
        };
      case AuthActions.adminLoginFailure:
        return {
          ...INITIAL_STATE,
          loginError: action.payload,
        };
      default:
        return state;
    }
};

export default adminLoginReducer;
