import { ReactNode } from 'react';

export namespace ServicesPageModels {
    export interface DescriptionTemplateProps {
        firstImg: string;
        secondImg: string;
        thirdImg?: string;
        children?: ReactNode;
        position: PositionTypes;
        extraClassName?: ExtraClassNameTypes;
    }

    export enum PositionTypes {
        Left = 'Left',
        Right = 'Right',
        Other = 'Other',
    }

    export enum ExtraClassNameTypes {
        SmallImageTop = 'small-image-top',
        SmallImageCenter = 'small-image-center',
        SectionDogsNutrition = 'section-dogs-nutrition',
    }

    export const enum ServiceDetailsTypes {
        P = 'p',
        H1 = 'h1',
        H3 = 'h3',
        H4 = 'h4',
        UL = 'ul'
    }

    export interface BaseMockDataDescription {
        bigImage: string;
        smallImage?: string;
        paragraphFirst: string;
        paragraphSecond: string;
        paragraphThird?: string;
    }

    export interface DogDescription extends BaseMockDataDescription {
        templateHeader: string;
        smallImage1?: string;
        smallImage2?: string;
    }

    export interface MockDataPrice {
        id: string;
        target: string;
        service: string;
        serviceTitle: string;
        category: string;
        categoryTitle: string;
        title: string;
        description: string;
        discount: string;
        value: string;
    }

    export interface PriceListProps {
        target: string;
        service: string;
        category: string;
        data: {
            price: MockDataPrice[],
        };
    }

    export interface ButtonProps {
        content: string;
    }

    export enum SelectedTabTypes {
        Zero = 0,
        One = 1,
    }

    export interface TabsLabelsType {
        label: string;
        smallLabel?: string;
    }

    export interface TabsProps {
        selectedTab: SelectedTabTypes;
        tabsLabels: TabsLabelsType[];
        contentPanels: ReactNode[];
    }

    export interface ServiceDetails {
        text?: string,
        type: ServiceDetailsTypes,
        componentClassName?: string,
        needToTranslate?: boolean,
        listOfItems?: string[],
    }

    export interface ServiceInfoProps {
        title?: string,
        position: PositionTypes,
        extraClassName?: ExtraClassNameTypes,
        details: ServiceDetails[],
        info?: BaseMockDataDescription,
    }

    export interface Location {
        selectedTab: SelectedTabTypes;
    }
}
