/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AxiosPromise, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { BlogPageModels } from '../../../../../../../models/blogPage.models';
import { LanguageData } from '../../../../../../../models/language.model';
import { GetStateType } from '../../../../../../../types/store.models';
import { BlogPopupAction } from './blogPopupStore.models';

export namespace BlogPopupActions {
  export type SimpleAction = () => BlogPopupAction;
  export type ErrorHandler = (error: Error) => BlogPopupAction;
  export type DisplayErrorMessage = (errorMessage: string) => BlogPopupAction;
  export type SetCategoryValidationError = (validationError: string) => BlogPopupAction;
  export type CloseModalWindow = (
    saveArticleForEdit: boolean,
    saveArticleInForm: boolean
  ) => BlogPopupAction;

  export type SelectCategory = (category: BlogPageModels.Category) => BlogPopupAction;
  export type TitleOrDescriptionChange = (language: string, title?: string, description?: string) => BlogPopupAction;
  export type EnterNewCategory = (categoryData: BlogPageModels.NewCategoryInputData) => BlogPopupAction;
  export type CategoryNameChange = (categoryData: BlogPageModels.Category) => BlogPopupAction;
  export type CategoryLanguageChange = (language: LanguageData) => BlogPopupAction;

  export type EditCategoryStart = (categoryData: BlogPageModels.Category) => BlogPopupAction;
  export type CategoryDeleteEnd = (index: number, selectedCategory: BlogPageModels.Category) => BlogPopupAction;

  export type FetchArticlesSuccess = (articles: BlogPageModels.Article[]) => BlogPopupAction;
  export type ArticlePostSuccess = (article: BlogPageModels.Article) => BlogPopupAction;
  export type ArticleUpdateSuccess = (articleUpdateData: BlogPageModels.ArticleUpdateData) => BlogPopupAction;
  export type CategoryFetchSuccess = (category: BlogPageModels.CategoryFetchData) => BlogPopupAction;
  export type CategoryPostSuccess = (category: BlogPageModels.CategoryDto) => BlogPopupAction;
  export type CategoryUpdateSuccess = (category: BlogPageModels.CategoryDto, language: LanguageData) => BlogPopupAction;
  export type PostArticleImagePostSuccess = (imgLink: string) => BlogPopupAction;

  export type FetchArticles = () => (dispatch: Dispatch) => void;
  export type FetchCategories = (cookies: Cookies) => (dispatch: Dispatch) => void;
  export type DeleteCategory = (
    id: number,
    index: number,
    selectedCategory: BlogPageModels.Category,
    cookies: Cookies,
  ) => (dispatch: Dispatch) => void;
  export type PostArticle = (cookies: Cookies) => (
    dispatch: Dispatch,
    getState: GetStateType,
  ) => AxiosPromise<BlogPageModels.Article> | BlogPopupAction;
  export type CreateSnapshot = (cookies: Cookies) => (
    dispatch: Dispatch,
    getState: GetStateType
  ) => AxiosPromise<number> | BlogPopupAction;
  export type UpdateSnapshot = (cookies: Cookies) => (dispatch: Dispatch, getState: GetStateType) => void;
  export type PostCategory = (cookies: Cookies) => (dispatch: Dispatch, getState: GetStateType) => void;
  export type UpdateCategory = (cookies: Cookies) => (dispatch: Dispatch, getState: GetStateType) => void;
  export type PostArticleImage = (imgLink: string) => (
    dispatch: Dispatch,
    getState: GetStateType
  ) => Promise<AxiosResponse | BlogPopupAction>;
}

export namespace BlogPopupActionsTypes {
  export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
  export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
  export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
  export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START';
  export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR';
  export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';

  export const SELECTED_CATEGORY_CHANGE = 'SELECTED_CATEGORY_CHANGE';
  export const CATEGORY_LANGUAGE_CHANGE = 'CATEGORY_LANGUAGE_CHANGE';
  export const ARTICLE_TITLE_CHANGE = 'ARTICLE_TITLE_CHANGE';
  export const ARTICLE_DESCRIPTION_CHANGE = 'ARTICLE_DESCRIPTION_CHANGE';
  export const ENTER_NEW_CATEGORY = 'ENTER_NEW_CATEGORY';
  export const CATEGORY_NAME_CHANGE = 'CATEGORY_NAME_CHANGE';
  export const ARTICLE_IS_MAIN_CHECKBOX_CHANGE = 'ARTICLE_IS_MAIN_CHECKBOX_CHANGE';

  export const POST_ARTICLE_START = 'POST_ARTICLE_START';
  export const POST_ARTICLE_ERROR = 'POST_ARTICLE_ERROR';

  export const UPDATE_ARTICLE_START = 'UPDATE_ARTICLE_START';
  export const UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR';

  export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
  export const CATEGORY_VALIDATION_ERROR = 'CATEGORY_VALIDATION_ERROR';

  export const DISPLAY_CANCEL_MESSAGE = 'DISPLAY_CANCEL_MESSAGE';
  export const HIDE_CANCEL_MESSAGE = 'HIDE_CANCEL_MESSAGE';

  export const POST_CATEGORY_START = 'POST_CATEGORY_START';
  export const POST_CATEGORY_ERROR = 'POST_CATEGORY_ERROR';
  export const POST_CATEGORY_SUCCESS = 'POST_CATEGORY_SUCCESS';

  export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
  export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
  export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
  export const EDIT_CATEGORY_START = 'EDIT_CATEGORY_START';
  export const EDIT_CATEGORY_CANCEL = 'EDIT_CATEGORY_CANCEL';

  export const DELETE_CATEGORIES_START = 'DELETE_CATEGORIES_START';
  export const DELETE_CATEGORIES_ERROR = 'DELETE_CATEGORIES_START';
  export const DELETE_CATEGORIES_END = 'DELETE_CATEGORIES_END';

  export const POST_ARTICLE_IMAGE_START = 'POST_ARTICLE_IMAGE_START';
  export const POST_ARTICLE_IMAGE_ERROR = 'POST_ARTICLE_IMAGE_ERROR';
  export const POST_ARTICLE_IMAGE_SUCCESS = 'POST_ARTICLE_IMAGE_SUCCESS';
  export const POST_ARTICLE_IMAGE_DELETE = 'POST_ARTICLE_IMAGE_DELETE';
}
