import React, { useLayoutEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styles from './RecommendedArticles.module.scss';
import { ArticleModels } from '../../models/article.models';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import useMatchMedia from '../../../../hooks/useMatchMedia';
import { ArticleSelectors } from '../../+store/selectors';
import { ArticleActions } from '../../+store/actions';
import Article from '../../../blog/components/articlesList/ArticlesListItem';
import Button from '../../../shared-ui/button';
import Navigation from './components/Navigation';

const START_SLIDER = 0;
const START_PAGINATION = 1;
const VISIBLE_ARTICLES_DESKTOP = 3;
const VISIBLE_ARTICLES_TABLET = 2;

const RecommendedArticles: FC = () => {
  const [translate, i18n] = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams<ArticleModels.PageParams>();
  const recommendedArticles = useSelector(ArticleSelectors.RecommentedItems);
  const hasMoreRecommendedArticles = useSelector(ArticleSelectors.HasRecommended);
  const { isMobile, isTablet } = useMatchMedia();
  const [paginationStep, setPaginationStep] = useState(START_SLIDER);
  const [pagination, setPagination] = useState(START_PAGINATION);

  const visibleArticles = isTablet ? VISIBLE_ARTICLES_TABLET : VISIBLE_ARTICLES_DESKTOP;
  const isShowNavigation = hasMoreRecommendedArticles || recommendedArticles.length > visibleArticles;
  const isDisabledNavigationLeftButton = paginationStep === START_SLIDER;
  const isDisabledNavigationRightButton = !hasMoreRecommendedArticles && !isDisabledNavigationLeftButton
    && (recommendedArticles.length - paginationStep <= visibleArticles);

  useLayoutEffect(() => {
    setPaginationStep(START_SLIDER);
    setPagination(START_PAGINATION);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, params.id]);

  const handleClickToPrevSlide = () => {
    setPaginationStep((prev) => (prev - visibleArticles < 0 ? 0 : prev - visibleArticles));
  };

  const handleClickToNextSlide = async () => {
    const newPagination = pagination + START_PAGINATION;

    if (hasMoreRecommendedArticles) {
      await dispatch(ArticleActions.fetchPaginationRecommended(newPagination));
      setPagination(() => newPagination);
    }

    setPaginationStep((prev) => prev + visibleArticles);
  };

  if (!recommendedArticles.length) return null

  return (
    <div className={styles.box}>
      <div className={styles.wrapper}>
        <div className={styles['navigation-wrapper']}>
          <h3 className={styles.title}>{translate(TRANSLATE_KEYS.recommendedArticles)}</h3>
          {
            isShowNavigation && (
              <Navigation
                handleClickToNextSlide={handleClickToNextSlide}
                handleClickToPrevSlide={handleClickToPrevSlide}
                isDisabledLeftButton={isDisabledNavigationLeftButton}
                isDisabledRightButton={isDisabledNavigationRightButton}
              />
            )
          }
        </div>
        <div className={styles.cards}>
          {(isMobile
              ? recommendedArticles
              : [...recommendedArticles].splice(paginationStep, visibleArticles)
            ).map((item) => (
              <Article
                key={`${item.title.trim()}-${item.id}`}
                id={item.id}
                title={item.title}
                category={item.category}
                image={item.image}
                from={location.pathname}
                isArticlePage
              />
          ))}
        </div>
        {
          (isMobile && hasMoreRecommendedArticles) && (
            <div className={styles['more-articles-button']}>
              <Button content={translate('moreArticles')} uiType="gray" onClick={handleClickToNextSlide} />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default RecommendedArticles;
