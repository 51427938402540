import React from 'react';
import styles from './styles/dog-data.module.scss';
import DogImageUploader from './dogImageUploader/dogImageUploader';
import { DogImageModels } from '../popupForm.models';

const DogImage = ({
  dogPhotos,
  onImgUpload,
}: DogImageModels.Props) => {
  const handleSelectedImg = (event, photoIndex) => {
    if (!event) {
      onImgUpload(null, photoIndex);
      return;
    }
    const img = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    event.preventDefault();
    onImgUpload(img, photoIndex);
  };

  return (
    <div className={styles['article-form-image-wrapper']}>
      <DogImageUploader
        onImgSelected={handleSelectedImg}
        dogPhotos={dogPhotos}
      />
    </div>
  );
}

export default DogImage;
