import cloudinary from 'cloudinary/lib/cloudinary';

export const cloud = process.env.REACT_APP_CLOUDINARY_CLOUD;
export const cloudKey = process.env.REACT_APP_CLOUDINARY_KEY;
export const cloudSecret = process.env.REACT_APP_CLOUDINARY_SECRET;

cloudinary.v2.config({ cloud_name: cloud, api_key: cloudKey, api_secret: cloudSecret });

const cl = cloudinary;

export const cloudDeleteFile = cl.uploader.destroy;
export const parseImgLink = link => JSON.stringify(link);
