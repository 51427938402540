import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { AdminBlogActions } from '../../+store/actions';
import styles from '../articleList/articleList.module.scss';
import Icon from '../../../../../shared-ui/icon';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

interface HideButtonProps {
  id: number;
  cookies: Cookies;
  position?: { bottom: string };
  onHideArticle: (id: number, cookies: Cookies) => void;
  t: TranslateFn;
}

const HideButton: FC<HideButtonProps> = ({
  id,
  cookies,
  position,
  onHideArticle,
  t: translate,
}) => {
  const onHideArticleHandler = (e: MouseEvent) => {
    e.preventDefault();
    onHideArticle(id, cookies);
  };

  return (
    <button className={styles['article-button--hide']} onClick={e => onHideArticleHandler(e)}>
      <Icon name="hide" />
      <span className={styles['tooltip-text']} style={position}>{translate(TRANSLATE_KEYS.hideBtn)}</span>
    </button>
  );
};

HideButton.defaultProps = { position: { bottom: '63px' } };

const mapDispatchToProps = dispatch => ({
  onHideArticle: (
    id: number,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.hideArticle(id, cookies)),
});

export default withTranslation('translations')(connect(null, mapDispatchToProps)(withCookies(HideButton)));
