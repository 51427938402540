import TRANSLATE_KEYS from '../../../../i18n_keys';
import { WAFModels } from '../../../../models/WAFPage.models';
import { LANGUAGES } from '../../../language/+store/languages';

export const LANGUAGE_TABS = [
  {
    value: LANGUAGES.UK,
    label: 'Українська',
  },
  {
    value: LANGUAGES.EN,
    label: 'English',
  },
];

export const DogParameterSelects = [
  {
    key: 1,
    name: TRANSLATE_KEYS.selectionListSex,
    itemsName: 'sex',
  },
  {
    key: 2,
    name: TRANSLATE_KEYS.selectionListAge,
    itemsName: 'age',
  },
  {
    key: 3,
    name: TRANSLATE_KEYS.selectionListSize,
    itemsName: 'size',
  },
  {
    key: 4,
    name: TRANSLATE_KEYS.selectionListSterilized,
    itemsName: 'sterilized',
  },
];

export const defaultTranslation = {
  uk: '',
  en: '',
};

export const defaultDogDto = {
  birthDate: '',
  breedId: 170,
  dogBreedValueDtoSet: [
    {
      breedName: 'Безпородна',
      language: {
        languageId: 1,
        languageValue: 'uk',
      },
    },
    {
      breedName: 'Беспородная',
      language: {
        languageId: 3,
        languageValue: 'en',
      },
    },
    {
      breedName: 'Mongrel',
      language: {
        languageId: 2,
        languageValue: 'ru',
      },
    },
  ],
  dogDescriptionDtoSet: [],
  dogNameDtoSet: [],
  dogPhotoDtoList: [],
  sizeId: 0,
  dogStatus: WAFModels.PetPublishStatuses.NOT_PUBLISHED,
  hasGuardianship: false,
  male: false,
  sterilized: false,
}

export const defaultHeaderHeight = '63px';

export const defaultDogPhoto = { id: 0, main: false, photo: '' };
