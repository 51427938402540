import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from '../../Footer.module.scss';

import { FooterModels } from '../../models/footer.models';

const Link: FC<FooterModels.LinkProps> = ({ text, path, isCaption, wrapperClasses, state, onClick }) => (
  <div className={`${isCaption ? styles['link-caption'] : styles['dropdown-link']} ${wrapperClasses}`}>
    <RouterLink
      to={state ? { pathname: path, state } : path}
      className={isCaption ? styles.link : `${styles.subtitle} ${styles['link-subtitle']}`}
      onClick={onClick}
    >
      {text}
    </RouterLink>
  </div>
);

export default Link;
