import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import styles from '../../../../../wantAFriendUserPage/components/paginationBlock/paginationBlock.module.scss';
import Button from '../../../../../shared-ui/button';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { TranslateFn } from '../../../../../../models/translate-function.models';

interface PaginationBlockProps {
  fetchNextPage: () => void;
  t: TranslateFn
}

const PaginationBlock: FC<PaginationBlockProps> = ({ t: translate, fetchNextPage }) => (
  <div className={`${styles['more-articles-button']} ${styles['pagination-block']}`}>
    <Button
      content={translate(TRANSLATE_KEYS.moreResults)}
      uiType="grayWide"
      onClick={fetchNextPage}
    />
  </div>
);

export default withTranslation('translations')(PaginationBlock);
