import { ArticleTransformedData } from '../../../../../../../models/blogPage.models';
import { CommonModels } from '../../../../../../../models/common.models';

const formatChangedArticleData = (formatingData: ArticleTransformedData.Content) => {
  const articleFormatResult: CommonModels.RecordInfo[] = [];

  const circlesArray = [formatingData.uk.length, formatingData.en.length];
  let i = Math.max(...circlesArray);
  while (i !== 0) {
    const formatEnPart = formatingData.en[i - 1] ? formatingData.en[i - 1].content : '';
    const formatUkPart = formatingData.uk[i - 1] ? formatingData.uk[i - 1].content : '';

    const unitFormatPart = {
      en: formatEnPart,
      uk: formatUkPart,
    };
    articleFormatResult.unshift(unitFormatPart);
    i -= 1;
  }

  return articleFormatResult;
};

export default formatChangedArticleData;
