export default function currentHotelAge(lang) {
    const foundingYear = 1996
    const currentYear = new Date().getFullYear();
    const yearDiff = currentYear - foundingYear;
    if (lang === 'uk') {
      const byTen = yearDiff % 10;
      const byHundred = yearDiff % 100;
      if (byTen === 1 && byHundred !== 11) return `${yearDiff} рік`;
      if ([2, 3, 4].includes(byTen) && !(byHundred >= 12 && byHundred <= 14)) return `${yearDiff} роки`;
      return `${yearDiff} років`;
    } if (lang === 'en') {
      return yearDiff
    }
    return `Unsupported language: ${lang} provided`;
  }


