import React, { FC, useState } from 'react';
import style from './create-article-content.module.scss';
import AddButton from './addButton';
import { ArticleContentTypes } from '../../../../../../../../models/blogPage.models';

interface CreateArticleContentProps {
  id?: number;
  isHidden: boolean;
  addBox: (id: number, type: ArticleContentTypes) => void;
}

const CreateArticleContent: FC<CreateArticleContentProps> = ({
  id,
  isHidden,
  addBox,
}) => {
  const [displayButton, setButtonDisplay] = useState(false);

  return isHidden
    ? (
      <div
        className={`${style['add-content-panel-wrapper']} ${displayButton ? style['is-visible'] : style['is-hidden']}`}
        onMouseOver={() => setButtonDisplay(true)}
        onMouseLeave={() => setButtonDisplay(false)}
      >
        <div className={style.divider} />
        <AddButton
          id={id}
          addBox={addBox}
          setButtonDisplay={() => setButtonDisplay(false)}
        />
        <div className={style.divider} />
      </div>
    )
    : (
      <div className={style['add-content-panel-wrapper']}>
        <div className={style.divider} />
        <AddButton
          id={id}
          addBox={addBox}
          setButtonDisplay={setButtonDisplay}
        />
        <div className={style.divider} />
      </div>
    );
};

CreateArticleContent.defaultProps = { id: 0 };

export default CreateArticleContent;
