import { transformAndFilterArticleDto } from '../../../../../utils/transformArticleDto';
import store from '../../../../../+store/store';
import { LANGUAGES } from '../../../../language/+store/languages';
import { BlogActionPayload, BlogStoreModels } from './blogStore.models';
import { ArticleContentTypes, ArticleTransformedData, BlogPageModels } from '../../../../../models/blogPage.models';
import { CommonModels } from '../../../../../models/common.models';
import { defaultContent, defaultTabs } from '../constants';
import { Languages } from '../../../../../models/language.model';

const articleFieldMap: ArticleTransformedData.FieldsMap = {
  category: {
    articleFieldName: 'categoryValueDtoSet',
    itemValue: 'categoryValue',
  },
  title: {
    articleFieldName: 'titleDtoSet',
    itemValue: 'titleValue',
  },
  thesis: {
    articleFieldName: 'thesisDtoSet',
    itemValue: 'thesisValue',
  },
};

export const getArticleInfo = (
  article: BlogPageModels.Article,
  articleField: ArticleTransformedData.Field,
): CommonModels.RecordInfo => {
  const result = { uk: '', en: '' };
  article[articleField.articleFieldName]?.forEach(item => {
    if (item.language) {
      const language = item.language.languageValue;
      result[language] = item[articleField.itemValue];
    }
  });
  return result;
}

export const getArticleCategory = (
  article: BlogPageModels.Article,
) => getArticleInfo(article, articleFieldMap.category);
export const getArticleTitle = (
  article: BlogPageModels.Article,
) => getArticleInfo(article, articleFieldMap.title);
export const getArticleThesis = (
  article: BlogPageModels.Article,
) => getArticleInfo(article, articleFieldMap.thesis);

export const transformContentTypeToNumber = (type: ArticleContentTypes) => {
  switch (type) {
    case ArticleContentTypes.TEXT:
      return 1;
    case ArticleContentTypes.IMG:
      return 3;
    default:
      return 2;
  }
};

export const getArticleContent = (
  { articleContentDtoList }: BlogPageModels.Article,
): ArticleTransformedData.Content => articleContentDtoList.reduce((result, item) => {
  const { id: articleContentSetId } = item;

  item.contentValueDtoSet.forEach(contentValue => {
    const contentType = contentValue.contentTypeEnum.contentTypeValue;
    const language = contentValue.language.languageValue;
    const content = contentValue.contentValue;
    const { id, contentTypeEnum, orderOnPage } = contentValue;
    if (language === 'ru') return;
    result[language].push({ type: contentType, content, id, articleContentSetId, contentTypeEnum, orderOnPage });
  });

  Object.keys(result).forEach(lang => (
    result[lang] as ArticleTransformedData.TransformedContent[]
  ).sort((a, b) => a.orderOnPage - b.orderOnPage));

  return result;
}, { en: [], uk: [] });

export const getArticleTabs = ({ articleTabDtoList }: BlogPageModels.Article): ArticleTransformedData.Tabs => {
  if (!articleTabDtoList || !articleTabDtoList.length) {
    return defaultTabs;
  }
  return articleTabDtoList.reduce((result, current) => ({
    ...result,
    [current.language.languageValue]: {
      id: current.id,
      isOriginal: current.isOriginal,
      hasChanged: current.hasChanged,
    },
  }), defaultTabs);
};

export const transformTabsToDto = (
  article: BlogPageModels.Article,
  tabs: ArticleTransformedData.Tabs,
): BlogPageModels.Article => {
  const articleTabDtoList = Object.keys(tabs).map((key, index) => ({
    language: {
      languageId: index + 1,
      languageValue: key,
    },
    id: tabs[key].id,
    isOriginal: tabs[key].isOriginal,
    hasChanged: tabs[key].hasChanged,
  }));
  return { ...article, articleTabDtoList };
};

export const updateBrowserPath = (id: number, location: { pathname: string }) => {
  const path = location.pathname.split('/').slice(0, -1);
  const newPath = [...path, id].join('/');
  const stateObj = { path: location.pathname };
  window.history.replaceState(stateObj, '', newPath);
};

export const addOldMainArticleToArticles = (state: BlogStoreModels.State, { id }: BlogActionPayload) => {
  const mainArticleBecomeArticle = state.mainArticle.id === id
    ? { ...state.mainArticle, main: false, thesis: '' }
    : null;
  return mainArticleBecomeArticle ? [mainArticleBecomeArticle, ...state.articles] : state.articles;
};

export const updateMainToMainShouldCreateSnapshot = (
  state: BlogStoreModels.State,
  { dataObject, languageValue }: BlogPageModels.ArticleUpdateData,
): BlogStoreModels.State => ({
  ...state,
  openModalCreatingPost: false,
  editing: false,
  article: transformAndFilterArticleDto(dataObject, languageValue),
  mainArticle: transformAndFilterArticleDto(dataObject, languageValue),
});

export const updateArticleToMainWhenMainExists = (
  state: BlogStoreModels.State,
  { dataObject, languageValue, id }: BlogPageModels.ArticleUpdateData,
): BlogStoreModels.State => ({
  ...state,
  openModalCreatingPost: false,
  editing: false,
  article: transformAndFilterArticleDto(dataObject, languageValue),
  articles: state.articles.map(article => (article.id === id
    ? transformAndFilterArticleDto(dataObject, languageValue)
    : article
  )),
});

export const updateArticleToArticleWhenMainIsMissing = (
  state: BlogStoreModels.State,
  { dataObject, languageValue, id }: BlogPageModels.ArticleUpdateData,
): BlogStoreModels.State => ({
  ...state,
  openModalCreatingPost: false,
  editing: false,
  article: transformAndFilterArticleDto(dataObject, languageValue),
  articles: state.articles.map(article => (article.id === id
    ? transformAndFilterArticleDto(dataObject, languageValue)
    : article
  )),
  mainArticle: null,
});

export const updateToArticleWhenMainExists = (
  state: BlogStoreModels.State,
  { dataObject, languageValue, id }: BlogPageModels.ArticleUpdateData,
): BlogStoreModels.State => ({
  ...state,
  openModalCreatingPost: false,
  editing: false,
  article: transformAndFilterArticleDto(dataObject, languageValue),
  articles: addOldMainArticleToArticles(state, { id }).map(article => (article.id === id
    ? transformAndFilterArticleDto(dataObject, languageValue)
    : article
  )),
  mainArticle: state.mainArticle.id === id ? null : state.mainArticle,
});

const updateMainArticleSnapshotToMain = (
  state: BlogStoreModels.State,
  { dataObject, languageValue }: BlogPageModels.ArticleUpdateData,
): BlogStoreModels.State => ({
  ...state,
  openModalCreatingPost: false,
  editing: false,
  article: transformAndFilterArticleDto(dataObject, languageValue),
  mainArticle: transformAndFilterArticleDto(dataObject, languageValue),
});

export const getUpdatedStateForUpdatedArticle = (
  state: BlogStoreModels.State,
  { dataObject, languageValue, id }: BlogPageModels.ArticleUpdateData,
) => {
  const { mainArticle } = state;

  if (dataObject.main && mainArticle) {
    const isCreatedSnapshotForOldMainArticle = mainArticle.id === id;

    return isCreatedSnapshotForOldMainArticle
      ? updateMainToMainShouldCreateSnapshot(state, { dataObject, languageValue, id })
      : updateArticleToMainWhenMainExists(state, { dataObject, languageValue, id });
  }

  if (mainArticle && dataObject.parentArticleId === mainArticle.id) {
    return updateMainArticleSnapshotToMain(state, { dataObject, languageValue, id });
  }

  return !mainArticle
    ? updateArticleToArticleWhenMainIsMissing(state, { dataObject, languageValue, id })
    : updateToArticleWhenMainExists(state, { dataObject, languageValue, id });
};

const publishMainToMain = (
  state: BlogStoreModels.State,
  { article, selectedLanguage }: BlogActionPayload,
): BlogStoreModels.State => ({
  ...state,
  mainArticle: transformAndFilterArticleDto(article, selectedLanguage),
  article: transformAndFilterArticleDto(article, selectedLanguage),
  loading: false,
});

const publishSimpleToMainAndMainToSimple = (
  state: BlogStoreModels.State,
  { article, selectedLanguage, id }: BlogActionPayload,
): BlogStoreModels.State => ({
  ...state,
  article: state.article.id === id
    ? transformAndFilterArticleDto(article, selectedLanguage)
    : state.article,
  articles: [{ ...state.mainArticle, main: false },
    ...state.articles.slice().filter(item => item.id !== id)],
  mainArticle: transformAndFilterArticleDto(article, selectedLanguage),
  loading: false,
});

const publishSimpleToMain = (
  state: BlogStoreModels.State,
  { article, selectedLanguage, id }: BlogActionPayload,
): BlogStoreModels.State => ({
  ...state,
  article: state.article.id === id
    ? transformAndFilterArticleDto(article, selectedLanguage)
    : state.article,
  articles: state.articles.slice().filter(item => item.id !== id),
  mainArticle: transformAndFilterArticleDto(article, selectedLanguage),
  loading: false,
});

const publishMainToSimple = (
  state: BlogStoreModels.State,
  { article, selectedLanguage, id }: BlogActionPayload,
): BlogStoreModels.State => ({
  ...state,
  article: state.article.id === id
    ? transformAndFilterArticleDto(article, selectedLanguage)
    : state.article,

  articles: [transformAndFilterArticleDto(article, selectedLanguage),
    ...state.articles],
  mainArticle: null,
  loading: false,
});

const publishSimpleToSimple = (
  state: BlogStoreModels.State,
  { article, selectedLanguage, id }: BlogActionPayload,
): BlogStoreModels.State => ({
  ...state,
  article: state.article.id === id
    ? transformAndFilterArticleDto(article, selectedLanguage)
    : state.article,
  articles: [
    ...state.articles.slice(0, state.articles.findIndex(item => item.id === id)),
    transformAndFilterArticleDto(article, selectedLanguage),
    ...state.articles.slice(state.articles.findIndex(item => item.id === id) + 1),
  ],
  loading: false,
});

export const getUpdatedStateForPublishedArticle = (
  state: BlogStoreModels.State,
  { article, selectedLanguage, id }: BlogActionPayload,
) => {
  if (!article.main) {
    return state.mainArticle && state.mainArticle.id === id
      ? publishMainToSimple(state, { article, selectedLanguage, id })
      : publishSimpleToSimple(state, { article, selectedLanguage, id });
  }
  if (state.mainArticle && state.mainArticle.id === id) {
    return publishMainToMain(state, { article, selectedLanguage, id });
  }
  if (state.mainArticle) {
    return publishSimpleToMainAndMainToSimple(state, { article, selectedLanguage, id });
  }
  return publishSimpleToMain(state, { article, selectedLanguage, id });
};

export const oldArticleContentValueIds = () => {
  const state: BlogStoreModels.State = store.getState().articlesPageReducer;
  const { articleContentDtoList } = state.article;

  const uniqueIdsFromServer = articleContentDtoList.reduce((result, { contentValueDtoSet }) => {
    contentValueDtoSet.forEach(({ id }) => result.push(id));
    return result;
  }, []);

  return uniqueIdsFromServer;
};

export const transformContentToDTO = ({
  id,
  categoryId,
  categoryValueDtoSet,
  mainImgLink,
  titleDtoSet,
  thesis,
  thesisDtoSet,
  dateTime,
  main,
  articleStatusName,
  parentArticleId,
  snapshotId,
}: BlogPageModels.Article,
  contentData: ArticleTransformedData.Content,
) => {
  const articleData: BlogPageModels.Article = {
    articleContentDtoList: [],
    id,
    categoryId,
    categoryValueDtoSet,
    mainImgLink,
    titleDtoSet,
    thesis,
    thesisDtoSet,
    dateTime,
    main,
    articleStatusName,
    parentArticleId,
    snapshotId,
  };

  const blocksLanguages = Object.keys(LANGUAGES)
    .map(item => item.toLocaleLowerCase())
    .sort((
      languageA: Languages,
      languageB: Languages,
    ) => contentData[languageB].length - contentData[languageA].length);

  blocksLanguages
    .forEach((language: Languages, languageIndex: number) => contentData[language]
      .forEach((content, contentIndex) => {
        const currentDtoSet = articleData.articleContentDtoList[contentIndex];
        const typeId = transformContentTypeToNumber(content.type);

        const contentSet: BlogPageModels.ArticleContentValue = {
          id: oldArticleContentValueIds().includes(content.id) ? content.id : null,
          contentValue: content.content,
          orderOnPage: contentIndex + 1,
          contentTypeEnum: { id: typeId, contentTypeValue: content.type },
          language: {
            languageId: languageIndex + 1,
            languageValue: language,
          },
        };


        if (!currentDtoSet) {
          articleData.articleContentDtoList.push({
            id: content.articleContentSetId,
            contentValueDtoSet: [contentSet],
          });
        } else {
          currentDtoSet.contentValueDtoSet.push(contentSet);
        }
      }));

  return articleData;
};

export const getArticle = (
  { articles, mainArticle }: BlogStoreModels.State,
  id: number,
) => articles.find(data => data.id === id) || mainArticle;
