import React from 'react';
import PropTypes from 'prop-types';
import styles from './paginationBlock.module.scss';
import Button from '../../../shared-ui/button';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const PaginationBlock = ({ translate, fetchNextPage }) => (
  <div className={`${styles['more-articles-button']} ${styles['pagination-block']}`}>
    <Button
      content={translate(TRANSLATE_KEYS.moreResults)}
      uiType="grayWide"
      onClick={fetchNextPage}
    />
  </div>
);


PaginationBlock.propTypes = {
  fetchNextPage: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default PaginationBlock;
