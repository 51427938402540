const enum BlogActionTypes {
  FETCH_ARTICLES = 'FETCH_ARTICLES',
  FETCH_ARTICLES_BY_CATEGORY = 'FETCH_ARTICLES_BY_CATEGORY',
  FETCH_CATEGORIES = 'FETCH_CATEGORIES',
  FETCH_MAIN_ARTICLE = 'FETCH_MAIN_ARTICLE',
  FETCH_START = 'REQUEST_START',
  FETCH_ERROR = 'REQUEST_ERROR',
  CLEAR_ARTICLES = 'CLEAR_ARTICLES',
  FETCH_PAGINATION = 'FETCH_PAGINATION',
  UPDATE_PAGINATION = 'UPDATE_PAGINATION',
}

export default BlogActionTypes;
