import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import { AdminWAFPageActions } from '../../+store/actions';
import Icon from '../../../../../shared-ui/icon';
import style from './popupMessage.module.scss';
import Button from '../../../../../shared-ui/button';
import { WAFModels } from '../../../../../../models/WAFPage.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { TranslateFn } from '../../../../../../models/translate-function.models';

interface PopupMessageDeleteProps extends RouteComponentProps {
  cookies: Cookies;
  petToDelete: WAFModels.PetToDelete;
  history: History;
  onDeletePet: (
    cookies: Cookies,
    petToDelete: WAFModels.PetToDelete,
    history: History,
  ) => void;
  onHideDeleteMessage: () => void;
  t: TranslateFn;
}

class PopupMessageDelete extends Component<PopupMessageDeleteProps> {
  removePet = () => {
    const {
      onDeletePet,
      onHideDeleteMessage,
      cookies,
      petToDelete,
      history,
    } = this.props;
    onDeletePet(cookies, petToDelete, history);
    onHideDeleteMessage();
  };

  render() {
    const {
      onHideDeleteMessage,
      petToDelete: { petName },
      t: translate,
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.body}>
            <div className={`${style['warning-icon']} ${style['warning-delete']}`}>
              <Icon name="trash-popup" />
            </div>
            <div className={style['text-container']}>
              <header className={`${style.header} ${style['header-delete']}`}>
                {translate(TRANSLATE_KEYS.deletePetTitle)}
              </header>
              <div className={style['warning-text']}>
                {translate(TRANSLATE_KEYS.deletePetQuestion)}
                {` "${petName}" ?`}
              </div>
            </div>
          </div>
          <div className={style.buttons}>
            <Button
              content={translate(TRANSLATE_KEYS.cancelBtn)}
              uiType="dark-rose-unfilled"
              onClick={onHideDeleteMessage}
            />
            <Button content={translate(TRANSLATE_KEYS.deleteBtn)} uiType="dark-rose-filled" onClick={this.removePet} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ petToDelete: state.wantAFriendPagesReducer.petToDelete });

const mapDispatchToProps = dispatch => ({
  onHideDeleteMessage: () => dispatch(AdminWAFPageActions.hideDeleteMessage()),
  onDeletePet: (
    cookies: Cookies,
    petToDelete: WAFModels.PetToDelete,
    history: History,
  ) => dispatch(AdminWAFPageActions.deletePet(cookies, petToDelete, history)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(PopupMessageDelete))),
);
