import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import { withTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../../i18n_keys'
import { formatDate } from '../../../../../utils/formatDate';
import styles from './feedback-item.module.scss';

const INITIAL_STATE = {
  isMoreTextOpened: false,
  isTextClamped: true,
  isAuthor: false,
};

class FeedbackItem extends Component {
  static propTypes = {
    feedback: PropTypes.shape({
      image: PropTypes.string.isRequired,
      review: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      petName: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ isTextClamped: this.textRef.current.clamped });
    this.checkAuthor();
  }

  checkAuthor = () => {
    const { feedback: { userId } } = this.props;
    if (userId === 0) this.setState({ isAuthor: true });
  };

  handleShowMoreText = () => {
    this.setState({ isMoreTextOpened: true });
    this.setState({ isTextClamped: false });
  };

  render() {
    const { feedback: { name, petName, date, image, review }, t: translate } = this.props;
    const { isMoreTextOpened, isTextClamped, isAuthor } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles['user-avatar']}>
          <img src={image} alt="avatar" className={styles.img} />
        </div>
        <div className={styles['user-feedback']}>
          <div className={styles['name-and-date']}>
            <div className={styles['name-container']}>
              <div className={styles.name}>{translate(TRANSLATE_KEYS[name])}</div>
              {translate(TRANSLATE_KEYS[petName]) && (
                <div className={styles.petName}>{translate(TRANSLATE_KEYS[petName])}</div>
              )}
            </div>
            <div className={styles.date}>
              <time>{formatDate(date)}</time>
            </div>
          </div>
          {!isMoreTextOpened
            ? (
              <LinesEllipsis
                className={styles.review}
                text={translate(TRANSLATE_KEYS[review])}
                maxLine={window.innerWidth < 600 ? 4 : 3}
                ref={this.textRef}
                style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
              />
            )
            : <p className={styles.review}>{translate(TRANSLATE_KEYS[review])}</p>
          }
          {isTextClamped
          && (
          <button className={styles['read-more-button']} onClick={this.handleShowMoreText}>
            {translate(TRANSLATE_KEYS.showMore)}
          </button>
          )}

          {/* ST-3975: commented out "Answer" button before the implementation of the feedback functionality */}
          {/* <div className={styles['edit-and-answer']}>
            {isAuthor && <button className={styles['edit-button']}>{translate(TRANSLATE_KEYS.editTool)}</button>}
            <button className={styles['answer-button']}>{translate(TRANSLATE_KEYS.answering)}</button>
          </div> */}

        </div>
      </div>
    );
  }
}

FeedbackItem.propTypes = { t: PropTypes.func.isRequired };

export default withTranslation('translations')(FeedbackItem);
