/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.8284 4 13.5 4.67157 13.5 5.5L13.5005 6.18915C14.051 6.33089 14.5709 6.5488 15.0484 6.83099L15.5355 6.34315C16.1213 5.75736 17.0711 5.75736 17.6569 6.34315C18.2426 6.92893 18.2426 7.87868 17.6569 8.46447L17.169 8.95161C17.4513 9.42921 17.6692 9.94932 17.811 10.5L18.5 10.5C19.3284 10.5 20 11.1716 20 12C20 12.8284 19.3284 13.5 18.5 13.5L17.8109 13.5005C17.6691 14.051 17.4512 14.5709 17.169 15.0484L17.6569 15.5355C18.2426 16.1213 18.2426 17.0711 17.6569 17.6569C17.0711 18.2426 16.1213 18.2426 15.5355 17.6569L15.0484 17.169C14.5709 17.4512 14.051 17.6691 13.5005 17.8109L13.5 18.5C13.5 19.3284 12.8284 20 12 20C11.1716 20 10.5 19.3284 10.5 18.5L10.5 17.811C9.94932 17.6692 9.42921 17.4513 8.95161 17.169L8.46447 17.6569C7.87868 18.2426 6.92893 18.2426 6.34315 17.6569C5.75736 17.0711 5.75736 16.1213 6.34315 15.5355L6.83099 15.0484C6.5488 14.5709 6.33089 14.051 6.18915 13.5005L5.5 13.5C4.67157 13.5 4 12.8284 4 12C4 11.1716 4.67157 10.5 5.5 10.5L6.18902 10.5C6.33071 9.94951 6.54857 9.42957 6.83069 8.95211L6.34315 8.46447C5.75736 7.87868 5.75736 6.92893 6.34315 6.34315C6.92893 5.75736 7.87868 5.75736 8.46447 6.34315L8.95211 6.83069C9.42957 6.54857 9.94951 6.33071 10.5 6.18902L10.5 5.5C10.5 4.67157 11.1716 4 12 4ZM12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5Z" fill="#373737" />
  </svg>
);

export default SVG;
