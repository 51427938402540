import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './styles/create-article.module.scss';
import { AdminBlogPopupActions } from '../../+store/actions';
import calcTextareaHeight from '../../../../../../../../utils/calcTextareaHeight';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { AVAILABLE_LANGUAGES } from '../../../../../../../language/+store/languages';
import { handleAutocomplete } from './helpers';
import { LanguageData, Languages } from '../../../../../../../../models/language.model';
import { CommonModels } from '../../../../../../../../models/common.models';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { getArticleThesis } from '../../../../+store/helpers';

const DESCRIPTION_MAXIMUM_CHARACTER_LENGTH = 100;

interface ArticleDescriptionProps {
  editing: boolean;
  language: LanguageData;
  articleDescription: CommonModels.RecordInfo;
  articleDescriptionEdit: CommonModels.RecordInfo;
  changeDescription: (language: string, description: string) => void;
  t: TranslateFn;
}

const ArticleDescription: FC<ArticleDescriptionProps> = ({
  editing,
  language,
  articleDescription,
  articleDescriptionEdit,
  changeDescription,
  t: translate,
}) => {
  const [autocompleteLanguages, setAutoCompleteLanguages] = useState<Languages[]>([]);

  useEffect(() => {
    if (editing) AVAILABLE_LANGUAGES.forEach((lang) => changeDescription(lang, articleDescriptionEdit[lang]));
  }, []);

  const handleDescriptionChange = (e) => {
    const notActiveLanguages = AVAILABLE_LANGUAGES.filter((lang) => language.languageValue !== lang);

    if (e.target.value.includes('\n')) return;
    const description = e.target.value;
    if (description.length > DESCRIPTION_MAXIMUM_CHARACTER_LENGTH) return;
    calcTextareaHeight(e);

    const {
      isStateShouldBeUpdated,
      newAutocompleteLanguages,
      shouldUpdateNotActiveLanguages,
    } = handleAutocomplete(autocompleteLanguages, articleDescription, notActiveLanguages);

    if (shouldUpdateNotActiveLanguages) {
      notActiveLanguages.forEach((notActiveLanguage) => changeDescription(notActiveLanguage, description));
    }

    changeDescription(language.languageValue, description);

    if (isStateShouldBeUpdated) setAutoCompleteLanguages([...newAutocompleteLanguages]);
  };

  const isTextareaAutocomplete = autocompleteLanguages
    .some((autocompleteLanguage) => autocompleteLanguage === language.languageValue);
  const textareaStyles = isTextareaAutocomplete ? `${styles.textarea} ${styles.autocomplete}` : styles.textarea;
  const labelStyles = articleDescription[language.languageValue]
    ? `${styles.label} ${styles['label-value']}`
    : styles.label;

  return (
    <>
      <label htmlFor="articleDescription" className={styles['article-form-description']}>
        <textarea
          id="articleDescription"
          value={articleDescription[language.languageValue]}
          className={textareaStyles}
          onChange={handleDescriptionChange}
        />
        <span className={labelStyles}>{translate(TRANSLATE_KEYS.inputDescription)}</span>
        <span className={styles.counter}>
          {articleDescription[language.languageValue]
            ? DESCRIPTION_MAXIMUM_CHARACTER_LENGTH - articleDescription[language.languageValue].length
            : DESCRIPTION_MAXIMUM_CHARACTER_LENGTH}
        </span>
      </label>
    </>
  );
}

const mapStateToProps = state => ({
  articleDescription: state.adminArticleCreateFormReducer.articleDescription,
  editing: state.articlesPageReducer.editing,
  articleDescriptionEdit: getArticleThesis(state.articlesPageReducer.article),
});

const mapDispatchToProps = dispatch => ({
  changeDescription: (
    language: string,
    description: string,
  ) => dispatch(AdminBlogPopupActions.articleDescriptionChange(language, description)),
});

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ArticleDescription));
