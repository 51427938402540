import React from 'react';
import PropTypes from 'prop-types';
import styles from './filterButton.module.scss';


const filterButton = ({ setActiveFilter, activeFilter, sizeFilter, genderFilter, ageFilter }) => {
  const isFiltered = () => {
    if (sizeFilter === null && ageFilter === null && genderFilter === null) {
      return false;
    }
    return true;
  };

  return (
    <button
      onClick={() => setActiveFilter(!activeFilter)}
      className={isFiltered() ? `${styles['filter-button']}
      ${styles['filter-button__filtered']} ${activeFilter ? styles['filter-button__hide'] : ''}`
        : `${styles['filter-button']} ${styles['filter-button_hide']}`}
    />
  );
};

filterButton.propTypes = {
  setActiveFilter: PropTypes.func.isRequired,
  activeFilter: PropTypes.bool.isRequired,
  ageFilter: PropTypes.bool.isRequired,
  sizeFilter: PropTypes.bool.isRequired,
  genderFilter: PropTypes.bool.isRequired,
};

export default filterButton;
