/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../../../../shared-ui/icon';
import { getDropdownBtnClasses } from './helpers';
import styles from './styles/article-category-select.module.scss';
import { LanguageData } from '../../../../../../../../models/language.model';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

interface ArticleCategoryDropdownProps {
  activeLanguage: LanguageData;
  categoryData: BlogPageModels.Category;
  onClick: () => void;
  t: TranslateFn;
}

const ArticleCategoryDropdown: FC<ArticleCategoryDropdownProps> = ({
  activeLanguage,
  categoryData,
  onClick,
  t: translate,
}) => {
  const title = categoryData ? categoryData.name[activeLanguage.languageId] : '';
  return (
    <button
      className={getDropdownBtnClasses(title)}
      onClick={() => onClick()}
    >
      <span className={styles.label}>
        {categoryData ? translate(TRANSLATE_KEYS.categoryName) : translate(TRANSLATE_KEYS.categoryNotSelected)}
      </span>
      {title ? <span>{title}</span> : null}
      <Icon name="arrow-down" />
    </button>
  );
};

export default withTranslation('translations')(ArticleCategoryDropdown);
