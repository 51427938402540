import React, { FC } from 'react';
import styles from './dogPageParameters.module.scss';
import dogPageTranslations from '../dogPageTranslations';
import getDogAgeTranslateKey from '../../../../../../../utils/getDogAgeTranslateKey';
import { DogPageParametersModels } from '../dogDetailsPage.models';

const DogPageParameters: FC<DogPageParametersModels.Props> = ({
  size,
  age,
  isMale,
  sterilized = false,
  activeLanguage,
}) => (
  <div className={styles.parametersBlock}>
    <h4 className={styles.title}>{dogPageTranslations[activeLanguage].meet}</h4>
    <div className={styles.parametersTable}>
      <div className={styles.parBlock}>
        <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].sexDog}</h3>
        <p className={styles.parText}>
          {isMale ? `${dogPageTranslations[activeLanguage].male}` : `${dogPageTranslations[activeLanguage].female}`}
        </p>
      </div>
      <div className={styles.parBlock}>
        <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].age}</h3>
        <p
          className={styles.parText}
        >
          {dogPageTranslations[activeLanguage][getDogAgeTranslateKey(age)]}
        </p>
      </div>
      <div className={styles.parBlock}>
        <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].size}</h3>
        <p className={styles.parText}>{size}</p>
      </div>
      <div className={styles.parBlock}>
        <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].sterilization}</h3>
        <p className={styles.parText}>
          {sterilized ? `${dogPageTranslations[activeLanguage].yes}` : `${dogPageTranslations[activeLanguage].no}`}
        </p>
      </div>
    </div>
  </div>
);


export default DogPageParameters;
