import { ArticleTransformedData } from '../../../../../../../models/blogPage.models';
import { defaultContent } from '../../../constants';

const removeEmptyContentObjects = (object: ArticleTransformedData.Content) => {
    const keys = Object.keys(object);
    const arr = Object.values(object);

    const result = arr.map((subArr: ArticleTransformedData.TransformedContent[]) =>
        /* eslint-disable implicit-arrow-linebreak */
        subArr.filter((obj) => Object.prototype.hasOwnProperty.call(obj, 'content') && obj.content.trim()),
    );
    /* eslint-disable no-param-reassign */
    return keys.reduce((finalObj, key, index) => {
        finalObj[key] = result[index];
        return finalObj;
    }, { ...defaultContent });
};

export default removeEmptyContentObjects;
