import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './EmptyContent.module.scss';
import noArticleImage from '../../../../assets/images/no-article.svg';

const EmptyContent: FC = () => {
  const [translate] = useTranslation();
  return (
    <div className={styles.noArticle}>
      <img width="224" height="224" src={noArticleImage} alt="Still no one friend" />
      <h1 className={styles['noArticle-title']}>{translate('noArticles')}</h1>
      <h3 className={styles['noArticle-subtitle']}>{translate('noArticlesTitle')}</h3>
    </div>
  );
};

export default EmptyContent;
