import React, { FC, useEffect, useState, MouseEvent } from 'react';
import ListHeader from '../../../shared-ui/adminListHeader/ListHeader';
import CardsGrid from './components/cardsGrid/CardsGrid';
import PaginationBlock from './components/paginationBlock/PaginationBlock';
import PopupForm from './components/popupForm/PopupForm';
import { AdminWAFPageModels } from './adminWAFPage.models';
import scrollToElement from '../../../../utils/scrollToElement';

const WantAFriendPage: FC<AdminWAFPageModels.Props> = ({
  pet,
  petsList,
  petsCount,
  pagesLoaded,
  totalPages,
  openDogPopupForm,
  location,
  history,
  cookies,
  fetchPetsRecords,
}) => {
  const [currentDogCardId, setCurrentDogCardId] = useState(0);
  useEffect(() => {
    const { id: prevPetId } = pet;
    const { state: prevUrl } = location;
    if (prevPetId && prevUrl) {
      scrollToElement(`[id='${prevPetId}']`, 200);
    }
    if (!pagesLoaded) {
      fetchNextPage();
    }
  }, []);

  const fetchNextPage = () => {
    fetchPetsRecords(pagesLoaded + 1, cookies);
  };

  const handleCurrentDogCardIdUpdate = (e: MouseEvent) => {
    const targetId = Number(e.currentTarget.id);
    if (currentDogCardId !== targetId) {
      setCurrentDogCardId(targetId);
    }
  };

  return (
    <>
      <ListHeader petsCount={petsCount} />
      <CardsGrid
        petsList={petsList}
        handleCurrentDogCardIdUpdate={handleCurrentDogCardIdUpdate}
        history={history}
      />
      {pagesLoaded < totalPages && (
        <PaginationBlock fetchNextPage={fetchNextPage} />
      )}
      {openDogPopupForm && (
        <PopupForm />
      )}
    </>
  );
}

export default WantAFriendPage;
