import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { BlogActions } from './+store/actions';

import { DispatchFn } from '../../types/dispatch.type';
import { RootStateType } from '../../types/store.models';

import BlogComponent from './BlogComponent';

const mapStateToProps = (state: RootStateType) => ({ ...state.blogReducer, openArticleId: state.openArticleReducer.openArticle?.id });
const mapDispatchToProps = (dispatch: DispatchFn) => ({
  clearArticles: () => dispatch(BlogActions.clearArticles()),
  fetchPaginationArticles: (...params: Parameters<typeof BlogActions.fetchPagination>) => (
    BlogActions.fetchPagination(...params)(dispatch)
  ),
  updatePagination: (...params: Parameters<typeof BlogActions.updatePagination>) => (
    dispatch(BlogActions.updatePagination(...params))
  ),
  fetchPaginationArticlesByCategory:
    (...params: Parameters<typeof BlogActions.fetchPaginationByCategory>) => (
      BlogActions.fetchPaginationByCategory(...params)(dispatch)
    ),
  fetchPageInfoBuilder: (...params: Parameters<typeof BlogActions.fetchPageInfoBuilder>) => (
    BlogActions.fetchPageInfoBuilder(...params)(dispatch)
  ),
});

const BlogContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogComponent));

export default BlogContainer;
