/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-–-Link-2" transform="translate(-817.000000, -1964.000000)">
        <g id="Paragraph" transform="translate(424.000000, 1744.000000)">
          <g id="Input" transform="translate(221.000000, 208.000000)">
            <g id="Button" transform="translate(164.000000, 4.000000)">
              <g id="Icon" transform="translate(8.000000, 8.000000)" fill="#373737">
                <path d="M12.0208153,1.39339828 C12.4113396,1.78392257 12.4113396,2.41708755 12.0208153,2.80761184 L3.828,10.9992915 L23,11 C23.5522847,11 24,11.4477153 24,12 C24,12.5522847 23.5522847,13 23,13 L3.828,12.9992915 L12.0208153,21.1923882 C12.4113396,21.5829124 12.4113396,22.2160774 12.0208153,22.6066017 C11.630291,22.997126 10.997126,22.997126 10.6066017,22.6066017 L0.707106781,12.7071068 C0.316582489,12.3165825 0.316582489,11.6834175 0.707106781,11.2928932 L10.6066017,1.39339828 C10.997126,1.00287399 11.630291,1.00287399 12.0208153,1.39339828 Z" id="Combined-Shape" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) " />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
