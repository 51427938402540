import { BlogPageModels } from '../../../../../../../models/blogPage.models';
import { CommonModels } from '../../../../../../../models/common.models';

const formatDefaultArticleData = (
  formatingData: BlogPageModels.ArticleContent[],
) => {
  const articleFormatResult: CommonModels.RecordInfo[] = [];

  let lastIndex = formatingData.length - 1;
  while (lastIndex >= 0) {
    const result = formatingData[lastIndex].contentValueDtoSet.reduce((acc, { contentValue, language }) => {
      acc[language.languageValue] = contentValue;
      return acc;
    }, { uk: '', en: '' });
    articleFormatResult.unshift(result);
    lastIndex -= 1;
  }

  return articleFormatResult;
};

export default formatDefaultArticleData;
