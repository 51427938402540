import { combineReducers } from 'redux';
import { REQUEST_FEEDBACK_BEGIN,
  REQUEST_FEEDBACK_SUCCESS,
  REQUEST_FEEDBACK_FAILURE,
  REQUEST_MORE_FEEDBACK_SUCCESS } from './types';

const initialState = {
  items: [],
  loading: false,
  hasMoreFeedback: false,
  error: null,
};

function feedback(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FEEDBACK_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case REQUEST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMoreFeedback: action.payload.hasMoreFeedback,
        items: action.payload.feedback,
      };

    case REQUEST_MORE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMoreFeedback: action.payload.hasMoreFeedback,
        items: action.payload.feedback,
      };

    case REQUEST_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}

export default combineReducers({ feedback });
