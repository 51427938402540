import React, { useState, FC, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Header.module.scss';
import Icon from '../icon';

import TRANSLATE_KEYS from '../../../i18n_keys';

import { isWAFLinkActive, isBlogLinkActive } from './helpers';

import LanguageForm from './components/languageForm/LanguageForm';
import DogForm from './components/dogForm/DogForm';
import setBodyScrollDisabled from '../../../utils/toogleBodyScroll';

export const MAX_HEADER_HEIGHT = 64;
const DESKTOP_MIN_WIDTH = 960;
const LANDSCAPE_TURN_DEGREE = 90;

const Header: FC = () => {
  const [translate] = useTranslation();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);

  const handleUpdateDimensions = useCallback(() => {
    if (window.innerWidth > DESKTOP_MIN_WIDTH && isMenuActive) {
      handleCloseMenu();
    }
  }, [isMenuActive]);

  useEffect(() => {
    window.addEventListener('resize', handleUpdateDimensions);

    return () => window.removeEventListener('resize', handleUpdateDimensions);
  }, [handleUpdateDimensions]);

  const isLandscape = window.orientation === LANDSCAPE_TURN_DEGREE;
  const mobileLandscapeWrapperStyles = isMenuActive && isLandscape ? `${styles['wrapper-landscape']}` : '';
  const mobileLandscapeLogoBlockStyles = isMenuActive && isLandscape ? `${styles['logoBlock-landscape']}` : '';

  const isMenuActiveClass = !isMenuActive
    ? `${styles.navigation} ${styles.hoveredItem}`
    : `${styles['navigation-mobile']} ${styles.hoveredItem}`
  const handleCloseMenu = () => {
    setIsMenuActive(false);
    setBodyScrollDisabled(false);
  };

  const handleBurgerToggle = () => {
    if (!isMenuActive) {
      setIsMenuActive(true);
    setBodyScrollDisabled(true);
    } else {
      handleCloseMenu();
    }
  };

  return (
    <header className={styles.box} style={{ maxHeight: `${MAX_HEADER_HEIGHT}px` }}>
      <div className={`${styles.wrapper} ${mobileLandscapeWrapperStyles}`}>
        <div className={`${styles.logoBlock} ${mobileLandscapeLogoBlockStyles}`}>
          <NavLink to="/home" className={styles.logo}>
            <div className={styles['icon-placeholder']}><Icon name="app-logo" /></div>
            <div>DogHotel</div>
          </NavLink>
          <LanguageForm />
        </div>
        <nav
          className={isMenuActiveClass}
          onClick={handleCloseMenu}
          role="presentation"
        >
          <NavLink to="/prices" className={styles.item} activeClassName={styles.active}>
            {translate(TRANSLATE_KEYS.servicesAndPrices)}
          </NavLink>
          <NavLink to="/about" className={styles.item} activeClassName={styles.active}>
            {translate(TRANSLATE_KEYS.aboutUs)}
          </NavLink>
          <NavLink
            to="/want-a-friend"
            isActive={isWAFLinkActive}
            className={styles.item}
            activeClassName={styles.active}
          >
            {translate(TRANSLATE_KEYS.wantAFriend)}
          </NavLink>
          <NavLink
            to={{ pathname: '/blog/0', state: { fromNav: true } }}
            isActive={isBlogLinkActive}
            className={styles.item}
            activeClassName={styles.active}
          >
            {translate(TRANSLATE_KEYS.blog)}
          </NavLink>
          <NavLink to="/contacts" className={styles.item} activeClassName={styles.active}>
            {translate(TRANSLATE_KEYS.contacts)}
          </NavLink>
        </nav>
        <DogForm
          handleCloseMenu={handleCloseMenu}
          isMenuActive={isMenuActive}
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
        />
        <button
          className={`${!isMenuActive ? styles['menu-icon'] : styles['menu-icon_close']} ${styles.icon}`}
          onClick={handleBurgerToggle}
        />
      </div>
    </header>
  );
};

export default Header;
