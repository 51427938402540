import { connect } from 'react-redux';
import SendMessageComponent from './sendMessageComponent';
import { sendMessage } from './+store/actions';

const mapDispatchToProps = dispatch => ({ onSendMessage: message => dispatch(sendMessage(message)) });

const mapStateToProps = state => ({ displayAlert: state.messageReducer.displayAlert });

const SendMessageContainer = connect(mapStateToProps, mapDispatchToProps)(SendMessageComponent);

export default SendMessageContainer;
