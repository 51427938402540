import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import PhotoGalleryComponent from './photo-galleryComponent';
import { requestAlbums } from './+store/actions';
import dotsLoader from '../../../shared-ui/dotsLoader/dotsLoader';
import styles from './photo-gallery.module.scss';

class PhotoGalleryContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    albums: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        albumName: PropTypes.objectOf.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
          }).isRequired,
        ).isRequired,
      }),
    ).isRequired,
  };

  static defaultProps = { error: false };

  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestAlbums());
  }

  render() {
    const { error, loading, albums } = this.props;
    if (error) {
      return <div>{`Error: ${error.message}`}</div>;
    }
    if (loading) {
      return (
        <div className={styles.loader}>
          <div className={styles.dotsLoader}>{dotsLoader()}</div>
        </div>
      );
    }
    return <PhotoGalleryComponent albums={albums} />;
  }
}

const mapStateToProps = (state) => {
  const { items, loading, error } = state.albumReducer.album;
  return ({
    albums: items,
    loading,
    error,
  });
};

export default compose(connect(mapStateToProps))(PhotoGalleryContainer);
