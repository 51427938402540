import React, { FC } from 'react';
// import PriceList from '../price-list';                                  //Todo ST-919
// import mockPrice from '../../../../mockData/price';                     //Todo ST-919
import AdditionalDescription from '../description/AdditionalDescription/AdditionalDescription';
// import Tabs from '../../../shared-ui/tabs';                             //Todo ST-919

// import styles from './additionalTab.module.scss';                       //Todo ST-919

const AdditionalTab: FC = () => (
  <>
    <AdditionalDescription />
    {/* Todo ST-919 Change content of the "Дополнительные услуги" page; */}
    {/* <h1 id="services" className={`${styles.title} ${styles.m_b_96}`}>Цены за услуги</h1> */}
    {/* <Tabs
      selected={0}
      tabsLabels={[{ label: 'Для собак' },
        { label: 'Для кошек' }]}
      contentPanels={[
        <React.Fragment>
          <PriceList
            target="dog"
            service="additional"
            category="grooming"
            data={mockPrice}
          />
          <PriceList
            target="dog"
            service="additional"
            category="veterinary services"
            data={mockPrice}
          />
          <PriceList
            target="dog"
            service="additional"
            category="transportation"
            data={mockPrice}
          />
        </React.Fragment>,
        <React.Fragment>
          <PriceList
            target="cat"
            service="additional"
            category="grooming"
            data={mockPrice}
          />
          <PriceList
            target="cat"
            service="additional"
            category="veterinary services"
            data={mockPrice}
          />
          <PriceList
            target="cat"
            service="additional"
            category="transportation"
            data={mockPrice}
          />
        </React.Fragment>,
      ]}
    /> */}
  </>
);

export default AdditionalTab;
