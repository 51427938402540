import React from 'react';
import PropTypes from 'prop-types';
import styles from './PersoneImage.module.scss';

const PersonImage = ({ source, isActive, goToSlideAtIndex, id }) => (
  <img
    onClick={goToSlideAtIndex}
    id={id}
    className={isActive ? styles['person-image_active'] : styles['person-image']}
    disabled={isActive && 'disabled'}
    src={source}
    alt="Person"
    role="presentation"
  />
);

PersonImage.propTypes = {
  source: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  goToSlideAtIndex: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default PersonImage;
