import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FeedbackListComponent from './FeedbackListComponent';
import { requestInitialFeedback, requestMoreFeedback } from './+store/actions';
import button from '../../../shared-ui/button/button.module.scss';
import styles from './feedback-list.module.scss';
import dotsLoader from '../../../shared-ui/dotsLoader/dotsLoader';

class FeedbackListContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    feedback: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        response: PropTypes.string,
      }),
    ).isRequired,
    hasMoreFeedback: PropTypes.bool.isRequired,
  };

  static defaultProps = { error: false };

  constructor(props) {
    super(props);
    this.state = { isInitialrequest: true };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestInitialFeedback());
  }

  handleShowMoreFeedback = () => {
    const { dispatch } = this.props;
    dispatch(requestMoreFeedback());
    this.setState({ isInitialrequest: false });
  };

  render() {
    const { error, loading, hasMoreFeedback, feedback, t } = this.props;
    const { isInitialrequest } = this.state;
    if (isInitialrequest) {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (loading) {
        return (
          <div className={styles.loader}>
            <div className={styles.dotsLoader}>{dotsLoader()}</div>
          </div>
        );
      }

      return (
        <>
          <FeedbackListComponent feedback={feedback} />
          {hasMoreFeedback && (
            <div className={styles['button-box']}>
              <button
                onClick={this.handleShowMoreFeedback}
                className={`${button.button} ${button.sunMain} ${styles.button}`}
              >
                {t('showAllAnswers')}
              </button>
            </div>
          )}
        </>
      );
    }

    if (error) {
      return (
        <>
          <FeedbackListComponent feedback={feedback} />
          <div className={styles['button-box']}>
            <button
              onClick={this.handleShowMoreFeedback}
              className={`${button.button} ${button.sunMain} ${styles.button}`}
            >
              {t('showAllAnswers')}
            </button>
            <div>{error.message}</div>
          </div>
        </>
      );
    }

    if (loading) {
      return (
        <>
          <FeedbackListComponent feedback={feedback} />
          <div className={styles['button-box']}>
            <button
              onClick={this.handleShowMoreFeedback}
              className={`${button.button} ${button.sunMain} ${styles.button}`}
            >
              {t('showAllAnswers')}
            </button>
          </div>
          <div className={styles.loader}>
            <div className={styles.dotsLoader}>{dotsLoader()}</div>
          </div>
        </>
      );
    }

    return (
      <>
        <FeedbackListComponent feedback={feedback} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { items, loading, error, hasMoreFeedback } = state.feedbackReducer.feedback;
  return ({
    feedback: items,
    loading,
    error,
    hasMoreFeedback,
  });
};

FeedbackListContainer.propTypes = { t: PropTypes.func.isRequired };


export default compose(withTranslation('translations'), connect(mapStateToProps))(FeedbackListContainer);
