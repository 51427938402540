import React, { useState, useEffect, FC, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './dogDetails.module.scss';
import DogPageGreeting from './dogPageGreeting/dogPageGreeting';
import DogPageHistory from './dogPageHistory/dogPageHistory';
import DogPageParameters from './dogPageParameters/dogPageParameters';
import DogPageGallery from './dogPageGallery/dogPageGallery';
import DogDetailsButtons from './dogPageButtons/dogPageButtons';
import Icon from '../../../../../shared-ui/icon';
import Dropdown from '../../../../../shared-ui/dropdown/Dropdown';
import PopupMessageDelete from '../cardsGrid/popupMessageDelete';
import PopupForm from '../popupForm/PopupForm';
import getDogDataForUpdate from '../../../../../../utils/getDogDataForUpdate';
import { DogDetailsPageModels } from './dogDetailsPage.models';
import { defaultTranslation, LANGUAGE_TABS } from '../../constants';

const DogDetailsComponent: FC<DogDetailsPageModels.Props> = ({
  pet,
  selectedLanguage,
  deleteMessageVisible,
  petName = defaultTranslation,
  petTitle = defaultTranslation,
  petDescription = defaultTranslation,
  openModalCreatingDog,
  petsAllList = [],
  match,
  cookies,
  i18n,
  location,
  getPet,
  getAllPets,
  handleSelectLanguage,
  onUpdateDogHandler,
  t: translate,
}) => {
  const [dog, setPet] = useState(null);
  const [isMenuOpened, setMenuOpenedState] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(selectedLanguage);

  const handleUpdateDog = () => {
    onUpdateDogHandler(getDogDataForUpdate(pet, translate), pet);
  };

  useEffect(() => {
    getPet(Number(match.params.id), cookies);
    getAllPets(cookies);
  }, [match]);

  useEffect(() => {
    handleSelectLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (!dog) return;
    setPet(pet);
  }, [dog]);


  const burgerToggle = (e: MouseEvent) => {
    e.preventDefault();
    setMenuOpenedState(!isMenuOpened);
  };

  const isVisiblePage = petDescription && pet
    && pet.dogPhotoDtoList && petsAllList;

  const { pathname: prevPathUrl } = location;

  return isVisiblePage && (
    <>
      <header className={styles.box}>
        <div className={`${styles.wrapperBlock}`}>
          <div className={`${styles.wrapper} ${styles.wrapDog}`}>
            <div className={styles['articles-list-dropdown']}>
              <Link
                to={{
                pathname: '/admin/want-a-friend',
                state: prevPathUrl,
                }}
                className={styles['back-link']}
              >
                <Icon name="back" />
              </Link>
              <Dropdown
                content={(
                  <ul className={styles.dropdownDog}>
                    {petsAllList.length && petsAllList.map(el => (
                      <li
                        key={el.id}
                        className={el.id === pet.id ? `${styles.active}` : ''}
                      >
                        <Link
                          to={`/admin/want-a-friend/dog/${el.id}`}
                        >
                          {el.name[activeLanguage]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              >
                <span className={styles.label}>{petName[activeLanguage]}</span>
              </Dropdown>
            </div>
            <nav
              className={isMenuOpened ? `${styles.navigation} ${styles['is-visible']}` : styles.navigation}
            >
              <ul className={styles.list}>
                {LANGUAGE_TABS.map(tab => (
                  <button
                    key={tab.value.languageValue}
                    onClick={() => setActiveLanguage(tab.value.languageValue)}
                  >
                    <span
                      className={
                        `${styles.link} ${activeLanguage === tab.value.languageValue ? styles['is-active'] : ''}`
                      }
                    >
                      {tab.label}
                    </span>
                  </button>
                ))}
              </ul>
            </nav>
            <DogDetailsButtons
              pet={pet}
              title={petName[activeLanguage]}
              handleUpdateDog={handleUpdateDog}
            />
            <div
              className={
                !isMenuOpened
                  ? `${styles['menu-icon']}`
                  : `${styles['menu-icon_close']}`
              }
              onClick={burgerToggle}
              role="presentation"
            />
          </div>
        </div>
      </header>
      <main className={styles.box}>
        <div className={styles.wrapper}>
          <DogPageGreeting
            name={petName[activeLanguage]}
            activeLanguage={activeLanguage}
          />
          <DogPageGallery
            photos={pet.dogPhotoDtoList}
          />
          <DogPageParameters
            isMale={pet.male}
            size={petTitle[activeLanguage]}
            sterilized={pet.sterilized}
            age={pet.birthDate}
            activeLanguage={activeLanguage}
          />
          <DogPageHistory
            description={petDescription[activeLanguage]}
            activeLanguage={activeLanguage}
          />
        </div>
      </main>
      {deleteMessageVisible && <PopupMessageDelete />}
      {openModalCreatingDog && (
        <PopupForm />
      )}
    </>
  )
};


export default withTranslation('translations')(DogDetailsComponent);
