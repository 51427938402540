import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { validateEmail } from '../../../utils/validation';
import styles from './signupForNews.module.scss';
import dotsLoader from '../dotsLoader/dotsLoader';
import { SignUpForNewsModels } from './models/signUpForNews.models';
import TRANSLATE_KEYS from '../../../i18n_keys';

const INITIAL_STATE: SignUpForNewsModels.State = {
  isValid: false,
  isValidating: false,
  error: '',
  width: window.innerWidth,
  sendingRequest: false,
  shouldDisplayAlert: false,
};
const MOBILE_WIDTH = 720;

const SignupForNewsComponent: FC<SignUpForNewsModels.Props> = (
  { i18n, onAddEmail, t: translate, onGetInputValue, displayAlert, email }) => {
  const [initState, setInitState] = useState(INITIAL_STATE);
  const [language, setLanguage] = useState(i18n.language);
  const isEmailInvalid = !initState.isValid && initState.isValidating;
  const isItDesktop = initState.width > MOBILE_WIDTH;
  const inputEmailClassName = isEmailInvalid ? styles['subscribe-form__input--error'] : styles['subscribe-form__input'];
  const inputEmailPlaceholder = isItDesktop
    ? `${translate(TRANSLATE_KEYS.emailPlaceholderDefault)}`
    : `${translate(TRANSLATE_KEYS.emailPlaceholderMobile)}`;
  const buttonSendEmailClassName = isEmailInvalid ? styles['button--error'] : styles.button;

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
      if (initState.error !== '') {
        setInitState({ ...initState, error: `${translate(TRANSLATE_KEYS.emailValidationError)}` });
      }
    }

    setInitState((prevInitState) => {
      if (prevInitState.shouldDisplayAlert !== displayAlert && displayAlert) {
        return { ...initState, sendingRequest: false }
      }
      return { ...initState }
    })
  }, [language, displayAlert]);

  const updateDimensions = () => setInitState({ ...initState, width: window.innerWidth });

  const handleInputChange = (e) => {
    const { value } = e.target;
    const isValid = validateEmail(value);

    setInitState((prevInitState) => ({
      ...initState,
      isValid,
      error: (prevInitState.isValidating && !isValid) ? `${translate(TRANSLATE_KEYS.emailValidationError)}` : '',
    }));

    if (isValid) onGetInputValue(value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (!initState.isValid) {
      setInitState({
        ...initState,
        isValid: false,
        isValidating: true,
        error: `${translate(TRANSLATE_KEYS.emailValidationError)}`,
      });

      return;
    }
    setInitState((prevInitState) => ({
      ...prevInitState,
      ...INITIAL_STATE,
      sendingRequest: true,
    }));

    onAddEmail(email);
    e.target.children[0].value = '';
    setLanguage('');
  };

  return (
    <div className={styles.subscribe}>
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles['text-block']}>
            <h3 className={styles.title}>{translate(TRANSLATE_KEYS.wantToKeepUpToDateWithNews)}</h3>
            <p className={styles.description}>{translate(TRANSLATE_KEYS.subscribeToOurNewsletter)}</p>
          </div>
          <div className={styles.alert} style={{ visibility: displayAlert ? 'visible' : 'hidden' }}>
            <button>
              <i />
              <p>{translate(TRANSLATE_KEYS.messageSent)}</p>
            </button>
          </div>
          <form className={styles['subscribe-form']} noValidate onSubmit={submitForm}>
            <input
              type="email"
              name="email"
              className={inputEmailClassName}
              placeholder={inputEmailPlaceholder}
              onChange={handleInputChange}
              required
            />
            <button
              className={buttonSendEmailClassName}
            >
              {initState.sendingRequest
                ? <div className={styles.dotsLoader}>{dotsLoader()}</div>
                : <span>&#xe904;</span>
              }
            </button>
            <small className={styles.error}>{initState.error}</small>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(SignupForNewsComponent);
