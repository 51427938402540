import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './NoFriendsBlock.module.scss';
import noFriendImage from '../../../../assets/images/no-friend.svg';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const NoFriendsBlock = ({ translate, petsAllList }) => (
  <div className={styles.noFriends}>
    <img width="224" height="224" src={noFriendImage} alt="Still no one friend" />
    <h1 className={styles['noFriends-title']}>{translate(TRANSLATE_KEYS.noFriends)}</h1>
    <h3 className={styles['noFriends-subtitle']}>
      {petsAllList.length === 0
      ? translate(TRANSLATE_KEYS.noFriendsTitle)
      : translate(TRANSLATE_KEYS.noFriendsTitleFilter)}
    </h3>
  </div>
);

NoFriendsBlock.propTypes = {
  translate: PropTypes.func.isRequired,
  petsAllList: PropTypes.arrayOf.isRequired,
};

export default NoFriendsBlock;
