import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import Card from '../../../wantAFriendUserPage/components/cardsGrid/Card';
import styles from './wantAFriend.module.scss';
import button from '../../../shared-ui/button/button.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { Dog } from '../../../../types/dog.model';
import { WaFWidgetProps } from './wafProps.interface';

const WantAFriend = ({ dogs, history }: WaFWidgetProps) => {
    const { t: translate } = useTranslation();
    return (
        dogs.length
            ? (
              <div className={styles.box}>
                <div className={styles.pets}>
                  <div className={styles['title-row']}>
                    <h1 className={styles.title}>{translate(TRANSLATE_KEYS.wantAFriend)}</h1>
                    <Link
                      to={{ pathname: '/want-a-friend', state: { fromNav: true } }}
                      className={`${button.button} ${button.sun} ${styles.link} ${styles['tablet-hidden']}`}
                    >
                      {translate(TRANSLATE_KEYS.viewAll)}
                    </Link>
                  </div>
                  <div className={styles.cards}>
                    {dogs.map((dog: Dog) => {
                      const cardProps = {
                        petData: dog,
                        key: dog.id,
                        translate,
                        history,
                      }
                      return (
                        <Card
                          {...cardProps}
                        />
                      )
                    })}
                  </div>
                  <Link
                    to={{ pathname: '/want-a-friend', state: { fromNav: true } }}
                    className={`${button.button} ${button.sun} ${styles.link} ${styles['desktop-hidden']}`}
                  >
                    {translate(TRANSLATE_KEYS.viewAll)}
                  </Link>
                </div>
              </div>
            ) : null
    );
};

export default withTranslation('translations')(WantAFriend);
