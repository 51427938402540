import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import requestWithHeaders from '../../../../utils/withHeaders';
import { SignUpForNewsActionTypes } from './signUpForNewsActions.models';
import { URL_SEND_EMAIL_SEND_PULSE } from '../../../../endpoints';

export const getInputValue = (value: string) => ({
  type: SignUpForNewsActionTypes.GetInputValue,
  payload: value,
});

export const sendEmailStart = () => ({ type: SignUpForNewsActionTypes.SendEmailStart });

export const sendEmailError = (error: string) => ({
  type: SignUpForNewsActionTypes.SendEmailError,
  payload: error,
});

export const sendEmailSuccess = (email: string) => ({
  type: SignUpForNewsActionTypes.SendEmailSuccess,
  payload: email,
});

export const displayAlert = () => ({ type: SignUpForNewsActionTypes.DisplayAlert });

export const addEmail = (email: string) => (dispatch: Dispatch) => {
  dispatch(sendEmailStart());
  requestWithHeaders({
    method: 'POST',
    url: URL_SEND_EMAIL_SEND_PULSE,
    data: { email },
  })
    .then((response: AxiosResponse<string>) => {
      dispatch(sendEmailSuccess(response.data));
      const interval = setInterval(() => {
        dispatch(displayAlert());
        clearInterval(interval);
      }, 1500);
    })
    .catch((error: AxiosError) => dispatch(sendEmailError(error.message)));
};
