import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WantAFriendPage from './WantAFriendUserPageContainer';
import WantDogsComponent from './components/dogDetailsPage/userDogDetailsContainer';

export default function WantAFriendComponent() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/want-a-friend"
          component={WantAFriendPage}
        />
        <Route
          exact
          path="/want-a-friend/dog/:id"
          component={WantDogsComponent}
        />
        <Route
          path="/want-a-friend/*"
          render={() => <Redirect to={{ pathname: '/404-not-found', state: { from: window.location.pathname } }} />}
        />
      </Switch>
    </>
  );
}
