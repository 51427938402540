import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import axios from 'axios'
import i18n from './i18n'
import store from './+store/store'
import history from './+store/history'
import './index.module.scss'
import App from './app'
import ScrollToTop from './app/shared-ui/scroll-top'
import { StatusCodes } from './models/common.models'
import handleLogout from './utils/handleLogout';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === StatusCodes.Unauthorized) {
      handleLogout();
      history.push('/admin')
    }

    throw error
  },
)

render(
  <CookiesProvider>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <Provider store={store}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Provider>
      </Router>
    </I18nextProvider>
  </CookiesProvider>,
  document.getElementById('root'),
)
