import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ArticleName from './articleName';
import ArticleImage from './articleImage';
import ArticleCategorySelect from './articleCategorySelect';
import ArticleDescription from './articleDescription';
import style from './styles/create-article.module.scss';
import DeleteButton from '../../../articleButtons/deleteButton';
import { AdminBlogPopupActions } from '../../+store/actions';
import { LanguageData } from '../../../../../../../../models/language.model';

interface CreateArticleProps {
  editing: boolean,
  createdEditing: boolean,
  mainImgLink: string,
  isMain: boolean;
  language: LanguageData;
  onChange: (image: Blob) => void;
  deleteImageLink: () => void;
  setMainImgLink: (imgLink: string) => void;
}

const CreateArticle: FC<CreateArticleProps> = ({
  editing,
  createdEditing,
  mainImgLink,
  isMain,
  language,
  onChange,
  deleteImageLink,
  setMainImgLink,
}) => {
  const [image, setImage] = useState(mainImgLink);

  useEffect(() => {
    if (editing) { setMainImgLink(mainImgLink) }
  }, []);

  const handleMainImg = (mainImg: Blob) => {
    onChange(mainImg);
  };

  const handleDeleteImg = () => {
    deleteImageLink();
    setImage(null);
  };

  return (
    <div className={style['article-form']}>
      <ArticleName language={language} />
      <ArticleCategorySelect />
      {isMain && <ArticleDescription language={language} />}
      {(editing || createdEditing) && image ? (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <DeleteButton
            style={{ position: 'absolute', top: '15px', right: '15px' }}
            handleDeleteImg={handleDeleteImg}
          />
          <img className={style['article-form-image-exist']} src={image} alt="main" />
        </div>
      ) : (
        <ArticleImage onImgUpload={handleMainImg} />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  createdEditing: state.articlesPageReducer.createdEditing,
  editing: state.articlesPageReducer.editing,
  mainImgLink: state.articlesPageReducer.article.image || state.adminArticleCreateFormReducer.mainImgLink,
  isMain: state.adminArticleCreateFormReducer.isMain,
});

const mapDispatchToProps = dispatch => ({
  deleteImageLink: () => dispatch(AdminBlogPopupActions.postArticleImageDelete()),
  setMainImgLink: (url: string) => dispatch(AdminBlogPopupActions.postArticleImagePostSuccess(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle);
