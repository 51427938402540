import axios from 'axios';

export const REQUEST_ALBUMS_BEGIN = 'REQUEST_ALBUMS_BEGIN';
export const REQUEST_ALBUMS_SUCCESS = 'REQUEST_ALBUMS_SUCCESS';
export const REQUEST_ALBUMS_FAILURE = 'REQUEST_ALBUMS_FAILURE';
export const OPEN_GALLERY_MODAL = 'OPEN_GALLERY_MODAL';
export const CLOSE_GALLERY_MODAL = 'CLOSE_GALLERY_MODAL';
export const SELECT_ACTIVE_PICTURE = 'SELECT_ACTIVE_PICTURE';
export const SELECT_ACTIVE_ALBUM = 'SELECT_ACTIVE_ALBUM';

export const requestAlbumsBegin = () => ({ type: REQUEST_ALBUMS_BEGIN });

export const requestAlbumsSuccess = albums => ({
  type: REQUEST_ALBUMS_SUCCESS,
  payload: { albums },
});

export const requestAlbumsFailure = error => ({
  type: REQUEST_ALBUMS_FAILURE,
  payload: { error },
});

const tags = ['cats', 'dogwalking', 'walking', 'accomodation', 'puppies', 'pastime'];
const cloudDomain = 'https://res.cloudinary.com';
const cloud = 'doghotelua';

// @return array of urls of json files which has pack of image links
function getUrlArray() {
  return tags.map(tag => ({
    tag: `${tag}`,
    url: `${cloudDomain}/${cloud}/image/list/${tag}.json`,
  }));
}

function getImages(resourcesArray) {
  return resourcesArray.map(({ version, public_id: id, format }) => ({
    image: `${cloudDomain}/${cloud}/image/upload/q_70/v${version}/${id}.${format}`,
    description: 'test',
  }));
}

export const requestAlbums = () => (dispatch) => {
  dispatch(requestAlbumsBegin());
  const tagImagesArray = [];
  const promiseArray = [];
  const tagsAndUrls = getUrlArray();
  tagsAndUrls.forEach((tagUrl) => {
    promiseArray.push(axios.get(tagUrl.url));
  });
  Promise.allSettled(promiseArray)
    .then((results) => {
      results.sort((a, b) => Date.parse(b.value.data.updated_at) - Date.parse(a.value.data.updated_at))
        .forEach(({ value }, index) => {
          const newAlbum = {
            id: index,
            albumName: '',
            images: getImages(value.data.resources),
          };
          const item = value.data.resources
            .find(object => object.context && object.context.custom);
          newAlbum.albumName = item ? item.context.custom : tagsAndUrls[index].tag;
          tagImagesArray.push(newAlbum);
        });
      dispatch(requestAlbumsSuccess(tagImagesArray));
    })
    .catch((error) => {
      dispatch(requestAlbumsFailure(error));
    });
};

export const openGalleryModal = albumId => ({
  type: OPEN_GALLERY_MODAL,
  payload: albumId,
});

export const closeGalleryModal = () => ({ type: CLOSE_GALLERY_MODAL });

export const selectActiveAlbum = albumId => ({
  type: SELECT_ACTIVE_ALBUM,
  payload: albumId,
});

export const selectActivePicture = pictureId => ({
  type: SELECT_ACTIVE_PICTURE,
  payload: pictureId,
});
