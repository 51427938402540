import { ArticleStatusIds, BlogPageModels } from '../models/blogPage.models';

const STATUSES = [
  {
    id: 1,
    name: 'PUBLISHED',
  },
  {
    id: 2,
    name: 'NOT_PUBLISHED',
  },
  {
    id: 3,
    name: 'HAS_CHANGES',
  },
];

const getStatusIdFromName = (
  name: string,
): ArticleStatusIds | undefined => STATUSES.find(status => status.name === name)?.id;

const getArticleFieldValue = (
  dtoSet: BlogPageModels.ArticleData[],
  language: string,
): BlogPageModels.ArticleData => dtoSet.find(item => item.language.languageValue === language);

/**
 * A mapping function to transform articleDto-s containing data for a single language
 * to be used with the original data structure of the front end.
 * @param {object} articleDto - The articleDto to be transformed.
 */
export const transformArticleDto = (articleDto: BlogPageModels.Article): BlogPageModels.Article => ({
  ...articleDto,
  title: articleDto.titleDtoSet[0].titleValue,
  date: articleDto.dateTime,
  category: articleDto.categoryValueDtoSet[0].categoryValue,
  image: articleDto.mainImgLink,
  statusId: getStatusIdFromName(articleDto.articleStatusName),
  thesis: articleDto.thesisDtoSet[0] ? articleDto.thesisDtoSet[0].thesisValue : '',
});

/**
 * A mapping function to transform articleDto-s returning data for all three languages
 * to be used with the original data structure of the front end.
 * @param {Object} article - The articleDto to be transformed.
 * @param {string} languageValue - The language to filter data for: 'uk' or 'en'.
 */
export const transformAndFilterArticleDto = (article: BlogPageModels.Article, languageValue: string) => ({
  ...article,
  title: getArticleFieldValue(article.titleDtoSet, languageValue).titleValue,
  date: article.dateTime,
  category: getArticleFieldValue(article.categoryValueDtoSet, languageValue).categoryValue,
  image: article.mainImgLink,
  statusId: getStatusIdFromName(article.articleStatusName),
  thesis: getArticleFieldValue(article.thesisDtoSet, languageValue).thesisValue || '',
});
