import { connect } from 'react-redux';
import SignupForNewsComponent from './signupForNewsComponent';
import { getInputValue, addEmail } from './+store/actions';
import { RootStateType } from '../../../types/store.models';

const mapDispatchToProps = (dispatch) => ({
  onGetInputValue: (value: string) => dispatch(getInputValue(value)),
  onAddEmail: (email: string) => dispatch(addEmail(email)),
});

const mapStateToProps = (state: RootStateType) => ({
  email: state.emailReducer.email,
  displayAlert: state.emailReducer.displayAlert,
});

const SignupForNewsContainer = connect(mapStateToProps, mapDispatchToProps)(SignupForNewsComponent);

export default SignupForNewsContainer;
