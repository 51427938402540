import React, { FC } from 'react';
import TRANSLATE_KEYS from '../../../i18n_keys';
import getDogAgeTranslateKey from '../../../utils/getDogAgeTranslateKey';
import getDogSizeTranslateKey from '../../../utils/getDogSizeTranslateKey';
import { WAFModels } from '../../../models/WAFPage.models';
import { TranslateFn } from '../../../models/translate-function.models';

interface DescriptionDetailsProps {
  styles: Record<string, string>,
  translate: TranslateFn,
  petData: WAFModels.Pet,
}

const DescriptionDetails: FC<DescriptionDetailsProps> = ({ styles, translate, petData }) => {
  const { sex, age, size, description__details: descriptionDetails } = styles;

  return (
    <div className={descriptionDetails}>
      <span className={sex}>{translate(petData.male ? TRANSLATE_KEYS.male : TRANSLATE_KEYS.female)}</span>
      <span className={age}>{translate(getDogAgeTranslateKey(petData.birthDate))}</span>
      <span className={size}>{translate(getDogSizeTranslateKey(petData.sizeId))}</span>
    </div>
  );
};

export default DescriptionDetails;
