import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomeContainer from './app/home/HomeContainer';
import ServicesPageContainer from './app/prices/ServicesPageContainer';
import BlogContainer from './app/blog/BlogContainer';
import ArticleContainer from './app/article/ArticleContainer';
import AboutContainer from './app/about/AboutContainer';
import ContactsContainer from './app/contacts/ContactContainer';
import WantAFriendContainer from './app/wantAFriendUserPage/WantAFriendContainer';
import AdminPage from './app/admin/AdminComponent';
import Header from './app/shared-ui/header/Header';
import Footer from './app/shared-ui/footer/Footer';
import DescriptionMeta from './app/shared-ui/descriptionMeta/descriptionMeta';
import ErrorPage from './app/errorPages/ErrorPage';
import { StatusCodes } from './models/common.models';

const UserPage = () => (
  <>
    <Header />
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/home" component={HomeContainer} />
      <Route exact path="/prices" component={ServicesPageContainer} />

      <Route path="/about" component={AboutContainer} />
      <Route exact path="/blog/:id" component={BlogContainer} />
      <Route exact path="/article/:id" component={ArticleContainer} />
      <Route exact path="/contacts" component={ContactsContainer} />
      <Route path="/want-a-friend" component={WantAFriendContainer} />

      <Route
        path="*"
        render={() => (
          <Redirect to={{
            pathname: '/404-not-found',
            state: { from: window.location.pathname },
          }}
          />
        )}
      />
    </Switch>
    <Footer />
  </>
)

const App = () => (
  <>
    <DescriptionMeta />
    <Switch>
      <Route path="/admin" component={AdminPage} />
      <Route path="/404-not-found" render={() => <ErrorPage statusCode={StatusCodes.NotFound} />} />
      <Route path="/" component={UserPage} />
    </Switch>
  </>
);

export default App;
