import React, { FC } from 'react';
import styles from './dogPageGreeting.module.scss';
import dogPageTranslations from '../dogPageTranslations';

const DogPageGreeting: FC<{name: string, activeLanguage: string}> = ({ name, activeLanguage }) => (
  <div className={styles.titleBlock}>
    <h4 className={styles.heading}>{dogPageTranslations[activeLanguage].welcomeWantsFriend} {name}!</h4>
  </div>
);

export default DogPageGreeting;
