/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <title>Layer 1</title>
      <g fill="none" id="Page-1">
        <g id="[3.0][D]-Create-an-article-–-Empty">
          <g id="Navigation-Navigation-/-Admin-/-Article">
            <g fill="currentColor" id="Icon-ic_back">
              <path fill="#bfbfbf" id="Combined-Shape" d="m8.48528,3.70711c0.39053,-0.39053 1.02369,-0.39053 1.41421,0c0.39053,0.39052 0.39053,1.02369 0,1.41421l0,0l-5.87828,5.87789l18.97879,0.00079c0.55228,0 1,0.44772 1,1c0,0.55228 -0.44772,1 -1,1l-19.36479,-0.00079l6.26428,6.26425c0.36049,0.36048 0.38822,0.92771 0.08319,1.32l-0.08319,0.09421c-0.39052,0.39052 -1.02368,0.39052 -1.41421,0l0,0l-7.77817,-7.77818c-0.39053,-0.39052 -0.39053,-1.02368 0,-1.41421l0,0l7.77817,-7.77817z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
