import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../header.module.scss';
import HeaderTitle from '../headerTitle/HeaderTitle';
import Navigation from '../navigation/Navigation';
import ProfileMenu from '../profileMenu/ProfileMenu';
import useScroll from '../../../../../../hooks/useScroll';
import store from '../../../../../../+store/store';
import browserStorage from '../../../../../../utils/localStorage';
import RecordsCount from '../../../../../shared-ui/adminListHeader/RecordsCount';
import Icon from '../../../../../shared-ui/icon';
import BurgerMenu from '../burgerMenu/BurgerMenu';
import { HeaderContentProps } from './HeaderContent.models';

const HeaderContent = ({
  locationPath,
  collapseY,
}: HeaderContentProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileMenuOpened, toggleMobileMenuOpened] = useState(false);
  const { scrollY } = useScroll();

  const [path, setPath] = useState(window.location.pathname);
  const listenToPathState = () => {
    const windowPath = window.location.pathname;
    setPath(windowPath);
  };

  useEffect(() => {
    listenToPathState();
  });

  // HARDCODED INCORRECT LINK IN DATABASE
  const INIT_URL = 'https://res-console.cloudinary.com/doghotelua/'
    + 'thumbnails/v1/image/upload/v1591744442/cGhvdG9nYWxsZXJ5L9CR0LD'
    + 'RgNC60LvQuF9zc3FxZmU=/preview';

  const PLACEHOLDER_URL = 'https://cdn.pixabay.com/photo/2016/12/13/05/15/puppy-1903313__340.jpg';

  const state = store.getState();
  let { imageLink } = state.adminProfileReducer.userData;

  if (imageLink === INIT_URL) {
    imageLink = PLACEHOLDER_URL;
  }

  if (localStorage.imageLink === 'undefined' || !localStorage.imageLink) {
    browserStorage.setKeyValue('imageLink', imageLink);
  } else {
    imageLink = browserStorage.getKeyValue('imageLink');
  }

  const handleWindowWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleMobileMenuOpened = () => {
    toggleMobileMenuOpened(!isMobileMenuOpened);
  };

  const menuClose = () => {
    handleMobileMenuOpened();
  };

  const updateDimensions = () => {
    handleWindowWidth();
    const desktopContainer = 960;
    if (width > desktopContainer && isMobileMenuOpened) menuClose();
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions, { once: true });

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [width]);

  return (
    <>
      <div className={styles.wrapper}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => toggleMobileMenuOpened(false)}
          className={styles.logo}
        >
          {path === '/admin/blog'
            ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              // @ts-ignore
              <Link>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                  className={styles['icon-placeholder']}
                  onClick={() => window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })}
                >
                  <Icon name="app-logo" />
                </div>
              </Link>
            ) : (
              <Link to="/admin/blog">
                <div className={styles['icon-placeholder']}>
                  <Icon name="app-logo" />
                </div>
              </Link>
            )
          }
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {/* @ts-ignore */}
          <Link>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div onClick={() => window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })}
            >
              { (scrollY < collapseY || locationPath === '/admin/profile')
                ? <Link to="/admin/blog"><HeaderTitle /></Link>
                : <RecordsCount small />}
            </div>
          </Link>
        </div>
        <div className={styles.navigationWrapper}>
          <Navigation />
        </div>
        <div className={styles.profileMenuWrapper}>
          <ProfileMenu
            imgUrl={imageLink}
            isActive={locationPath === '/admin/profile'}
            collapseY={collapseY}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={handleMobileMenuOpened}
          className={
            isMobileMenuOpened
              ? `${styles['menu-icon_close']} ${styles.icon}`
              : `${styles['menu-icon']} ${styles.icon}`
          }
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={handleMobileMenuOpened}
        className={
          isMobileMenuOpened
            ? `${styles.burgerMenuWrapper} ${styles.isVisible}`
            : `${styles.burgerMenuWrapper}`
          }
      >
        <BurgerMenu
          imageLink={imageLink}
          isActive={locationPath === '/admin/profile'}
          collapseY={collapseY}
        />
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={handleMobileMenuOpened}
          className={
            isMobileMenuOpened
              ? `${styles.burgerMenuOverlay} ${styles.isVisible}`
              : `${styles.burgerMenuOverlay}`
          }
        />
      </div>
    </>

  );
};

HeaderContent.defaultProps = { collapseY: 0 };

export default HeaderContent;
