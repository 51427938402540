const parseImageFilenameFromURL = (URL: string) => {
    const imageFilenameRegexp = /[\w-]+\/[\w-]+\.(jpg|jpeg|png|gif)/g;
    const matches = URL.match(imageFilenameRegexp);
    if (matches.length) {
        const filename = matches[0].split('.')[0];
        return filename;
    }

    return null;
}

export default parseImageFilenameFromURL;
