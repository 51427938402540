/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import { LanguageData } from '../../../../../../../../models/language.model';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import Icon from '../../../../../../../shared-ui/icon/index';
import Tooltip from '../../../../../../../shared-ui/tooltip/tooltip';
import style from './styles/article-category-select.module.scss';

interface ArticleCategoryItemProps {
  categoryData: BlogPageModels.Category;
  language: LanguageData;
  deleteCategoryUI: (id: number) => void;
  editCategory: (category: BlogPageModels.Category) => void;
  onClick: (categoryData: BlogPageModels.Category) => void;
  t: TranslateFn;
}

const ArticleCategoryItem: FC<ArticleCategoryItemProps> = ({
  categoryData,
  language,
  editCategory,
  deleteCategoryUI,
  onClick,
  t: translate,
}) => (
  <li
    className={style['category-list']}
    key={categoryData.id}
  >
    <button
      className={style['select-button']}
      onClick={() => onClick(categoryData)}
      data-title={categoryData.name[language.languageId]}
    >
      {categoryData.name[language.languageId]}
    </button>
    <div className={style['right-button-wrapper']}>
      {!categoryData.canBeDeleted
        ? (
          <button
            className={`${style.tooltip} ${style.blocked}`}
          >
            <Tooltip
              content={translate(TRANSLATE_KEYS.canNotBeDeleted)}
              positionClassName="top-right"
              isMultiline
            >
              <Icon name="trash-blocked" />
            </Tooltip>
          </button>
        ) : (
          <button
            className={style.delete}
            onClick={() => deleteCategoryUI(categoryData.id)}
          >
            <Icon name="trash" />
          </button>
        )}
      <button
        className={style.edit}
        onClick={() => editCategory(categoryData)}
      >
        <Icon name="edit" />
      </button>
    </div>
  </li>
);

export default withTranslation('translations')(ArticleCategoryItem);
