import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardDogHistory.module.scss';
import dogPageTranslations from '../userDogPageTranslations';

const cardDogHistory = (props) => {
  const { description, activeLanguage } = props;

  return (
    <div className={styles.storyBlock}>
      <h2 className={styles.storyTitle}>{dogPageTranslations[activeLanguage].myHistory}</h2>
      <p className={styles.storyText}>{description}</p>
    </div>
  );
};


cardDogHistory.propTypes = {
  description: PropTypes.string.isRequired,
  activeLanguage: PropTypes.string.isRequired,
};


export default cardDogHistory;
