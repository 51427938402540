import { WAFModels } from '../../../../../models/WAFPage.models';
import { Dog } from '../../../../../types/dog.model';

const petFieldMap = {
  name: {
    petFieldName: 'dogNameDtoSet',
    itemValue: 'name',
  },
  sizeValue: {
    petFieldName: 'dogSizeValueDtoSet',
    itemValue: 'sizeValue',
  },
  description: {
    petFieldName: 'dogDescriptionDtoSet',
    itemValue: 'description',
  },
};

export const getPetInfo = (
  pet: WAFModels.Pet | Dog,
  petField: {petFieldName: string, itemValue: string},
): WAFModels.PetInfo => pet[petField.petFieldName].reduce((res, item) => {
  const result = { ...res };
  if (item.language) {
    const language = item.language.languageValue;
    result[language] = item[petField.itemValue];
  }
  return result;
}, { uk: null, en: null });

export const sortPetsByDateCreated = (petsList: WAFModels.Pet[]) => petsList.sort(
  (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
);

export const getPetName = (pet: WAFModels.Pet | Dog) => getPetInfo(pet, petFieldMap.name);
export const getPetTitle = (pet: WAFModels.Pet) => getPetInfo(pet, petFieldMap.sizeValue);
export const getPetDescription = (pet: WAFModels.Pet) => getPetInfo(pet, petFieldMap.description);
