import { Article } from '../../../types/article.model';
import { Dog } from '../../../types/dog.model';
import { ActionPayloadContent } from '../../../types/action.interface';

export namespace HomeComponentActions {
  export const fetchStartType = 'REQUEST_START';
  export const fetchErrorType = 'REQUEST_ERROR';
  export const fetchArticlesType = 'FETCH_ARTICLES';
  export const fetchDogsType = 'FETCH_DOGS';
  export const fetchStart = () => ({ type: fetchStartType })
  export const fetchError = (error: Error) => ({
    type: fetchErrorType,
    payload: error,
  })
  export const fetchArticlesSuccess = (articles: ActionPayloadContent<Article[]>) => ({
    type: fetchArticlesType,
    payload: articles,
  });
  export const fetchDogsSuccess = (dogs: Dog[]) => ({
    type: fetchDogsType,
    payload: dogs,
  })
}
