import { useLayoutEffect, useState } from 'react';

const DEFAULT_HEADER_HEIGHT = 65;

const useScroll = () => {
  const [scrollY, setScrollY] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = () => setScrollY(document.documentElement.scrollTop);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  return {
    scrollY,
    headerHeight: DEFAULT_HEADER_HEIGHT,
  }
};

export default useScroll;
