import React, { FC, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AdminBlogActions } from '../../+store/actions';
import styles from '../articleList/articleList.module.scss';
import Icon from '../../../../../shared-ui/icon';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

interface SettingsButtonProps {
  id: number,
  size?: number,
  position?: { bottom: string };
  onEditPost: (id: number) => void;
  t: TranslateFn;
}

const SettingsButton: FC<SettingsButtonProps> = ({
  id,
  size,
  position,
  onEditPost,
  t: translate,
}) => {
  const displayModalWindow = (e: MouseEvent) => {
    e.preventDefault();
    onEditPost(id);
  };

  return (
    <button
      className={styles['article-button--settings']}
      style={{ width: `${size}px`, height: `${size}px` }}
      onClick={e => displayModalWindow(e)}
    >
      <Icon name="settings" />
      <span className={styles['tooltip-text']} style={position}>{translate(TRANSLATE_KEYS.editBtn)}</span>
    </button>
  );
};

SettingsButton.defaultProps = { size: 48, position: { bottom: '63px' } };

const mapDispatchToProps = dispatch => ({ onEditPost: (id: number) => dispatch(AdminBlogActions.editPost(id)) });

export default withTranslation('translations')(connect(null, mapDispatchToProps)(SettingsButton));
