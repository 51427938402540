import React, { FC, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import Promo from './components/promo/Promo';
import Tabs from './components/tabs/Tabs';
import DogTab from './components/dog-tab/DogTab';
import AdditionalTab from './components/additional-tab/AdditionalTab';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';
import TRANSLATE_KEYS from '../../i18n_keys';
import { ServicesPageModels } from './models/servicesPage.models';

const ServicesPageComponent: FC = () => {
  const location = useLocation<ServicesPageModels.Location>();
  const [translate] = useTranslation();
  const tabsRef = useRef();

  return (
    <>
      <Promo tabsRef={tabsRef} />
      <div ref={tabsRef}>
        <Tabs
          selectedTab={location?.state?.selectedTab ?? 0}
          tabsLabels={[
            { label: `${translate(TRANSLATE_KEYS.forDogs)}` },
            {
              label: `${translate(TRANSLATE_KEYS.additionalServices)}`,
              smallLabel: `${translate(TRANSLATE_KEYS.additionalServicesSmall)}`,
            }]}
          contentPanels={[
            <DogTab />,
            <AdditionalTab />,
          ]}
        />
      </div>
      <SignupForNewsContainer />
    </>
  );
};

export default withTranslation('translations')(ServicesPageComponent);
