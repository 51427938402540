import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import style from '../../popup.module.scss';
import createArticleStyle from './styles/create-article.module.scss';
import { AdminBlogPopupActions } from '../../+store/actions';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { AVAILABLE_LANGUAGES } from '../../../../../../../language/+store/languages';
import { handleAutocomplete } from './helpers';
import { LanguageData, Languages } from '../../../../../../../../models/language.model';
import { getArticleTitle } from '../../../../+store/helpers';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { CommonModels } from '../../../../../../../../models/common.models';

const TITLE_MAXIMUM_CHARACTER_LENGTH = 52;

interface ArticleNameProps {
  validationError: string;
  language: LanguageData;
  articleTitle: CommonModels.RecordInfo;
  articleTitleEdit: CommonModels.RecordInfo;
  editing: boolean;
  changeTitle: (language: string, title: string) => void;
  t: TranslateFn;
}

const ArticleName: FC<ArticleNameProps> = ({
  validationError,
  language,
  articleTitle,
  articleTitleEdit,
  editing,
  changeTitle,
  t: translate,
}) => {
  const [autocompleteLanguages, setAutocompleteLanguages] = useState<Languages[]>([]);

  useEffect(() => {
    AVAILABLE_LANGUAGES.forEach((lang) => {
      const articleTitleValue = editing ? articleTitleEdit[lang] : '';
      changeTitle(lang, articleTitleValue);
    });
  }, []);

  const titleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const notActiveLanguages = AVAILABLE_LANGUAGES.filter((lang) => language.languageValue !== lang);
    const title = event.target.value;

    if (title.length > TITLE_MAXIMUM_CHARACTER_LENGTH) return;

    const {
      isStateShouldBeUpdated,
      newAutocompleteLanguages,
      shouldUpdateNotActiveLanguages,
    } = handleAutocomplete(autocompleteLanguages, articleTitle, notActiveLanguages);

    if (shouldUpdateNotActiveLanguages) {
      notActiveLanguages.forEach((notActiveLanguage) => changeTitle(notActiveLanguage, title));
    }

    changeTitle(language.languageValue, title);

    if (isStateShouldBeUpdated) setAutocompleteLanguages([...newAutocompleteLanguages]);
  };

  const isInputAutocomplete = autocompleteLanguages
    .some((autocompleteLanguage) => autocompleteLanguage === language.languageValue);
  const autocompleteClass = isInputAutocomplete ? createArticleStyle.autocomplete : '';
  const warningClass = validationError && !articleTitle[language.languageValue]
    ? style['border-warning'] : '';

  return (
    <>
      <label className={createArticleStyle['article-form-name']} htmlFor="articleName">
        <input
          className={[createArticleStyle.input, autocompleteClass, warningClass].join(' ')}
          type="text"
          id="articleName"
          onChange={titleChange}
          value={articleTitle[language.languageValue]}
        />
        <span className={createArticleStyle.label}>{translate(TRANSLATE_KEYS.inputTitle)}</span>
        <span className={createArticleStyle.counter}>
          { articleTitle[language.languageValue]
            ? TITLE_MAXIMUM_CHARACTER_LENGTH - articleTitle[language.languageValue].length
            : TITLE_MAXIMUM_CHARACTER_LENGTH
          }
        </span>
      </label>
      {(validationError && !articleTitle[language.languageValue])
        && <span className={style['error-message']}>{validationError}</span>
      }
    </>
  );
}

const mapStateToProps = state => ({
  articleTitle: state.adminArticleCreateFormReducer.articleTitle,
  articleTitleEdit: getArticleTitle(state.articlesPageReducer.article),
  editing: state.articlesPageReducer.editing,
  validationError: state.adminArticleCreateFormReducer.articleValidationError,
});

const mapDispatchToProps = dispatch => ({
  changeTitle: (
    language: string,
    title: string,
  ) => dispatch(AdminBlogPopupActions.articleTitleChange(language, title)),
}
);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ArticleName));
