/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { ChangeEvent, FC, FocusEvent, MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Cookies, withCookies } from 'react-cookie';
import style from '../../popup.module.scss';
import articleCategorySelectStyle from './styles/article-category-select.module.scss';
import { AdminBlogPopupActions } from '../../+store/actions';
import ArticleCategoryDropdown from './articleCategoryDropdown';
import ArticleCategoryInput from './articleCategoryInput';
import ArticleCategoryEdit from './articleCategoryEdit';
import ArticleCategoryItem from './articleCategoryItem';
import { getCategoryInputClasses,
  getCategorySelectClasses } from './helpers';
import { transformCategoryDto } from '../../../../../../../../utils/transformCategoryDto';
import isCategoryExists from '../../../../../../../../utils/isCategoryExists';
import { LanguageData } from '../../../../../../../../models/language.model';
import { CommonModels } from '../../../../../../../../models/common.models';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

interface ArticleCategorySelectProps {
  validationError: string;
  maxCategoriesAmount: number;
  activeLanguage: LanguageData;
  articleTitle: CommonModels.RecordInfo;
  categories: BlogPageModels.Category[];
  cookies: Cookies;
  selectedCategory: BlogPageModels.Category;
  canBeDeleted: number[];
  categoryEdit: BlogPageModels.Category;
  categoryData: BlogPageModels.NewCategoryInputData;
  categoryInNameChange: BlogPageModels.Category;
  changeCategory: (category: BlogPageModels.Category) => void;
  editCategory: (cookies: Cookies) => void;
  handleEnterNewCategory: (categoryData: BlogPageModels.NewCategoryInputData) => void;
  handleCategoryNameChange: (categoryData: BlogPageModels.NewCategoryInputData) => void;
  createCategory: (cookies: Cookies) => void;
  removeCategory: (id: number, index: number, selectedCategory: BlogPageModels.Category, cookies: Cookies) => void;
  startCategoryEdit: (category: BlogPageModels.Category) => void;
  cancelCategoryEdit: () => void;
  t: TranslateFn;
}

const ArticleCategorySelect: FC<ArticleCategorySelectProps> = ({
  validationError,
  maxCategoriesAmount,
  activeLanguage,
  articleTitle,
  categories,
  cookies,
  selectedCategory,
  canBeDeleted,
  categoryEdit,
  categoryData,
  categoryInNameChange,
  changeCategory,
  editCategory,
  handleEnterNewCategory,
  handleCategoryNameChange,
  createCategory,
  removeCategory,
  startCategoryEdit,
  cancelCategoryEdit,
  t: translate,
}) => {
  // @TODO should update the name after editing the category
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [category, setCategory] = useState<BlogPageModels.Category>(null);
  const [isMaxCategoriesAmountValidationError, setIsMaxCategoriesAmountValidationError] = useState(false);
  const [isCategoryExistsValidationError, setIsCategoryExistsValidationError] = useState(false);
  const [isCategoryEnteredValidationError, setIsCategoryEnteredValidationError] = useState(false);
  const [isCategoryExistsWhileEditing, setIsCategoryExistsWhileEditing] = useState(false);

  useEffect(() => {
    setDisplayDropdown(false);
    setCategory(categoryEdit || selectedCategory);
    changeCategory(categoryEdit || selectedCategory);
  }, []);

  useEffect(() => {
    setCategory(selectedCategory);
  }, [selectedCategory]);

  const handleCategorySelect = (selectedCategoryData: BlogPageModels.Category) => {
    setDisplayDropdown(false);
    setCategory(selectedCategoryData);

    changeCategory(selectedCategoryData);
  };

  const onFocusDropdown = (event: FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDisplayDropdown(true);
  };

  const setCloseDropdown = (event: FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const listeningClick = (element) => {
      if (element.target !== event.target) setDisplayDropdown(false);
    };
    window.addEventListener('click', listeningClick);
    setTimeout(() => {
      window.removeEventListener('click', listeningClick);
    }, 100);
    event.persist();
  };

  const onNewCategoryInputSubmit = (e: MouseEvent) => {
    e.stopPropagation();

    const categoryName = categoryData ? categoryData.name[activeLanguage.languageId].replace(/\s+/g, ' ').trim() : '';

    if (categories.length === maxCategoriesAmount) {
      setIsMaxCategoriesAmountValidationError(true);
    } else if (isCategoryExists(categoryName, categories)) {
      setIsCategoryExistsValidationError(true);
    } else if (!categoryName.length) {
      setIsCategoryEnteredValidationError(true);
    } else {
      createCategory(cookies);
    }
  };

  const onCategoryNameSubmit = () => {
    editCategory(cookies);
  };

  const resetValidationForEditing = () => {
    setIsCategoryExistsWhileEditing(false);
  };

  const onNewCategoryInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsMaxCategoriesAmountValidationError(false);
    setIsCategoryExistsValidationError(false);
    setIsCategoryEnteredValidationError(false);

    const newCategoryData = {
      id: -1,
      name: {},
    };
    newCategoryData.name[activeLanguage.languageId] = e.target.value;
    handleEnterNewCategory(newCategoryData);
  };

  const onEditCategoryInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newIsCategoryExists = isCategoryExists(e.target.value, categories)

    setIsMaxCategoriesAmountValidationError(false);
    setIsCategoryExistsValidationError(false);
    setIsCategoryEnteredValidationError(false);
    setIsCategoryExistsWhileEditing(newIsCategoryExists);
    const newCategoryData = {
      id: categoryInNameChange.id,
      name: {},
      ids: {},
    };
    newCategoryData.name[activeLanguage.languageId] = e.target.value;
    newCategoryData.ids[activeLanguage.languageId] = categoryInNameChange.ids[activeLanguage.languageId];
    handleCategoryNameChange(newCategoryData);
  };

  const deleteCategoryUI = (id: number) => {
    let index;
    let selectedCategoryAfterDeleting;
    categories.forEach((option, currentIndex) => {
      option.id === id && (index = currentIndex);
    });

    if (selectedCategory && selectedCategory.id === id) {
      selectedCategoryAfterDeleting = null;
    } else {
      selectedCategoryAfterDeleting = selectedCategory;
    }
    removeCategory(id, index, selectedCategoryAfterDeleting, cookies);
  };

  const filterCategories = (
    categoriesForRender: BlogPageModels.Category[],
  ) => categoriesForRender.map(item => (canBeDeleted.includes(item.id) ? { ...item, canBeDeleted: true } : item));

  const categoryName = categoryData ? categoryData.name[activeLanguage.languageId] : '';

  const getTooltipText = () => {
    if (isMaxCategoriesAmountValidationError) {
      return translate(TRANSLATE_KEYS.maxCategoriesAmount);
    } if (isCategoryExistsValidationError) {
      return translate(TRANSLATE_KEYS.categoryExists);
    } if (isCategoryEnteredValidationError) {
      return translate(TRANSLATE_KEYS.enterCategoryName);
    }
    return null;
  }

  const getTooltipTextForEditing = () => {
    if (isCategoryExistsWhileEditing) {
      return translate(TRANSLATE_KEYS.categoryExists);
    }
    return null;
  }

  const onEditButtonClick = (categoryValue: BlogPageModels.Category) => {
    startCategoryEdit(categoryValue);
  };

  const onCancelButtonClick = () => {
    resetValidationForEditing();
    cancelCategoryEdit();
  };

  return (
    <>
      <div className={getCategorySelectClasses(displayDropdown)}>
        <ArticleCategoryDropdown
          activeLanguage={activeLanguage}
          categoryData={category}
          onClick={() => setDisplayDropdown(!displayDropdown)}
        />
        { displayDropdown && (
          <ul
            className={articleCategorySelectStyle['dropdown-content']}
            onBlur={e => setCloseDropdown(e)}
            onFocus={e => onFocusDropdown(e)}
          >
            <ArticleCategoryInput
              className={getCategoryInputClasses(validationError, style)}
              content={getTooltipText()}
              onChange={onNewCategoryInputChange}
              onInputSubmit={e => onNewCategoryInputSubmit(e)}
              value={categoryName}
            />
            {filterCategories(categories).map(item => ((categoryInNameChange && item.id === categoryInNameChange.id)
              ? (
                <ArticleCategoryEdit
                  className={getCategoryInputClasses(validationError, style)}
                  key={item.id}
                  onChange={onEditCategoryInputChange}
                  onCancel={onCancelButtonClick}
                  onSubmit={onCategoryNameSubmit}
                  value={categoryInNameChange.name[activeLanguage.languageId]}
                  errorText={getTooltipTextForEditing()}
                />
              )
              : (
                <ArticleCategoryItem
                  categoryData={item}
                  deleteCategoryUI={deleteCategoryUI}
                  editCategory={onEditButtonClick}
                  key={item.id}
                  language={activeLanguage}
                  onClick={handleCategorySelect}
                />
              )),
            )}
          </ul>
        )}
      </div>
      {(validationError && !selectedCategory && articleTitle) && (
        <span className={style['error-message']}>{validationError}</span>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const categoryCreatedGetValue = state.articlesPageReducer.createdEditing
    ? state.adminArticleCreateFormReducer.selectCategory
    : null;
  return {
    activeLanguage: state.adminArticleCreateFormReducer.activeLanguage,
    articleTitle: state.adminArticleCreateFormReducer.articleTitle,
    canBeDeleted: state.adminArticleCreateFormReducer.canBeDeleted,
    categories: state.adminArticleCreateFormReducer.categories,
    categoryData: state.adminArticleCreateFormReducer.inputCategory,
    categoryInNameChange: state.adminArticleCreateFormReducer.categoryInNameChange,
    categoryEdit: state.articlesPageReducer.editing
      ? transformCategoryDto(state.articlesPageReducer.article)
      : categoryCreatedGetValue,
    error: state.adminArticleCreateFormReducer.error,
    maxCategoriesAmount: state.adminArticleCreateFormReducer.maxCategoriesAmount,
    selectedCategory: state.adminArticleCreateFormReducer.selectedCategory,
    validationError: state.adminArticleCreateFormReducer.categoryValidationError,
  };
};

const mapDispatchToProps = dispatch => ({
  cancelCategoryEdit: () => dispatch(AdminBlogPopupActions.editCategoryCancel()),
  changeCategory: (category: BlogPageModels.Category) => dispatch(AdminBlogPopupActions.selectCategory(category)),
  createCategory: (cookies: Cookies) => dispatch(AdminBlogPopupActions.postCategory(cookies)),
  editCategory: (cookies: Cookies) => dispatch(AdminBlogPopupActions.updateCategory(cookies)),
  handleEnterNewCategory: (
    categoryData: BlogPageModels.NewCategoryInputData,
  ) => dispatch(AdminBlogPopupActions.enterNewCategory(categoryData)),
  handleCategoryNameChange: (
    categoryData: BlogPageModels.Category,
  ) => dispatch(AdminBlogPopupActions.categoryNameChange(categoryData)),
  removeCategory: (
    id: number,
    index: number,
    selectedCategory: BlogPageModels.Category,
    cookies: Cookies,
  ) => dispatch(AdminBlogPopupActions.deleteCategory(id, index, selectedCategory, cookies)),
  startCategoryEdit: (category: BlogPageModels.Category) => dispatch(AdminBlogPopupActions.editCategoryStart(category)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(ArticleCategorySelect)),
);
