import React, { Component } from 'react';
import Swipe from 'react-easy-swipe';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slide from './Slide';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import PersonImage from './PersonImage';
import styles from './slider.module.scss';
import mockUsers from './mock-users';
import TRANSLATE_KEYS from '../../../i18n_keys';

class Slider extends Component {
  static changeActiveSlide = (slides, currentIndex) => (
    slides.map((el, index) => {
      if (el.isActive) {
        return Object.assign(el, { isActive: false });
      }
      if (index === currentIndex) {
        return Object.assign(el, { isActive: true });
      }
      return el;
    }));

  state = {
    slides: mockUsers,
    currentIndex: 0,
  };

  static onSwipeMove(position) {
    Slider.xPosition = position.x;
  }

  // eslint-disable-next-line class-methods-use-this
  changeActiveSlide = (slides, currentIndex) => (
    slides.map((el, index) => {
      if (el.isActive) {
        return Object.assign(el, { isActive: false });
      }
      if (index === currentIndex) {
        return Object.assign(el, { isActive: true });
      }
      return el;
    }));

  onSwipeEnd = () => {
    if (Slider.xPosition > 50) this.goToPrevSlide();
    if (Slider.xPosition < -50) this.goToNextSlide();
  };

  goToPrevSlide = () => {
    let { currentIndex } = this.state;
    const { slides } = this.state;
    if (currentIndex - 1 < 0) {
      currentIndex = slides.length - 1;
    } else {
      currentIndex -= 1;
    }

    const newSlides = this.changeActiveSlide(slides, currentIndex);
    this.setState(() => ({
      currentIndex,
      slides: newSlides,
    }));
  };

  goToNextSlide = () => {
    let { currentIndex } = this.state;
    const { slides } = this.state;
    if (currentIndex + 1 > slides.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += 1;
    }
    const newSlides = this.changeActiveSlide(slides, currentIndex);
    this.setState(() => ({
      currentIndex,
      slides: newSlides,
    }));
  };

  goToSlideAtIndex = (e) => {
    const currentIndex = Number.parseInt(e.target.id, 10);
    const { slides } = this.state;
    const newSlides = this.changeActiveSlide(slides, currentIndex);

    this.setState(() => ({
      currentIndex,
      slides: newSlides,
    }));
  };

  render() {
    const { slides } = this.state;
    const { t: translate } = this.props;

    return (
      <div className={styles['slider-box']}>
        <div className={styles['slider-container']}>
          <Link
            to={'/about/feedback'}
            className={styles['slider-container__heading']}
          >
            {translate(TRANSLATE_KEYS.feedbacks)}
          </Link>
          <Swipe onSwipeMove={Slider.onSwipeMove} onSwipeEnd={this.onSwipeEnd}>
            <div className={styles['slider-container__slide']}>
              {slides.map((el) => {
                if (el.isActive) {
                  return (
                    <Slide
                      key={el.id}
                      image={el.image}
                      review={translate(TRANSLATE_KEYS[el.review])}
                      name={translate(TRANSLATE_KEYS[el.name])}
                      info={translate(TRANSLATE_KEYS[el.info])}
                    />
                  );
                }
                return null;
              })}
              <LeftArrow goToPrevSlide={this.goToPrevSlide} />
              <RightArrow goToNextSlide={this.goToNextSlide} />
            </div>
          </Swipe>

          <div className={styles['person-container']}>
            {slides.map(el => (
              <PersonImage
                goToSlideAtIndex={this.goToSlideAtIndex}
                key={el.id}
                id={el.id}
                source={el.image}
                isActive={el.isActive}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = { t: PropTypes.func.isRequired };

export default withTranslation('translations')(Slider);
