import React from 'react';
import styles from './picture.module.scss';
import { PictureProps } from './Picture.models';

const Picture = ({ image = '' }:PictureProps) => (
  <div className={styles.container}>
    <img src={image} alt="Doggy" className={styles.image} />
  </div>
);

export default Picture;
