import React, { FC } from 'react';
import styles from './locationMap.module.scss';

const LocationMap: FC = () => (
  <iframe
    title="Temp map"
    src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.371450709104!2d30.'
      + '431967016055143!3d50.43418147947313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!'
      + '1s0x40d4cc1cce2c208b%3A0xb710358b8adf9e86!2z0J7RgtGA0LDQtNC90YvQuSDQv9GA0L7RgdC_'
      + 'LiwgMzEsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1sru!2sua!4v1540371286564'}
    width="100%"
    height={650}
    frameBorder={0}
    className={styles.map}
  />
);

export default LocationMap;
