export const validateEmail = email => /^([\w._%+-]+)@([\w-]+\.)+([\w]{2,6})$/i.test(email);

export const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$');

export const validateName = name => !!name.trim() && /[/a-zа-я ,.'-]{2,}/i.test(name);

export const validatePhone = (phone) => {
  const trimmedPhone = phone.replace(/[+() _/-]/gi, '');
  return !!trimmedPhone && /[0-9]{12}/.test(trimmedPhone);
};

export const validateMessage = message => !!message.trim();

export const validate = (value, name) => {
  switch (name) {
    case 'name':
      return validateName(value);
    case 'phone':
      return validatePhone(value);
    case 'message':
      return validateMessage(value);
    case 'email':
      return validateEmail(value);
    default:
      return true;
  }
};
