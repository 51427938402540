import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './listHeader.module.scss';

import PetsCount from './PetsArticlesCount';
import useScroll from '../../../../hooks/useScroll';

const ListHeader = ({ petsCount, t: translate }) => {
  const { scrollY, headerHeight } = useScroll();
  const isCollapse = scrollY > headerHeight;
  const header = (
    <div className={`${styles['title-container']}`}>
      <PetsCount petsCount={petsCount} translate={translate} />
    </div>
  );

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        {isCollapse ? (
          <div
            className={`${styles['title-container']}`}
          >
            {header}
          </div>
        ) : (
          header
        )}
      </div>
    </div>
  );
};

ListHeader.propTypes = {
  petsCount: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('translations')(ListHeader);
