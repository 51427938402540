import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { AdminWAFPageActions } from '../../../+store/actions';
import styles from './dogPageButtons.module.scss';
import Icon from '../../../../../../shared-ui/icon';
import { HideAndPublishButtonsModels } from '../dogDetailsPage.models';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { defaultHeaderHeight } from '../../../constants';
import navigateAfterPetStatusChange from '../../../../../../../utils/navigateAfterPetStatusChange';

const HideButton: FC<HideAndPublishButtonsModels.Props> = ({
  id,
  cookies,
  position = { top: defaultHeaderHeight },
  onHidePet,
  t: translate,
}) => {
  const onHidePetHandler = (e: MouseEvent) => {
    e.preventDefault();
    onHidePet(id, cookies).then(
      response => navigateAfterPetStatusChange(response.data.id),
    );
  };

  return (
    <button className={styles['buttons-wrapper__hide']} onClick={e => onHidePetHandler(e)}>
      <Icon name="hide" />
      <span className={styles['tooltip-text']} style={position}>{translate(TRANSLATE_KEYS.hideBtn)}</span>
    </button>
  );
};

const mapDispatchToProps = dispatch => (
  { onHidePet: (id: number, cookies: Cookies) => dispatch(AdminWAFPageActions.hidePet(id, cookies)) }
);

export default withTranslation('translations')(connect(null, mapDispatchToProps)(withCookies(HideButton)));
