import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { displayDeleteMessage, hidePet, publishPet } from '../../+store/actions';
import findNameByLanguage from './helpers';
import styles from './card.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import DescriptionDetails from '../../../shared-ui/description-details/DescriptionDetails';

const Card = ({
  petData,
  translate,
  language,
  history,
}) => {
  const getMain = (dogPhotoDtoList) => {
    const main = dogPhotoDtoList.find(img => img.main);
    return main ? main.photo : undefined;
  };

  const location = useLocation();
  const petCardHandleClick = (e) => {
    if (e.target.nodeName === 'IMG') {
      history.push(`/want-a-friend/dog/${petData.id}`, { prevUrl: location.pathname });
    }
  };

  return (
    <div
      className={styles['pet-card']}
      id={petData.id}
      onClick={petCardHandleClick}
      role="button"
      tabIndex={0}
    >
      { petData.hasGuardianship
        ? (
          <div className={styles['guardianship-lable']}>
            {translate(TRANSLATE_KEYS.hasGuardianship)}
          </div>
        )
        : null
      }
      <div className={styles['image-container']}>
        <img
          className={styles['image-container__img']}
          src={getMain(petData.dogPhotoDtoList)}
          alt={`${findNameByLanguage(petData.dogNameDtoSet, language)}`}
        />
      </div>
      <div className={styles.description}>
        <Link to={`/want-a-friend/dog/${petData.id}`}>
          <p className={styles.description__name}>
            {findNameByLanguage(petData.dogNameDtoSet, language)}
          </p>
        </Link>
        <DescriptionDetails styles={styles} translate={translate} petData={petData} />
      </div>
    </div>
  );
};

Card.propTypes = {
  petData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

Card.defaultProps = { petData: {}, history: null };

const mapStateToProps = state => ({ language: state.languageReducer.selectedLanguage.languageValue });

const mapDispatchToProps = dispatch => ({
  onDisplayDeleteMessage: pet => dispatch(displayDeleteMessage(pet)),
  onPublishPet: (...params) => dispatch(publishPet(...params)),
  onHidePet: (...params) => dispatch(hidePet(...params)),
});

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(withCookies(Card)));
