import React, { FC } from 'react';
import { withCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';
import { ProtectedRouteModels } from './protectedRoute.models';

const ProtectedRoute: FC<ProtectedRouteModels.Props> = ({
  component: Component,
  cookies,
  path,
  location = null,
}) => (
  <Route
    exact
    path={path}
    location={location}
    render={props => (
      cookies.get('token')
        ? <Component {...props} />
        : (<Redirect to={{ pathname: '/admin', state: { from: props.location } }} />)
    )}
  />
);

export default withCookies(ProtectedRoute);
