import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import styles from './ChangePassword.module.scss';
import Input from '../../../../../shared-ui/input';
import Button from '../../../../../shared-ui/button';
import { ProfilePageActions } from '../../+store/actions';
import { validPassword } from '../../../../../../utils/validation';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { ChangePasswordModels, InputValues } from './changePassword.models';

class ChangePassword extends Component<ChangePasswordModels.Props, ChangePasswordModels.State> {
  constructor(props: ChangePasswordModels.Props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      userId: 1,
      errors: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        validation: '',
        requestError: '',
      },
    };
  }

  componentDidUpdate(prevProps: ChangePasswordModels.Props) {
    const { requestError, t: translate } = this.props;
    const initialRequestError = prevProps.requestError;

    if (initialRequestError !== requestError && requestError !== null) {
      this.setState((state) => ({
        errors: {
          ...state.errors,
          requestError: translate(TRANSLATE_KEYS.wrongCurrentPassword),
        },
      }));
    }
    return null;
  }

  handleInputChange = (key: InputValues, value: string) => {
    this.setState((state) => ({
      ...state,
      errors: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        validation: '',
        requestError: '',
      },
      [key]: value,
    }));
  };

  handlePasswordSubmit = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmNewPassword, userId } = this.state;
    const { sendUpdatePasswordRequest, cookies, t: translate } = this.props;

    const emptyFieldError = () => {
      this.setState(state => ({
        errors: {
          ...state.errors,
          currentPassword: translate(TRANSLATE_KEYS.currentPasswordRequired),
          newPassword: translate(TRANSLATE_KEYS.newPasswordRequired),
        },
      }));
    };

    const setEmptyCurrentPasswordError = () => {
      this.setState((state) => ({
        errors:
          { ...state.errors, currentPassword: translate(TRANSLATE_KEYS.currentPasswordRequired) },
      }));
    };

    const setEmptyNewPasswordError = () => {
      this.setState((state) => ({
        errors:
          { ...state.errors, newPassword: translate(TRANSLATE_KEYS.newPasswordRequired) },
      }));
    };

    const setNewPasswordValidationError = () => {
      this.setState((state) => ({
        errors:
          { ...state.errors, validation: translate(TRANSLATE_KEYS.passwordValidation) },
      }));
    };

    const setConfirmNewPasswordError = () => {
      this.setState((state) => ({
        errors:
          { ...state.errors, confirmNewPassword: translate(TRANSLATE_KEYS.passwordNotMatch) },
      }));
    };

    if (!currentPassword && !newPassword) {
      emptyFieldError();
    } else if (!currentPassword) {
      setEmptyCurrentPasswordError();
    } else if (!newPassword) {
      setEmptyNewPasswordError();
    } else if (!validPassword.test(newPassword)) {
      setNewPasswordValidationError();
    } else if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError();
    } else {
      sendUpdatePasswordRequest({
        currentPassword,
        newPassword,
        userId,
      }, cookies);
    }
  };

  render() {
    const { closeChangePasswordModal, t: translate } = this.props;
    const { errors } = this.state;
    return (
      <div className={styles.modalWrapper}>
        <div className={styles.modal}>
          <div className={styles.form}>
            <h3 className={styles['title-settings']}> {translate(TRANSLATE_KEYS.changePassword)}</h3>
            <form>
              <div className={styles['field-container']}>
                <Input
                  htmlType="password"
                  id="currentPassword"
                  placeholder={translate(TRANSLATE_KEYS.currentPassword)}
                  label={translate(TRANSLATE_KEYS.currentPassword)}
                  onChange={value => this.handleInputChange(InputValues.CurrentPassword, value)}
                  uiType={errors.currentPassword || errors.requestError ? 'form-error' : 'lightGrayMarginBottom'}
                />

                <p className={styles.error}>{errors.currentPassword || errors.requestError}</p>
              </div>
              <div className={styles['field-container']}>
                <Input
                  htmlType="password"
                  id="newPassword"
                  placeholder={translate(TRANSLATE_KEYS.newPassword)}
                  label={translate(TRANSLATE_KEYS.newPassword)}
                  onChange={value => this.handleInputChange(InputValues.NewPassword, value)}
                  uiType={errors.newPassword || errors.validation ? 'form-error' : 'lightGrayMarginBottom'}
                />

                <p className={styles.error}>{errors.newPassword || errors.validation}</p>
              </div>
              <div className={styles['field-container']}>
                <Input
                  htmlType="password"
                  id="confirmNewPassword"
                  placeholder={translate(TRANSLATE_KEYS.confirmNewPassword)}
                  label={translate(TRANSLATE_KEYS.confirmNewPassword)}
                  onChange={value => this.handleInputChange(InputValues.ConfirmNewPassword, value)}
                  uiType={errors.confirmNewPassword ? 'form-error' : 'lightGrayMarginBottom'}
                />
                <p className={styles.error}>{errors.confirmNewPassword}</p>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  content={translate(TRANSLATE_KEYS.cancelBtn)}
                  uiType="grayShort"
                  onClick={closeChangePasswordModal}
                />
                <Button
                  content={translate(TRANSLATE_KEYS.change)}
                  uiType="sunMainBold"
                  onClick={this.handlePasswordSubmit}
                />
              </div>
            </form>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ requestError: state.adminProfileReducer.error });

const mapDispatchToProps = dispatch => ({
  closeChangePasswordModal: () => dispatch(ProfilePageActions.closeModalChangePassword()),
  sendUpdatePasswordRequest: (password: ChangePasswordModels.PasswordUpdateData, cookies: Cookies) => dispatch(
    ProfilePageActions.adminUpdatePasswordRequest(cookies, password),
  ),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(ChangePassword)),
);
