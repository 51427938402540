export const FETCH_START = 'FETCH_START';
export const ADMIN_FETCH_PETS_RECORDS = 'ADMIN_FETCH_PETS_RECORDS';
export const FETCH_END = 'FETCH_END';
export const FETCH_ERROR = 'FETCH_ERROR';
export const ADMIN_FETCH_PET = 'ADMIN_FETCH_PET';
export const ADMIN_FETCH_PET_LIST = 'ADMIN_FETCH_PET_LIST';
export const CLEAR_PETS = 'CLEAR_PETS';
export const ADMIN_FETCH_ALL_PETS = 'ADMIN_FETCH_ALL_PETS';
export const DISPLAY_DELETE_PET = 'DISPLAY_DELETE_PET';
export const DISPLAY_DELETE_MESSAGE = 'DISPLAY_DELETE_MESSAGE';
export const HIDE_DELETE_MESSAGE = 'HIDE_DELETE_MESSAGE';
export const DELETE_PET_SUCCESS = 'DELETE_PET_SUCCESS';
export const HIDE_OR_PUBLICH_PET_SUCCESS = 'HIDE_OR_PUBLICH_PET_SUCCESS';
export const CREATE_NEW_DOG = 'CREATE_NEW_DOG';
export const DOG_CREATED_SUCCESSFULLY = 'DOG_CREATED_SUCCESSFULLY';
export const DOG_UPDATED_SUCCESSFULLY = 'DOG_UPDATED_SUCCESSFULLY';
export const CLOTHE_CREATE_NEW_DOG = 'CLOTHE_CREATE_NEW_DOG';
export const CLOSE_MODAL_WINDOW = 'CLOSE_MODAL_WINDOW';
export const USER_DOG_FILTER_MALE = 'USER_DOG_FILTER_MALE';
export const USER_FETCH_ALL_PETS = 'USER_FETCH_ALL_PETS';
export const USER_DOG_FILTER_FEMALE = 'USER_DOG_FILTER_FEMALE';
export const USER_DOG_FILTER_SEX_ALL = 'USER_DOG_FILTER_SEX_ALL';
export const USER_DOG_FILTER_BEFORE_YEAR = 'USER_DOG_FILTER_BEFORE_YEAR';
export const USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS = 'USER_DOG_FILTER_FROM_ONE_TO_FIVE_YEARS';
export const USER_DOG_FILTER_FIVE_AND_MORE = 'USER_DOG_FILTER_FIVE_AND_MORE';
export const USER_DOG_FILTER_SMALL_DOG = 'USER_DOG_FILTER_SMALL_DOG';
export const USER_DOG_FILTER_MEDIUM_DOG = 'USER_DOG_FILTER_MEDIUM_DOG';
export const USER_DOG_FILTER_BIG_DOG = 'USER_DOG_FILTER_BIG_DOG';
export const USER_DOG_FILTER_AGE_ALL = 'USER_DOG_FILTER_AGE_ALL';
export const USER_DOG_FILTER_SIZE_ALL = 'USER_DOG_FILTER_SIZE_ALL';
export const USER_DOG_FETCH_NEXT_ITEMS = 'USER_DOG_FETCH_NEXT_ITEMS';
