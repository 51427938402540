import { Cookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { ProfileAction } from './profileStore.models';

export namespace ProfileActions {
  export type HandleStatus = (status: number) => string;
  export type SimpleAction = () => ProfileAction;
  export type ErrorHandler = (error: string) => ProfileAction;
  export type FetchDataSuccess<T> = (data: T) => ProfileAction;
  export type Request<T = void> = (
    cookies: Cookies,
    credentials?: T
  ) => (dispatch: Dispatch) => Promise<void>;
}

export namespace ProfileActionTypes {
  export const fetchProfileDataSuccess = 'FETCH_PROFILE_DATA_SUCCESS';
  export const fetchProfileDataStart = 'FETCH_PROFILE_DATA_START';
  export const fetchProfileDataError = 'FETCH_PROFILE_DATA_ERROR';
  export const adminUpdateProfileRequest = 'ADMIN_UPDATE_PROFILE_REQUEST';
  export const adminUpdateProfileSuccess = 'ADMIN_UPDATE_PROFILE_SUCCESS';
  export const adminUpdateProfileFailure = 'ADMIN_UPDATE_PROFILE_FAILURE';
  export const adminUpdateProfileStart = 'ADMIN_UPDATE_PROFILE_START';
  export const openChangePasswordModal = 'OPEN_CHANGE_PASSWORD_MODAL';
  export const closeChangePasswordModal = 'CLOSE_CHANGE_PASSWORD_MODAL';
  export const adminUpdatePasswordStart = 'ADMIN_UPDATE_PASSWORD_START';
  export const adminUpdatePasswordSuccess = 'ADMIN_UPDATE_PASSWORD_SUCCESS';
  export const adminUpdatePasswordFailure = 'ADMIN_UPDATE_PASSWORD_FAILURE';
  export const hideProfileAlert = 'HIDE_PROFILE_ALERT';
}
