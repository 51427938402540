import requestWithHeaders from '../../../../../../../utils/withHeaders';
import { WAFPopupFormActions, WAFPopupFormActionTypes } from './WAFPopupFormActions.models';
import { URL_WANT_A_FRIEND_CREATE, URL_WANT_A_FRIEND_UPDATE } from '../../../../../../../endpoints';
import { createDogDtoForCreate, createDogDtoForUpdate } from '../helpers';
import getFormDogData from './selectors';

export namespace AdminWAFPopupActions {
  export const changeLanguageTab: WAFPopupFormActions.ChangeLanguageTab = language => ({
    type: WAFPopupFormActionTypes.CHANGE_LANGUAGE_TAB,
    payload: { language },
  });

  export const openPopupForDogCreate: WAFPopupFormActions.SimpleAction = () => (
    { type: WAFPopupFormActionTypes.OPEN_POPUP_FOR_DOG_CREATE }
  );

  export const openPopupForDogUpdate: WAFPopupFormActions.OpenPopupForDogUpdate = (dogData, petBeforeUpdate) => ({
    type: WAFPopupFormActionTypes.OPEN_POPUP_FOR_DOG_UPDATE,
    payload: { dogData, petBeforeUpdate },
  });

  export const dogNameChange: WAFPopupFormActions.DogInputChange = (language, name) => ({
    type: WAFPopupFormActionTypes.DOG_NAME_CHANGE,
    payload: { inputChangeData: { language, name } },
  });

  export const dogDescriptionChange: WAFPopupFormActions.DogInputChange = (language, description) => ({
    type: WAFPopupFormActionTypes.DOG_DESCRIPTION_CHANGE,
    payload: { inputChangeData: { language, description } },
  });

  export const dogParameterChange: WAFPopupFormActions.DogParameterChange = parameter => ({
    type: WAFPopupFormActionTypes.DOG_PARAMETER_CHANGE,
    payload: { parameter },
  });

  export const dogPhotosChange: WAFPopupFormActions.DogPhotosChange = dogPhotos => ({
    type: WAFPopupFormActionTypes.DOG_PHOTOS_CHANGE,
    payload: { dogPhotos },
  });

  export const isDogUnderCareChange: WAFPopupFormActions.IsDogUnderCareChange = (isUnderCare) => ({
    type: WAFPopupFormActionTypes.IS_DOG_UNDER_CARE_CHANGE,
    payload: { isUnderCare },
  });

  export const dogCreateStart: WAFPopupFormActions.SimpleAction = () => (
    { type: WAFPopupFormActionTypes.DOG_CREATE_START }
  );

  export const dogCreateSuccess: WAFPopupFormActions.SimpleAction = () => (
    { type: WAFPopupFormActionTypes.DOG_CREATED_SUCCESSFULLY }
  );

  export const createDog: WAFPopupFormActions.CreateOrUpdateDog = (cookies) => (dispatch, getState) => {
    const { formDogData } = getFormDogData(getState());

    dispatch(dogCreateStart());
    requestWithHeaders({
      method: 'POST',
      url: URL_WANT_A_FRIEND_CREATE,
      data: createDogDtoForCreate(formDogData),
    }, cookies).then(() => {
      dispatch(dogCreateSuccess());
    });
  };

  export const dogUpdateStart: WAFPopupFormActions.SimpleAction = () => (
    { type: WAFPopupFormActionTypes.DOG_UPDATE_START }
  );

  export const dogUpdateSuccess: WAFPopupFormActions.SimpleAction = () => (
    { type: WAFPopupFormActionTypes.DOG_UPDATED_SUCCESSFULLY }
  );

  export const updateDog: WAFPopupFormActions.CreateOrUpdateDog = (cookies) => (dispatch, getState) => {
    const { formDogData, dogBeforeUpdate } = getFormDogData(getState());

    dispatch(dogUpdateStart());
    return requestWithHeaders({
      method: 'PUT',
      url: URL_WANT_A_FRIEND_UPDATE,
      data: {
        ...createDogDtoForUpdate(formDogData, dogBeforeUpdate),
        id: formDogData.dogId,
        parentDogId: formDogData.parentDogId,
        dogStatus: dogBeforeUpdate.dogStatus,
      },
    }, cookies).then(() => {
      dispatch(dogUpdateSuccess());
    });
  };

  export const addDogPhoto: WAFPopupFormActions.AddDogPhoto = (cloudinaryPhotoUrl, photoName, photoIndex) => ({
    type: WAFPopupFormActionTypes.ADD_DOG_PHOTO,
    payload: { addPhotoData: { cloudinaryPhotoUrl, photoName, photoIndex } },
  });

  export const dogPhotoDelete: WAFPopupFormActions.DogPhotoDelete = (photoDeleteIndex) => ({
    type: WAFPopupFormActionTypes.DOG_PHOTO_DELETE,
    payload: { photoDeleteIndex },
  });
}
