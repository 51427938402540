import React, { FC } from 'react';
import { connect } from 'react-redux';
import ArticleItem from './articleItem';
import PopupMessageDelete from '../popupForm/components/popupMessages/delete';
import styles from './articleList.module.scss';
import { BlogPageModels } from '../../../../../../models/blogPage.models';

interface ArticleListProps {
  articles: BlogPageModels.Article[];
  mainArticle: BlogPageModels.Article;
  deleteMessageVisible: boolean;
  handleChangeRemoving: () => void;
}

const ArticleList: FC<ArticleListProps> = ({
  articles,
  mainArticle,
  deleteMessageVisible,
  handleChangeRemoving,
}) => (
  <div className={styles['article-list']}>
    {mainArticle && (
      <ArticleItem
        key={mainArticle.id}
        isMain
        title={mainArticle.title}
        image={mainArticle.image}
        statusId={mainArticle.statusId}
        id={mainArticle.id}
        parentArticleId={mainArticle.parentArticleId}
        categoryValueDtoSet={mainArticle.categoryValueDtoSet}
      />
    )}
    {articles.map(article => (
      <ArticleItem
        key={article.id}
        isMain={false}
        title={article.title}
        image={article.image}
        statusId={article.statusId}
        id={article.id}
        parentArticleId={article.parentArticleId}
        categoryValueDtoSet={article.categoryValueDtoSet}
      />
      ))}
    {deleteMessageVisible && <PopupMessageDelete handleChangeRemoving={handleChangeRemoving} />}
  </div>
);

const mapStateToProps = state => ({ deleteMessageVisible: state.articlesPageReducer.deleteMessageVisible });

export default connect(mapStateToProps)(ArticleList);
