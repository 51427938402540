import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import Button from '../button/PriceListButton';
import styles from './price-item.module.scss';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import TRANSLATE_KEYS from '../../../../../i18n_keys';

const PriceItem: FC<ServicesPageModels.MockDataPrice> = (
    { title = '', description = '', discount = '', value = '' }) => {
  const [translate] = useTranslation();

  const getDiscount = (discountValue: string): string => (
      `${translate(TRANSLATE_KEYS.discount)} ${discountValue} ${translate(TRANSLATE_KEYS.money)}`);

  return (
    <>
      {title && <h3 className={styles.title}>{translate(title)}</h3>}
      <div className={`${styles.description__wrapper}`}>
        {description && <p className={styles.description}>{translate(description)}</p>}
        <div className={styles.price}>
          {discount && <span className={styles.discount}>{getDiscount(discount)}</span>}
          <Button content={value || translate(TRANSLATE_KEYS.contact)} />
        </div>
      </div>
    </>
  );
};

export default withTranslation('translations')(PriceItem);
