import { withRouter } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import WantAFriendPageComponent from './WantAFriendPageComponent';
import { AdminWAFPageActions } from './+store/actions';

const mapStateToProps = state => ({
  pet: state.wantAFriendPagesReducer.pet,
  petsList: state.wantAFriendPagesReducer.petsList,
  petsCount: state.wantAFriendPagesReducer.petsCount,
  pagesLoaded: state.wantAFriendPagesReducer.pagesLoaded,
  totalPages: state.wantAFriendPagesReducer.totalPages,
  openDogPopupForm: state.wantAFriendPagesReducer.openDogPopupForm,
});

const mapDispatchToProps = dispatch => ({
  fetchPetsRecords: (
    page: number, cookies: Cookies,
  ) => dispatch(AdminWAFPageActions.fetchPetsRecords(page, cookies)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(WantAFriendPageComponent)));
