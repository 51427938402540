/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-–-Link" transform="translate(-352.000000, -656.000000)">
        <g id="Icon" transform="translate(352.000000, 656.000000)" fill="#F97272">
          <path d="M21.03125,10 L21.03125,11.46875 L20.90625,11.46875 C16.5937284,11.46875 13.1666794,12.1145769 10.625,13.40625 C8.08332062,14.6979231 6.8125,16.4062394 6.8125,18.53125 C6.8125,19.1770866 6.95833187,19.6562484 7.25,19.96875 C7.54166813,20.2812516 7.98958031,20.4375 8.59375,20.4375 C8.927085,20.4375 9.58853672,20.307293 10.578125,20.046875 C11.5677133,19.786457 12.4062466,19.65625 13.09375,19.65625 C15.3854281,19.65625 17.3541584,20.5312412 19,22.28125 C20.6458416,24.0312588 21.46875,26.1249878 21.46875,28.5625 C21.46875,31.4166809 20.5416759,33.7187412 18.6875,35.46875 C16.8333241,37.2187588 14.3958484,38.09375 11.375,38.09375 C8.18748406,38.09375 5.66146766,37.057302 3.796875,34.984375 C1.93228234,32.911448 1,30.0937678 1,26.53125 C1,21.2603903 2.70831625,17.1875144 6.125,14.3125 C9.54168375,11.4374856 14.4062184,10 20.71875,10 L21.03125,10 Z M47.03125,10 L47.03125,11.46875 L46.90625,11.46875 C42.5937284,11.46875 39.1666794,12.1145769 36.625,13.40625 C34.0833206,14.6979231 32.8125,16.4062394 32.8125,18.53125 C32.8125,19.1770866 32.9583319,19.6562484 33.25,19.96875 C33.5416681,20.2812516 33.9895803,20.4375 34.59375,20.4375 C34.927085,20.4375 35.5885367,20.307293 36.578125,20.046875 C37.5677133,19.786457 38.4062466,19.65625 39.09375,19.65625 C41.3854281,19.65625 43.3541584,20.5312412 45,22.28125 C46.6458416,24.0312588 47.46875,26.1249878 47.46875,28.5625 C47.46875,31.4166809 46.5416759,33.7187412 44.6875,35.46875 C42.8333241,37.2187588 40.3958484,38.09375 37.375,38.09375 C34.1874841,38.09375 31.6614677,37.057302 29.796875,34.984375 C27.9322823,32.911448 27,30.0937678 27,26.53125 C27,21.2603903 28.7083162,17.1875144 32.125,14.3125 C35.5416838,11.4374856 40.4062184,10 46.71875,10 L47.03125,10 Z" id="Combined-Shape" />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
