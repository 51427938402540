import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import DogDescription from '../description/DogDescription/DogDescription';
import PriceList from '../price-list/PriceList';
import mockPrice from '../../../../mockData/price.json';
import styles from './dogTab.module.scss';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const DogTab: FC = () => {
  const [translate] = useTranslation();

  return (
    <>
      <DogDescription />
      <h1 className={styles.title}>{translate(TRANSLATE_KEYS.theCostOfLiving)}</h1>
      <PriceList
        target="dog"
        service="hotel"
        category="hotel"
        data={mockPrice}
      />
    </>
  );
};

export default withTranslation('translations')(DogTab);
