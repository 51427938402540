import { DISPLAY_ALERT,
  SEND_MESSAGE_BEGIN,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  SEND_SUCCESS_NULL } from './types';

const initialState = {
  displayAlert: false,
  loading: false,
  error: null,
  success: null,
};

export default function message(state = initialState, action) {
  switch (action.type) {
    case SEND_MESSAGE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        displayAlert: true,
        success: true,
      };
    case DISPLAY_ALERT:
      return {
        ...state,
        displayAlert: false,
      };
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };
    case SEND_SUCCESS_NULL:
      return {
        ...state,
        success: null,
      };

    default:
      return state;
  }
}
