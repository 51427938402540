import i18n from 'i18next';
import { URL_ADMIN_FETCH_PROFILE_DATA,
  URL_ADMIN_UPDATE_PROFILE } from '../../../../../endpoints';
import requestWithHeaders from '../../../../../utils/withHeaders';
import browserStorage from '../../../../../utils/localStorage';
import selectLanguage from '../../../../language/+store/actions';
import history from '../../../../../+store/history';
import { ProfileStoreModels } from './profileStore.models';
import { ProfileActions, ProfileActionTypes } from './profileActions.models';
import { ProfileFormModels } from '../profileForm.models';
import { ChangePasswordModels } from '../components/changePassword/changePassword.models';

const handleStatusCode: ProfileActions.HandleStatus = (status) => {
  switch (status) {
    case 400:
      return 'Неверный пароль';

    case 401:
      return 'Некорректное имя пользователя или пароль';

    default:
      return 'Что-то пошло не так';
  }
};

const handleFetchStatus: ProfileActions.HandleStatus = (status) => {
  switch (status) {
    case 401:
      return 'Пользователь не авторизован';

    default:
      return 'Что-то пошло не так';
  }
};

export namespace ProfilePageActions {
  export const adminFetchProfileDataStart: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.fetchProfileDataStart }
  );

  export const adminFetchProfileDataFailure: ProfileActions.ErrorHandler = (error) => ({
    type: ProfileActionTypes.fetchProfileDataError,
    payload: { error },
  });

  export const adminFetchProfileDataSuccess: ProfileActions.FetchDataSuccess<ProfileStoreModels.UserData> = (data) => ({
    type: ProfileActionTypes.fetchProfileDataSuccess,
    payload: data,
  });

  export const adminFetchProfileDataRequest: ProfileActions.Request = (cookies) => (dispatch) => {
    dispatch(adminFetchProfileDataStart());
    return requestWithHeaders({
      method: 'GET',
      url: `${URL_ADMIN_FETCH_PROFILE_DATA}${browserStorage.getKeyValue('login')}`,
    }, cookies)
    .then((response) => {
      dispatch(adminFetchProfileDataSuccess(response.data));
      dispatch(selectLanguage(response.data.preferredLanguage.languageValue));
      i18n.changeLanguage(response.data.preferredLanguage.languageValue);
      return response.data;
    })
    .catch((error) => {
      const { status } = error.response;
      const message = handleFetchStatus(status);
      dispatch(adminFetchProfileDataFailure(message));
      browserStorage.removeKey('login')
      history.push('/admin');
    });
  };

  export const adminUpdateProfileStart: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.adminUpdateProfileStart }
  );

  export const adminUpdateProfileFailure: ProfileActions.ErrorHandler = (error) => ({
    type: ProfileActionTypes.adminUpdateProfileFailure,
    payload: { error },
  });

  export const adminUpdateProfileSuccess: ProfileActions.FetchDataSuccess<ProfileStoreModels.UserData> = (data) => ({
    type: ProfileActionTypes.adminUpdateProfileSuccess,
    payload: data,
  });

  export const adminUpdateProfileRequest: ProfileActions.Request<ProfileFormModels.UpdateRequestCredentials> = (
    cookies,
    credentials,
    ) => (dispatch) => {
      dispatch(adminUpdateProfileStart());
      return requestWithHeaders({
        method: 'PUT',
        url: URL_ADMIN_UPDATE_PROFILE,
        data: credentials.userData,
      }, cookies)
      .then(() => {
        browserStorage.setKeyValue('login', credentials.userData.login);
        dispatch(adminUpdateProfileSuccess(credentials.userData));
      })
      .catch((error) => {
        const { status } = error.response;
        const message = handleStatusCode(status);
        dispatch(adminUpdateProfileFailure(message));
      });
    };

  export const openChangePasswordModal: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.openChangePasswordModal }
  );

  export const closeModalChangePassword: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.closeChangePasswordModal }
  );

  export const adminUpdatePasswordStart: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.adminUpdatePasswordStart }
  );

  export const adminUpdatePasswordSuccess: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.adminUpdatePasswordSuccess }
  );

  export const adminUpdatePasswordFailure: ProfileActions.ErrorHandler = (error) => ({
    type: ProfileActionTypes.adminUpdatePasswordFailure,
    payload: { error },
  });

  export const hideProfileAlert: ProfileActions.SimpleAction = () => (
    { type: ProfileActionTypes.hideProfileAlert }
  );

  export const adminUpdatePasswordRequest: ProfileActions.Request<ChangePasswordModels.PasswordUpdateData> = (
    cookies,
    password,
  ) => (dispatch) => {
    dispatch(adminUpdatePasswordStart());
    return requestWithHeaders({
      method: 'PUT',
      url: URL_ADMIN_UPDATE_PROFILE,
      data: password,
    }, cookies)
      .then((response) => {
        const token = response.headers.authorization.split(' ')[1];
        cookies.set('token', token, { path: '/' });
        dispatch(adminUpdatePasswordSuccess());
        setTimeout(() => dispatch(hideProfileAlert()), 5000);
      })
      .catch((error) => {
        const { status } = error.response;
        const message = handleStatusCode(status);
        dispatch(adminUpdatePasswordFailure(message));
      });
  };
}
