import React, { useState, useEffect, useRef, FC, ReactElement } from 'react';
import { TranslatedText } from '../../../models/translate-function.models';
import style from './tooltip.module.scss';

interface TooltipProps {
  children?: ReactElement;
  content: TranslatedText;
  positionClassName?: string;
  extraClassName?: string;
  isMultiline?: boolean;
  isHandleClickable?: boolean;
}

const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  positionClassName,
  extraClassName,
  isMultiline,
  isHandleClickable,
}) => {
  const containerRef = useRef(null);
  const [displayTooltip, setTooltipDisplay] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const hideTooltip = () => {
    setTooltipDisplay(false);
  };

  const showTooltip = () => {
    setTooltipDisplay(true);
  };

  const handleClick = (e) => {
    if (containerRef.current.contains(e.target)) {
      return;
    }
    setTooltipDisplay(false);
  };

  return (
    <div ref={containerRef} className={displayTooltip ? style.tooltip : null}>
      {isHandleClickable
        ? (
          <div
            className={style['tooltip-target']}
            role="button"
            tabIndex={0}
            onClick={() => (displayTooltip ? hideTooltip() : showTooltip())}
          >
            {children}
          </div>
        )
        : (
          <div className={style['tooltip-target']} onMouseOver={showTooltip} onMouseLeave={hideTooltip}>
            {children}
          </div>
        )}
      {displayTooltip && (
        <div
          onClick={isHandleClickable ? hideTooltip : null}
          role="textbox"
          tabIndex={0}
          className={`${style['tooltip-content']} ${style[positionClassName]} ${style[extraClassName]} ${isMultiline
            ? style['tooltip-multiline']
            : style['tooltip-singleline']}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  children: null,
  positionClassName: 'bottom-center',
  extraClassName: '',
  isMultiline: false,
  isHandleClickable: false,
};

export default Tooltip;
