import React, { useState } from 'react';
import { connect } from 'react-redux';
import dogParameterSelect from './styles/dog-parameter-select.module.scss';
import { AdminWAFPopupActions } from '../+store/actions';
import DogParametersDropdown from './DogParametersDropdown';
import { getParameterSelectClasses } from './helpers';
import { DogParameterSelectModels } from '../popupForm.models';
import DogParameterItem from './DogParameterItem';
import { TranslatedText } from '../../../../../../../models/translate-function.models';

const DogParameterSelect = ({
  selectTitle,
  parameterValues,
  parameter,
  dogParameters,
  addRequiredLabel,
  changeDogParameters,
}: DogParameterSelectModels.Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleParameterSelect = (dogParameter: TranslatedText) => {
    const parameterIndex = parameterValues.indexOf(dogParameter);
    setDropdownVisible(false);
    changeDogParameters({ [parameter]: parameterIndex });
  };

  const setCloseDropdown = (event) => {
    event.preventDefault();
    setDropdownVisible(false);
  };

  return (
    <div
      className={getParameterSelectClasses(dropdownVisible)}
      onBlur={setCloseDropdown}
    >
      <DogParametersDropdown
        name={addRequiredLabel ? `${selectTitle}*` : `${selectTitle}`}
        selectedParameter={parameterValues[dogParameters[parameter]]}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      />
      { dropdownVisible && (
        <ul className={dogParameterSelect['dropdown-content']}>
          {
            parameterValues.map(item => (
              <DogParameterItem
                parameter={item}
                onParameterSelect={handleParameterSelect}
                key={item.toString()}
              />
            ))
          }
        </ul>
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  changeDogParameters: (parameter: {[value: string]: number}) => dispatch(
    AdminWAFPopupActions.dogParameterChange(parameter),
  ),
});

export default connect(null, mapDispatchToProps)(DogParameterSelect);
