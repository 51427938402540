import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArticleActions } from './+store/actions';
import ArticleComponent from './ArticleComponent';
import { DispatchFn } from '../../types/dispatch.type';
import { RootStateType } from '../../types/store.models';

const mapStateToProps = (state: RootStateType) => (state.openArticleReducer);
const mapDispatchToProps = (dispatch: DispatchFn) => ({
  resetArticleError: () => dispatch(ArticleActions.fetchError(null)),
  fetchOpenArticle: (id: Parameters<typeof ArticleActions.fetchOpen>[0]) => (
    ArticleActions.fetchOpen(id)(dispatch)
  ),
});
const ArticleContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ArticleComponent));

export default ArticleContainer;
