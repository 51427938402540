import React, { FC, useState } from 'react';
import { withTranslation } from 'react-i18next';
import style from './create-article-content.module.scss';
import Icon from '../../../../../../../shared-ui/icon/index';
import Tooltip from '../../../../../../../shared-ui/tooltip/tooltip';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { ArticleContentTypes } from '../../../../../../../../models/blogPage.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import ArticleActionTypes from '../../../../../../../article/+store/types';

interface AddButtonProps {
  id: number;
  addBox: (id: number, type: ArticleContentTypes) => void;
  setButtonDisplay: (state: boolean) => void;
  t: TranslateFn;
}

const AddButton: FC<AddButtonProps> = ({
  id,
  setButtonDisplay,
  addBox,
  t: translate,
}) => {
  const [isAddContentPanelOpen, setAddContentPanelOpen] = useState(false);
  const contentButtonsStyles = isAddContentPanelOpen ? style['is-visible'] : style['is-hidden'];

  const onAddContentBox = (contentType: ArticleContentTypes) => {
    addBox(id, contentType);
    setButtonDisplay(true);
    setAddContentPanelOpen(false);
  }

  return (
    <div
      className={`${style['add-content-panel']} ${isAddContentPanelOpen ? style['is-open'] : ''}`}
    >
      <button
        className={`${style['add-text']} ${contentButtonsStyles}`}
        onClick={() => onAddContentBox(ArticleContentTypes.TEXT)}
      >
        <Tooltip content={translate(TRANSLATE_KEYS.addText)} positionClassName="top-center">
          <Icon name="text" />
        </Tooltip>
      </button>
      <button
        className={style['open-close-panel']}
        onClick={() => { setAddContentPanelOpen(!isAddContentPanelOpen); }}
      >
        {isAddContentPanelOpen
          ? <Icon name="close" />
          : (
            <Tooltip content={translate(TRANSLATE_KEYS.addContent)} positionClassName="top-center">
              <Icon name="add" />
            </Tooltip>
          )
        }
      </button>
      <button
        className={`${style['add-image']} ${contentButtonsStyles}`}
        onClick={() => onAddContentBox(ArticleContentTypes.IMG)}
      >
        <Tooltip content={translate(TRANSLATE_KEYS.addImage)} positionClassName="top-center">
          <Icon name="image" />
        </Tooltip>
      </button>
    </div>
  );
};

export default withTranslation('translations')(AddButton);
