/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Empty" transform="translate(-712.000000, -676.000000)">
        <g id="Add-content-btn-Buttons-/-Add-content-/-Default" transform="translate(112.000000, 664.000000)" fill="currentColor">
          <g id="Icon-Icons-/-Add-" transform="translate(612.000000, 24.000000) rotate(-360.000000) translate(-612.000000, -24.000000) translate(600.000000, 12.000000)">
            <path d="M12,4 C12.5522847,4 13,4.44771525 13,5 L13,11 L19,11 C19.5522847,11 20,11.4477153 20,12 C20,12.5522847 19.5522847,13 19,13 L13,13 L13,19 C13,19.5522847 12.5522847,20 12,20 C11.4477153,20 11,19.5522847 11,19 L11,13 L5,13 C4.44771525,13 4,12.5522847 4,12 C4,11.4477153 4.44771525,11 5,11 L11,11 L11,5 C11,4.44771525 11.4477153,4 12,4 Z" id="Combined-Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
