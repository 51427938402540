import React, { FC } from 'react';
import styles from '../../LanguageForm.module.scss';
import { HeaderModels } from '../../../../header.models';

const LanguageOption: FC<HeaderModels.LanguageOptionProps> = ({ option, onChange, isSelected }) => (
  <button
    type="button"
    key={option.value}
    onMouseDown={() => {
      onChange(option.value);
    }}
    className={styles['select-option']}
  >
    {isSelected ? (
      <>
        <span className={styles.active}>{option.label}</span>
        <div className={styles['select-option-main-label']} />
      </>
    ) : (
      option.label
    )}
  </button>
);

export default LanguageOption;
