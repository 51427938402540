import store from '../+store/store';
import { LANGUAGES } from '../app/language/+store/languages';

const transformArticleCreatedTitlesToDto = () => {
  const state = store.getState();
  const { articleTitle } = state.adminArticleCreateFormReducer;
  const titleDtoCreatedArticle = [];
  Object.keys(articleTitle).forEach(lang => titleDtoCreatedArticle.push({
    id: null,
    language: LANGUAGES[lang.toUpperCase()],
    titleValue: articleTitle[lang],
  }));
  return titleDtoCreatedArticle;
};

export default transformArticleCreatedTitlesToDto;
