import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabsModels } from './tabs.models';

import styles from '../../../shared-ui/tabs/tabs.module.scss';

import TabsItem from './TabsItem';

const Tabs: FC<TabsModels.Props> = ({ activeTabIndex, handleChangeCategoryTab, categories }) => {
  const [translate] = useTranslation();

  const allCategory = {
    id: 0,
    name: `${translate('all')}`,
  };

  return categories.length > 1 ? (
    <ul className={styles.labelsList}>
      {[allCategory, ...categories].map((category) => (
        <TabsItem
          key={category.id}
          category={category}
          handleChangeCategoryTab={handleChangeCategoryTab}
          categories={categories}
          isActive={category.id === activeTabIndex}
        />
      ))}
    </ul>
  ) : null;
};

export default memo(Tabs);
