import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import styles from './cardsGrid.module.scss';
import Card from './Card';
import PopupMessageDelete from './popupMessageDelete';
import { WAFModels } from '../../../../../../models/WAFPage.models';

interface CardsGridProps {
  petsList: WAFModels.Pet[];
  deleteMessageVisible: boolean;
  history: History;
  handleCurrentDogCardIdUpdate: (event: MouseEvent) => void;
}

const CardsGrid: FC<CardsGridProps> = ({
  petsList,
  deleteMessageVisible,
  history,
  handleCurrentDogCardIdUpdate,
}) => (
  <>
    <div className={styles['cards-grid']}>
      {petsList.map(petData => (
        <Card
          petData={petData}
          key={petData.id}
          handleCurrentDogCardIdUpdate={handleCurrentDogCardIdUpdate}
          history={history}
        />
      ))}
    </div>
    {deleteMessageVisible && <PopupMessageDelete />}
  </>
);

const mapStateToProps = state => ({ deleteMessageVisible: state.wantAFriendPagesReducer.deleteMessageVisible });

export default connect(mapStateToProps, null)(CardsGrid);
