/* eslint-disable max-len */
import React from 'react';
import { IconModels } from '../icon.models';

const SVG = (props: IconModels.Props) => (
  <svg {...props} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <rect id="path-1" x="0" y="0" width="392" height="48" rx="4" />
    </defs>
    <g id="Profile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[4.1][D]-Profile-–-Language-–-Open" transform="translate(-780.000000, -284.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1448" height="940" />
        <g id="Dropdown-Dropdown-/-Linear-/-Entered" transform="translate(424.000000, 272.000000)">
          <g id="Base">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use id="Background" fill="#F5F5F5" xlinkHref="#path-1" />
          </g>
          <g id="Icon-Icons-/-Arrow-/-Up" transform="translate(356.000000, 12.000000)" fill="currentColor">
            <path d="M8.11576839,9.29423077 L12,13.17 L12,13.17 L15.8842316,9.29423077 C16.2741412,8.90517069 16.905515,8.90551495 17.295,9.295 C17.6843607,9.68436075 17.6843607,10.3156393 17.295,10.705 L12.7071068,15.2928932 C12.3165825,15.6834175 11.6834175,15.6834175 11.2928932,15.2928932 L6.705,10.705 C6.31563925,10.3156393 6.31563925,9.68436075 6.705,9.295 C7.09448505,8.90551495 7.72585884,8.90517069 8.11576839,9.29423077 Z" id="Path" transform="translate(12.000000, 12.295000) scale(1, -1) translate(-12.000000, -12.295000) " />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
