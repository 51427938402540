import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styles from './FAQList.module.scss';
import animation from './animation.scss'; // eslint-disable-line no-unused-vars

const FAQListComponent = ({ index, isOpened, question: { question, answer }, onToggleResponse }) => (
  <div className={styles.question__wrapper} key={question}>
    <div
      role="presentation"
      className={isOpened ? styles.question__closed : styles.question}
      onClick={onToggleResponse.bind(null, index)}
    >
      {question}
      <div className={styles.number}>{index + 1}</div>
    </div>
    {isOpened && <div className={styles.response}>{parse(answer)}</div>}
  </div>
);

FAQListComponent.propTypes = {
  index: PropTypes.number.isRequired,
  isOpened: PropTypes.bool,
  question: PropTypes.shape({
    answer: PropTypes.string,
    id: PropTypes.number,
    question: PropTypes.string,
  }).isRequired,
  onToggleResponse: PropTypes.func.isRequired,
};

FAQListComponent.defaultProps = { isOpened: false };

export default FAQListComponent;
