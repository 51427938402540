import React, { FC, ReactNode, useState } from 'react';

import style from './Dropdown.module.scss';
import Icon from '../icon';

import useOnOutsideClick from '../../../hooks/useOnOutsideClick';

interface DropdownProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode,
  // eslint-disable-next-line react/require-default-props
  content?: ReactNode
}

const Dropdown: FC<DropdownProps> = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [dropdownRef] = useOnOutsideClick<HTMLDivElement>({
    handler: () => {
      setIsOpen(false);
    },
  });

  const handleChangeIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div ref={dropdownRef} className={`${style.dropdown} ${isOpen ? style['is-open'] : ''}`}>
      <button
        className={style['dropdown-button']}
        onClick={handleChangeIsOpen}
      >
        {children}
        <Icon name="arrow-down" />
      </button>
      {
        isOpen && (
          <div
            onClick={handleChangeIsOpen}
            role="textbox"
            tabIndex={0}
            className={style['dropdown-content']}
          >
            {content}
          </div>
      )
    }
    </div>
  )
};


export default Dropdown;
