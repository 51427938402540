import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'

export namespace FooterModels {
  type AnchorElement = Omit<
    DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    'href' | 'className'
  >

  interface BaseLinkProps {
    text: string,
    path: string
  }

  export interface HiddenLinkProps extends AnchorElement, BaseLinkProps {}

  export interface LinkProps extends BaseLinkProps {
    wrapperClasses?: string,
    isCaption?: boolean,
    state?: object
    onClick?: () => void
  }

  export const enum DropdownMenuNames {
    Submenu1 = 'Submenu1',
    Submenu2 = 'Submenu2'
  }
}
