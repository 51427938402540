/* eslint-disable max-len */
import React from 'react';
import { IconModels } from '../icon.models';

const SVG = (props: IconModels.Props) => (
  <svg
    {...props}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Back-–-Hover" transform="translate(-376.000000, -20.000000)">
        <g id="Navigation-Navigation-/-Admin-/-Article">
          <g id="Group" transform="translate(152.000000, 20.000000)" fill="currentColor">
            <g id="Icon-Icons-/-Arrow-/-Down" transform="translate(224.000000, 0.000000)">
              <path d="M8.11576839,9.29423077 L12,13.17 L12,13.17 L15.8842316,9.29423077 C16.2741412,8.90517069 16.905515,8.90551495 17.295,9.295 C17.6843607,9.68436075 17.6843607,10.3156393 17.295,10.705 L12.7071068,15.2928932 C12.3165825,15.6834175 11.6834175,15.6834175 11.2928932,15.2928932 L6.705,10.705 C6.31563925,10.3156393 6.31563925,9.68436075 6.705,9.295 C7.09448505,8.90551495 7.72585884,8.90517069 8.11576839,9.29423077 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
