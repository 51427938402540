/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <title>Layer 1</title>
      <g id="Profile" fill="none" fillRule="evenodd">
        <g id="[4.1][D]-Profile-–-Alert">
          <rect fill="#FFFFFF" x="-597" y="-92" width="1448" height="940" id="svg_1" />
          <g id="Alert-Alerts-/-Default">
            <g id="Base">
              <use fill="black" filter="url(#filter-2)" xlinkHref="#path-1" y="-12" x="-12" id="svg_2" />
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" y="-12" x="-12" id="svg_3" />
            </g>
            <rect id="Base" fillOpacity="0.1" fill="#F9BF41" x="-4" y="-4" width="32" height="32" rx="16" />
            <g id="Icon-Icons-/-Check-mark" fill="#F9BF41">
              <path fillRule="evenodd" fill="#373737" d="m12,17c1.10457,0 2,0.89543 2,2c0,1.10457 -0.89543,2 -2,2c-1.10457,0 -2,-0.89543 -2,-2c0,-1.10457 0.89543,-2 2,-2zm0.12384,-14c1.72485,0 3.12311,1.39826 3.12311,3.12311c0,0.25535 -0.03132,0.50973 -0.09325,0.75746l-1.78732,7.14929c-0.14254,0.57016 -0.65483,0.97014 -1.24254,0.97014c-0.5877,0 -1.09999,-0.39998 -1.24253,-0.97014l-1.78732,-7.14929c-0.41834,-1.67334 0.59905,-3.36899 2.27239,-3.78732c0.24773,-0.06193 0.50211,-0.09325 0.75746,-0.09325z" id="svg_4" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
