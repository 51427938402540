import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { Article } from '../../../types/article.model';
import { DispatchFn } from '../../../types/dispatch.type';
import { BlogActionsModels } from './store.models';
import { BlogPageModels } from '../../../models/blogPage.models'

import BlogActionTypes from './types';

import requestWithHeaders from '../../../utils/withHeaders';

import { URL_BLOG_ARTICLES,
  URL_BLOG_CATEGORIES,
  URL_BLOG_ARTICLES_BY_CATEGORY,
  URL_BLOG_MAIN_ARTICLE } from '../../../endpoints';
import { Action } from '../../../types/action.interface';

const fetchStart = () => ({ type: BlogActionTypes.FETCH_START });

const fetchError = (error: AxiosError | null) => ({
  type: BlogActionTypes.FETCH_ERROR,
  payload: error,
});

const fetchArticlesSuccess = (articles: Article[]) => ({
  type: BlogActionTypes.FETCH_ARTICLES,
  payload: articles,
});

const fetchCategoriesSuccess = (categories: BlogPageModels.CategoryDto[]) => ({
  type: BlogActionTypes.FETCH_CATEGORIES,
  payload: categories,
});

const fetchArticlesByCategorySuccess = (articles: Article[]) => ({
  type: BlogActionTypes.FETCH_ARTICLES_BY_CATEGORY,
  payload: articles,
});

const fetchMainArticleSuccess = (article: Article) => ({
  type: BlogActionTypes.FETCH_MAIN_ARTICLE,
  payload: article,
});

const fetchPaginationSuccess = (articles: Article[]) => ({
  type: BlogActionTypes.FETCH_PAGINATION,
  payload: articles,
});

const getOptionFetchArticles = (pagination: number) => ({
  request: requestWithHeaders({ url: `${URL_BLOG_ARTICLES}${pagination}` }),
  action: (data: Article[]) => fetchArticlesSuccess(data),
});

const getOptionFetchArticlesByCategory = (category: number, pagination: number) => ({
  request: requestWithHeaders({ url: `${URL_BLOG_ARTICLES_BY_CATEGORY}/${category}/${pagination}` }),
  action: (data: Article[]) => fetchArticlesByCategorySuccess(data),
});

export namespace BlogActions {
  export const fetchPageInfoBuilder = (
    categoryId: number,
    paginationId: number,
    options: BlogActionsModels.ArticleOptions,
  ) => (dispatch: DispatchFn) => {
      const fetchConfig: {
        request: AxiosPromise<Article | Article[] | BlogPageModels.CategoryFetchData>;
        action: (param: Article | Article[] | BlogPageModels.CategoryFetchData) =>
          Action<Article | Article[] | BlogPageModels.CategoryDto[]>;
      }[] = [];

      if (options.isMainArticle) {
        fetchConfig.push({
          request: requestWithHeaders({ url: URL_BLOG_MAIN_ARTICLE }),
          action: (data: Article) => fetchMainArticleSuccess(data),
        });
      }

      if (options.isArticles) {
        fetchConfig.push(
          categoryId === 0
            ? getOptionFetchArticles(paginationId)
            : getOptionFetchArticlesByCategory(categoryId, paginationId),
        );
      }

      if (options.isCategories) {
        fetchConfig.push({
          request: requestWithHeaders({ url: URL_BLOG_CATEGORIES }),
          action: (data: BlogPageModels.CategoryFetchData) => fetchCategoriesSuccess(data.currentCategories),
        });
      }

      dispatch(fetchStart());
      Promise.all(fetchConfig.map((f) => f.request))
        .then((responses) => {
          responses.forEach((r, idx) => {
            dispatch(fetchConfig[idx].action(r.data));
          });
        })
        .catch((error) => {
          dispatch(fetchError(error));
        });
    };

  export const updatePagination = (pagination: number) => ({
    type: BlogActionTypes.UPDATE_PAGINATION,
    payload: pagination,
  });

  export const fetchPagination = (pagination: number) => (dispatch: DispatchFn) => {
    dispatch(fetchStart());
    requestWithHeaders({ url: `${URL_BLOG_ARTICLES}${pagination}` })
      .then((response: AxiosResponse<Article[]>) => dispatch(fetchPaginationSuccess(response.data)))
      .catch((error: AxiosError) => dispatch(fetchError(error)));
  };

  export const fetchPaginationByCategory = (category: number, pagination: number) => (dispatch: DispatchFn) => {
    dispatch(fetchStart());
    requestWithHeaders({ url: `${URL_BLOG_ARTICLES_BY_CATEGORY}/${category}/${pagination}` })
      .then((response: AxiosResponse<Article[]>) => dispatch(fetchPaginationSuccess(response.data)))
      .catch((error: AxiosError) => dispatch(fetchError(error)));
  };

  export const clearArticles = () => ({ type: BlogActionTypes.CLEAR_ARTICLES });
}
