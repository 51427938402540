import React from 'react';
import styles from './LeftArrow.module.scss';
import { LeftArrowProps } from './LeftArrow.models';

const LeftArrow = ({ goToPrevSlide, isFirstPicture }:LeftArrowProps) => (
  <button
    className={`${styles.arrow} ${(isFirstPicture) ? styles.hidden : ''}`}
    onClick={isFirstPicture ? null : goToPrevSlide}
    tabIndex={0}
  >
    <i className={styles.icon} />
  </button>
);


export default LeftArrow;
