/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { BlogPopupActionsTypes } from './blogPopupActions.models';
import { BlogActionsTypes } from '../../../+store/blogActions.models';
import { LANGUAGES } from '../../../../../../language/+store/languages';
import { transformCategoryDtoAfterUpdate } from '../../../../../../../utils/transformCategoryDto';
import { defaultTranslation } from '../../../constants';
import { BlogPopupAction, BlogPopupStoreModels } from './blogPopupStore.models';

export const INITIAL_STATE: BlogPopupStoreModels.State = {
  activeLanguage: LANGUAGES.UK,
  articleTitle: defaultTranslation,
  articleDescription: defaultTranslation,
  articleValidationError: '',
  canBeDeleted: [],
  cancelMessageVisible: false,
  categories: [],
  categoriesWithArticles: [],
  categoryInNameChange: null,
  categoryName: '',
  categoryValidationError: '',
  error: null,
  inputCategory: null,
  isEditing: false,
  isLoading: false,
  isMain: false,
  imgIsLoading: false,
  imgIsLoaded: false,
  mainImgLink: '',
  maxCategoriesAmount: 6,
  selectedImg: null,
  selectedCategory: null,
  isTouched: false,
};

const adminArticleCreateFormReducer = (state = INITIAL_STATE, action: BlogPopupAction) => {
  switch (action.type) {
    case BlogActionsTypes.ARTICLE_CREATED_SUCCESSFULLY:
      return {
        ...state,
        articleTitle: defaultTranslation,
        selectedCategory: null,
        isMain: false,
        mainImgLink: '',
      };
    case BlogPopupActionsTypes.ARTICLE_DESCRIPTION_CHANGE:
      return {
        ...state,
        articleValidationError: '',
        articleDescription: {
          ...state.articleDescription,
          [action.payload.articleChangeData.language]: action.payload.articleChangeData.description,
        },
      };
    case BlogPopupActionsTypes.ARTICLE_IS_MAIN_CHECKBOX_CHANGE:
      return {
        ...state,
        isMain: !state.isMain,
      };
    case BlogPopupActionsTypes.ARTICLE_TITLE_CHANGE:
      return {
        ...state,
        articleValidationError: '',
        articleTitle: {
          ...state.articleTitle,
          [action.payload.articleChangeData.language]: action.payload.articleChangeData.title,
        },
      };
    case BlogPopupActionsTypes.CATEGORY_NAME_CHANGE:
      return {
        ...state,
        categoryInNameChange: action.payload.category,
        isTouched: true,
      };
    case BlogPopupActionsTypes.CATEGORY_LANGUAGE_CHANGE:
      return {
        ...state,
        activeLanguage: action.payload.language,
      };
    case BlogPopupActionsTypes.CATEGORY_VALIDATION_ERROR:
      return {
        ...state,
        categoryValidationError: action.payload.validationError,
        selectedCategory: null,
      };
    case BlogActionsTypes.CLOSE_MODAL_WINDOW:
    case BlogActionsTypes.ARTICLE_UPDATED_SUCCESSFULLY:
      if (action.payload.closeModalWindowData?.saveArticleInForm) return { ...state };
      return {
        ...state,
        articleTitle: defaultTranslation,
        articleDescription: defaultTranslation,
        selectedCategory: null,
        categoryValidationError: '',
        articleValidationError: '',
        inputCategory: null,
        isMain: false,
        mainImgLink: null,
      };
    case BlogPopupActionsTypes.DELETE_CATEGORIES_END:
      return {
        ...state,
        categories: state.categories.filter((_, index) => index !== action.payload.index),
        isLoading: false,
        selectedCategory: action.payload.category,
      };
    case BlogPopupActionsTypes.DELETE_CATEGORIES_START:
      return {
        ...state,
        isLoading: true,
      };
    case BlogPopupActionsTypes.DELETE_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case BlogPopupActionsTypes.DISPLAY_CANCEL_MESSAGE:
      return {
        ...state,
        cancelMessageVisible: true,
      };
    case BlogPopupActionsTypes.DISPLAY_ERROR_MESSAGE:
      return {
        ...state,
        articleValidationError: action.payload.errorMessage,
      };
    case BlogPopupActionsTypes.EDIT_CATEGORY_CANCEL:
      return {
        ...state,
        isEditing: false,
        isTouched: false,
        categoryInNameChange: null,
      };
    case BlogPopupActionsTypes.EDIT_CATEGORY_START:
      return {
        ...state,
        isEditing: true,
        categoryInNameChange: action.payload.category,
      };
    case BlogPopupActionsTypes.ENTER_NEW_CATEGORY:
      return {
        ...state,
        inputCategory: action.payload.newCategoryInputData,
      };
    case BlogPopupActionsTypes.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        categoriesWithArticles: state.categoriesWithArticles.concat(
          action.payload.articles.map(item => ({ id: item.categoryId })),
        ),
        isLoading: false,
      };
    case BlogPopupActionsTypes.FETCH_CATEGORIES_START:
      return {
        ...state,
        isLoading: true,
      };
    case BlogPopupActionsTypes.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case BlogPopupActionsTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload.categoryFetchData,
        isLoading: false,
      };
    case BlogPopupActionsTypes.HIDE_CANCEL_MESSAGE:
      return {
        ...state,
        cancelMessageVisible: false,
      };
    case BlogPopupActionsTypes.POST_ARTICLE_IMAGE_DELETE:
      return {
        ...state,
        imgIsLoaded: false,
        mainImgLink: null,
      };
    case BlogPopupActionsTypes.POST_ARTICLE_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        imgIsLoading: false,
        error: action.payload.error,
        imgIsLoaded: false,
      };
    case BlogPopupActionsTypes.POST_ARTICLE_IMAGE_START:
      return {
        ...state,
        imgIsLoading: true,
      };
    case BlogPopupActionsTypes.POST_ARTICLE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        imgIsLoading: false,
        imgIsLoaded: !!action.payload.imgLink,
        mainImgLink: action.payload.imgLink,
      };
    case BlogPopupActionsTypes.POST_CATEGORY_SUCCESS:
      return {
        ...state,
        canBeDeleted: [
          ...state.canBeDeleted,
          action.payload.category.id,
        ],
        categories: [
          ...state.categories,
          action.payload.category,
        ],
        inputCategory: null,
      };
    case BlogPopupActionsTypes.SELECTED_CATEGORY_CHANGE:
      return {
        ...state,
        articleValidationError: '',
        selectedCategory: action.payload.category,
      };
    case BlogPopupActionsTypes.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        categoryInNameChange: null,
        error: action.payload.error,
      };
    case BlogPopupActionsTypes.UPDATE_CATEGORY_START:
      return {
        ...state,
        isLoading: true,
      };
    case BlogPopupActionsTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((el) => {
          const { category, language } = action.payload;
          if (el.id === action.payload.category.id) {
            return transformCategoryDtoAfterUpdate(el, category, language);
          }
          return el;
        }),
        categoryInNameChange: null,
        isLoading: false,
        isTouched: false,
      };
    default:
      return state;
  }
};

export default adminArticleCreateFormReducer;
