/* eslint-disable react/no-unused-state */
import React, { Component, FormEvent } from 'react';
import { withRouter } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProfileImage from './components/profileImage/ProfileImage';
import Input from '../../../shared-ui/input';
import LanguageSelect from './components/languageSelect/LanguageSelect';
import Button from '../../../shared-ui/button';
import styles from './ProfileForm.module.scss';
import { ProfilePageActions } from './+store/actions';
import selectLanguage from '../../../language/+store/actions';
import ChangePassword from './components/changePassword/ChangePassword';
import Alerts from '../../../shared-ui/alert';
import browserStorage from '../../../../utils/localStorage';
import { ProfileFormModels, InputValues } from './profileForm.models';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { ButtonTypes } from '../../../shared-ui/button/button.models';
import adminUploadToCloudinaryRequest from '../../../../utils/postCloudinaryImage';

const POPUP_TIMER = 5000;
const EMAIL_REGEXP = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$');
const LOGIN_MIN_LENGTH = 2;
const ALBUM_NAME = 'profile';

class ProfileForm extends Component<ProfileFormModels.Props, ProfileFormModels.State> {
  constructor(props: ProfileFormModels.Props) {
    super(props);
    this.state = {
      userId: 1,
      imageLink: '',
      selectedImg: '',
      login: '',
      email: '',
      preferredLanguage: '',
      marker: false,
      isPopupShown: 'popup-shown',
      isPopupHidden: 'popup-hidden',
      errors: {
        imageLink: '',
        login: '',
        email: '',
        preferredLanguage: '',
      },
      disabled: true,
      isModalOpen: false,
    };

    this.handleImgSelected = this.handleImgSelected.bind(this);
  }

  componentDidMount() {
    const { fetchAdminProfileData, cookies, selectedLanguage } = this.props;
    this.setState({ preferredLanguage: selectedLanguage.languageValue });
    fetchAdminProfileData(cookies);
  }

  componentDidUpdate(prevProps: ProfileFormModels.Props) {
    const { userData, selectedLanguage } = this.props;
    const initialUserData = prevProps.userData;
    if (userData !== initialUserData) {
      this.setState({
        userId: userData.userId,
        imageLink: userData.imageLink,
        login: userData.login,
        email: userData.email,
        preferredLanguage: selectedLanguage.languageValue,
      });

      browserStorage.setKeyValue('imageLink', userData.imageLink);
    }
    return null;
  }

  handleImgSelected = async (mainImg) => {
    if (mainImg) {
      const { cookies } = this.props;
      const imgWithPath = `${ALBUM_NAME}/${mainImg.name}`;
      const formData = new FormData();
      formData.append('image', mainImg, imgWithPath);
      const imageUrl = await adminUploadToCloudinaryRequest(cookies, formData);
      this.setState({
        disabled: false,
        imageLink: imageUrl,
        selectedImg: imageUrl,
      });
    }
  };

  handleInputChange = (key: InputValues, value: string) => {
    const { userData } = this.props;

    this.setState((state) => ({ ...state, [key]: value }));

    if (value.trim().length) {
      this.setState({
        disabled: false,
        errors: {
          imageLink: '',
          login: '',
          email: '',
          preferredLanguage: '',
        },
      });

      if (userData[key] === value) {
        this.setState({ disabled: true });
      }
    } else {
      this.setState({ disabled: true });
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      userId,
      imageLink,
      login,
      email,
      preferredLanguage: newlang,
    } = this.state;

    const {
      i18n,
      handleSelectLanguage,
      sendUpdateRequest,
      cookies,
      t: translate,
    } = this.props;

    this.setState({ preferredLanguage: newlang });
    i18n.changeLanguage(newlang);

    const setLoginError = () => {
      this.setState((state) => ({ errors: { ...state.errors, login: translate(TRANSLATE_KEYS.loginError) } }));
    };

    const setEmailError = () => {
      this.setState((state) => ({ errors: { ...state.errors, email: translate(TRANSLATE_KEYS.emailError) } }));
    };

    const setLanguageError = () => {
      this.setState((state) => ({
        errors: {
          ...state.errors,
          preferredLanguage: translate(TRANSLATE_KEYS.languageError),
        },
      }));
    };

    const setImageError = () => {
      this.setState((state) => ({ errors: { ...state.errors, imageLink: translate(TRANSLATE_KEYS.imageError) } }));
    };

    const setNoError = () => {
      this.setState({ errors: { login: '', email: '', preferredLanguage: '', imageLink: '' } });
    };


    if (login.length < LOGIN_MIN_LENGTH) {
      setLoginError();
    } else if (!EMAIL_REGEXP.test(email)) {
      setEmailError();
    } else if (!newlang) {
      setLanguageError();
    } else if (!imageLink) {
      setImageError();
    } else {
      setNoError();

      sendUpdateRequest({
        userId,
        userData: {
          login,
          email,
          imageLink,
          preferredLanguage: { languageValue: newlang },
          userId,
        },
      }, cookies);

      this.showPopup();
    }
    handleSelectLanguage(newlang);
  };

  showPopup() {
    this.setState((state) => ({ ...state, marker: !state.marker }));
    const hidePopup = () => {
      this.setState((state) => ({ ...state, marker: !state.marker }));
    };
    setTimeout(hidePopup, POPUP_TIMER);
  }

  render() {
    const {
      login,
      email,
      imageLink,
      preferredLanguage,
      errors,
      disabled,
      marker,
      isPopupShown,
      isPopupHidden,
    } = this.state;

    const { t: translate, isModalOpen, openPasswordModal, hideProfileAlert } = this.props;

    return (
      <>
        <div className={styles.wrapper}>
          <div className={marker ? styles[isPopupShown] : styles[isPopupHidden]}>
            <Alerts
              messageType="update"
              iconType="update"
              message={translate(TRANSLATE_KEYS.updateSuccessfullySaved)}
            />
          </div>
          <div className={hideProfileAlert ? styles['popup-shown'] : styles['popup-hidden']}>
            <Alerts message={translate(TRANSLATE_KEYS.passwordChanged)} messageType="update" iconType="update" />
          </div>
          <h2 className={styles.title}>{translate(TRANSLATE_KEYS.profileTitle)}</h2>
          <form
            onSubmit={this.handleSubmit}
            className={styles['form-container']}
          >
            <div>
              <ProfileImage onImgUpload={this.handleImgSelected} imageLink={imageLink} />
              <p className={styles.error}>{errors.imageLink}</p>
            </div>
            <div className={styles['input-container']}>
              <div className={styles['field-container']}>
                <Input
                  id="login"
                  placeholder={login}
                  label={translate(TRANSLATE_KEYS.labelName)}
                  onChange={value => this.handleInputChange(InputValues.Login, value)}
                  uiType="lightGrayMarginBottom"
                />
                <p className={styles.error}>{errors.login}</p>
              </div>
              <div className={styles['field-container']}>
                <Input
                  id="email"
                  placeholder={email}
                  label={translate(TRANSLATE_KEYS.labelEmail)}
                  onChange={value => this.handleInputChange(InputValues.Email, value)}
                  uiType="lightGrayMarginBottom"
                />
                <p className={styles.error}>{errors.email}</p>
              </div>
              <div className={styles['field-container']}>
                <LanguageSelect
                  value={preferredLanguage}
                  onChange={(value: string) => {
                    this.handleInputChange(InputValues.PreferredLanguage, value)
                  }}
                />
                <p className={styles.error}>{errors.preferredLanguage}</p>
              </div>
              <div className={styles['button-wrapper']}>
                <Button
                  content={translate(TRANSLATE_KEYS.saveChanges)}
                  disabled={disabled}
                  uiType={
                    disabled
                      ? 'sun-disabled'
                      : 'sunMainBold'}
                  htmlType={ButtonTypes.Submit}
                />
                <Button
                  content={translate(TRANSLATE_KEYS.changePassword)}
                  uiType="gray"
                  onClick={openPasswordModal}
                  htmlType={ButtonTypes.Button}
                />
              </div>
            </div>
          </form>
        </div>
        {isModalOpen && <ChangePassword />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedLanguage: state.languageReducer.selectedLanguage,
  userData: state.adminProfileReducer.userData,
  error: state.adminProfileReducer.error,
  selectedImg: state.adminProfileReducer.selectedImg,
  isModalOpen: state.adminProfileReducer.isModalOpen,
  hideProfileAlert: state.adminProfileReducer.displayAlert,
});

const mapDispatchToProps = dispatch => ({
  handleSelectLanguage: (value: string) => dispatch(
    selectLanguage(value),
  ),
  sendUpdateRequest: (credentials: ProfileFormModels.UpdateRequestCredentials, cookies: Cookies) => dispatch(
    ProfilePageActions.adminUpdateProfileRequest(cookies, credentials),
  ),
  fetchAdminProfileData: (cookies: Cookies) => dispatch(
    ProfilePageActions.adminFetchProfileDataRequest(cookies),
  ),
  openPasswordModal: () => dispatch(ProfilePageActions.openChangePasswordModal()),

});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(ProfileForm))),
);
