const getNextMainPhoto = (imagesList, currentClickedPhotoId) => (
  imagesList.reduce((nextMainPhoto, currentImage, currentIndex) => {
    if (nextMainPhoto && currentIndex >= currentClickedPhotoId) return nextMainPhoto;
    if (currentImage.photo && currentImage.id !== currentClickedPhotoId) {
      return currentImage;
    }

    return nextMainPhoto;
  }, undefined)
);

const buildImagesListWithNewMainPhoto = (imagesList, currentClickedPhotoId) => {
  const nextMainPhoto = getNextMainPhoto(imagesList, currentClickedPhotoId);
  const currentClickedPhoto = imagesList.find((image) => image.id === currentClickedPhotoId);
  const shouldClickedPhotoBeMain = !currentClickedPhoto.main || !nextMainPhoto;

  return imagesList.map((image) => ({
      ...image,
      main: shouldClickedPhotoBeMain ? image.id === currentClickedPhoto.id : image.id === nextMainPhoto.id,
    }));
};

export default buildImagesListWithNewMainPhoto;
