import React, { KeyboardEvent } from 'react';
import styles from './input.module.scss';
import { InputProps } from './input.models';
import { KeyCodes } from '../../../constants';

const Input = (props: InputProps) => {
  const { htmlType, id, placeholder, onChange, label, uiType, disabled } = props;
  const handleInputKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === KeyCodes.Enter) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
    <div className={`${styles['form-item']} ${styles[uiType]}`}>
      <input
        type={htmlType}
        id={id}
        placeholder={placeholder as string}
        onChange={event => onChange(event.target.value)}
        className={`${styles['form-input']} ${styles[uiType]}`}
        disabled={disabled}
        onKeyDown={handleInputKeydown}
      />
      <label className={styles['form-label']} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Input.defaultProps = { htmlType: 'text', placeholder: null, disabled: false };

export default Input;
