import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import DescriptionTemplate from '../DescriptionTemplate';
import styles from './DogDescription.module.scss';
import dataDescription from '../../../../../mockData/dataDescription.json';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import ServiceInfo from '../../serviceInfo/ServiceInfo';
import defaultDogServices from './defaultDescriptionData';

const DogDescription: FC = () => (
  <div className={styles.box}>
    {(dataDescription.dogs).map((info: ServicesPageModels.DogDescription, index: number) => (
      <div key={info.paragraphFirst}>
        <DescriptionTemplate
          position={defaultDogServices[index].position}
          firstImg={info.bigImage}
          secondImg={info.smallImage ?? info.smallImage1}
          thirdImg={info.smallImage2 ?? ''}
          extraClassName={defaultDogServices[index].extraClassName}
        >
          <article>
            <ServiceInfo
              details={defaultDogServices[index].details}
              info={info}
            />
          </article>
        </DescriptionTemplate>
      </div>
    ))}
  </div>
);

export default withTranslation('translations')(DogDescription);
