import React, { useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ArticleModels } from './models/article.models';

import Story from './components/story/Story';
import ArticleContent from './components/articleContent/ArticleContent';
import RecommendedArticles from './components/recommendedArticles';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';

const ArticleComponent = ({
  fetchOpenArticle,
  resetArticleError,
  error,
  openArticle,
}: ArticleModels.Props) => {
  const params = useParams<ArticleModels.PageParams>()
  const history = useHistory()
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    if (error) {
      resetArticleError();
      history.push('/404-not-found', { from: window.location.pathname })
    } else {
      fetchOpenArticle(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, error, params.id])

  return openArticle && (
    <>
      <Story
        title={openArticle.title}
        image={openArticle.image}
        date={openArticle.date}
        category={openArticle.category}
      />
      <ArticleContent articleContent={openArticle.articleContent} />
      <RecommendedArticles />
      <SignupForNewsContainer />
    </>
  )
}

export default ArticleComponent
