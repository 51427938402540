import React, { useState, useEffect, FC } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History, Location } from 'history';
import { i18n } from 'i18next';
import Button from '../../../../../../../shared-ui/button';
import { AdminBlogActions } from '../../../../+store/actions';
import Icon from '../../../../../../../shared-ui/icon';
import style from './popupMessage.module.scss';
import { AdminBlogPopupActions } from '../../+store/actions';
import { updateBrowserPath } from '../../../../+store/helpers';
import { TranslateFn } from '../../../../../../../../models/translate-function.models';
import { BlogPageModels } from '../../../../../../../../models/blogPage.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';

interface ChangeMainArticleProps extends RouteComponentProps {
  createdEditing: boolean;
  articleTitles: BlogPageModels.MainArticlesProps;
  cookies: Cookies;
  history: History;
  location: Location;
  i18n: i18n;
  onClosePopupMessage: () => void;
  returnToEditArticle: (id: number) => void;
  updateSnapshotFromPopup: (cookies: Cookies) => void;
  createSnapshotFromPopup: (cookies: Cookies) => Promise<number>;
  submitArticleForm: (cookies: Cookies) => Promise<BlogPageModels.Article>;
  onCloseModalWindow: () => void;
  t: TranslateFn;
}


const ChangeMainArticle: FC<ChangeMainArticleProps> = ({
  createdEditing,
  articleTitles,
  cookies,
  location,
  history,
  i18n: { language },
  onClosePopupMessage,
  returnToEditArticle,
  updateSnapshotFromPopup,
  createSnapshotFromPopup,
  submitArticleForm,
  onCloseModalWindow,
  t: translate,
}) => {
  const [oldMainArticleTitle, setOldMainArticleTitle] = useState('');
  const [newMainArticleTitle, setNewMainArticleTitle] = useState('');

  const getTitles = (titles, activeLanguage) => {
    titles.mainArticleTitles.forEach((el) => {
      if (el.language.languageValue === activeLanguage) setOldMainArticleTitle(el.titleValue);
    });

    titles.newMainArticleTitles.forEach((el) => {
      if (el.language.languageValue === activeLanguage) setNewMainArticleTitle(el.titleValue);
    });
  };

  useEffect(() => {
    getTitles(articleTitles, language);
  }, []);

  const handleCancelChange = () => {
    onClosePopupMessage();
    returnToEditArticle(articleTitles.idArticleForEdit);
  };

  const handleSubmitChange = () => {
    const isSnapshot = !!articleTitles.parentArticleId;
    const shouldCreateSnapshot = !articleTitles.parentArticleId && !articleTitles.snapshotId;
    const newPost = createdEditing;
    if (newPost) {
      submitArticleForm(cookies).then((response) => {
        onClosePopupMessage();
        history.push(`/admin/blog/edit/article/${response.snapshotId}`);
      });
      return;
    }
    if (isSnapshot) {
      updateSnapshotFromPopup(cookies);
    }

    if (shouldCreateSnapshot) {
      if (articleTitles.browserPathCanUpdate) {
        createSnapshotFromPopup(cookies)
          .then((id) => {
            if (id) updateBrowserPath(id, location);
          });
      } else {
        createSnapshotFromPopup(cookies);
      }
    }
    onCloseModalWindow();
    onClosePopupMessage();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.body}>
          <div className={`${style['warning-icon-exclamation']} ${style['warning-exclamation']}`}>
            <Icon name="exclamation-mark" />
          </div>
          <div className={style['text-container']}>
            <header className={`${style.header} ${style['header-delete']}`}>
              {translate(TRANSLATE_KEYS.changeMainArticle)}
            </header>
            <p className={style['warning-text']}>
              {translate(TRANSLATE_KEYS.changeMainArticleWarningBegin)}
              {` "${oldMainArticleTitle}" `}
              {translate(TRANSLATE_KEYS.changeMainArticleQuestionEnd)}
              {` "${newMainArticleTitle}"`}
            </p>
          </div>
        </div>
        <div className={style.buttons}>
          <Button
            content={translate(TRANSLATE_KEYS.cancelBtn)}
            uiType="dark-rose-unfilled"
            onClick={handleCancelChange}
          />
          <Button
            content={translate(TRANSLATE_KEYS.saveNewMainArticle)}
            uiType="sunMainLongTextBold"
            onClick={handleSubmitChange}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  articleTitles: state.articlesPageReducer.articleTitlesToChangeMainArticle,
  createdEditing: state.articlesPageReducer.createdEditing,
});

const mapDispatchToProps = dispatch => ({
  onClosePopupMessage: () => dispatch(AdminBlogActions.toggleChangeMainArticleMessage(null, null)),
  returnToEditArticle: (id: number) => dispatch(AdminBlogActions.editPost(id)),
  createSnapshotFromPopup: (cookies: Cookies) => dispatch(AdminBlogPopupActions.createSnapshot(cookies)),
  updateSnapshotFromPopup: (cookies: Cookies) => dispatch(AdminBlogPopupActions.updateSnapshot(cookies)),
  submitArticleForm: (cookies: Cookies) => dispatch(AdminBlogPopupActions.postArticle(cookies)),
  onCloseModalWindow: () => dispatch(AdminBlogPopupActions.closeModalWindow(false, false)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(ChangeMainArticle))),
);
