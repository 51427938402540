import React, { FC } from 'react';
import { ServicesPageModels } from '../../models/servicesPage.models';
import TemplateLeft from './templateLeft/TemplateLeft';
import TemplateRight from './templateRight/TemplateRight';
import TemplateOther from './templateOther/TemplateOther';

const DescriptionTemplate: FC<ServicesPageModels.DescriptionTemplateProps> = ({
  firstImg,
  secondImg,
  thirdImg = '',
  children,
  position,
  extraClassName,
}) => {
  const templateProps: ServicesPageModels.DescriptionTemplateProps = {
    firstImg,
    secondImg,
    thirdImg,
    children,
    position,
    extraClassName,
  };

  switch (position) {
    case ServicesPageModels.PositionTypes.Left:
      return <TemplateLeft {...templateProps} />;
    case ServicesPageModels.PositionTypes.Right:
      return <TemplateRight {...templateProps} />;
    case ServicesPageModels.PositionTypes.Other:
      return <TemplateOther {...templateProps} />;
    default:
      return <TemplateOther {...templateProps} />;
  }
};

export default DescriptionTemplate;
