import React from 'react';
import PropTypes from 'prop-types';
import styles from './LeftArrow.module.scss';

const LeftArrow = ({ goToPrevSlide }) => (
  <div
    className={styles['arrow-left']}
    onClick={goToPrevSlide}
    role="button"
    tabIndex={0}
    onKeyUp={goToPrevSlide}
  >
    <button className={styles.button} type="button">
      <i className={styles['icon-arrow-left']} />
    </button>
  </div>
);

LeftArrow.propTypes = { goToPrevSlide: PropTypes.func.isRequired };

export default LeftArrow;
