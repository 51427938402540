import { Cookies } from 'react-cookie';
import requestWithHeaders from './withHeaders';
import { URL_CLOUDINARY_IMAGE_UPLOAD } from '../endpoints';

const adminUploadToCloudinaryRequest = (cookies: Cookies, imageFile) => {
    const result = requestWithHeaders({
        method: 'POST',
        url: URL_CLOUDINARY_IMAGE_UPLOAD,
        data: imageFile,
        headers: { 'content-type': 'multipart/form-data' },
    }, cookies)
        .then((response) => response.data)
        .catch((error) => error.response);
    return result;
};

export default adminUploadToCloudinaryRequest;
