import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import styles from './popup.module.scss';
import DogData from './components/DogData';
import Button from '../../../../../shared-ui/button';
import dotsLoader from '../../../../../shared-ui/dotsLoader/dotsLoader';
import adminUploadToCloudinaryRequest from '../../../../../../utils/postCloudinaryImage';
import { AdminWAFPopupActions } from './+store/actions';
import { AdminWAFPageActions } from '../../+store/actions';
import IsUnderCare from './components/IsDogUnderCare';
import { LANGUAGES } from '../../../../../language/+store/languages';
import MessageWindow from '../messageWindow/MessageWindow';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import setBodyScrollDisabled from '../../../../../../utils/toogleBodyScroll';
import { WAFPopupFormModels } from './popupForm.models';
import { WAFPopupStoreModels } from './+store/WAFPopupFormStore.models';
import { LANGUAGE_TABS } from '../../constants';
import { LanguageData } from '../../../../../../models/language.model';
import { CommonModels } from '../../../../../../models/common.models';

const CUSTOM_TIMER = 1500;
const ALBUM_NAME = 'want-a-friend';
const MANDATORY_FIELDS_COUNT = 3;

const PopupComponent = ({
  activeLanguage = LANGUAGES.UK,
  dogData,
  isEditing = false,
  i18n,
  cookies,
  changeFormLanguage,
  dogPhotoAdd,
  closeDogPopup,
  submitCreateForm,
  submitUpdateForm,
  t: translate,
}: WAFPopupFormModels.Props) => {
  const [currentDog, setCurrentDog] = useState<WAFPopupStoreModels.DogFormData>(dogData);
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(false);

  let hintTimer: NodeJS.Timeout;

  useEffect(() => {
    if (isEditing) {
      setCurrentDog(dogData);
    }
    setScrollPosition(window.pageYOffset);
    setIsSubmitButtonAvailable(true);

    setBodyScrollDisabled(true);

    return () => {
      setBodyScrollDisabled(false);
      clearTimeout(hintTimer);
    }
  }, []);

  useEffect(() => {
    setCurrentDog(dogData);
  }, [dogData]);

  const validateFieldsAreNotEmpty = (dogInfo: CommonModels.RecordInfo) => {
    const values = Object.values(dogInfo);
    return values.every(value => !!value.trim());
  };

  const validateFiledsAreMoreMinSymbols = (dogInfo: CommonModels.RecordInfo, minLength: number) => {
    const values = Object.values(dogInfo);
    return values.every(value => value.trim().length >= minLength);
  };

  const isDogHasMainPhoto = (dogPhotos) => !!dogPhotos.find((photo) => photo.main && photo.photo);

  const isDogParameteresFilled = (dogParameters) => {
    const filledParametersCount = Object.entries(dogParameters)
      .filter(parameter => parameter[0] !== 'sterilized' && parameter[1] !== null).length;
    return filledParametersCount === MANDATORY_FIELDS_COUNT;
  }

  const validateFormFields = () => isSubmitButtonAvailable
      && validateFieldsAreNotEmpty(currentDog.dogName)
      && validateFiledsAreMoreMinSymbols(currentDog.dogName, 2)
      && validateFieldsAreNotEmpty(currentDog.dogDescription)
      && validateFiledsAreMoreMinSymbols(currentDog.dogDescription, 1)
      && isDogParameteresFilled(currentDog.dogParameters)
      && isDogHasMainPhoto(currentDog.dogPhotos);

  const handleImgSelected = async (dogPhoto, photoIndex) => {
    setIsSubmitButtonAvailable(false);
    if (!dogPhoto) {
      setIsSubmitButtonAvailable(true);
    }
    const imgWithPath = `${ALBUM_NAME}/${dogPhoto.name}`;
    const formData = new FormData();
    formData.append('image', dogPhoto, imgWithPath);
    const imageLink = await adminUploadToCloudinaryRequest(cookies, formData);
    dogPhotoAdd(imageLink, dogPhoto.name, photoIndex);
    setIsSubmitButtonAvailable(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitButtonAvailable(false);
    showPopup();
    submitCreateForm(cookies);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    setIsSubmitButtonAvailable(false);
    showPopup();
    submitUpdateForm(cookies);
  };

  const handleCancel = () => {
    closeDogPopup();
  };

  const setChangeCreateButton = () => {
    switch (i18n.language) {
      case 'uk':
        return (<div className={styles.createUkButton}>{translate(TRANSLATE_KEYS.createBtn)}</div>);
      default: return translate(TRANSLATE_KEYS.createBtn);
    }
  };

  const getDotsCreateLoader = () => {
    switch (i18n.language) {
      case 'en':
        return (<div className={styles.dotsCreateEnLoader}>{dotsLoader()}</div>);
      default: return (<div className={styles.dotsCreateUkLoader}>{dotsLoader()}</div>);
    }
  };

  const getDotsUpdateLoader = () => {
    switch (i18n.language) {
      case 'en':
        return (<div className={styles.dotsUpdateEnLoader}>{dotsLoader()}</div>);
      default: return (<div className={styles.dotsUpdateUkLoader}>{dotsLoader()}</div>);
    }
  };

  const showPopup = () => {
    setIsHintVisible(true);
    hintTimer = setTimeout(() => {
      setIsHintVisible(false);
      window.location.reload();
    }, CUSTOM_TIMER);
  }

  const isAllFieldsFill = validateFormFields();

  return (
    <div>
      <div className={styles.wrapper} />
      <div className={styles.formWrapper} style={{ top: scrollPosition }}>
        <div className={styles.form}>
          <header className={styles.header}>
            <h3 className={styles.title}>
              {isEditing ? translate(TRANSLATE_KEYS.updateDogTitle) : translate(TRANSLATE_KEYS.createDogTitle)}
            </h3>
          </header>
          {isHintVisible && <MessageWindow isEditingDog={isEditing} />}
          <section>
            <ul className={styles.tabs}>
              {LANGUAGE_TABS.map((item) => {
                const activeClass = activeLanguage.languageId === item.value.languageId ? styles.active : '';
                return (
                  <li
                    key={item.value.languageId}
                    className={`${styles.tab} ${activeClass}`}
                  >
                    <span
                      role="button"
                      onClick={() => changeFormLanguage(item.value)}
                      tabIndex={0}
                    >
                      {item.label}
                    </span>
                  </li>
                );
              })}
            </ul>
            <DogData
              onImageSelected={handleImgSelected}
              language={activeLanguage}
              t={translate}
              currentDog={currentDog}
              isEditing={isEditing}
            />
          </section>
          <footer className={styles.footer}>
            <IsUnderCare
              content={translate(TRANSLATE_KEYS.hasGuardianship)}
              isUnderCare={currentDog.isUnderCare}
            />
            <Button
              content={<div className={styles.cancelButton}>{translate(TRANSLATE_KEYS.declineBtn)}</div>}
              onClick={handleCancel}
              uiType="gray"
            />
            {isEditing ? (
              <Button
                content={isSubmitButtonAvailable ? translate(TRANSLATE_KEYS.saveUpdate) : getDotsUpdateLoader()}
                uiType={
                  isAllFieldsFill
                    ? 'sun'
                    : 'sun-disabled'}
                onClick={handleUpdate}
              />
            ) : (
              <Button
                content={isSubmitButtonAvailable ? setChangeCreateButton() : getDotsCreateLoader()}
                uiType={
                  isAllFieldsFill
                    ? 'sun'
                    : 'sun-disabled'}
                onClick={handleSubmit}
              />
            )}
          </footer>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  dogPhotoAdd: (
    imageLink: string,
    imageName: string,
    index: number,
  ) => dispatch(AdminWAFPopupActions.addDogPhoto(imageLink, imageName, index)),
  changeFormLanguage: (lang: LanguageData) => dispatch(AdminWAFPopupActions.changeLanguageTab(lang)),
  submitCreateForm: (cookies: Cookies) => dispatch(AdminWAFPopupActions.createDog(cookies)),
  submitUpdateForm: (cookies: Cookies) => dispatch(AdminWAFPopupActions.updateDog(cookies)),
  closeDogPopup: () => dispatch(AdminWAFPageActions.closeDogPopupForm()),
});

const mapStateToProps = state => ({
  activeLanguage: state.adminDogPopupFormReducer.activeLanguage,
  dogData: state.adminDogPopupFormReducer.formDog,
  isEditing: state.adminDogPopupFormReducer.isEditing,
});

export default withTranslation('translations')(connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(withCookies(PopupComponent))));
