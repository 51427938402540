import React, { FC, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from '../articleList/articleList.module.scss';
import Icon from '../../../../../shared-ui/icon';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

interface DisabledSettingsButtonProps {
  size?: number;
  position?: { bottom: string };
  t: TranslateFn;
}

const DisabledSettingsButton: FC<DisabledSettingsButtonProps> = ({
  size,
  position,
  t: translate,
}) => (
  <button
    className={styles['article-button--settings-disabled']}
    style={{ width: `${size}px`, height: `${size}px` }}
    disabled
  >
    <Icon name="settings" />
    <span className={styles['tooltip-big-text']} style={position}>
      {translate(TRANSLATE_KEYS.needPublish)}
    </span>
  </button>
);

DisabledSettingsButton.defaultProps = { size: 48, position: { bottom: '63px' } };

export default withTranslation('translations')(connect(null)(DisabledSettingsButton));
