import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TRANSLATE_KEYS from '../../../../i18n_keys';
import { ServicesPageModels } from '../../models/servicesPage.models';

const ServiceInfo: FC<Pick<ServicesPageModels.ServiceInfoProps, 'details' | 'info'>> = ({
    details,
    info,
  }) => {
    const [translate] = useTranslation();

    const getDetailNode = ({
      type,
      text,
      componentClassName = '',
      needToTranslate = true,
      listOfItems,
    }: ServicesPageModels.ServiceDetails) => {
        switch (type) {
            case ServicesPageModels.ServiceDetailsTypes.H1:
              return <h1 key={text} className={componentClassName}>{translate(info[text])}</h1>;
            case ServicesPageModels.ServiceDetailsTypes.H3:
              return <h3 key={text} className={componentClassName}>{translate(TRANSLATE_KEYS[text])}</h3>;
            case ServicesPageModels.ServiceDetailsTypes.H4:
              return <h4 key={text} className={componentClassName}>{translate(TRANSLATE_KEYS[text])}</h4>;
            case ServicesPageModels.ServiceDetailsTypes.P:
              return (
                <p key={text} className={componentClassName}>{needToTranslate ? translate(info[text]) : info[text]}</p>
              );
            case ServicesPageModels.ServiceDetailsTypes.UL:
              return (
                <ul key={listOfItems.length}>
                  {listOfItems.map((foodItem) => (
                    <li key={foodItem} className={componentClassName}>{translate(TRANSLATE_KEYS[foodItem])}</li>
                  ))}
                </ul>
              );
          default:
            return <p key={text}>{text}</p>
        }
    }

    return <>{details.map(getDetailNode)}</>;
};

export default ServiceInfo;
