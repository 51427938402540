import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ArticleContent.module.scss';
import noContentImage from '../../../../assets/images/no-content.svg';

import { ArticleContentModels } from './article-content.models';
import TRANSLATE_KEYS from '../../../../i18n_keys';

import ArticleContentFactory from './ArticleContentFactory';

const ArticleContent = ({ articleContent }: ArticleContentModels.Props) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.box}>
      <div className={styles.wrapper}>
        {articleContent.length
          ? articleContent.map((a) => (
            <ArticleContentFactory key={`article-content-${a.type}-${a.orderOnPage}`} {...a} />
          ))
          : (
            <div className={styles.noArticleContent}>
              <img width="224" height="224" src={noContentImage} alt="No content in article" />
              <h1 className={styles['noArticleContent-title']}>{translate(TRANSLATE_KEYS.noArticleContent)}</h1>
              <h3 className={styles['noArticleContent-subtitle']}>{translate(TRANSLATE_KEYS.noArticleContentTitle)}</h3>
            </div>
          )}
      </div>
    </div>
  );
};

export default ArticleContent;
