import React from 'react';
import PropTypes from 'prop-types';
import styles from './RightArrow.module.scss';

const RightArrow = ({ goToNextSlide }) => (
  <div
    className={styles['arrow-right']}
    onClick={goToNextSlide}
    role="button"
    tabIndex={0}
    onKeyUp={goToNextSlide}
  >
    <button className={styles.button} type="button">
      <i className={styles['icon-arrow-right']} />
    </button>
  </div>
);

RightArrow.propTypes = { goToNextSlide: PropTypes.func.isRequired };

export default RightArrow;
