import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './dogFilter.module.scss';
import FilterButton from '../filterButton/filterButton';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const DogFilter = ({
  translate,
  male,
  female,
  allsex,
  beforeYear,
  fromOneToFiveYears,
  fiveAndMore,
  small,
  medium,
  big,
  genderFilter,
  ageFilter,
  sizeFilter,
  allAgeDogFilter,
  allSizeDogFilter,
  setActiveFilter,
  activeFilter,
}) => {
  const onClick = (filter) => {
    filter();
  };

  return (
    <>
      <aside
        className={activeFilter ? `${styles['dogs-filter']} ${styles.block}` : styles['dogs-filter']}
      >
        <div className={styles['dogs-filter__headerWrapper']}>
          <button onClick={() => setActiveFilter()} className={styles['back-button']} />
          <h3 className={styles['filter-title']}>{translate(TRANSLATE_KEYS.filters)}</h3>
        </div>

        <div className={styles['dogs-filter__filterLabel']}>
          <h3 className={styles['filter-title']}>{translate(TRANSLATE_KEYS.sexDog)}</h3>
          <button
            onClick={() => onClick(allsex)}
            className={`${styles['dogs-filter__button']}
            ${genderFilter === null ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.all)}
          </button>
          <button
            onClick={() => onClick(male)}
            className={`${styles['dogs-filter__button']}
            ${genderFilter === 'male' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.male)}
          </button>
          <button
            onClick={() => onClick(female)}
            className={`${styles['dogs-filter__button']}
            ${genderFilter === 'female' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.female)}
          </button>
        </div>
        <div className={styles['dogs-filter__filterLabel']}>
          <h3 className={styles['filter-title']}>{translate(TRANSLATE_KEYS.age)}</h3>
          <button
            onClick={() => onClick(allAgeDogFilter)}
            className={`${styles['dogs-filter__button']}
            ${ageFilter === null ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.all)}
          </button>
          <button
            onClick={() => onClick(beforeYear)}
            className={`${styles['dogs-filter__button']}
            ${ageFilter === 'beforeYear' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.dogFilterBeforeYear)}
          </button>
          <button
            onClick={() => onClick(fromOneToFiveYears)}
            className={`${styles['dogs-filter__button']}
            ${ageFilter === 'fromOneToFiveYears' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.dogFilterFromOneToFive)}
          </button>
          <button
            onClick={() => onClick(fiveAndMore)}
            className={`${styles['dogs-filter__button']}
            ${ageFilter === 'fiveAndMore' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.dogFilterFiveAndMore)}
          </button>
        </div>
        <div className={styles['dogs-filter__filterLabel']}>
          <h3 className={styles['filter-title']}>{translate(TRANSLATE_KEYS.size)}</h3>
          <button
            onClick={() => onClick(allSizeDogFilter)}
            className={`${styles['dogs-filter__button']}
            ${sizeFilter === null ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.all)}
          </button>
          <button
            onClick={() => onClick(small)}
            className={`${styles['dogs-filter__button']}
            ${sizeFilter === 'small' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.small)}
          </button>
          <button
            onClick={() => onClick(medium)}
            className={`${styles['dogs-filter__button']}
            ${sizeFilter === 'medium' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.medium)}
          </button>
          <button
            onClick={() => onClick(big)}
            className={`${styles['dogs-filter__button']}
            ${sizeFilter === 'big' ? styles['dogs-filter__button_active'] : ''}`}
          >{translate(TRANSLATE_KEYS.big)}
          </button>
        </div>
      </aside>
      <FilterButton
        sizeFilter={sizeFilter}
        genderFilter={genderFilter}
        ageFilter={ageFilter}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    </>
  );
};


DogFilter.propTypes = {
  translate: PropTypes.func.isRequired,
  male: PropTypes.func.isRequired,
  female: PropTypes.func.isRequired,
  allsex: PropTypes.func.isRequired,
  beforeYear: PropTypes.func.isRequired,
  fromOneToFiveYears: PropTypes.func.isRequired,
  fiveAndMore: PropTypes.func.isRequired,
  small: PropTypes.func.isRequired,
  medium: PropTypes.func.isRequired,
  big: PropTypes.func.isRequired,
  ageFilter: PropTypes.string,
  sizeFilter: PropTypes.string,
  genderFilter: PropTypes.string,
  allAgeDogFilter: PropTypes.func.isRequired,
  allSizeDogFilter: PropTypes.func.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  activeFilter: PropTypes.bool.isRequired,
};

DogFilter.defaultProps = { genderFilter: null, sizeFilter: null, ageFilter: null };

export default withTranslation('translations')(DogFilter);
