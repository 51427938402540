import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import DeleteButton from './deleteButton';
import PublishButton from './publishButton';
import HideButton from './hideButton';
import styles from './dogPageButtons.module.scss';
import Icon from '../../../../../../shared-ui/icon';
import { DogPageButtonsModels } from '../dogDetailsPage.models';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { defaultHeaderHeight } from '../../../constants';
import { WAFModels } from '../../../../../../../models/WAFPage.models';

const DodDetailsButtons: FC<DogPageButtonsModels.Props> = ({
  pet,
  title,
  position = { top: defaultHeaderHeight },
  handleUpdateDog,
  t: translate,
}) => (
  <div className={styles['buttons-wrapper']}>
    <DeleteButton pet={pet} title={title} position={position} />
    <button
      className={styles['buttons-wrapper__settings']}
      onClick={handleUpdateDog}
    >
      <Icon name="settings" />
      <span className={styles['tooltip-text']} style={position}>{translate(TRANSLATE_KEYS.editBtn)}</span>
    </button>
    {pet.dogStatus === WAFModels.PetPublishStatuses.PUBLISHED
      ? <HideButton id={pet.id} position={position} />
      : <PublishButton id={pet.id} />
    }
  </div>
);

export default withTranslation('translations')(DodDetailsButtons);
