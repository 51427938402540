/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Ru" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][D]-Create-New-Article-–-Dropdown-–-Edit-category-2" transform="translate(-956.000000, -424.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 112.000000)">
          <g id="Dropdown" transform="translate(32.000000, 264.000000)">
            <g id="Icon" transform="translate(500.000000, 48.000000)" fill="currentColor">
              <path d="M18.2928932,6.29289322 C18.6834175,5.90236893 19.3165825,5.90236893 19.7071068,6.29289322 C20.0675907,6.65337718 20.0953203,7.22060824 19.7902954,7.61289944 L19.7071068,7.70710678 L9.70710678,17.7071068 C9.34662282,18.0675907 8.77939176,18.0953203 8.38710056,17.7902954 L8.29289322,17.7071068 L4.29289322,13.7071068 C3.90236893,13.3165825 3.90236893,12.6834175 4.29289322,12.2928932 C4.65337718,11.9324093 5.22060824,11.9046797 5.61289944,12.2097046 L5.70710678,12.2928932 L9,15.585 L18.2928932,6.29289322 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
