/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M32 16C32 19.1645 31.0616 22.2579 29.3035 24.8891C27.5454 27.5203 25.0466 29.5711 22.1229 30.7821C19.1993 31.9931 15.9823 32.3099 12.8786 31.6926C9.77487 31.0752 6.92394 29.5513 4.6863 27.3137C2.44866 25.0761 0.924806 22.2251 0.307443 19.1214C-0.309921 16.0177 0.0069325 12.8007 1.21793 9.87706C2.42894 6.95345 4.4797 4.45459 7.11088 2.69649C9.74207 0.938384 12.8355 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16Z" fill="#F85871" />
      <path d="M22.82 7.23998C25.03 4.23998 32.05 8.28998 22.82 14.03C13.59 8.28998 20.72 4.23998 22.82 7.23998Z" fill="#F5F5F5" />
      <path d="M23.18 6.84003C25.66 4.65003 31.5 8.51003 23 13.9L22.79 14.03L22.58 13.9C14.11 8.51003 20.03 4.67003 22.43 6.84003L22.6 7.01003L22.78 7.24003L23 7.00003L23.18 6.84003ZM22.82 13.41C24.339 12.5602 25.6239 11.3475 26.56 9.88003C26.7848 9.55383 26.9143 9.17138 26.9337 8.77568C26.9531 8.37997 26.8618 7.98669 26.67 7.64003C26.4812 7.37006 26.2319 7.14805 25.9419 6.99176C25.6519 6.83548 25.3293 6.74926 25 6.74003C24.6597 6.71025 24.3174 6.76815 24.0058 6.90819C23.6942 7.04823 23.4237 7.26576 23.22 7.54003L22.79 8.13003L22.37 7.53003C22.1767 7.26025 21.9164 7.04552 21.6147 6.90699C21.3131 6.76846 20.9806 6.71091 20.65 6.74003C20.3246 6.75459 20.0069 6.84323 19.7209 6.99919C19.435 7.15516 19.1884 7.37434 19 7.64003C18.8042 7.9845 18.7088 8.37697 18.7247 8.7729C18.7406 9.16883 18.8672 9.55238 19.09 9.88003C20.0187 11.35 21.3011 12.5637 22.82 13.41Z" fill="#4B4A4A" />
      <path d="M17.93 15.72C8.89001 8.50002 0.750005 20.82 1.68001 21.51C2.19001 21.89 3.17001 22.51 3.96001 23.01L5.14001 23.74C5.02382 24.864 4.79261 25.9732 4.45001 27.05C6.87802 29.5898 10.0772 31.2562 13.55 31.79C13.4867 29.8804 13.7888 27.9763 14.44 26.18C14.8 25.18 18.44 23.65 20.77 22.18C21.6889 21.4038 22.3812 20.3938 22.7737 19.2568C23.1662 18.1197 23.2444 16.8978 23 15.72H17.93Z" fill="#F5F5F5" />
      <path d="M17.93 15.72C8.89001 8.50002 0.750005 20.82 1.68001 21.51C2.14001 21.85 2.99001 22.4 3.74001 22.87L4.00001 23L5.00001 23.64L5.10001 23.7V23.92C4.97314 24.9383 4.7522 25.9425 4.44001 26.92L4.86001 27.34C5.22844 26.2389 5.4831 25.103 5.62001 23.95C6.85247 24.672 8.20915 25.157 9.62001 25.38C9.66875 25.3856 9.7181 25.3774 9.76234 25.3561C9.80658 25.3349 9.84391 25.3016 9.87001 25.26C10.365 24.3832 10.6722 23.413 10.772 22.411C10.8719 21.4091 10.7622 20.3973 10.45 19.44C10.2539 18.7063 9.99632 17.9905 9.68001 17.3C9.56001 17.03 9.45001 16.82 9.37001 16.67L9.28 16.5L9.00001 16.71L9.23 16.58C9.19433 16.5228 9.1378 16.4816 9.07236 16.4653C9.00691 16.4489 8.93767 16.4586 8.87927 16.4924C8.82086 16.5261 8.77785 16.5813 8.75933 16.6461C8.74081 16.711 8.74822 16.7805 8.78 16.84L8.87001 17C8.94001 17.14 9.05 17.34 9.16001 17.6C9.50102 18.2775 9.78209 18.9835 10 19.71C10.2798 20.5779 10.3833 21.493 10.3043 22.4015C10.2253 23.3099 9.96543 24.1934 9.54001 25C8.17747 24.7555 6.87208 24.2604 5.69001 23.54L5.41001 23.37H5.34001L5.09001 23.22L4.25001 22.7C3.53001 22.25 2.68001 21.7 2.16001 21.34C2.17344 21.2249 2.19686 21.1111 2.23001 21C2.43438 20.5033 2.69259 20.0305 3.00001 19.59C3.93122 18.2374 5.07616 17.0452 6.39001 16.06C7.86212 14.9068 9.62995 14.1928 11.49 14C13.7283 13.8822 15.9242 14.6428 17.61 16.12L17.75 16.23H22.56C22.6706 17.127 22.5888 18.0372 22.32 18.9C22.0151 20.0111 21.3678 20.9979 20.47 21.72C19.68 22.22 18.75 22.72 17.84 23.22L16.6 23.9C16.0297 24.2042 15.4756 24.538 14.94 24.9C14.7272 25.047 14.5265 25.2108 14.34 25.39C14.1849 25.5671 14.0691 25.775 14 26C13.3112 27.8237 12.972 29.7608 13 31.71L13.53 31.79C13.4667 29.8804 13.7688 27.9763 14.42 26.18C14.66 25.55 16.29 24.65 18.05 23.69C18.98 23.19 19.94 22.69 20.75 22.15C21.6674 21.3787 22.3604 20.3747 22.7563 19.2434C23.1521 18.1121 23.2363 16.8951 23 15.72H17.93Z" fill="#4B4A4A" />
      <path d="M23 17.14C22.86 17.1628 22.7168 17.1555 22.5799 17.1188C22.443 17.082 22.3154 17.0165 22.2057 16.9267C22.096 16.8368 22.0067 16.7247 21.9436 16.5977C21.8806 16.4707 21.8452 16.3317 21.84 16.19H22.84L23 17.14Z" fill="#4B4A4A" />
      <path d="M15 18.5C15 18.5989 14.9707 18.6956 14.9157 18.7778C14.8608 18.86 14.7827 18.9241 14.6913 18.9619C14.6 18.9998 14.4994 19.0097 14.4025 18.9904C14.3055 18.9711 14.2164 18.9235 14.1464 18.8536C14.0765 18.7836 14.0289 18.6945 14.0096 18.5975C13.9903 18.5006 14.0002 18.4 14.0381 18.3087C14.0759 18.2173 14.14 18.1392 14.2222 18.0843C14.3044 18.0293 14.4011 18 14.5 18C14.6326 18 14.7598 18.0527 14.8536 18.1464C14.9473 18.2402 15 18.3674 15 18.5Z" fill="#4B4A4A" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
