import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { instanceOf, PropTypes } from 'prop-types';
import { Cookies } from 'react-cookie';
import ListHeader from './components/listHeader/ListHeader';
import CardsGrid from './components/cardsGrid/CardsGrid';
import PaginationBlock from './components/paginationBlock/PaginationBlock';
import DogFilter from './components/dogFilter/DogFilter';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';
import styles from './components/dogFilter/dogFilter.module.scss';
import NoFriendsBlock from './components/noFriendsBlock/NoFriendsBlock';
import scrollToElement from '../../utils/scrollToElement';


class WantAFriendUserPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { activeFilter: false };
    this.setActiveFilter = this.setActiveFilter.bind(this);
  }

  componentDidMount() {
    const { pagesLoaded, history } = this.props;
    const { pet: { id } } = this.props;
    if (pagesLoaded === 0) {
      this.fetchNextPage();
    }
    if (id && history.action === 'POP') {
      scrollToElement(`[id='${id}']`, 200);
    }
  }

  setActiveFilter() {
    this.setState(prev => ({ activeFilter: !prev.activeFilter }));
  }

  fetchNextPage = () => {
    const { fetchPetsRecords, fetchUserPetsRecords, cookies, pagesLoaded } = this.props;
    fetchPetsRecords(pagesLoaded + 1, cookies);
    fetchUserPetsRecords(cookies);
  };

  fetchNextItems = () => {
    const { fetchNextItems } = this.props;
    fetchNextItems();
  };

  render() {
    const {
      petsList,
      filteredPetsList,
      petsAllList,
      petsCount,
      t: translate,
      pagesLoaded,
      totalPages,
      maleDogFilter,
      femaleDogFilter,
      allSexDogFilter,
      beforeYearDogFilter,
      fromOneToFiveYears,
      fiveAndMore,
      smallDogFilter,
      mediumDogFilter,
      bigDogFilter,
      genderFilter,
      sizeFilter,
      ageFilter,
      allAgeDogFilter,
      allSizeDogFilter,
      filtered,
      items,
      history,
    } = this.props;

    const { activeFilter } = this.state;
    const petsAllAmount = petsAllList.length;
    return (
      <>
        <div className={styles.container}>
          <>
            {petsAllAmount
              && (
                <ListHeader
                  petsCount={petsCount}
                />
              )}
            <div style={{ display: 'flex', width: '100%' }}>
              {petsAllAmount
                && (
                  <DogFilter
                    petsAllList={petsAllList}
                    male={maleDogFilter}
                    translate={translate}
                    female={femaleDogFilter}
                    allsex={allSexDogFilter}
                    beforeYear={beforeYearDogFilter}
                    fromOneToFiveYears={fromOneToFiveYears}
                    fiveAndMore={fiveAndMore}
                    small={smallDogFilter}
                    medium={mediumDogFilter}
                    big={bigDogFilter}
                    genderFilter={genderFilter}
                    ageFilter={ageFilter}
                    sizeFilter={sizeFilter}
                    allAgeDogFilter={allAgeDogFilter}
                    allSizeDogFilter={allSizeDogFilter}
                    setActiveFilter={this.setActiveFilter}
                    activeFilter={activeFilter}
                  />
                )}
              {petsCount === 0 ? (
                <NoFriendsBlock translate={translate} petsAllList={petsAllList} />
              ) : (
                <CardsGrid
                  petsList={petsList}
                  translate={translate}
                  activeFilter={activeFilter}
                  history={history}
                >
                  {filtered === false && pagesLoaded < totalPages && (
                    <PaginationBlock fetchNextPage={this.fetchNextPage} translate={translate} />
                  )}
                  {filtered === true && items < filteredPetsList.length && (
                    <PaginationBlock fetchNextPage={this.fetchNextItems} translate={translate} />
                  )}
                </CardsGrid>
              )}
            </div>
          </>
        </div>
        <SignupForNewsContainer />
      </>
    );
  }
}

WantAFriendUserPageComponent.propTypes = {
  pagesLoaded: PropTypes.number.isRequired,
  fetchPetsRecords: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  petsCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  petsAllList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  petsList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  filteredPetsList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  pet: PropTypes.shape({ id: PropTypes.number }),
  fetchUserPetsRecords: PropTypes.func.isRequired,
  maleDogFilter: PropTypes.func.isRequired,
  femaleDogFilter: PropTypes.func.isRequired,
  allSexDogFilter: PropTypes.func.isRequired,
  beforeYearDogFilter: PropTypes.func.isRequired,
  fromOneToFiveYears: PropTypes.func.isRequired,
  fiveAndMore: PropTypes.func.isRequired,
  smallDogFilter: PropTypes.func.isRequired,
  mediumDogFilter: PropTypes.func.isRequired,
  bigDogFilter: PropTypes.func.isRequired,
  ageFilter: PropTypes.string,
  sizeFilter: PropTypes.string,
  genderFilter: PropTypes.string,
  allAgeDogFilter: PropTypes.func.isRequired,
  allSizeDogFilter: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
  fetchNextItems: PropTypes.func.isRequired,
  items: PropTypes.number.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired, action: PropTypes.string }),
};

WantAFriendUserPageComponent.defaultProps = {
  petsAllList: [],
  petsList: [],
  filteredPetsList: [],
  ageFilter: null,
  sizeFilter: null,
  genderFilter: null,
  history: null,
  pet: null,
};

export default withTranslation('translations')(WantAFriendUserPageComponent);
