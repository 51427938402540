import React from 'react';
import { PropTypes } from 'prop-types';

const ImageInput = ({ handleImageChange }) => (
  <>
    <button
      name="articleFormImg"
      onClick={handleImageChange}
    />
  </>
);

ImageInput.propTypes = { handleImageChange: PropTypes.func.isRequired };

export default ImageInput;
