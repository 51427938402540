import React, { FC } from 'react';

import styles from '../../Footer.module.scss';

import { FooterModels } from '../../models/footer.models';

const HiddenLink: FC<FooterModels.HiddenLinkProps> = ({ text, path, ...props }) => (
  <div className={styles['hidden-elem']}>
    <a
      href={path}
      className={`${styles.subtitle} ${styles['link-subtitle']}`}
      {...props}
    >
      {text}
    </a>
  </div>
);

export default HiddenLink;
