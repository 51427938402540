import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './album-item.module.scss';

const Item = ({ position, album }) => {
  const { t } = useTranslation();
  const changeLink = (link, size) => {
    const { height, width } = size;
    const str = height ? `/c_fill,h_${height},w_${width}` : `/c_fill,w_${width}`;
    const result = link.split('/')
      .map((item) => {
        if (item === 'upload') {
          return item + str;
        }
        return item;
      })
      .join('/');
    return result;
  };

  const chooseSize = (value) => {
    let result;
    switch (value) {
      case '1:1':
        result = [
          {
            width: 392,
            height: 288,
          },
        ];
        return result;
      case '1:2':
        result = [
          {
            width: 195,
            height: 288,
          },
          {
            width: 195,
            height: 288,
          },
        ];
        return result;
      case '1:3':
        result = [
          {
            width: 206,
            height: 288,
          },
          {
            width: 184,
            height: 143,
          },
          {
            width: 184,
            height: 143,
          },
        ];
        return result;
      case '2:1':
        result = [
          {
            width: 288,
            height: 392,
          },
        ];
        return result;
      case '2:2':
        result = [
          {
            width: 288,
            height: 195,
          },
          {
            width: 288,
            height: 195,
          },
        ];
        return result;
      case '2:3':
        result = [
          {
            width: 294,
            height: 250,
          },
          {
            width: 146,
            height: 144,
          },
          {
            width: 146,
            height: 144,
          },
        ];
        return result;
      default: return result;
    }
  };

  const chooseStyles = (value) => {
    let result;
    switch (value) {
      case 1:
        result = {
          classItem: `${styles['col-4']} ${styles.item} ${styles.item_horizontal}`,
          classItemInfo: styles['col-4'],
        };
        return result;
      case 2:
        result = {
          classItem: `${styles['col-3']} ${styles.item} ${styles.item_vertical}`,
          classItemInfo: styles['col-3'],
        };
        return result;
      default: return result;
    }
  };

  const quantityImages = album.images.length;

  let size;
  const { classItem, classItemInfo } = chooseStyles(position);
  if (quantityImages === 1) {
    size = position === 1 ? chooseSize('1:1') : chooseSize('2:1');
    return (
      <>
        <div className={classItem}>
          <div className={styles.img__single}>
            <img
              className={styles.img}
              src={changeLink(album.images[0].image, size[0])}
              alt=""
            />
          </div>
        </div>
        <div className={`${classItemInfo} ${styles.info__wrapper}`}>
          <span className={styles.quantity}>{`${album.images.length} ${t('photo')}`}</span>
          <h3 className={styles.title}>{album.albumName[`${t('locale')}`]}</h3>
        </div>
      </>
    );
  } if (quantityImages === 2) {
    size = position === 1 ? chooseSize('1:2') : chooseSize('2:2');

    return (
      <>
        {/* TODO in 918 <div className={styles.item_vertical} */}
        <div className={classItem}>
          <div className={styles.img__pair}>
            <img
              src={changeLink(album.images[0].image, size[0])}
              alt=""
            />
          </div>
          <div className={styles.img__pair}>
            <img
              src={changeLink(album.images[1].image, size[1])}
              alt=""
            />
          </div>
        </div>
        <div className={`${classItemInfo} ${styles.info__wrapper}`}>
          <span className={styles.quantity}>{`${album.images.length} ${t('photo')}`}</span>
          <h3 className={styles.title}>{album.albumName[`${t('locale')}`]}</h3>
        </div>
      </>
    );
  }
  size = position === 1 ? chooseSize('1:3') : chooseSize('2:3');
  return (
    <>
      <div className={classItem}>
        <div className={styles.img__first}>
          <img
            className={styles.img}
            src={changeLink(album.images[0].image, size[0])}
            alt=""
          />
        </div>
        <div className={styles.img__wrapper}>
          <img
            className={styles.img_small}
            src={changeLink(album.images[1].image, size[1])}
            alt=""
          />
          <img
            className={styles.img_small}
            src={changeLink(album.images[2].image, size[2])}
            alt=""
          />
        </div>
      </div>
      <div className={`${classItemInfo} ${styles.info__wrapper}`}>
        <span className={styles.quantity}>{`${album.images.length} ${t('photo')}`}</span>
        <h3 className={styles.title}>{album.albumName[`${t('locale')}`]}</h3>
      </div>
    </>
  );
};

Item.propTypes = {
  position: PropTypes.number.isRequired,
  album: PropTypes.shape({
    id: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    albumName: PropTypes.objectOf.isRequired,
  }).isRequired,
};


const mapStateToProps = (state) => {
  const { currentAlbumIndex } = state.albumReducer.album;
  return ({ currentAlbumIndex });
};

export default compose(withTranslation('translations'), connect(mapStateToProps))(Item);
