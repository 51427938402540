/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { WAFPopupFormActionTypes } from './WAFPopupFormActions.models';
import { LANGUAGES } from '../../../../../../language/+store/languages';
import { WAFPopupAction, WAFPopupStoreModels } from './WAFPopupFormStore.models';
import { WAFActionTypes } from '../../../+store/wantAFriendActions.models';
import { defaultTranslation } from '../../../constants';

export const INITIAL_STATE: WAFPopupStoreModels.State = {
  activeLanguage: LANGUAGES.UK,
  formDog: {
    dogId: null,
    parentDogId: null,
    dogName: defaultTranslation,
    dogDescription: defaultTranslation,
    dogParameters: {
      sex: null,
      age: null,
      size: null,
      sterilized: null,
    },
    dogPhotos: [
      {
        main: true,
        activeImage: true,
        id: 0,
        photo: '',
        name: '',
        source: '',
        selectedImg: null,
        isUploading: false,
        progress: 0,
      },
      {
        main: false,
        activeImage: false,
        id: 1,
        photo: '',
        name: '',
        source: '',
        selectedImg: null,
        isUploading: false,
        progress: 0,
      },
      {
        main: false,
        activeImage: false,
        id: 2,
        photo: '',
        name: '',
        source: '',
        selectedImg: null,
        isUploading: false,
        progress: 0,
      },
      {
        main: false,
        activeImage: false,
        id: 3,
        photo: '',
        name: '',
        source: '',
        selectedImg: null,
        isUploading: false,
        progress: 0,
      },
      {
        main: false,
        activeImage: false,
        id: 4,
        photo: '',
        name: '',
        source: '',
        selectedImg: null,
        isUploading: false,
        progress: 0,
      },
    ],
    isUnderCare: false,
  },
  isEditing: false,
  dogBeforeUpdate: null,
};

const adminDogPopupFormReducer = (state = INITIAL_STATE, action: WAFPopupAction) => {
  switch (action.type) {
    case WAFPopupFormActionTypes.CHANGE_LANGUAGE_TAB:
      return {
        ...state,
        activeLanguage: action.payload.language,
      };
    case WAFActionTypes.CLOSE_DOG_POPUP_FORM:
      return { ...INITIAL_STATE };
    case WAFPopupFormActionTypes.OPEN_POPUP_FOR_DOG_CREATE:
      return {
        ...state,
        isEditing: false,
      }
    case WAFPopupFormActionTypes.OPEN_POPUP_FOR_DOG_UPDATE:
      return {
        ...state,
        isEditing: true,
        formDog: {
          ...state.formDog,
          ...action.payload.dogData,
          dogPhotos: state.formDog.dogPhotos.map((photo, index) => ({
            ...photo,
            photo: action.payload.dogData.dogPhotos[index],
          })),
        },
        dogBeforeUpdate: action.payload.petBeforeUpdate,
      }
    case WAFPopupFormActionTypes.DOG_NAME_CHANGE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogName: {
            ...state.formDog.dogName,
            [action.payload.inputChangeData.language]: action.payload.inputChangeData.name,
          },
        },
      };
    case WAFPopupFormActionTypes.DOG_DESCRIPTION_CHANGE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogDescription: {
            ...state.formDog.dogDescription,
            [action.payload.inputChangeData.language]: action.payload.inputChangeData.description,
          },
        },
      };
    case WAFPopupFormActionTypes.DOG_PARAMETER_CHANGE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogParameters: {
            ...state.formDog.dogParameters,
            ...action.payload.parameter,
          },
        },
      };
    case WAFPopupFormActionTypes.DOG_PHOTOS_CHANGE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogPhotos: action.payload.dogPhotos,
        },
      };
    case WAFPopupFormActionTypes.IS_DOG_UNDER_CARE_CHANGE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          isUnderCare: action.payload.isUnderCare ?? !state.formDog.isUnderCare,
        },
      };
    case WAFPopupFormActionTypes.DOG_CREATED_SUCCESSFULLY:
    case WAFPopupFormActionTypes.DOG_UPDATED_SUCCESSFULLY:
      return {
        ...state,
        dogName: defaultTranslation,
        dogDescription: defaultTranslation,
        isUnderCare: false,
      };
    case WAFPopupFormActionTypes.ADD_DOG_PHOTO:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogPhotos: [...state.formDog.dogPhotos.map((dogPhoto, index) => {
              if (index === action.payload.addPhotoData.photoIndex) {
                return {
                  ...dogPhoto,
                  photo: action.payload.addPhotoData.cloudinaryPhotoUrl,
                  name: action.payload.addPhotoData.photoName,
                }
              }
              return dogPhoto;
            }),
          ],
        },
      };
    case WAFPopupFormActionTypes.DOG_PHOTO_DELETE:
      return {
        ...state,
        formDog: {
          ...state.formDog,
          dogPhotos: [...state.formDog.dogPhotos.map((photo, index) => (index === action.payload.photoDeleteIndex
            ? {
              ...photo,
              photo: '',
            } : photo))],
        },
      };
    default:
      return state;
  }
};

export default adminDogPopupFormReducer;
