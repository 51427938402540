/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import Button from '../button';
import { validate } from '../../../utils/validation';
import styles from './sendMessage.module.scss';
import grid from '../../../assets/styles/grid.module.scss';
import dotsLoader from '../dotsLoader/dotsLoader';

const BY_EMAIL = 'by email';
const BY_PHONE = 'by phone';

const VALIDATION_ERROR = {
  name: 'theNameMustBe',
  phone: 'incorrectNumber',
  message: 'emptyMessage',
  email: 'emailIncorrect',
};

const VALIDATION_PLACEHOLDER = {
  name: 'yourName',
  phone: 'contactNumber',
  message: 'messageText',
  email: 'Email',
};

const PHONE_HINT = '+38(0__)___-__-__';
const PHONE_FORMAT = '+##(###)###-##-##';

const INITIAL_STATE = {
  name: {
    isValid: false,
    isValidating: false,
    value: '',
    error: '',
  },
  phone: {
    isValid: false,
    isValidating: false,
    value: '',
    error: '',
  },
  email: {
    isValid: false,
    isValidating: false,
    value: '',
    error: '',
  },
  message: {
    isValid: false,
    isValidating: false,
    value: '',
    error: '',
  },
};

class SendMessageComponent extends Component {
  static handleAutoGrow = (e) => {
    const element = e.target;
    element.style.height = '25px';
    element.style.height = `${element.scrollHeight + 5}px`;
  };

  static propTypes = {
    onSendMessage: PropTypes.func.isRequired,
    method: PropTypes.oneOf([BY_EMAIL, BY_PHONE]).isRequired,
  };

  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { ...INITIAL_STATE, language: i18n.language, sendingRequest: false };
    this.textarea = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { t, i18n, displayAlert } = this.props;
    const { name, phone, email, message, language } = this.state;
    if (language !== i18n.language) {
      this.setState({ language: i18n.language });
      if (name.error !== '') {
        name.error = t(VALIDATION_ERROR.name);
        this.setState({ name });
      }
      if (phone.error !== '') {
        phone.error = t(VALIDATION_ERROR.phone);
        this.setState({ phone });
      }
      if (email.error !== '') {
        email.error = t(VALIDATION_ERROR.email);
        this.setState({ email });
      }
      if (message.error !== '') {
        message.error = t(VALIDATION_ERROR.message);
        this.setState({ message });
      }
    }
    if (prevProps.displayAlert !== displayAlert) {
      if (displayAlert) {
        this.setState({ sendingRequest: false });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleAutoGrow = (e) => {
    const element = e.target;
    element.style.height = '25px';
    element.style.height = `${element.scrollHeight + 5}px`;
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') this.setInputValue(value.trim(), name);
    else this.setInputValue(value, name);
  };

  handleInputPhoneChange = (values) => {
    const { formattedValue } = values;
    const isPhoneContainCountryCode = formattedValue.startsWith('+38(0');

    if (!isPhoneContainCountryCode) {
      this.setInputValue(PHONE_HINT, 'phone');
    } else {
      this.setInputValue(formattedValue, 'phone');
    }
  };

  setInputValue = (value, name) => {
    const isValid = validate(value, name);
    const { t } = this.props;
    this.setState(prevState => ({
      [name]: {
        isValid,
        value,
        error: (prevState[name].isValidating && !isValid) ? t(VALIDATION_ERROR[name]) : '',
      },
    }
    ));
  };

  handlePhoneFocus = (e) => {
    const { phone: { value } } = this.state;
    const inputField = e.target;

    inputField.value = value || PHONE_HINT;

    if (inputField.value.includes('_')) {
      const currentCursorPosition = inputField.value.indexOf('_');
      inputField.selectionStart = currentCursorPosition;
      inputField.selectionEnd = currentCursorPosition;
    }
  };

  handleInputFocus = (e) => {
    const field = e.target.name;
    const { [field]: { value } } = this.state;
    e.target.value = value || '';
  };

  // handleInputLeave = field => this.setState(prevState => ({ [field]: { ...prevState[field] } }));

  autoGrowReset = () => {
    const textarea = this.textarea.current;
    textarea.style.height = '33px';
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, phone, message, email } = this.state;
    const { method, onSendMessage } = this.props;
    if (!this.isValidationPassed()) {
      if (!name.isValid) this.validateWithoutInput('name');
      if (!email.isValid) this.validateWithoutInput('email');
      if (!phone.isValid) this.validateWithoutInput('phone');
      if (!message.isValid) this.validateWithoutInput('message');
      return;
    }
    this.setState({ sendingRequest: true });
    if (method === BY_PHONE) {
      onSendMessage({
        name: name.value,
        phone: phone.value,
        message: message.value,
      });
    } else {
      onSendMessage({
        name: name.value,
        email: email.value,
        message: message.value,
      });
    }
    this.setState({ ...INITIAL_STATE });
    this.autoGrowReset();
  };

  isValidationPassed = () => {
    const { name, phone, message, email } = this.state;
    const { method } = this.props;
    if (method === BY_PHONE) {
      return name.isValid && phone.isValid && message.isValid;
    }
    return name.isValid && email.isValid && message.isValid;
  };

  validateWithoutInput = field => this.setState((prevState) => {
    const { t } = this.props;
    return ({
      [field]: {
        ...prevState[field],
        error: t(VALIDATION_ERROR[field]),
        isValidating: true,
      },
    });
  });

  renderTextArea = () => {
    const { message } = this.state;
    const { t } = this.props;
    return (
      <div className={(!message.isValid && message.isValidating) ? styles.field__error : styles.field}>
        <textarea
          ref={this.textarea}
          className={styles.textarea}
          name="message"
          placeholder={t(VALIDATION_PLACEHOLDER.message)}
          value={message.value}
          onChange={this.handleInputChange}
          onKeyUp={this.handleAutoGrow}
          onFocus={this.handleInputFocus}
        />
        <div className={styles.hint}>{t(VALIDATION_PLACEHOLDER.message)}</div>
        <div className={styles.error}>{message.error}</div>
      </div>
    );
  };

  renderInput = (inputName) => {
    const { name, email } = this.state;
    const { t } = this.props;
    const inputState = inputName === 'name' ? name : email;
    return (
      <div className={(!inputState.isValid && inputState.isValidating) ? styles.field__error : styles.field}>
        <input
          className={styles.input}
          name={inputName}
          type="text"
          placeholder={t(VALIDATION_PLACEHOLDER[inputName])}
          value={inputState.value}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
        />
        <div className={styles.hint}>{t(VALIDATION_PLACEHOLDER[inputName])}</div>
        <div className={styles.error}>{inputState.error}</div>
      </div>
    );
  };

  renderPhoneInput = () => {
    const { phone } = this.state;
    const { t } = this.props;
    return (
      <div className={(!phone.isValid && phone.isValidating) ? styles.field__error : styles.field}>
        <NumberFormat
          format={PHONE_FORMAT}
          mask="_"
          className={styles.input}
          name="phone"
          type="tel"
          placeholder={t(VALIDATION_PLACEHOLDER.phone)}
          value={phone.value}
          onValueChange={this.handleInputPhoneChange}
          onFocus={this.handlePhoneFocus}
        />
        <div className={styles.hint}>{t(VALIDATION_PLACEHOLDER.phone)}</div>
        <div className={styles.error}>{phone.error}</div>
      </div>
    );
  };

  render() {
    const { method, t } = this.props;
    const { sendingRequest } = this.state;
    return (
      method === BY_PHONE
        ? (
          <>
            <h3 className={styles.title}>{t('writeToUs')}</h3>
            <p className={styles.description}>
              {t('ifForSomeReasonYouCouldNotContact')}
            </p>
            <form className={styles.form} onSubmit={this.handleSubmit}>
              <div className={grid.row}>
                <div className={grid['col-6']}>
                  {this.renderInput('name')}
                </div>
                <div className={grid['col-6']}>
                  {this.renderPhoneInput()}
                </div>
              </div>
              {this.renderTextArea()}
              <Button
                uiType="sunShort"
                htmlType="submit"
                content={(
                  sendingRequest
                    ? (
                      <div className={styles.blockForButtonContent}>
                        <div className={styles.dotsLoader}>{dotsLoader()}</div>
                        <span>{t('sendMessage')}</span>
                      </div>
                    ) : (
                      <span>{t('sendMessage')}</span>
                    )
                )}
              />
            </form>
          </>
        )
        : (
          <section className={styles.box}>
            <div className={styles.wrapper}>
              <div className={styles['form-wrapper']}>
                <h3 className={styles.title__phone}>{t('askYourQuestions')}</h3>
                <div className={styles.description__phone}>
                  {t('didntFindTheAnswers')}
                  <a className={styles.tel} href="tel:+380679179585"> 8(067) 917-95-85.</a>
                </div>
                <form className={styles.form} onSubmit={this.handleSubmit}>
                  {this.renderTextArea()}
                  <div className={styles.row}>
                    <div className={styles['input-wrapper']}>
                      {this.renderInput('name')}
                    </div>
                    <div className={styles['input-wrapper']}>
                      {this.renderInput('email')}
                    </div>
                    <div className={styles['button-wrapper']}>
                      <Button uiType="sunShort" htmlType="submit" content={t('sendMessage')} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        ));
  }
}

SendMessageComponent.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
  displayAlert: PropTypes.bool.isRequired,
};

export default withTranslation('translations')(SendMessageComponent);
