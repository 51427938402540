import React from 'react';
import styles from '../../../../../blog/components/articlesList/ArticlesList.module.scss';

const HeaderTitle = () => (
  <div className={`${styles['title-container-counter']}`}>
    <h2>
      DogHotel
    </h2>
    <span className={styles['articles-count']}>
      Admin
    </span>
  </div>
);

export default HeaderTitle;
