/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, FocusEvent } from 'react';
import NumberFormat from 'react-number-format';
import styles from './cardDogModalForm.module.scss';
import TRANSLATE_KEYS from '../../../../../i18n_keys';
import { EventObject, Validations, CardDogModalFormProps } from './cardDogModalForm.types';

const PHONE_HINT = '+38(0__)___-__-__';

const useValidation = (value: string, validations: Validations) => {
  const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  const nameRegexStr = "^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]+'[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+-[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+)$"
  + "|^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]+-[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+'[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+)$"
  + "|^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]+'[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+)$"
  + '|^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]+-[a-zA-Zа-яА-ЯіёІЁєЄїЇ]+)$|^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]+)$|^([a-zA-Zа-яА-ЯіёІЁєЄїЇ]{1,2})$';
  const nameRegex = new RegExp(nameRegexStr);
  const telRegex = /^38\d{3}\d{3}\d{2}\d{2}$/;

  const [isEmpty, setEmpty] = useState(true);
  const [emailErr, setEmailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [telErr, setTelErr] = useState(false);
  const [minLengthErr, setMinLengthErr] = useState(false);
  const [maxLengthErr, setMaxLengthErr] = useState(false);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'minLength':
          value.length < validations[validation]
            ? setMinLengthErr(true)
            : setMinLengthErr(false);
          break;
        case 'isEmpty':
          value ? setEmpty(false) : setEmpty(true);
          break;
        case 'isEmail':
          emailRegex.test(String(value).toLowerCase())
            ? setEmailErr(false)
            : setEmailErr(true);
          break;
        case 'isName':
          nameRegex.test(String(value).toLowerCase())
            ? setNameErr(false)
            : setNameErr(true);
          break;
        case 'maxLength':
          value.length > validations[validation]
            ? setMaxLengthErr(true)
            : setMaxLengthErr(false);
          break;
        case 'isTel':
          telRegex.test(String(value).toLowerCase())
            ? setTelErr(false)
            : setTelErr(true);
          break;
        case 'isTelEmpty':
            value === PHONE_HINT || value === '' ? setEmpty(true) : setEmpty(false);
            break;
          default:
          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (maxLengthErr || minLengthErr || emailErr || nameErr || telErr) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [maxLengthErr, minLengthErr, emailErr, nameErr, telErr]);

  return {
    isEmpty,
    minLengthErr,
    maxLengthErr,
    emailErr,
    inputValid,
    nameErr,
    telErr,
  };
};

const useInput = (
  initialValue: string,
  validations: Validations,
) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);

  const valid = useValidation(value, validations);

  const onChange = <T, >(e: EventObject<T>) => {
    if (e.target) {
      setValue(e.target.value);
      return;
    }
    if (e.value) {
      setValue(e.value);
    }
  };

  const onSubmit = () => {
    setValue('');
    setDirty(false);
  };

  // eslint-disable-next-line no-unused-vars
  const onBlur = () => {
    setDirty(true);
  };

  return {
    value,
    onChange,
    onBlur,
    onSubmit,
    isDirty,
    ...valid,
  };
};

const CardDogModalForm = (props: CardDogModalFormProps) => {
  const PHONE_FORMAT = '+##(###)###-##-##';
  const NAME_MAX_LENGTH = 16;
  const EMAIL_MAX_LENGTH = 64;
  const EMAIL_MIN_LENGTH = 6;
  const TEXTAREA_MAX_LENGTH = 500;
  const [displayAlert, setDisplayAlert] = useState(false);
  const email = useInput('', { minLength: EMAIL_MIN_LENGTH, maxLength: EMAIL_MAX_LENGTH, isEmail: true });
  const name = useInput('', {
    isEmpty: true,
    minLength: 2,
    maxLength: 16,
    isName: true,
  });
  const tel = useInput('', { isEmpty: true, isTel: true });
  const msg = useInput('', { maxLength: TEXTAREA_MAX_LENGTH });

  if (email.value === '') email.inputValid = true;
  if (msg.value === '') msg.inputValid = true;

  const {
    active,
    setActive,
    setRedirect,
    formTitle,
    translate,
    success,
    sendSuccessNull,
    dogName,
    locale,
    dogUrl,
  } = props;

  useEffect(() => {
    if (success === true) {
      setTimeout(() => {
        setDisplayAlert(false);
        sendSuccessNull();
        if (typeof setRedirect === 'function') {
          setRedirect(true);
        }
      }, 5000);
    }
    if (success === false) {
      setTimeout(() => {
        setActive(true);
        setDisplayAlert(false);
      }, 5000);
    }
  }, [success]);

  const handlePhoneFocus = (e: FocusEvent<HTMLInputElement>) => {
    const inputField = e.target;

    inputField.value = e.target?.value || PHONE_HINT;

    if (inputField.value.includes('_')) {
      const currentCursorPosition = inputField.value.indexOf('_');
      inputField.selectionStart = currentCursorPosition;
      inputField.selectionEnd = currentCursorPosition;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const { onSendMessage } = props;

    e.preventDefault();

    setActive(false);

    await onSendMessage({
      email: email.value,
      phone: tel.value
        .slice(2)
        .replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/g, '+38($1)$2-$3-$4'),
      name: name.value,
      message: msg.value,
      subject: translate(formTitle),
      dogName,
      locale,
      dogUrl,
    });

    setDisplayAlert(true);
  };

  return (
    <>
      <div className={styles.alert}>
        <button
          style={displayAlert ? { display: 'flex' } : { display: 'none' }}
        >
          <i />
          <p>
            {success === true
              ? translate(TRANSLATE_KEYS.messageSent)
              : translate(TRANSLATE_KEYS.messageNotSent)}
          </p>
        </button>
      </div>
      <section className={active ? `${styles.modal} ${styles.active}` : `${styles.modal}`}>
        <div className={styles['modal-content']}>
          <h3 className={styles['form-title']}>{translate(formTitle)}</h3>
          <p className={styles.subtitle}>{translate(TRANSLATE_KEYS.sendYourContact)}</p>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className={styles.modal__wrapper}>
              <div className={`${styles.modal__inputs} ${styles.changewidth}`}>
                <input
                  onChange={e => name.onChange(e)}
                  onBlur={() => name.onBlur()}
                  value={name.value}
                  className={styles.modal__input}
                  type="text"
                  name="name"
                  id="name"
                  maxLength={NAME_MAX_LENGTH}
                  placeholder={translate(TRANSLATE_KEYS.yourName) as string}
                />
                {(name.isDirty && name.isEmpty)
                && (
                <div className={styles['error-msg']}>
                  {translate(TRANSLATE_KEYS.fieldNameNotEmpty)}
                </div>
                )}
                {(!name.isEmpty && name.maxLengthErr)
                && (
                <div className={styles['error-msg']}>
                  {translate(TRANSLATE_KEYS.theNameMustBe)}
                </div>
                )}
                {(!name.isEmpty && name.minLengthErr)
                && (
                <div className={styles['error-msg']}>
                  {translate(TRANSLATE_KEYS.theNameMustBe)}
                </div>
                )}
                {(!name.isEmpty && !name.minLengthErr && name.nameErr)
                && (
                <div className={styles['error-msg']}>
                  {translate(TRANSLATE_KEYS.IncorrectName)}
                </div>
                )}
              </div>
              <div className={`${styles.modal__inputs} ${styles.changewidth}`}>
                <input
                  onChange={e => email.onChange(e)}
                  onBlur={() => email.onBlur()}
                  value={email.value}
                  className={styles.modal__input}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  maxLength={EMAIL_MAX_LENGTH}
                />
                {(email.isDirty && !email.inputValid && email.emailErr)
                && (
                <div className={styles['error-msg']}>
                    {translate(TRANSLATE_KEYS.emailIncorrect)}
                </div>
                )}
              </div>
              <div className={styles.modal__inputs}>
                <NumberFormat
                  format={PHONE_FORMAT}
                  mask="_"
                  className={styles.modal__input}
                  name="phone"
                  type="tel"
                  placeholder={translate(TRANSLATE_KEYS.contactNumber) as string}
                  value={tel.value}
                  onValueChange={e => tel.onChange(e)}
                  onFocus={handlePhoneFocus}
                  onBlur={() => tel.onBlur()}
                />
                {(tel.isDirty && tel.isEmpty)
                && (
                  <div className={styles['error-msg']}>
                    {translate(TRANSLATE_KEYS.emptyNumber)}
                  </div>
                )}
                {(tel.isDirty && !tel.isEmpty && tel.telErr)
                && (
                  <div className={styles['error-msg']}>
                    {translate(TRANSLATE_KEYS.incorrectNumber)}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.modal__inputs}>
              <textarea
                placeholder={translate(TRANSLATE_KEYS.messageBioText) as string}
                className={styles.modal__input}
                onChange={e => msg.onChange(e)}
                onBlur={() => msg.onBlur()}
                value={msg.value}
                name="message"
                id="message"
                maxLength={TEXTAREA_MAX_LENGTH}
              />
            </div>
            <div className={styles.modal__buttons}>
              <button
                aria-label="Закрыть"
                onClick={() => {
                  setActive(false);
                  sendSuccessNull();
                }}
                className={`${styles.modal__button} ${styles['modal__button--cancel']}`}
                type="button"
              >
                {translate(TRANSLATE_KEYS.declineBtn)}
              </button>
              <button
                disabled={!name.inputValid || !tel.inputValid || !msg.inputValid || !email.inputValid}
                className={styles.modal__button}
                type="submit"
              >
                {translate(TRANSLATE_KEYS.sendMessage)}
              </button>
            </div>
          </form>
          <button
            aria-label="Закрыть"
            className={styles.closeBtn}
            onClick={() => {
              setActive(false);
              sendSuccessNull();
            }}
          />
        </div>
      </section>
    </>
  );
};

export default CardDogModalForm;
