/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="19"
    height="13"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M9.265 0C5.05364 0 1.45713 2.61947 0 6.31705C1.45713 10.0146 5.05364 12.6341 9.265 12.6341C13.4764 12.6341 17.0729 10.0146 18.53 6.31705C17.0729 2.61947 13.4764 0 9.265 0ZM9.265 10.5284C6.94033 10.5284 5.05364 8.64172 5.05364 6.31705C5.05364 3.99237 6.94033 2.10568 9.265 2.10568C11.5897 2.10568 13.4764 3.99237 13.4764 6.31705C13.4764 8.64172 11.5897 10.5284 9.265 10.5284ZM9.265 3.79023C7.86683 3.79023 6.73818 4.91887 6.73818 6.31705C6.73818 7.71522 7.86683 8.84386 9.265 8.84386C10.6632 8.84386 11.7918 7.71522 11.7918 6.31705C11.7918 4.91887 10.6632 3.79023 9.265 3.79023Z" fill="#373737" />
  </svg>
);

export default SVG;
