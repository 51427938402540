/* eslint-disable no-continue */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Swipe from 'react-easy-swipe';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import Picture from './Picture';
import { closeGalleryModal,
  requestAlbums,
  selectActiveAlbum,
  selectActivePicture } from '../photo-gallery/+store/actions';
import styles from './gallery.module.scss';


class GalleryPreview extends Component {
  xPosition = 0;

  constructor(props) {
    super(props);
    this.state = { ...props, widthOfWindow: (window.innerWidth / 2) };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentAlbumIndex, currentPictureIndex, items } = this.props;
    if (prevProps.currentAlbumIndex !== currentAlbumIndex || prevProps.currentPictureIndex !== currentPictureIndex
      || prevProps.items.length !== items.length
    ) {
      this.setState({
        currentPictureIndex,
        currentAlbumIndex,
      });
      this.activateCurrentImage(currentPictureIndex, currentAlbumIndex);
    }
    if (prevState.widthOfWindow !== (window.innerWidth / 2)) {
      this.setState({ widthOfWindow: (window.innerWidth / 2) });
    }
  }

  onLeftArrowClick = () => {
    let { currentAlbumIndex, currentPictureIndex } = this.props;
    const { items } = this.props;
    const slides = items[currentAlbumIndex];
    if (!slides.images) {
      return;
    }
    if (currentPictureIndex >= 0) currentPictureIndex -= 1;
    if (currentPictureIndex === -1) {
      currentAlbumIndex -= 1;
      currentPictureIndex = items[currentAlbumIndex].images.length - 1;
    }
    this.setState({
      currentPictureIndex,
      currentAlbumIndex,
    });
    this.activateCurrentImage(currentPictureIndex, currentAlbumIndex);
  };

  onRightArrowClick = () => {
    let { currentAlbumIndex, currentPictureIndex } = this.props;
    const { items } = this.props;
    const slides = items[currentAlbumIndex];
    if (slides == null || slides.length === 0) {
      return;
    }
    currentPictureIndex += 1;
    if (
      slides.images != null
      && slides.images.length === currentPictureIndex
    ) {
      currentPictureIndex = 0;
      currentAlbumIndex += 1;
      if (slides.length === currentAlbumIndex) {
        currentAlbumIndex = 0;
        currentPictureIndex = 0;
      }
    }
    this.setState({
      currentPictureIndex,
      currentAlbumIndex,
    });
    this.activateCurrentImage(currentPictureIndex, currentAlbumIndex);
  };

  closeGallery = () => {
    const { closeGallery } = this.props;
    closeGallery();
  };

  activateCurrentImage = (currentPictureIndex, currentAlbumIndex) => {
    const { getSelectActivePicture, getSelectActiveAlbum } = this.props;
    const picturesContainer = window.document.getElementById(
      'albums-container',
    );
    let margin = 0;
    const activeAlbum = currentAlbumIndex;
    for (let i = 0; i < activeAlbum; i += 1) {
      const currentAlbum = window.document.getElementById(`album-${i}`);
      if (currentAlbum == null) {
        continue;
      }
      margin -= currentAlbum.offsetWidth;
    }
    let widthToCurrentPicture = 0;
    for (let i = 0; i <= currentPictureIndex; i += 1) {
      const currentPicture = window.document.getElementById(
        `album-${activeAlbum}-picture-${i}`,
      );
      if (currentPicture !== null) {
        widthToCurrentPicture += currentPicture.offsetWidth;
      }
    }

    const { widthOfWindow } = this.state;
    const wrapper = window.document.getElementById('wrapper');
    if (wrapper === null) {
      return;
    }
    if (widthToCurrentPicture > widthOfWindow) {
      margin -= widthToCurrentPicture - widthOfWindow;
    }
    picturesContainer.style.marginLeft = `${margin}px`;
    getSelectActiveAlbum(activeAlbum);
    getSelectActivePicture(currentPictureIndex);
  };

  isPictureActive = (pictureID, albumID) => {
    const { currentAlbumIndex, currentPictureIndex } = this.state;
    return currentAlbumIndex === albumID && currentPictureIndex === pictureID;
  };

  onSmallPictureClick = (pictureID, albumID) => {
    this.setState({
      currentPictureIndex: pictureID,
      currentAlbumIndex: albumID,
    });
    this.activateCurrentImage(pictureID, albumID);
  };

  createPicture = (album, pictureID, albumID, picture) => {
    const activeClass = this.isPictureActive(pictureID, albumID)
      ? styles.active
      : '';
    return (
      <div
        className={styles.picture_wrapper}
        key={`${pictureID}+${albumID}`}
        id={`album-${albumID}-picture-${pictureID}`}
      >
        <div
          className={`${styles.pictures} ${activeClass}`}
          onClick={() => this.onSmallPictureClick(pictureID, albumID)}
          role="presentation"
        >
          <img
            className={`${styles.image}`}
            src={picture.image}
            alt="Gallery pic"
          />
        </div>
      </div>
    );
  };

  createAlbum = (album, albumID) => {
    if (album == null || album.images == null || album.images.length === 0) return <div>EMPTY</div>;
    return (
      <div
        className={styles.albums}
        key={albumID.toString()}
        id={`album-${albumID}`}
      >
        {album.images.map((picture, pictureID) => this.createPicture(album, pictureID, albumID, picture))}
      </div>
    );
  };

  onSwipeMove = (position) => {
    this.xPosition = position.x;
  };

  onSwipeEnd = () => {
    if (this.xPosition > 50) this.onLeftArrowClick();
    if (this.xPosition < -50) this.onRightArrowClick();
  };

  render() {
    const {
      currentAlbumIndex,
      currentPictureIndex,
      openGallery,
      items,
      t,
    } = this.props;
    const albums = items;
    if (currentAlbumIndex == null || albums == null || albums.length === 0) return null;
    let isLastPicture = currentAlbumIndex === albums.length - 1;
    const currentAlbumPictures = albums[currentAlbumIndex].images;
    isLastPicture = isLastPicture
      && currentPictureIndex
        === (currentAlbumPictures == null ? 0 : currentAlbumPictures.length - 1);
    const isFirstPicture = currentAlbumIndex === 0 && currentPictureIndex === 0;

    const slideMultiArray = albums.map((album, albumID) => {
      if (album == null || album.images == null || album.images.length === 0) {
        return <div>currentAlbumPictures</div>;
      }
      return this.createAlbum(album, albumID, currentAlbumIndex, currentPictureIndex);
    });

    const emptyPicture = {
      image: '',
      description: '',
    };
    const currentAlbum = albums[currentAlbumIndex];
    let currentPicture = currentAlbum.images == null
      ? emptyPicture
      : currentAlbum.images[currentPictureIndex];
    if (currentPicture == null) {
      currentPicture = emptyPicture;
    }
    return (
      <div className={`${styles.box} ${openGallery ? '' : styles.closed}`}>
        <div className={styles.swipe}>
          <button
            className={styles.close_button}
            onClick={this.closeGallery}
            tabIndex={0}
          >
            <i className={styles.icon} />
          </button>
          <LeftArrow
            goToPrevSlide={this.onLeftArrowClick}
            isFirstPicture={isFirstPicture}
          />
          <Swipe onSwipeMove={this.onSwipeMove} onSwipeEnd={this.onSwipeEnd}>
            <div className={styles.custom_wrapper} id="wrapper">
              <Picture
                image={currentPicture.image}
                description={currentPicture.description}
              />
            </div>
          </Swipe>
          <RightArrow
            goToNextSlide={this.onRightArrowClick}
            isLastPicture={isLastPicture}
          />
        </div>
        <div className={styles.wrapper} id="wrapper">
          <div className={styles.container}>
            <div className={styles.text}>
              <p className={styles.title}>{currentAlbum.albumName[`${t('locale')}`]}</p>
              <p className={styles.number}>
                {`${currentAlbumIndex === currentAlbum.id ? currentPictureIndex + 1 : 1}/${
                  currentAlbum.images === null ? 1 : currentAlbum.images.length
                }`}
              </p>
            </div>
            <div className={styles.slides} id="albums-container">
              {slideMultiArray[currentAlbumIndex]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GalleryPreview.propTypes = {
  currentAlbumIndex: PropTypes.number.isRequired,
  currentPictureIndex: PropTypes.number.isRequired,
  openGallery: PropTypes.bool.isRequired,
  closeGallery: PropTypes.func.isRequired,
  getSelectActivePicture: PropTypes.func.isRequired,
  getSelectActiveAlbum: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      albumName: PropTypes.objectOf.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }),
  ).isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestAlbums: () => dispatch(requestAlbums()),
  closeGallery: () => dispatch(closeGalleryModal()),
  getSelectActivePicture: currentPictureIndex => dispatch(selectActivePicture(currentPictureIndex)),
  getSelectActiveAlbum: currentAlbumIndex => dispatch(selectActiveAlbum(currentAlbumIndex)),
});

const mapStateToProps = (state) => {
  const {
    openGallery,
    currentAlbumIndex,
    currentPictureIndex,
    items,
  } = state.albumReducer.album;
  return {
    items,
    openGallery,
    currentAlbumIndex,
    currentPictureIndex,
  };
};

GalleryPreview.propTypes = { t: PropTypes.func.isRequired };

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(GalleryPreview);
