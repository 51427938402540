import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './ProfileImage.module.scss';
import ImageUploader from '../../../../../shared-ui/image-uploader';
import { ProfileImageModels } from './profileImage.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

class ProfileImage extends Component<ProfileImageModels.Props, ProfileImageModels.State > {
  constructor(props: ProfileImageModels.Props) {
    super(props);
    this.state = { isProfilePage: true };

    this.handleSelectedImg = this.handleSelectedImg.bind(this);
  }

  handleSelectedImg = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { onImgUpload } = this.props;
    const img = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    onImgUpload(img);
  };

  render() {
    const { imageLink, t: translate } = this.props;
    const { isProfilePage } = this.state;

    return (
      <>
        <figure
          className={styles['profile-image']}
          style={{ backgroundImage: `url(${imageLink})` }}
        >
          <ImageUploader onImgSelected={this.handleSelectedImg} isProfilePage={isProfilePage} />
        </figure>
        <figcaption className={styles['image-caption']}>{translate(TRANSLATE_KEYS.imgInfo)}</figcaption>
      </>
    );
  }
}

export default withTranslation('translations')(ProfileImage);
