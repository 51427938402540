import React, { FC } from 'react';
import TextBox from '../textBox/textBox';
import ImageBox from '../imageBox/imageBox';
import CreateArticleContent from '../createArticleContent/createArticleContent';
import { ArticleContentTypes } from '../../../../../../../../models/blogPage.models';

interface ArticleContentItemProps {
  id: number;
  content: string;
  type: ArticleContentTypes;
  autofilled: boolean;
  addBox: (id: number, type: ArticleContentTypes) => void;
  handleTextChange: (e, id: number) => void;
  handleImageChange: (url: string, id: number) => void;
  deleteBox: (id: number) => void;
  activeLanguage: string;
}

const ArticleContentItem: FC<ArticleContentItemProps> = ({
  id,
  content,
  type,
  autofilled,
  addBox,
  handleTextChange,
  handleImageChange,
  deleteBox,
  activeLanguage,
}) => (
  <>
    {(type === ArticleContentTypes.TEXT || type === ArticleContentTypes.QUOTE) && (
      <TextBox
        id={id}
        type={type}
        content={content}
        autofilled={autofilled}
        handleTextChange={handleTextChange}
        deleteBox={deleteBox}
      />
    )}
    {type === ArticleContentTypes.IMG && (
      <ImageBox
        id={id}
        url={content}
        handleImageChange={handleImageChange}
        deleteBox={deleteBox}
        activeLanguage={activeLanguage}
      />
    )}
    <CreateArticleContent addBox={addBox} id={id} isHidden />
  </>
);

export default ArticleContentItem;
