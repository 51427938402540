import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/protectedRoute';
import AdminAuthentication from './components/authentication/index';
import ProfilePage from './components/profilePage/ProfilePageComponent';
import ArticlesPage from './components/articlesPage/ArticlesPageContainer';
import ArticleEditPage from './components/articlesPage/components/articleEditPage/articleEditPage';
import WantAFriendPage from './components/wantAFriendPage/WantAFriendPageContainer';
import DogDetailsPage from './components/wantAFriendPage/components/dogDetailsPage/dogDetailsContainer';
import Header from './components/header/header';

const AdminComponent: FC = () => (
  <>
    <Header />
    <Switch>
      <Route
        exact
        path="/admin"
        render={() => <AdminAuthentication />}
      />
      <ProtectedRoute
        exact
        path="/admin/profile"
        component={ProfilePage}
      />
      <ProtectedRoute
        exact
        path="/admin/blog"
        component={ArticlesPage}
      />
      <Route
        exact
        path="/admin/blog/edit/article/:id"
        component={ArticleEditPage}
      />
      <ProtectedRoute
        exact
        path="/admin/want-a-friend"
        component={WantAFriendPage}
      />
      <Route
        exact
        path="/admin/want-a-friend/dog/:id"
        component={DogDetailsPage}
      />
      <ProtectedRoute
        exact
        path="*"
        component={() => <Redirect to={{ pathname: '/404-not-found', state: { from: window.location.pathname } }} />}
      />
    </Switch>
  </>
)

export default AdminComponent;
