import { HomeComponentActions } from './action-types';
import { transformPetDto } from '../../../utils/transformPetDto';
import { transformArticleDto } from '../../../utils/transformArticleDto';
import { Action, ActionPayloadContent } from '../../../types/action.interface';
import { Article, MainArticle } from '../../../types/article.model';
import { Dog } from '../../../types/dog.model';
import { BlogPageModels } from '../../../models/blogPage.models';

export const initialState = {
    articles: [],
    dogs: [],
    loading: false,
    error: null,
};

export default function homeReducer(state = initialState, action: Action<Article[] | Dog[]>) {
    switch (action.type) {
        case HomeComponentActions.fetchStartType:
            return {
                ...state,
                loading: true,
            };

        case HomeComponentActions.fetchArticlesType:
            return {
                ...state,
                loading: false,
                articles: (action.payload as {content: BlogPageModels.Article[]})!.content
                    .filter(Boolean)
                    .slice(0, 3).map(transformArticleDto),
            };

        case HomeComponentActions.fetchDogsType:
            return {
                ...state,
                loading: false,
                dogs: (<ActionPayloadContent<Dog[]>>action.payload)!.content
                    .slice(0, 3)
                    .map(transformPetDto),
            }

        case HomeComponentActions.fetchErrorType:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
