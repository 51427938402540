/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Edit-mode-" transform="translate(-420.000000, -1940.000000)">
        <g id="Paragraph" transform="translate(408.000000, 1744.000000)">
          <g id="DropDown" transform="translate(0.000000, 184.000000)">
            <g id="Icon" transform="translate(12.000000, 12.000000)" fill="#373737">
              <path d="M6,20 C5.44771525,20 5,19.5522847 5,19 C5,18.4477153 5.44771525,18 6,18 L7,18 L7,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L13,4 C15.209139,4 17,5.790861 17,8 C17,8.93675816 16.677989,9.79830662 16.1386199,10.4799923 C17.8296885,11.2800786 19,13.0033689 19,15 C19,17.7614237 16.7614237,20 14,20 L6,20 Z M14,12 L9,12 L9,18 L14,18 C15.6568542,18 17,16.6568542 17,15 C17,13.4023191 15.75108,12.0963391 14.1762728,12.0050927 L14,12 Z M13,6 L9,6 L9,10 L13,10 C14.1045695,10 15,9.1045695 15,8 C15,6.9456382 14.1841222,6.08183488 13.1492623,6.00548574 L13,6 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
