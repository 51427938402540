import { Cookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { LanguageData } from '../../../../../../../models/language.model';
import { WAFModels } from '../../../../../../../models/WAFPage.models';
import { WAFPopupAction, WAFPopupStoreModels } from './WAFPopupFormStore.models';

export namespace WAFPopupFormActions {
  export type SimpleAction = () => WAFPopupAction;
  export type ErrorHandler = (error: Error) => WAFPopupAction;

  export type ChangeLanguageTab = (lang: LanguageData) => WAFPopupAction;
  export type OpenPopupForDogUpdate = (
    dogData: WAFPopupStoreModels.DogFormData,
    petBeforeUpdate: WAFModels.Pet,
  ) => WAFPopupAction;
  export type DogInputChange = (language: string, name?: string, description?: string) => WAFPopupAction;
  export type DogParameterChange = (parameter: {[value: string]: number}) => WAFPopupAction;
  export type DogPhotosChange = (dogPhotos: WAFPopupStoreModels.DogPhoto[]) => WAFPopupAction;
  export type IsDogUnderCareChange = (isUnderCare: boolean) => WAFPopupAction;
  export type AddDogPhoto = (
    cloudinaryPhotoUrl: string,
    photoName: string,
    photoIndex: number
  ) => WAFPopupAction;
  export type DogPhotoDelete = (index: number) => WAFPopupAction;

  export type CreateOrUpdateDog = (cookies: Cookies) => (
    dispatch: Dispatch,
    getState: () => { adminDogPopupFormReducer: WAFPopupStoreModels.State }
  ) => void;
}

export namespace WAFPopupFormActionTypes {
  export const CHANGE_LANGUAGE_TAB = 'CHANGE_LANGUAGE_TAB';
  export const DOG_NAME_CHANGE = 'DOG_NAME_CHANGE';
  export const DOG_DESCRIPTION_CHANGE = 'DOG_DESCRIPTION_CHANGE';
  export const DOG_PARAMETER_CHANGE = 'DOG_PARAMETER_CHANGE';
  export const IS_DOG_UNDER_CARE_CHANGE = 'IS_DOG_UNDER_CARE_CHANGE';
  export const DOG_CREATE_START = 'DOG_CREATE_START';
  export const DOG_CREATE_ERROR = 'DOG_CREATE_ERROR';
  export const DOG_CREATED_SUCCESSFULLY = 'DOG_CREATED_SUCCESSFULLY';
  export const DOG_UPDATE_START = 'DOG_UPDATE_START';
  export const DOG_UPDATE_ERROR = 'DOG_UPDATE_ERROR';
  export const DOG_UPDATED_SUCCESSFULLY = 'DOG_UPDATED_SUCCESSFULLY';
  export const DOG_PHOTOS_CHANGE = 'DOG_PHOTOS_CHANGE';
  export const ADD_DOG_PHOTO = 'ADD_DOG_PHOTO';
  export const DOG_PHOTO_DELETE = 'DOG_PHOTO_DELETE';
  export const OPEN_POPUP_FOR_DOG_CREATE = 'OPEN_POPUP_FOR_DOG_CREATE';
  export const OPEN_POPUP_FOR_DOG_UPDATE = 'OPEN_POPUP_FOR_DOG_UPDATE';

}
