import React, { Fragment } from 'react';
import ProfileForm from './ProfileForm';

const ProfilePage = () => (
  <Fragment>
    <ProfileForm />
  </Fragment>
);

export default ProfilePage;
