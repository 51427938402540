import { Languages } from '../../../models/language.model';

export const LANGUAGES = {
  UK: {
    languageId: 1,
    languageValue: 'uk',
  },
  EN: {
    languageId: 3,
    languageValue: 'en',
  },
};

export const AVAILABLE_LANGUAGES = [Languages.EN, Languages.UK];

export const DEFAULT_LANGUAGE = LANGUAGES.UK;
