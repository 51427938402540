import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Item from './album-item/index';
import styles from './photo-gallery.module.scss';
import { openGalleryModal } from './+store/actions';

function choosePosition(position) {
  const positionState = position + 1;
  if ((positionState % 3) === 0) {
    return 2;
  } if ((positionState % 3) === 1) {
    return 1;
  } if ((positionState % 3) === 2) {
    return 2;
  } if ((positionState % 2) === 0) {
    return 2;
  }
  return 1;
}

const PhotoGalleryComponent = ({ albums, openGallery }) => (
  <div className={styles.box}>
    <div className={styles.container}>
      {
        albums.map((item, index) => (
          <div
            className={`${styles.row} ${index % 2 ? styles.gallery__item_l : styles.gallery__item_r}`}
            key={item.id}
            onClick={() => openGallery(item.id)}
            role="button"
            tabIndex="0"
          >
            <Item position={choosePosition(index)} album={item} />
          </div>
        ))
      }
    </div>
  </div>
);

PhotoGalleryComponent.propTypes = {
  albums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      albumName: PropTypes.objectOf.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }),
  ).isRequired,
  openGallery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { openGallery } = state.albumReducer.album;
  return ({ openGallery });
};

const mapDispatchToProps = dispatch => ({ openGallery: id => dispatch(openGalleryModal(id)) });

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryComponent);
