import React, { FC } from 'react';
import { ServicesPageModels } from '../../../models/servicesPage.models';
import styles from '../DescriptionTemplate.module.scss';
import grid from '../../../../../assets/styles/grid.module.scss';

const TemplateOther: FC<ServicesPageModels.DescriptionTemplateProps> = ({
  position,
  firstImg,
  children,
  extraClassName = '',
  secondImg = '',
  thirdImg = '',
}) => (
  <div className={`${styles.container} ${grid.row} ${position} ${styles[extraClassName]}`}>
    <div className={grid['col-8']}>
      <div className={`${styles['box-other-left']} `}>
        <div className={styles['big-image-left']}><img src={firstImg} alt="pet photos" /></div>
        {secondImg && (
          <div className={styles['small-image-left']}>
            {thirdImg ? (
              <>
                <img src={secondImg} alt="pet photos" />
                <img src={thirdImg} alt="pet photos" />
              </>
          ) : (
            <img src={secondImg} alt="pet photos" />
          )}
          </div>
        )}
      </div>
    </div>
    <div className={`${grid['col-4']} ${styles.text}`}>{children}</div>
  </div>
);

export default TemplateOther;
