/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useRef, useEffect, FC, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../../../../shared-ui/icon';
import TooltipText from '../../../../../../../shared-ui/tooltip-text/tooltipText';
import style from './styles/article-category-select.module.scss';
import { TranslatedText } from '../../../../../../../../models/translate-function.models';
import useOnOutsideClick from '../../../../../../../../hooks/useOnOutsideClick';
import { EventTypes } from '../../../../../../../../models/common.models';

interface ArticleCategoryEditProps {
  className: string;
  value: string;
  isTouched: boolean;
  errorText: TranslatedText;
  onCancel: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const ArticleCategoryEdit: FC<ArticleCategoryEditProps> = ({
  isTouched,
  className,
  value,
  errorText,
  onCancel,
  onChange,
  onSubmit,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef.current]);

  const [domNode] = useOnOutsideClick<HTMLLIElement>(({
    eventType: EventTypes.MOUSEDOWN,
    handler: () => onCancel(),
  }));

  return (
    <li className={style['category-list']} ref={domNode}>
      <input
        type="text"
        maxLength={23}
        className={className}
        onChange={onChange}
        ref={inputRef}
        value={value}
      />
      <div className={style['right-button-wrapper']}>
        <button
          className={style.close}
          onClick={onCancel}
        >
          <Icon name="close-small" />
        </button>
        {isTouched && value.trim() && !errorText ? (
          <button
            className={style.check}
            onClick={() => onSubmit()}
          >
            <Icon name="check-mark" />
          </button>
        )
          : undefined}
      </div>
      <TooltipText
        content={errorText}
        propModificator="EDIT"
      />
    </li>
  );
};

const mapStateToProps = state => ({ isTouched: state.adminArticleCreateFormReducer.isTouched });

export default connect(mapStateToProps)(withTranslation('translations')(ArticleCategoryEdit));
