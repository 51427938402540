/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Eng" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][Eng][D]-Create-New-Article-–-Item-–-Hover" transform="translate(-956.000000, -424.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 112.000000)">
          <g id="Dropdown" transform="translate(32.000000, 264.000000)">
            <g id="Icon" transform="translate(500.000000, 48.000000)" fill="currentColor">
              <rect id="Combined-Shape" x="4" y="11" width="16" height="2" rx="1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
