/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[3.0][D]-Create-an-article-–-Add-image-–-Hover" transform="translate(-756.000000, -676.000000)">
        <g id="Add-content-Buttons-/-Add-content-/-Open" transform="translate(112.000000, 664.000000)">
          <g id="Menu" transform="translate(544.000000, 0.000000)">
            <g id="Icon-Icons-/-Image-" transform="translate(100.000000, 12.000000)" fill="currentColor">
              <path d="M16.0654584,6.21577178 C16.4567728,6.40929919 16.7740716,6.72314417 16.9697288,7.11019897 L21.7860878,17.1055728 C22.2855025,18.0935298 21.8806469,19.2948759 20.8818174,19.7888544 C20.6010504,19.9277098 20.2914542,20 19.9775471,20 L4.02200997,20 C2.9052847,20 2,19.1045695 2,18 C2,17.6458907 2.09505145,17.2981301 2.27544042,16.9922579 L5.52965332,10.9973731 C6.09233883,10.0432693 7.33045138,9.72099681 8.29505511,10.2775574 C8.67560802,10.4971302 8.97324457,10.8336335 9.14269361,11.2358844 L10.105227,13.9996978 L13.3526474,7.11019897 C13.8520621,6.12224198 15.066629,5.72179329 16.0654584,6.21577178 Z M7.0731482,4 C8.18987347,4 9.09515817,4.8954305 9.09515817,6 C9.09515817,7.1045695 8.18987347,8 7.0731482,8 C5.95642293,8 5.05113823,7.1045695 5.05113823,6 C5.05113823,4.8954305 5.95642293,4 7.0731482,4 Z" id="Combined-Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
