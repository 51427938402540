import React, { FC } from 'react';
import styles from './styles/create-article.module.scss';
import ImageUploader from '../../../../../../../shared-ui/image-uploader';

interface ArticleImageProps { onImgUpload: (img: Blob) => void }

const ArticleImage: FC<ArticleImageProps> = ({ onImgUpload }) => {
  const handleSelectedImg = (event) => {
    if (!event) {
      onImgUpload(null);
      return;
    }
    const img = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    event.preventDefault();
    onImgUpload(img);
  };

  return (
    <div className={styles['article-form-image-wrapper']}>
      <ImageUploader onImgSelected={handleSelectedImg} isProfilePage={false} />
    </div>
  );
}

export default ArticleImage;
