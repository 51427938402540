import { RefObject, useEffect, useRef } from 'react'
import { EventTypes } from '../models/common.models';
import { HooksModels } from './hooks.models';

const useOnOutsideClick = <T extends HTMLElement>({
  eventType = EventTypes.CLICK,
  handler,
}: HooksModels.UseOnOutsideClick): RefObject<T>[] => {
  const elementRef = useRef<T>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      const target = event.target as HTMLElement;

      if (elementRef.current && !elementRef?.current?.contains(target)) {
        handler();
      }
    }

    document.addEventListener(eventType, handleOutsideClick)
    return () => document.removeEventListener(eventType, handleOutsideClick)
  }, [elementRef, handler]);

  return [elementRef];
}

export default useOnOutsideClick;
