/* eslint-disable max-len */
import React from 'react';

const SVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Create/Edit-new-article-Eng" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[2.1][Eng][D]-Create-New-Article-–-Settings" transform="translate(-748.000000, -564.000000)">
        <g id="Dialog-Window" transform="translate(424.000000, 64.000000)">
          <g id="Image" transform="translate(32.000000, 376.000000)">
            <g id="Button" transform="translate(280.000000, 112.000000)">
              <g id="Icon" transform="translate(12.000000, 12.000000)" fill="currentColor">
                <path d="M17.8667291,7.41200152 C19.2091895,8.54438388 20,10.2088782 20,12 C20,15.2383969 17.4344251,17.8775718 14.2249383,17.9958615 L14,18 L12.414,17.999 L13.7071068,19.2928932 C14.0675907,19.6533772 14.0953203,20.2206082 13.7902954,20.6128994 L13.7071068,20.7071068 C13.3466228,21.0675907 12.7793918,21.0953203 12.3871006,20.7902954 L12.2928932,20.7071068 L9.29289322,17.7071068 C8.93240926,17.3466228 8.90467972,16.7793918 9.20970461,16.3871006 L9.29289322,16.2928932 L12.2928932,13.2928932 C12.6834175,12.9023689 13.3165825,12.9023689 13.7071068,13.2928932 C14.0675907,13.6533772 14.0953203,14.2206082 13.7902954,14.6128994 L13.7071068,14.7071068 L12.414,15.999 L14,16 C16.209139,16 18,14.209139 18,12 C18,10.8045856 17.473812,9.69706761 16.5771991,8.94076327 C16.1550432,8.58466941 16.1014894,7.95377332 16.4575832,7.53161742 C16.8136771,7.10946152 17.4445732,7.05590765 17.8667291,7.41200152 Z M11.6128994,3.20970461 L11.7071068,3.29289322 L14.7071068,6.29289322 C15.0675907,6.65337718 15.0953203,7.22060824 14.7902954,7.61289944 L14.7071068,7.70710678 L11.7071068,10.7071068 C11.3165825,11.0976311 10.6834175,11.0976311 10.2928932,10.7071068 C9.93240926,10.3466228 9.90467972,9.77939176 10.2097046,9.38710056 L10.2928932,9.29289322 L11.585,8 L10,8 C7.790861,8 6,9.790861 6,12 C6,13.1527242 6.48892582,14.2243012 7.3307733,14.9791945 C7.74195524,15.3479055 7.7763847,15.9801337 7.40767363,16.3913157 C7.03896257,16.8024976 6.40673436,16.8369271 5.99555241,16.468216 C4.73497433,15.3378427 4,13.727002 4,12 C4,8.76160306 6.56557489,6.12242824 9.77506174,6.00413847 L10,6 L11.585,6 L10.2928932,4.70710678 C9.93240926,4.34662282 9.90467972,3.77939176 10.2097046,3.38710056 L10.2928932,3.29289322 C10.6533772,2.93240926 11.2206082,2.90467972 11.6128994,3.20970461 Z" id="Combined-Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
