export default class browserStorage {
  static setKeyValue(key, value) {
    localStorage.setItem(key, value)
  }

  static getKeyValue(key) {
    return localStorage.getItem(key)
  }

  static removeKey(key) {
    localStorage.removeItem(key)
  }
}
