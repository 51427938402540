export namespace CommonModels {
  export interface RecordInfo {
    uk: string;
    en: string;
  }

  export interface DetailsPageMatch {
    params: { id: string },
    path: string,
    url: string,
    isExact: boolean
  }
  export interface Style {
    readonly [key: string]: string;
  }
}

export enum EventTypes {
  CLICK = 'click',
  MOUSEDOWN = 'mousedown'
}

export enum StatusCodes {
  'Unauthorized' = 401,
  'NotFound' = 404
}
