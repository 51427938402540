export const enum KeyCodes {
  Enter = 'Enter'
}

export const enum ScreenQueries {
  PhoneMin = 320,
  PhoneMax = 599,
  TabletMin = 600,
  TabletMax = 959,
  DesktopMin = 900
}
