import axios from 'axios';
import getSelectedLanguage from './getSelectedLanguage';

export const createHeaders = (cookies) => {
  const headers = { 'Accept-DHLanguage': getSelectedLanguage().languageValue };
  if (cookies) {
    headers.Authorization = `Bearer ${cookies.get('token')}`;
  }
  return headers;
};

export default (config, cookies) => {
  const defaultHeaders = createHeaders(cookies);
  return axios({
    ...config,
    headers:
      config.headers
        ? { ...config.headers, ...defaultHeaders }
        : { ...defaultHeaders },
  });
};
