import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardDogParameters.module.scss';
import dogPageTranslations from '../userDogPageTranslations';
import getDogAgeTranslateKey from '../../../../../utils/getDogAgeTranslateKey';

const cardDogParameters = (props) => {
  const { size, age, male, sterilized, activeLanguage, translate } = props;

  return (

    <div className={styles.parametersBlock}>
      <h4 className={styles.title}>{dogPageTranslations[activeLanguage].meet}</h4>
      <div className={styles.parametersTable}>
        <div className={styles.parBlock}>
          <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].sexDog}</h3>
          <p className={styles.parText}>
            {male ? `${dogPageTranslations[activeLanguage].male}` : `${dogPageTranslations[activeLanguage].female}`}
          </p>
        </div>
        <div className={styles.parBlock}>
          <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].age}</h3>
          <p
            className={styles.parText}
          >
            {translate(getDogAgeTranslateKey(age))}
          </p>
        </div>
        <div className={styles.parBlock}>
          <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].size}</h3>
          <p className={styles.parText}>{size}</p>
        </div>
        <div className={styles.parBlock}>
          <h3 className={styles.parTitle}>{dogPageTranslations[activeLanguage].sterilization}</h3>
          <p className={styles.parText}>
            {sterilized ? `${dogPageTranslations[activeLanguage].yes}` : `${dogPageTranslations[activeLanguage].no}`}
          </p>
        </div>
      </div>
    </div>
  );
};

cardDogParameters.propTypes = {
  size: PropTypes.string.isRequired,
  sterilized: PropTypes.bool.isRequired,
  age: PropTypes.string.isRequired,
  male: PropTypes.bool.isRequired,
  activeLanguage: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};


export default cardDogParameters;
