import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './listHeader.module.scss';
import TRANSLATE_KEYS from '../../../i18n_keys';
import { TranslateFn } from '../../../models/translate-function.models';

interface RecordsCountProps {
  petsCount: number;
  articlesCount: number;
  small?: boolean;
  t: TranslateFn;
}

const RecordsCount: FC<RecordsCountProps> = ({
  petsCount,
  articlesCount,
  small,
  t: translate,
}) => {
  const [path, setPath] = useState(window.location.pathname);
  const pathAdminBlog = '/admin/blog';
  const isAdminBlog = pathAdminBlog === path;

  useEffect(() => {
    setPath(window.location.pathname);
  }, [path]);

  return (
    <div className={`${styles['title-container-counter']}`}>
      <h2 className={small ? styles['title-in-header'] : styles.title}>
        {isAdminBlog ? translate(TRANSLATE_KEYS.allArticles) : translate(TRANSLATE_KEYS.allRecords)}
      </h2>
      <span
        className={small ? styles['records-count_collapse'] : styles['records-count']}
      >
        {isAdminBlog ? articlesCount : petsCount}
      </span>
    </div>
  );
};

RecordsCount.defaultProps = { small: false };

const mapStateToProps = state => ({
  petsCount: state.wantAFriendPagesReducer.petsCount,
  articlesCount: state.articlesPageReducer.articlesCount,
});

export default withTranslation('translations')(
  connect(mapStateToProps)(RecordsCount),
);
