import React, { FC, useCallback, useLayoutEffect, useRef, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import MainArticle from '../shared-ui/main-article';
import SignupForNewsContainer from '../shared-ui/sign-up-for-news/signupForNewsContainer';
import scrollToElement from '../../utils/scrollToElement';

import EmptyContent from './components/emptyContent/EmptyContent';
import ArticlesList from './components/articlesList/ArticlesList';
import Tabs from './components/tabs/Tabs';

import styles from '../shared-ui/tabs/tabs.module.scss';

import { BlogModels } from './models/blog.models';

const START_PAGINATION = 1;

const BlogComponent: FC<BlogModels.Props> = ({
  clearArticles,
  fetchPaginationArticles,
  updatePagination,
  fetchPaginationArticlesByCategory,
  fetchPageInfoBuilder,
  articles,
  categories,
  mainArticle,
  pagination,
  isPagination,
  openArticleId,
}) => {
  const history = useHistory();
  const location = useLocation<BlogModels.Location>();
  const params = useParams<BlogModels.Params>();
  const { i18n } = useTranslation();
  const tabsRef = useRef();

  const isNoArticles = !articles.length && !mainArticle;

  useEffect(() => {
    if (openArticleId && history.action === 'POP') {
      scrollToElement(`[href$='/article/${openArticleId}']`, 200);
    }
  }, []);

  const handleChangeCategoryTab = useCallback(
    (id: number) => {
      history.push(`/blog/${id}`);

      fetchPageInfoBuilder(id, START_PAGINATION, { isArticles: true });
      updatePagination(START_PAGINATION);
    },
    [updatePagination, fetchPageInfoBuilder, history],
  );

  const handlePagination = useCallback(() => {
    const newPagination = pagination + START_PAGINATION;

    updatePagination(newPagination);
    if (Number(params.id) === 0) {
      fetchPaginationArticles(newPagination);
    } else {
      fetchPaginationArticlesByCategory(Number(params.id), newPagination);
    }
  }, [fetchPaginationArticles, fetchPaginationArticlesByCategory, updatePagination, params.id, pagination]);

  useLayoutEffect(() => {
    fetchPageInfoBuilder(Number(params.id), START_PAGINATION, {
      isArticles: true,
      isMainArticle: true,
      isCategories: true,
    });

    return () => {
      updatePagination(START_PAGINATION);
      clearArticles();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      {isNoArticles ? (
        <EmptyContent />
      ) : (
        <>
          {mainArticle && <MainArticle article={mainArticle} tabsRef={tabsRef} />}
          <div className={`${styles.box} ${mainArticle ? '' : styles.boxOffsetTop}`}>
            <div className={styles.wrapper} ref={tabsRef}>
              <Tabs
                activeTabIndex={Number(params.id)}
                handleChangeCategoryTab={handleChangeCategoryTab}
                categories={categories}
              />
              <ArticlesList
                articles={articles}
                handlePagination={handlePagination}
                isPagination={isPagination && Boolean(articles.length)}
                from={location.pathname}
              />
            </div>
          </div>
        </>
      )}
      <SignupForNewsContainer />
    </>
  );
};

export default BlogComponent;
