import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './promo.module.scss';
import button from '../../../shared-ui/button/button.module.scss';
import { MAX_HEADER_HEIGHT } from '../../../shared-ui/header/Header';
import TRANSLATE_KEYS from '../../../../i18n_keys';

const Promo = (tabsRef: { tabsRef: RefObject<HTMLDivElement> }) => {
  const { t: translate } = useTranslation();
  const handleGoToTabs = () => {
    window.scrollTo({
      // eslint-disable-next-line react/destructuring-assignment
      top: tabsRef.tabsRef.current!.offsetTop - MAX_HEADER_HEIGHT,
      behavior: 'smooth',
    });
  };
  return (
    <div className={styles.box}>
      <div className={styles.wrapper}>
        <h1 className={styles.heading}>{translate(TRANSLATE_KEYS.hotelForAnimalsInKiev)}</h1>
        <p className={styles.text}>
          {translate(TRANSLATE_KEYS.forMoreThan20Years)}
        </p>
        <div className={styles.button}>
          <Link to="/about" className={`${button.button} ${button.sunMain}`}>
            {translate(TRANSLATE_KEYS.learnMore)}
          </Link>
        </div>
        <div className={styles.arrowWrapper}>
          <i className={styles.arrow} aria-hidden="true" onClick={handleGoToTabs} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Promo);
