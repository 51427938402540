import React from 'react';
import styles from './RightArrow.module.scss';
import { RightArrowProps } from './RightArrow.models';

const RightArrow = ({ goToNextSlide, isLastPicture }:RightArrowProps) => (
  <button
    className={`${styles.arrow} ${(isLastPicture) ? styles.hidden : ''}`}
    onClick={isLastPicture ? null : goToNextSlide}
    tabIndex={0}
  >
    <i className={styles.icon} />
  </button>
);

export default RightArrow;
