import React, { FC } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import TRANSLATE_KEYS from '../../../../i18n_keys';

import styles from './team.module.scss';

const Team: FC = () => {
  const [translate] = useTranslation();

  return (
    <>
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>{translate(TRANSLATE_KEYS.team)}</h2>
          <div className={styles.team}>
            <div className={styles['team-item']}>
              <img
                className={`${styles.image}`}
                src="https://res.cloudinary.com/doghotelua/image/upload/v1581003990/about-us/IMG_1236_erkhg4.jpg"
                alt="person"
              />
              <p className={styles.name}>{translate(TRANSLATE_KEYS.victoriaKishchenko)}</p>
              <p className={styles.description}>{translate(TRANSLATE_KEYS.victoriaProf)}</p>
            </div>
            <div className={styles['team-item']}>
              <img
                className={`${styles.image}`}
                src="https://res.cloudinary.com/doghotelua/image/upload/v1581003990/about-us/IMG_1232_ywvq7z.jpg"
                alt="person"
              />
              <p className={styles.name}>{translate(TRANSLATE_KEYS.andreyMalyuti)}</p>
              <p className={styles.description}>{translate(TRANSLATE_KEYS.andreyProf)}</p>
            </div>
            <div className={styles['team-item']}>
              <img
                className={`${styles.image}`}
                src="https://res.cloudinary.com/doghotelua/image/upload/v1581003990/about-us/IMG_1230_fzjzem.jpg"
                alt="person"
              />
              <p className={styles.name}>{translate(TRANSLATE_KEYS.innaVasilkovskaya)}</p>
              <p className={styles.description}>{translate(TRANSLATE_KEYS.innaProf)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation('translations')(Team);
