import { getPetName } from '../app/admin/components/wantAFriendPage/+store/helpers';
import { WAFModels } from '../models/WAFPage.models';
import { Dog } from '../types/dog.model';

/**
 * A mapping function to transform petDto-s containing data for a single language
 * to be used with the original data structure of the front end.
 * @param {object} petDto - The petDto to be transformed.
 */

export const transformPetDto = (petDto: WAFModels.Pet | Dog) => ({
  ...petDto,
  name: getPetName(petDto),
});


/**
 * A mapping function to transform petDto-s returning data for all three languages
 * to be used with the original data structure of the front end.
 * @param {Object} petDto - The petDto to be transformed.
 * @param {string} languageValue - The language to filter data for: 'uk' or 'en'.
 */
export const transformAndFilterPetDto = (petDto: WAFModels.Pet, languageValue: string) => ({
  ...petDto,
  name: petDto?.dogNameDtoSet.filter(
    nameSetItem => nameSetItem.language.languageValue === languageValue,
  )[0].name,
  sizeValue: petDto.dogSizeValueDtoSet.filter(
    categoryValueSetItem => categoryValueSetItem.language.languageValue === languageValue,
  )[0].sizeValue,
  description: petDto.dogDescriptionDtoSet.filter(
    thesisDtoSetItem => thesisDtoSetItem.language.languageValue === languageValue,
  )[0].description || '',
});
