import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardDogCareButton.module.scss';


const cardDogCareButton = (props) => {
  const { text, care, setActive, setFormTitle } = props;

  const handleClick = (str) => {
    setActive(true);
    setFormTitle(`${str}`);
  };

  return (
    <>
      {!care ? (
        <button
          onClick={() => handleClick('takeHome')}
          className={`${styles['dog-button']} ${styles['dog-button_want']}`}
        >
          {text}
        </button>
      )
        : <button onClick={() => handleClick('takeCare')} className={styles['dog-button']}>{text}</button>}
    </>
  );
};


cardDogCareButton.propTypes = {
  text: PropTypes.string.isRequired,
  care: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  setFormTitle: PropTypes.func.isRequired,
};


export default cardDogCareButton;
