import React, { useEffect, useState, FC } from 'react';
import shortid from 'shortid';
import styles from '../../../shared-ui/tabs/tabs.module.scss';
import { ServicesPageModels } from '../../models/servicesPage.models';

const Tabs: FC<ServicesPageModels.TabsProps> = ({ selectedTab, contentPanels, tabsLabels }) => {
  const MOBILE_DEVICE_MAX_WIDTH = 600;
  const [width, setWidth] = useState(window.innerWidth);
  const [activeTabIndex, setActiveTabIndex] = useState(selectedTab);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    setActiveTabIndex(selectedTab);
  }, [selectedTab]);

  const labels = tabsLabels.map((tabsLabel: ServicesPageModels.TabsLabelsType, index: ServicesPageModels.SelectedTabTypes) => {
    const uniqueKey = shortid();
    const activeClass = activeTabIndex === index ? styles.active : '';

    return (
      <li key={uniqueKey} className={`${styles.labelsBigItem} ${activeClass}`}>
        <div
          role="button"
          tabIndex={activeTabIndex}
          onClick={() => handleClick(index)}
        >
          {(width < MOBILE_DEVICE_MAX_WIDTH && tabsLabel.smallLabel) ? tabsLabel.smallLabel : tabsLabel.label}
        </div>
      </li>
    );
  });

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const handleClick = (index: ServicesPageModels.SelectedTabTypes) => {
    setActiveTabIndex(index);
  }

  return (
    <div className={styles.box}>
      <div className={styles.wrapper}>
        <ul className={`${styles.labelsList}`}>
          {labels}
        </ul>
        <div>
          {contentPanels[activeTabIndex]}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
