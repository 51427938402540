/*
  we don't use the i18n translate func because it's
  connected to current page interface set in admin profile,
  but we need translation sync with current tab chosen
*/

const dogPageTranslations = {
  uk: {
    welcomeWantsFriend: 'Привіт, я',
    meet: 'Давай знайомитись',
    sexDog: 'Стать',
    male: 'Хлопчик',
    female: 'Дівчинка',
    age: 'Вік',
    smallAge: 'До року',
    mediumAge: 'Від 1 року до 4 ',
    bigAge: 'Більше 5 років',
    upToSixMonths: 'До 6 міс',
    afterSixMonthsToOneYear: '6 міс-1 рік',
    fromOneToTwoYears: '1-2 роки',
    fromTwoToThreeYears: '2-3 роки',
    fromThreeToFourYears: '3-4 роки',
    fromFourToFiveYears: '4-5 років',
    fromFiveToSixYears: '5-6 років',
    fromSixToEightYears: '6-8 років',
    moreThanEightYears: 'Більше 8 років',
    size: 'Розмір',
    sterilization: 'Стерилізація',
    yes: 'Так',
    no: 'Ні',
    myHistory: 'Моя історія',
    deletePetTitle: 'Видалення запису',
    deletePetQuestion: 'Ви впевнені, що хочете назавжди видалити запис',
    deleteBtn: 'Видалити',
    cancelBtn: 'Скасувати',
  },
  ru: {
    welcomeWantsFriend: 'Привет, я',
    meet: 'Давай знакомиться',
    sexDog: 'Пол',
    male: 'Мальчик',
    female: 'Девочка',
    age: 'Возраст',
    smallAge: 'До года',
    mediumAge: 'От 1 года до 4 лет',
    bigAge: 'Больше 5 лет',
    upToSixMonths: 'До 6 мес',
    afterSixMonthsToOneYear: '6 мес-1 год',
    fromOneToTwoYears: '1-2 года',
    fromTwoToThreeYears: '2-3 года',
    fromThreeToFourYears: '3-4 года',
    fromFourToFiveYears: '4-5 лет',
    fromFiveToSixYears: '5-6 лет',
    fromSixToEightYears: '6-8 лет',
    moreThanEightYears: 'Больше 8 лет',
    size: 'Размер',
    sterilization: 'Стерилизация',
    yes: 'Да',
    no: 'Нет',
    myHistory: 'Моя история',
    deletePetTitle: 'Удаление записи',
    deletePetQuestion: 'Вы уверены, что хотите навсегда удалить запись',
    deleteBtn: 'Удалить',
    cancelBtn: 'Отменить',
  },
  en: {
    welcomeWantsFriend: 'Hello, I am',
    meet: 'Let\'s get acquainted',
    sexDog: 'Sex',
    male: 'Boy',
    female: 'Girl',
    age: 'Age',
    smallAge: 'Up to a year',
    mediumAge: 'From 1 age till 4',
    bigAge: 'More than 5 years',
    upToSixMonths: 'up to 6 mon',
    afterSixMonthsToOneYear: '6 mon-1 year',
    fromOneToTwoYears: '1-2 yr',
    fromTwoToThreeYears: '2-3 yr',
    fromThreeToFourYears: '3-4 yr',
    fromFourToFiveYears: '4-5 yr',
    fromFiveToSixYears: '5-6 yr',
    fromSixToEightYears: '6-8 yr',
    moreThanEightYears: 'more 8 yr',
    size: 'Size',
    sterilization: 'Sterilization',
    yes: 'Yes',
    no: 'No',
    myHistory: 'My story',
    deletePetTitle: 'Delete record',
    deletePetQuestion: 'Are you sure you want to permanently delete the record',
    deleteBtn: 'Delete',
    cancelBtn: 'Cancel',
  },
};

export default dogPageTranslations;
