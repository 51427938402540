import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './cardDogGallery.module.scss';

const CardDogGallery = (props) => {
  const [curPhoto, setCurPhoto] = useState('');
  const { photos } = props;

  useEffect(() => {
    const isCurrentPhotoAddedToPhotos = photos?.some(({ id }) => id === curPhoto?.id);
    if (isCurrentPhotoAddedToPhotos) return;
    setCurPhoto(photos.find(photoDto => photoDto.main) || photos[0]);
  }, [photos]);

  function changePhoto(elId) {
    setCurPhoto(elId);
  }

  return (
    <>
      {(props
      ) ? (
          // eslint-disable-next-line indent
        <>
          <div className={styles.galleryBlock}>
            <div className={styles.mainPhotoBlock}>
              <img className={styles.mainPhoto} src={curPhoto.photo} alt="dog" />
            </div>

            <div>
              <div className={styles.albums}>
                {photos.map(el => (
                  <div
                    className={styles.image}
                    key={el.id}
                    onClick={() => changePhoto(el)}
                    role="button"
                    tabIndex={0}
                  >
                    <img
                      src={el.photo}
                      alt="dog"
                      className={el.id === curPhoto.id ? `${styles.dogImg} ${styles.active}` : styles.dogImg}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line indent */}
        </>
        ) : ''}

    </>
  );
};


CardDogGallery.propTypes = {
  photos: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

CardDogGallery.defaultProps = { photos: [] };


export default CardDogGallery;
