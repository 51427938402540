import { ServicesPageModels } from '../../../models/servicesPage.models';

const defaultAdditionalServices: ServicesPageModels.ServiceInfoProps[] = [
  {
    title: 'walkingTheDog',
    position: ServicesPageModels.PositionTypes.Left,
    details: [
      { text: 'theFirstOption', type: ServicesPageModels.ServiceDetailsTypes.H3 },
      { text: 'paragraphFirst', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'theSecondOption', type: ServicesPageModels.ServiceDetailsTypes.H3 },
      { text: 'paragraphSecond', type: ServicesPageModels.ServiceDetailsTypes.P },
    ],
  },
  {
    title: 'veterinaryServices',
    position: ServicesPageModels.PositionTypes.Right,
    details: [
      { text: 'paragraphFirst', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'paragraphSecond', type: ServicesPageModels.ServiceDetailsTypes.P },
    ],
  },
  {
    title: 'grooming',
    position: ServicesPageModels.PositionTypes.Other,
    extraClassName: ServicesPageModels.ExtraClassNameTypes.SmallImageCenter,
    details: [
      { text: 'paragraphFirst', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'paragraphSecond', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'paragraphThird', type: ServicesPageModels.ServiceDetailsTypes.P },
    ],
  },
  {
    title: 'transportationOfAnimals',
    position: ServicesPageModels.PositionTypes.Right,
    details: [
      { text: 'paragraphFirst', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'paragraphSecond', type: ServicesPageModels.ServiceDetailsTypes.P },
      { text: 'paragraphThird', type: ServicesPageModels.ServiceDetailsTypes.P },
    ],
  },
];

export default defaultAdditionalServices;
