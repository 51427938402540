import { ArticleTransformedData } from '../../../../../../../models/blogPage.models';
import { CommonModels } from '../../../../../../../models/common.models';

const validateObjectEquals = (
  object1: CommonModels.RecordInfo[],
  object2: CommonModels.RecordInfo[],
  tabs: ArticleTransformedData.Tabs,
) => {
  let unitQuantity = object2.length;
  const languagesQuantity = Object.values(tabs).length;
  while (unitQuantity !== 0) {
    const arr1Part = object1[unitQuantity - 1] ? Object.values(object1[unitQuantity - 1]).sort() : '';
    const arr2Part = object2[unitQuantity - 1] ? Object.values(object2[unitQuantity - 1]).sort() : '';
    for (let i = languagesQuantity; i > 0; i -= 1) {
      if (arr1Part[i] !== arr2Part[i]) {
        return false;
      }
    }
    unitQuantity -= 1;
  }
  return true;
};

export default validateObjectEquals;
