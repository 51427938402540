export interface LanguageData {
  languageId?: number;
  languageValue: string;
}

export const enum Languages {
  UK = 'uk',
  EN = 'en'
}

export const enum LanguageLabels {
  UK = 'Укр',
  EN = 'Eng'
}
