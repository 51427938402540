import React from 'react';
import styles from './burgerMenu.module.scss';
import Navigation from '../navigation/Navigation';
import ProfileMenu from '../profileMenu/ProfileMenu';
import { BurgerMenuProps } from './BurgerMenu.models';

const BurgerMenu = ({ imageLink, isActive, collapseY }: BurgerMenuProps) => (
  <div className={styles.burgerWrapper}>
    <Navigation />
    <ProfileMenu
      imgUrl={imageLink}
      isActive={isActive}
      collapseY={collapseY}
    />
  </div>
);
export default BurgerMenu;
