import React from 'react';
import style from './styles/dog-parameter-select.module.scss';
import { DogParameterItemModels } from '../popupForm.models';

const DogParameterItem = ({
  parameter,
  onParameterSelect,
}: DogParameterItemModels.Props) => (
  <li className={style['category-list']}>
    <button
      className={style['select-button']}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => onParameterSelect(parameter)}
    >
      {parameter}
    </button>
  </li>
);

export default DogParameterItem;
