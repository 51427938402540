import React from 'react';
import { Helmet } from 'react-helmet';

const UK_DESCRIPTION = 'Вітаємо у DogHotel - готелі для собак та місці перебування чотирилапих друзів,'
  + ' що поки не мають господаря. Розташуйте свого улюбленця або знайдіть тваринку, якій Ви подаруєте новий дім,'
  + ' у DogHotel. Утримання, перевезення, грумінг, ветеринарна допомога.';
const EN_DESCRIPTION = 'Welcome to DogHotel - a hotel for dogs and a place for four-legged friends'
  + " who don't have an owner yet. Accommodate your beloved pet or find an animal to give a new home"
  + ' to at DogHotel. We provide care, transportation, grooming, and veterinary assistance.';

const DescriptionMeta = () => {
  const ukLanguageCodes = ['uk', 'uk-UA'];
  const preferredLanguage = window.navigator.language;
  const content = ukLanguageCodes.includes(preferredLanguage) ? UK_DESCRIPTION : EN_DESCRIPTION;

  return (
    <Helmet>
      <meta name="description" content={content} />
    </Helmet>
  )
};

export default DescriptionMeta;
