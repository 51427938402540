import React, { FC } from 'react';
import { TranslatedText } from '../../../models/translate-function.models';
import style from './tooltip-text.module.scss';

const stylingMapper = { ADD: 'tooltip-text--add', EDIT: 'tooltip-text--edit' };

interface TooltipTextProps {
  content: TranslatedText;
  propModificator: 'ADD' | 'EDIT';
}

const TooltipText: FC<TooltipTextProps> = ({ content, propModificator }) => {
  if (!content) return null;

  return (
    <span className={`${style['tooltip-text']} ${propModificator ? style[stylingMapper[propModificator]] : null}`}>
      {content}
    </span>
  );
};

export default TooltipText;
