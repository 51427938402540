import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import styles from '../image-uploader.module.scss';
import Icon from '../../icon';


const ImagePreview = ({
  imgIsLoaded,
  isProfilePage,
  imgUploadedUrl,
  src,
  selectedImg,
  t,
  handleImageChange,
  handleRemoveImg,
  mainImgLink,
}) => {
  const [isUploaded] = useState('isUploaded');

  return (
    <div className={styles['image-preview-container']}>
      {!isProfilePage
        ? (
          <>
            {mainImgLink !== undefined
              ? (
                <>
                  <img
                    src={imgUploadedUrl}
                    alt={src ? src.name : null}
                    className={styles.image}
                  />
                  <div className={styles['image-info']}>
                    <div>
                      <p className={styles['image-status']}>{imgIsLoaded ? t(isUploaded) : null}</p>
                      <div className={styles['image-name']}>
                        {selectedImg ? selectedImg.name : null}
                      </div>
                    </div>
                    <div className={styles['image-btns']}>
                      <button
                        onClick={handleImageChange}
                        className={styles.icon_replace}
                      >
                        <Icon name="repeat" />
                      </button>
                      <button
                        onClick={handleRemoveImg}
                        className={styles.icon_remove}
                      >
                        <Icon name="trash" />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.image} />
                  <div className={styles['image-info']}>
                    <div>
                      <p className={styles['image-status']}>{t('uploadingError')}</p>
                      <div className={styles['image-name']}>
                        {t('uploadingErrorMessage')}
                      </div>
                    </div>
                    <div className={styles['image-btns']}>
                      <button
                        onClick={handleImageChange}
                        className={styles.icon_replace}
                      >
                        <Icon name="repeat" />
                      </button>
                      <button
                        onClick={handleRemoveImg}
                        className={styles.icon_remove}
                      >
                        <Icon name="trash" />
                      </button>
                    </div>
                  </div>
                </>
              )
            }
          </>
        )
        : (
          <>
            {/* eslint-disable jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions */}
            <img
              src={imgUploadedUrl}
              alt={src ? src.name : null}
              className={styles.image_profile}
              onClick={handleImageChange}
            />
          </>
        )
            }
    </div>
  );
};

ImagePreview.propTypes = {
  imgIsLoaded: PropTypes.bool.isRequired,
  isProfilePage: PropTypes.bool.isRequired,
  imgUploadedUrl: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  selectedImg: PropTypes.shape({ name: PropTypes.string.isRequired }),
  t: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleRemoveImg: PropTypes.func.isRequired,
  mainImgLink: PropTypes.string.isRequired,
};

ImagePreview.defaultProps = { selectedImg: null };

const mapStateToProps = state => ({ imgIsLoaded: state.adminArticleCreateFormReducer.imgIsLoaded });

export default connect(mapStateToProps)(ImagePreview);
