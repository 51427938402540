import React, { FC, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import styles from '../articleList/articleList.module.scss';
import { AdminBlogActions } from '../../+store/actions';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';

interface UpdateButtonProps {
  id: number;
  cookies: Cookies;
  onPublishArticle: (id: number, cookies: Cookies) => void;
  t: TranslateFn;
}

const UpdateButton: FC<UpdateButtonProps> = ({
  id,
  cookies,
  onPublishArticle,
  t: translate,
}) => {
  const onPublishArticleHandler = (e: MouseEvent) => {
    e.preventDefault();
    onPublishArticle(id, cookies);
  };

  return (
    <button
      className={styles['article-button--update']}
      onClick={onPublishArticleHandler}
    >
      {translate(TRANSLATE_KEYS.updateBtn)}
    </button>
  );
};

const mapDispatchToProps = dispatch => ({
  onPublishArticle: (
    id: number,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.publishArticle(id, cookies)),
});

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(withCookies(UpdateButton)),
);
