import { combineReducers } from 'redux';
import { REQUEST_ALBUMS_BEGIN, REQUEST_ALBUMS_SUCCESS, REQUEST_ALBUMS_FAILURE,
  OPEN_GALLERY_MODAL, CLOSE_GALLERY_MODAL,
  SELECT_ACTIVE_PICTURE, SELECT_ACTIVE_ALBUM } from './actions';

const initialState = {
  items: [],
  slides: [],
  loading: false,
  error: null,
  openGallery: false,
  currentAlbumIndex: null,
  currentPictureIndex: 0,
};

const album = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALBUMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case REQUEST_ALBUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.albums,
        slides: action.payload.slides,
      };

    case REQUEST_ALBUMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case OPEN_GALLERY_MODAL:
      return {
        ...state,
        currentAlbumIndex: action.payload,
        currentPictureIndex: 0,
        openGallery: true,
      };

    case CLOSE_GALLERY_MODAL:
      return {
        ...state,
        currentAlbumIndex: null,
        currentPictureIndex: 0,
        openGallery: false,
      };

    case SELECT_ACTIVE_PICTURE:
      return {
        ...state,
        currentPictureIndex: action.payload,
      };

    case SELECT_ACTIVE_ALBUM:
      return {
        ...state,
        currentAlbumIndex: action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({ album });
