import requestWithHeaders from '../../../../../utils/withHeaders';
import { URL_ABOUT_ASK } from '../../../../../endpoints';
import { REQUEST_QUESTIONS_BEGIN,
  REQUEST_QUESTIONS_SUCCESS,
  REQUEST_QUESTIONS_FAILURE,
  CLEAR_QUESTIONS } from './types';

export const requestQuestionsBegin = () => ({ type: REQUEST_QUESTIONS_BEGIN });

export const requestQuestionsSuccess = questions => ({
  type: REQUEST_QUESTIONS_SUCCESS,
  payload: questions,
});

export const requestQuestionsFailure = error => ({
  type: REQUEST_QUESTIONS_FAILURE,
  payload: error,
});

export const requestQuestions = () => (dispatch) => {
  dispatch(requestQuestionsBegin());
  requestWithHeaders({ url: URL_ABOUT_ASK })
    .then(response => dispatch(requestQuestionsSuccess(response.data)))
    .catch(error => dispatch(requestQuestionsFailure(error)));
};

export const clearQuestions = () => ({ type: CLEAR_QUESTIONS });
