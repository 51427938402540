/*eslint-disable*/
import React, { Component, FC, MouseEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import style from './styles/create-article.module.scss';
import { AdminBlogPopupActions } from '../../+store/actions';
import Icon from '../../../../../../../shared-ui/icon';
import { TranslatedText } from '../../../../../../../../models/translate-function.models';

interface IsArticleMainProps {
  isChecked: boolean;
  isArticleMain: boolean;
  content: TranslatedText;
  changeIsMain: () => void;
};

const isArticleMain: FC<IsArticleMainProps> = ({
  isChecked,
  isArticleMain,
  content,
  changeIsMain,
}) => {
  useEffect(() => {
    if (isArticleMain) changeIsMain();
  }, []);

  const handleCheckBoxClick = (event: MouseEvent) => {
    event.preventDefault();
    changeIsMain();
  };

  return (
    <div className={style['article-form_main']}>
      <input
        type="checkbox"
        checked={isChecked}
        className={style['article-form_main-article']}
        id="mainArticle"
      />
      <label 
        className={style['article-form_main-name']} 
        onClick={handleCheckBoxClick}
        htmlFor="mainArticle"
      >
        <Icon name="checkbox" />
        <span>{content}</span>
      </label>
    </div>
  );
}

const mapStateToProps = state => ({
  isChecked: state.adminArticleCreateFormReducer.isMain,
  isArticleMain: state.articlesPageReducer.article.main,
});

const mapDispatchToProps = dispatch => ({ changeIsMain: () => dispatch(AdminBlogPopupActions.articleIsMainCheckboxChange()) });

export default connect(mapStateToProps, mapDispatchToProps)(isArticleMain);
