import store from '../+store/store';
import { BlogPageModels } from '../models/blogPage.models';

const getArticleDTOfromArticleForm = (): BlogPageModels.Article | null => {
  const state = store.getState();
  const {
    articleTitle, selectedCategory,
    mainImgLink, articleDescription, isMain,
  } = state.adminArticleCreateFormReducer;

  const { article } = state.articlesPageReducer;

  if (!selectedCategory) {
    return null;
  }

  const categoryId = selectedCategory.id;
  const image = mainImgLink || article.mainImgLink;

  return {
    ...article,
    categoryId,
    mainImgLink: image,
    titleDtoSet: article.titleDtoSet.map((item) => {
      const titleValue = articleTitle[item.language.languageValue] || item.titleValue;
      return { ...item, titleValue };
    }),
    categoryValueDtoSet: article.categoryValueDtoSet
      .map(item => ({ ...item, categoryValue: selectedCategory.name[item.language.languageId] })),
    main: isMain,
    thesisDtoSet: article.thesisDtoSet.map((item) => {
      const thesisValue = articleDescription[item.language.languageValue] || item.thesisValue;
      return { ...item, thesisValue };
    }),
  };
};

export default getArticleDTOfromArticleForm;
