import { CommonModels } from '../../../../../../../../models/common.models';
import { Languages } from '../../../../../../../../models/language.model';
import articleCategorySelect from './styles/article-category-select.module.scss';

interface AutocompleteState {
  isStateShouldBeUpdated: boolean,
  newAutocompleteLanguages: Languages[],
  shouldUpdateNotActiveLanguages: boolean,
}

export const handleAutocomplete = (
  autocompleteLanguages: Languages[],
  articleValue: CommonModels.RecordInfo,
  notActiveLanguages: Languages[],
): AutocompleteState => {
  const firstNotActiveLanguage = notActiveLanguages[0];
  const firstAutocompleteLanguage = autocompleteLanguages[0];

  const isFirstAutocompleteLanguageTheSame = firstAutocompleteLanguage === firstNotActiveLanguage;
  const isFirstNotActiveLanguageEmpty = articleValue[firstNotActiveLanguage] === '';
  const shouldUpdateNotActiveLanguages = isFirstNotActiveLanguageEmpty || isFirstAutocompleteLanguageTheSame;
  const shouldClearAutocompleteLanguages = autocompleteLanguages.length && !isFirstNotActiveLanguageEmpty;

  const articleAutocompleteState: AutocompleteState = {
    isStateShouldBeUpdated: false,
    newAutocompleteLanguages: [],
    shouldUpdateNotActiveLanguages,
  };

  if (shouldUpdateNotActiveLanguages) {
    articleAutocompleteState.isStateShouldBeUpdated = true;
    articleAutocompleteState.newAutocompleteLanguages = [...notActiveLanguages];
  } else if (shouldClearAutocompleteLanguages) {
    articleAutocompleteState.isStateShouldBeUpdated = true;
  }

  return articleAutocompleteState;
};

export const getCategoryInputClasses = (validationError: string, style: CommonModels.Style) => [
  articleCategorySelect['create-category-input'],
  (validationError ? style['border-warning'] : style['']),
].join(' ');

export const getCategorySelectClasses = (displayDropdown: boolean) => `${articleCategorySelect['category-select']} ${
  displayDropdown ? articleCategorySelect['is-open'] : ''
}`;

export const getDropdownBtnClasses = (category: string) => `${articleCategorySelect['dropdown-button']} ${
  category ? articleCategorySelect['category-selected'] : ''
}`;
