import React, { FC } from 'react';
import styles from './dogPageHistory.module.scss';
import dogPageTranslations from '../dogPageTranslations';

const DogPageHistory: FC<{ description: string, activeLanguage: string }> = ({ description, activeLanguage }) => (
  <div className={styles.storyBlock}>
    <h2 className={styles.storyTitle}>{dogPageTranslations[activeLanguage].myHistory}</h2>
    <p className={styles.storyText}>{description}</p>
  </div>
);

export default DogPageHistory;
