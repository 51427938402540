import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { AdminWAFPageActions } from '../../../+store/actions';
import styles from './dogPageButtons.module.scss';
import Icon from '../../../../../../shared-ui/icon';
import TRANSLATE_KEYS from '../../../../../../../i18n_keys';
import { HideAndPublishButtonsModels } from '../dogDetailsPage.models';
import navigateAfterPetStatusChange from '../../../../../../../utils/navigateAfterPetStatusChange';

const PublishButton: FC<HideAndPublishButtonsModels.Props> = ({
  id,
  cookies,
  onPublishPet,
  t: translate,
}) => {
  const onPublishPetHandler = (e: MouseEvent) => {
    e.preventDefault();
    onPublishPet(id, cookies).then(
      response => navigateAfterPetStatusChange(response.data.id),
    );
  };

  return (
    <button
      className={styles['buttons-wrapper__publish']}
      onClick={e => onPublishPetHandler(e)}
    >
      <span className={styles.publishTextDesktop}>{translate(TRANSLATE_KEYS.publishBtn)}</span>
      <span className={styles.hiddenLabel}><Icon name="show" /></span>
    </button>
  );
};

const mapDispatchToProps = dispatch => (
  { onPublishPet: (id: number, cookies: Cookies) => dispatch(AdminWAFPageActions.publishPet(id, cookies)) }
);

export default withTranslation('translations')(
  connect(null, mapDispatchToProps)(withCookies(PublishButton)),
);
