import React, { Component, createRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import { withTranslation } from 'react-i18next';
import EditPopup, { SelectedTextValues } from '../editPopup/editPopup';
import './text-box.scss';
import { TextBoxModels } from './textBox.models';
import TRANSLATE_KEYS from '../../../../../../../../i18n_keys';
import { ArticleFormatingTypes } from '../../../../../../../../models/blogPage.models';

const POPUP_OFFSET_X = 18;
const POPUP_OFFSET_Y = 65;

class TextBox extends Component<TextBoxModels.Props, TextBoxModels.State> {
  static focus = (e) => {
    e.target.className = 'textarea';
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  textareaRef = createRef<HTMLTextAreaElement>();

  constructor(props) {
    super(props);
    this.state = {
      isTextSelected: false,
      isFormattedArticle: true,
      editPopupX: 0,
      editPopupY: 0,
      selectedText: '',
      selectedTextStart: 0,
      selectedTextEnd: 0,
      isBold: false,
      isItalic: false,
      isUppercase: false,
      isQuote: false,
      isLink: false,
      isLinkInput: false,
      linkValue: '',
      linkDeleteBtn: false,
      textBoxId: '',
      linkIndex: 0,
      linkInputWidth: '158px',
    };
  }

  componentDidMount() {
    const { content } = this.props;
    if (!content) {
      this.setState({ isFormattedArticle: false });
    }
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = (e) => {
    if (e.target.tagName !== 'TEXTAREA' && e.target.closest('div')?.className !== 'edit__popup'
        && e.target.closest('div')?.className !== 'link__popup') {
      this.setState({ isTextSelected: false });
    }
    if (e.target.className !== 'link__input' && !e.target.closest('.edit__icon--link')
        && !e.target.closest('.link__submit') && !e.target.closest('.link__delete')) {
      this.setState({ isLinkInput: false });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  focus = (e) => {
    e.target.className = 'textarea';
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  blur = (e, deleteBox, id) => {
    if (!e.relatedTarget) {
      this.setState({
        isFormattedArticle: true,
        isTextSelected: false,
      });
    }
    if (e.relatedTarget && !e.relatedTarget.className.includes('edit__icon')
        && !e.relatedTarget.className.includes('link__input')) {
      this.setState({
        isFormattedArticle: true,
        isTextSelected: false,
      });
    }

    e.target.value
      ? (e.target.className = 'textarea textarea-blur')
      : deleteBox(id);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  select = (e) => {
    if (e.nativeEvent.pageX) {
      this.setState({
        editPopupX: e.nativeEvent.pageX - POPUP_OFFSET_X,
        editPopupY: e.nativeEvent.pageY - POPUP_OFFSET_Y,
      });
    }

    const selection = e.target.value.substring(e.target.selectionStart, e.target.selectionEnd);
    this.setState({
      isTextSelected: !!selection.length,
      selectedText: selection,
      selectedTextStart: e.target.selectionStart,
      selectedTextEnd: e.target.selectionEnd,
      isBold: selection.includes('<b>') && selection.includes('</b>'),
      isItalic: selection.includes('<i>') && selection.includes('</i>'),
      isUppercase: selection.includes('<mark>') && selection.includes('</mark>'),
      isQuote: selection.includes('<blockquote>') && selection.includes('</blockquote>'),
      isLink: selection.includes('<a') && selection.includes('</a>'),
    });
  };

  toggleFormatting = (textSelection: SelectedTextValues, tag: ArticleFormatingTypes) => {
    const { selectedText: textValue, selectedTextStart: textStart, selectedTextEnd: textEnd } = textSelection;
    const textarea = this.textareaRef.current;
    if (textValue.includes(`<${tag}>`) && textValue.includes(`</${tag}>`)) {
      const textValueWithoutTags = textValue.replace(`<${tag}>`, '').replace(`</${tag}>`, '');
      textarea.setRangeText(textValueWithoutTags, textStart, textEnd, 'select');
      textarea.focus();
      this.setState({
        isBold: tag !== ArticleFormatingTypes.BOLD,
        isItalic: tag !== ArticleFormatingTypes.ITALIC,
        isUppercase: tag !== ArticleFormatingTypes.MARK,
        isQuote: tag !== ArticleFormatingTypes.BLOCKQUOTE,
      });
    } else {
      textarea.setRangeText(`<${tag}>${textValue}</${tag}>`, textStart, textEnd, 'select');
      textarea.focus();
    }
  };

  showTextarea = () => {
    this.setState({ isFormattedArticle: false }, () => {
      const textarea = this.textareaRef.current;
      textarea.focus();
    });
  };

  handleLinkChange = (e) => {
    this.setState({
      linkValue: e.target.value,
      linkDeleteBtn: false,
    });
    e.target.value
      ? this.setState({ linkInputWidth: '136px' })
      : this.setState({ linkInputWidth: '158px' });
  };

  toggleLinkPopup = (textSelection: SelectedTextValues) => {
    const { selectedText: textValue, selectedTextStart: textStart, selectedTextEnd: textEnd } = textSelection;
    const textarea = this.textareaRef.current;
    if (textValue.includes('<a') && textValue.includes('</a>')) {
      const textValueWithoutTags = textValue.replace(/<a.+?>/, '').replace('</a>', '');
      textarea.setRangeText(textValueWithoutTags, textStart, textEnd, 'select');
      textarea.focus();
      this.setState({ isLink: false });
    } else {
      this.setState({
        isLinkInput: true,
        linkDeleteBtn: false,
        linkValue: '',
      });
      textarea.focus();
    }
  };

  addEditLink = (e, textSelection: SelectedTextValues) => {
    const { linkValue, textBoxId, linkIndex } = this.state;
    const { selectedText: textValue, selectedTextStart: textStart, selectedTextEnd: textEnd } = textSelection;
    const { handleTextChange } = this.props;
    const textBox = e.target.closest('div').previousElementSibling;
    if (textBox.tagName === 'TEXTAREA') {
      textBox.setRangeText(`<a href='${linkValue}'>${textValue}</a>`, textStart, textEnd, 'select');
      textBox.focus();
      this.setState({ isLinkInput: false });
    } else {
      const article = document.getElementById(textBoxId);
      const links = Array.from(article.getElementsByTagName('a'));
      const link = links[linkIndex];
      link.href = linkValue;
      handleTextChange(article.innerHTML, Number(textBoxId));
      this.setState({
        isTextSelected: false,
        isLinkInput: false,
      });
    }
  };

  deleteLink = () => {
    const { textBoxId, linkIndex } = this.state;
    const { handleTextChange } = this.props;
    const article = document.getElementById(textBoxId);
    const links = Array.from(article.getElementsByTagName('a'));
    const link = links[linkIndex];
    if (link.childNodes[0].nodeName !== '#text') {
      const child = link.childNodes[0];
      const withoutLink = document.createElement(child.nodeName);
      withoutLink.innerHTML = child.textContent;
      link.replaceWith(withoutLink);
    } else {
      link.replaceWith(link.innerText);
    }
    handleTextChange(article.innerHTML, Number(textBoxId));
    this.setState({
      isTextSelected: false,
      isLinkInput: false,
    });
  };

  showLinkUrl = (textBoxId) => {
    const article = document.getElementById(textBoxId);
    const links = Array.from(article.getElementsByTagName('a'));
    links.forEach((link) => {
      link.addEventListener('mouseover', (e) => {
        const linkIndex = links.indexOf(link);
        this.setState({
          isFormattedArticle: true,
          isTextSelected: true,
          isLinkInput: true,
          linkValue: link.href,
          linkDeleteBtn: true,
          textBoxId,
          linkIndex,
          editPopupX: e.pageX - POPUP_OFFSET_X,
          editPopupY: e.pageY - POPUP_OFFSET_Y,
          linkInputWidth: '136px',
        });
      });
    });
  };

  render() {
    const { id, content, type, autofilled, handleTextChange, deleteBox, t: translate } = this.props;
    const {
      isTextSelected, isFormattedArticle, selectedTextStart, selectedTextEnd, selectedText, editPopupX, editPopupY,
      isBold, isItalic, isUppercase, isQuote, isLink, isLinkInput, linkValue, linkInputWidth, linkDeleteBtn,
    } = this.state;
    const htmlContent = type === 'QUOTE' ? `<blockquote>${content}</blockquote>` : content;
    const sanitizedHtmlContent = sanitizeHtml(htmlContent);
    return (
      <>
        {!isFormattedArticle ? (
          <textarea
            className="textarea"
            autoCorrect="off"
            onBlur={e => this.blur(e, deleteBox, id)}
            onFocus={(e) => {
              this.focus(e);
              handleTextChange(e, id);
            }}
            onChange={e => handleTextChange(e, id)}
            onKeyUp={e => this.focus(e)}
            onSelect={e => this.select(e)}
            value={content}
            placeholder={translate(TRANSLATE_KEYS.typeTextHere) as string}
            ref={this.textareaRef}
          />
        ) : (
          <div
            role="button"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={
            { __html: sanitizedHtmlContent }}
            tabIndex={0}
            className={`formatted__article formatted__article_${autofilled ? 'autofilled' : 'article'}`}
            id={`${id}`}
            onClick={this.showTextarea}
            onMouseOver={() => this.showLinkUrl(id)}
          />
        )}

        { isTextSelected && (
          <EditPopup
            isTextSelected
            editPopupX={editPopupX}
            editPopupY={editPopupY}
            textSelection={{ selectedText, selectedTextStart, selectedTextEnd }}
            textFormatting={{ isBold, isItalic, isUppercase, isQuote }}
            linkFormatting={{ isLink, isLinkInput, linkValue, linkInputWidth, linkDeleteBtn }}
            handleLinkChange={this.handleLinkChange}
            toggleFormatting={this.toggleFormatting}
            toggleLinkPopup={this.toggleLinkPopup}
            addEditLink={this.addEditLink}
            deleteLink={this.deleteLink}
          />
          )
        }
      </>
    );
  }
}

export default withTranslation('translations')(TextBox);
