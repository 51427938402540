/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { History, Location } from 'history';
import { i18n } from 'i18next';
import styles from './popup.module.scss';
import CreateArticle from './components/formFields/createArticle';
import Button from '../../../../../shared-ui/button';
import dotsLoader from '../../../../../shared-ui/dotsLoader/dotsLoader';
import adminUploadToCloudinaryRequest from '../../../../../../utils/postCloudinaryImage';
import { AdminBlogPopupActions } from './+store/actions';
import { AdminBlogActions } from '../../+store/actions';
import MainArticle from './components/formFields/articleMain';
import { updateBrowserPath } from '../../+store/helpers';
import transformArticleCreatedTitlesToDto from '../../../../../../utils/transformArticleCreatedTitlesToDto';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import setBodyScrollDisabled from '../../../../../../utils/toogleBodyScroll';
import { LanguageData } from '../../../../../../models/language.model';
import { BlogPageModels } from '../../../../../../models/blogPage.models';
import { defaultTranslation, LANGUAGE_TABS } from '../../constants';
import { CommonModels } from '../../../../../../models/common.models';
import { LANGUAGES } from '../../../../../language/+store/languages';
import { TranslateFn } from '../../../../../../models/translate-function.models';

const articleTitleMinLength = 2;
const articleDescriptionMinLength = 1;
const albumName = 'blog';

interface PopupComponentProps extends RouteComponentProps {
  editing: boolean;
  isArticleMain: boolean;
  mainImgLink: string;
  idArticleForEdit: number;
  browserPathCanUpdate?: boolean;
  activeLanguage: LanguageData;
  selectedCategory: BlogPageModels.Category;
  articleTitles: CommonModels.RecordInfo;
  mainArticle: BlogPageModels.Article;
  descriptionsMainArticle: CommonModels.RecordInfo;
  articleForEditTitles: BlogPageModels.ArticleData[];
  article: BlogPageModels.Article;
  cookies: Cookies;
  history: History;
  location: Location;
  i18n: i18n;
  changeCategoryLanguage: (lang: LanguageData) => void;
  submitArticleImage: (imgLink: string) => void;
  submitArticleForm: (cookies: Cookies) => Promise<BlogPageModels.Article>;
  getCategories: (cookies: Cookies) => void;
  getArticles: () => void;
  onCloseModalWindow: (saveArticleForEdit, saveArticleInForm) => void;
  setImageToState: (imgLink: string) => void;
  deleteImageLink: () => void;
  createSnapshotFromForm: (cookies: Cookies) => Promise<number>;
  updateSnapshotFromForm: (cookies: Cookies) => void;
  changeMainArticleMessage: (mainArticles: BlogPageModels.MainArticlesProps, browserPathCanUpdate: boolean,) => void;
  t: TranslateFn;
}

const PopupComponent: FC<PopupComponentProps> = ({
  editing,
  isArticleMain,
  mainImgLink,
  idArticleForEdit,
  browserPathCanUpdate,
  activeLanguage,
  selectedCategory,
  articleTitles,
  mainArticle,
  descriptionsMainArticle,
  articleForEditTitles,
  article,
  cookies,
  history,
  location,
  i18n: i18next,
  changeCategoryLanguage,
  submitArticleImage,
  submitArticleForm,
  getCategories,
  getArticles,
  onCloseModalWindow,
  setImageToState,
  deleteImageLink,
  createSnapshotFromForm,
  updateSnapshotFromForm,
  changeMainArticleMessage,
  t: translate,
}) => {
  const [selectedImg, setSelectedImg] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [buttonClickBlocker, setButtonClickBlocker] = useState(false);
  const [editionTitle, setEditionTitle] = useState(defaultTranslation);
  const [editionDescription, setEditionDescription] = useState(defaultTranslation);
  const [editionCategory, setEditionCategory] = useState(0);
  const [editionMain, setEditionMain] = useState(false);
  const [editionMainImage, setEditionMainImage] = useState('');

  useEffect(() => {
    getCategories(cookies);
    getArticles();
    setScrollPosition(window.pageYOffset);
    setButtonClickBlocker(true);
    setBodyScrollDisabled(true);

    return () => setBodyScrollDisabled(false);
  }, []);

  useEffect(() => {
    setSelectedImg(mainImgLink);
  }, [mainImgLink]);

  useEffect(() => {
    if (editing) {
      const { titleDtoSet, thesisDtoSet, categoryId, main, image } = article;

      const newEditionTitle = titleDtoSet.reduce((acc, element) => {
        acc[element.language.languageValue] = element.titleValue;
        return acc;
      }, { ...defaultTranslation });

      const newEditionDescription = thesisDtoSet.reduce((acc, element) => {
        acc[element.language.languageValue] = element.thesisValue;
        return acc;
      }, { ...defaultTranslation });

      const newEditionCategory = categoryId;
      const newEditionMain = main;
      const newEditionMainImage = image;

      setEditionTitle(newEditionTitle);
      setEditionDescription(newEditionDescription);
      setEditionCategory(newEditionCategory);
      setEditionMain(newEditionMain);
      setEditionMainImage(newEditionMainImage);
    }
  }, [articleTitles, articleForEditTitles]);

  const validateFiledsAreNotEmpty = (formTitles: CommonModels.RecordInfo) => {
    const titles = Object.values(formTitles);
    return titles.every(articleTitle => !!articleTitle.trim());
  };

  const validateFiledsAreMoreMinSymbols = (formTitles: CommonModels.RecordInfo, minLength: number) => {
    const titles = Object.values(formTitles);
    return titles.every(articleTitle => articleTitle.trim().length >= minLength);
  };

  const validateObjectEquals = (object1: CommonModels.RecordInfo, object2: CommonModels.RecordInfo) => {
    const arr1 = Object.values(object1).sort();
    const arr2 = Object.values(object2).sort();

    for (let i = 0; i < arr1.length; i += 1) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };
  const handleImgSelected = async (mainImg) => {
    if (!mainImg) {
      setSelectedImg(null);
      return;
    }
    const imgWithPath = `${albumName}/${mainImg.name}`;
    const formData = new FormData();
    formData.append('image', mainImg, imgWithPath);
    const imageLink = await adminUploadToCloudinaryRequest(cookies, formData);
    setSelectedImg(imageLink);
    setImageToState(imageLink);
  };

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();

    const articleForCreateTitles = transformArticleCreatedTitlesToDto();
    if (mainArticle && isArticleMain) {
      onCloseModalWindow(isArticleMain, true);
      changeMainArticleMessage({
        created: true,
        idArticleForEdit: null,
        mainArticleTitles: mainArticle.titleDtoSet,
        newMainArticleTitles: articleForCreateTitles,
        parentArticleId: null,
        snapshotId: null,
      }, browserPathCanUpdate);
      return;
    }

    setButtonClickBlocker(false);

    const willCreateArticle = () => {
      submitArticleImage(selectedImg);
      submitArticleForm(cookies).then(response => history.push(`/admin/blog/edit/article/${response.snapshotId}`));
    }

    willCreateArticle();
  };

  const handleUpdate = (event: MouseEvent) => {
    event.preventDefault();
    const { parentArticleId, snapshotId } = article;

    if (mainArticle && isArticleMain && mainArticle.titleDtoSet && mainArticle.id !== idArticleForEdit) {
      onCloseModalWindow(isArticleMain, true);
      changeMainArticleMessage({
        idArticleForEdit,
        mainArticleTitles: mainArticle.titleDtoSet,
        newMainArticleTitles: articleForEditTitles,
        parentArticleId,
        snapshotId,
      }, browserPathCanUpdate);
      return;
    }

    setButtonClickBlocker(false);

    const willUpdateArticle = () => {
      if (selectedImg) {
        submitArticleImage(selectedImg);

        const isSnapshot = !!parentArticleId;
        const shouldCreateSnapshot = !parentArticleId && !snapshotId;
        if (isSnapshot) updateSnapshotFromForm(cookies);

        if (shouldCreateSnapshot) {
          browserPathCanUpdate
            ? createSnapshotFromForm(cookies).then((id) => updateBrowserPath(id, location))
            : createSnapshotFromForm(cookies);
        }
      }
    }
    willUpdateArticle();
  };

  const validatePostFields = () => {
    const category = selectedCategory ? selectedCategory.name[activeLanguage.languageId] : '';
    return isArticleMain
      ? category.length
      && buttonClickBlocker
      && selectedImg
      && validateFiledsAreNotEmpty(articleTitles)
      && validateFiledsAreNotEmpty(descriptionsMainArticle)
      : category.length && buttonClickBlocker && selectedImg && validateFiledsAreNotEmpty(articleTitles);
  };

  const validateUpdateFields = () => {
    const categoryEditionTitle = editionTitle || null;
    const categoryEditionDescription = editionDescription || null;
    const categoryEditionCategory = editionCategory || '';
    const selectedCategoryId = selectedCategory ? selectedCategory.id : null;
    const category = selectedCategory ? selectedCategory.name[activeLanguage.languageId] : '';

    return isArticleMain
      ? validateFiledsAreNotEmpty(articleTitles)
      && validateFiledsAreMoreMinSymbols(articleTitles, articleTitleMinLength)
      && validateFiledsAreNotEmpty(descriptionsMainArticle)
      && validateFiledsAreMoreMinSymbols(descriptionsMainArticle, articleDescriptionMinLength)
      && mainImgLink
      && buttonClickBlocker
      && (!validateObjectEquals(articleTitles, categoryEditionTitle)
        || !validateObjectEquals(descriptionsMainArticle, categoryEditionDescription)
        || (categoryEditionCategory !== selectedCategoryId)
        || editionMain !== isArticleMain
        || mainImgLink !== editionMainImage)
      : category.length
      && selectedImg
      && buttonClickBlocker
      && validateFiledsAreNotEmpty(articleTitles)
      && validateFiledsAreMoreMinSymbols(articleTitles, articleTitleMinLength)
      && (!validateObjectEquals(articleTitles, categoryEditionTitle)
        || (categoryEditionCategory !== selectedCategoryId)
        || editionMain !== isArticleMain
        || mainImgLink !== editionMainImage);
  };

  const handleCancel = () => {
    const isEditPage = location.pathname.includes('edit');
    onCloseModalWindow(isEditPage, null);
  };

  const setChangeCreateButton = () => {
    switch (i18next.language) {
      case 'en':
        return (
          <div className={styles.createEnButton}>
            {translate(TRANSLATE_KEYS.createBtn)}
          </div>
        );
      default: return translate(TRANSLATE_KEYS.createBtn);
    }
  };

  const setChangeSaveButton = () => {
    switch (i18next.language) {
      case 'en':
        return (
          <div className={styles.saveEnButton}>
            {translate(TRANSLATE_KEYS.saveUpdate)}
          </div>
        );
      default: return translate(TRANSLATE_KEYS.saveUpdate);
    }
  };

  const getDotsCreateLoader = () => {
    switch (i18next.language) {
      case 'en':
        return (<div className={styles.dotsCreateEnLoader}>{dotsLoader()}</div>);
      default: return (<div className={styles.dotsCreateUkLoader}>{dotsLoader()}</div>);
    }
  };

  const getDotsUpdateLoader = () => {
    switch (i18next.language) {
      case 'en':
        return (<div className={styles.dotsUpdateEnLoader}>{dotsLoader()}</div>);
      default: return (<div className={styles.dotsUpdateUkLoader}>{dotsLoader()}</div>);
    }
  };

  const isAllFieldsFill = validatePostFields();
  const isAllUpdateFieldsFill = validateUpdateFields();
  return (
    <div>
      <div className={styles.wrapper} />
      <div className={styles.formWrapper} style={{ top: scrollPosition }}>
        <div className={styles.form}>
          <header className={styles.header}>
            <h3 className={styles.title}>
              {editing ? translate(TRANSLATE_KEYS.updateArticleTitle) : translate(TRANSLATE_KEYS.createArticleTitle)}
            </h3>
          </header>
          <section>
            <ul className={styles.tabs}>
              {LANGUAGE_TABS.map((item) => {
                const activeClass = activeLanguage.languageValue === item.value ? styles.active : '';
                return (
                  <li
                    key={item.value}
                    className={`${styles.tab} ${activeClass}`}
                  >
                    <span
                      role="button"
                      onClick={() => changeCategoryLanguage(LANGUAGES[item.value.toUpperCase()])}
                      tabIndex={0}
                    >
                      {item.label}
                    </span>
                  </li>
                );
              })}
            </ul>
            <CreateArticle
              onChange={handleImgSelected}
              language={activeLanguage}
            />
          </section>
          <footer className={styles.footer}>
            <MainArticle content={translate(TRANSLATE_KEYS.mainArticle)} />
            <Button
              content={translate(TRANSLATE_KEYS.declineBtn)}
              onClick={handleCancel}
              uiType="gray"
            />
            {editing ? (
              <Button
                content={buttonClickBlocker ? setChangeSaveButton() : getDotsUpdateLoader()}
                uiType={isAllUpdateFieldsFill ? 'sun' : 'sun-disabled'}
                onClick={handleUpdate}
              />
            ) : (
              <Button
                content={buttonClickBlocker ? setChangeCreateButton() : getDotsCreateLoader()}
                uiType={isAllFieldsFill ? 'sun' : 'sun-disabled'}
                onClick={handleSubmit}
              />
            )}
          </footer>
        </div>
      </div>
    </div>
  );
}

PopupComponent.defaultProps = { browserPathCanUpdate: false };

const mapStateToProps = state => ({
  activeLanguage: state.adminArticleCreateFormReducer.activeLanguage,
  editing: state.articlesPageReducer.editing,
  categories: state.adminArticleCreateFormReducer.categories,
  cancelMessageVisible: state.adminArticleCreateFormReducer.cancelMessageVisible,
  selectedCategory: state.adminArticleCreateFormReducer.selectedCategory,
  articleTitles: state.adminArticleCreateFormReducer.articleTitle,
  mainImgLink: state.adminArticleCreateFormReducer.mainImgLink,
  isArticleMain: state.adminArticleCreateFormReducer.isMain,
  mainArticle: state.articlesPageReducer.mainArticle,
  articleForCreacteTitles: state.adminArticleCreateFormReducer.articleTitle,
  articleForEditTitles: state.articlesPageReducer.article.titleDtoSet,
  idArticleForEdit: state.articlesPageReducer.article.id,
  descriptionsMainArticle: state.adminArticleCreateFormReducer.articleDescription,
  article: state.articlesPageReducer.article,
});

const mapDispatchToProps = dispatch => ({
  changeCategoryLanguage: (lang: LanguageData) => dispatch(AdminBlogPopupActions.categoryLanguageChange(lang)),
  submitArticleImage: (imgFD: string) => dispatch(AdminBlogPopupActions.postArticleImage(imgFD)),
  submitArticleForm: (cookies: Cookies) => dispatch(AdminBlogPopupActions.postArticle(cookies)),
  getCategories: (cookies: Cookies) => dispatch(AdminBlogPopupActions.fetchCategories(cookies)),
  getArticles: () => dispatch(AdminBlogPopupActions.fetchArticles()),
  onCloseModalWindow: (saveArticleForEdit, saveDataInAdminArticleCreateFormReducer) => dispatch(
    AdminBlogPopupActions.closeModalWindow(saveArticleForEdit, saveDataInAdminArticleCreateFormReducer),
  ),
  setImageToState: (imgLink: string) => dispatch(AdminBlogPopupActions.postArticleImagePostSuccess(imgLink)),
  deleteImageLink: () => dispatch(AdminBlogPopupActions.postArticleImageDelete()),
  createSnapshotFromForm: (cookies: Cookies) => dispatch(AdminBlogPopupActions.createSnapshot(cookies)),
  updateSnapshotFromForm: (cookies: Cookies) => dispatch(AdminBlogPopupActions.updateSnapshot(cookies)),
  changeMainArticleMessage: (
    mainArticles: BlogPageModels.MainArticlesProps,
    browserPathCanUpdate: boolean,
  ) => dispatch(AdminBlogActions.toggleChangeMainArticleMessage(mainArticles, browserPathCanUpdate)),
});

export default withTranslation('translations')(connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(withCookies(PopupComponent))));
