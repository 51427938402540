import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { TranslatedText, TranslateFn } from '../../../../../../models/translate-function.models';
import buildDogPhotoList from '../../../../../../utils/buildDogPhotoList';
import createBirthDate from '../../../../../../utils/createBirthDate';
import { LANGUAGES } from '../../../../../language/+store/languages';
import { WAFPopupStoreModels } from './+store/WAFPopupFormStore.models';
import { defaultDogDto } from '../../constants';
import { WAFModels } from '../../../../../../models/WAFPage.models';

export function getParametersList(
  translate: TranslateFn,
  parameterValue: string,
): TranslatedText[] {
  switch (parameterValue) {
    case 'age':
      return [
        translate(TRANSLATE_KEYS.upToSixMonths),
        translate(TRANSLATE_KEYS.afterSixMonthsToOneYear),
        translate(TRANSLATE_KEYS.fromOneToTwoYears),
        translate(TRANSLATE_KEYS.fromTwoToThreeYears),
        translate(TRANSLATE_KEYS.fromThreeToFourYears),
        translate(TRANSLATE_KEYS.fromFourToFiveYears),
        translate(TRANSLATE_KEYS.fromFiveToSixYears),
        translate(TRANSLATE_KEYS.fromSixToEightYears),
        translate(TRANSLATE_KEYS.moreThanEightYears),
      ];
    case 'sex':
      return [
        translate(TRANSLATE_KEYS.male),
        translate(TRANSLATE_KEYS.female),
      ];
    case 'size':
      return [
        translate(TRANSLATE_KEYS.small),
        translate(TRANSLATE_KEYS.medium),
        translate(TRANSLATE_KEYS.big),
      ];
    case 'sterilized':
      return [
        translate(TRANSLATE_KEYS.no),
        translate(TRANSLATE_KEYS.yes),
      ];
    default: return [];
  }
}

export function allValuesAreEqual(obj: object): boolean {
  return new Set(Object.values(obj)).size === 1;
}

/* this feature will be deleted in future */
export function handleAutocomplete({
  language,
  otherLanguages,
  initLanguage,
  modifyInitLang,
  isEditing,
}, obj, changeFunc, text) {
  if (isEditing) {
    changeFunc(language.languageValue, text);
    return;
  }

  if (initLanguage === '') {
    modifyInitLang({
      initLanguage: language.languageValue,
      otherLanguages: Object.keys(obj).filter(lang => lang !== language.languageValue),
    })
    changeFunc('uk', text);
    changeFunc('en', text);
  }

  if (initLanguage === language.languageValue && allValuesAreEqual(obj)) {
    changeFunc(language.languageValue, text);
    otherLanguages.forEach((lang) => {
      changeFunc(lang, text)
    })
  }
  changeFunc(language.languageValue, text);
}

const getDogBirthDate = (dogAgeIndex: number): string => {
  let dogDirthDate;
  switch (dogAgeIndex) {
    case 0:
      dogDirthDate = createBirthDate(3);
      break;
    case 1:
      dogDirthDate = createBirthDate(9);
      break;
    case 2:
      dogDirthDate = createBirthDate(18);
      break;
    case 3:
      dogDirthDate = createBirthDate(30);
      break;
    case 4:
      dogDirthDate = createBirthDate(42);
      break;
    case 5:
      dogDirthDate = createBirthDate(54);
      break;
    case 6:
      dogDirthDate = createBirthDate(66);
      break;
    case 7:
      dogDirthDate = createBirthDate(84);
      break;
    case 8:
      dogDirthDate = createBirthDate(168);
      break;
  }
  return dogDirthDate;
}

export function createDogDtoForCreate({
  dogName,
  dogDescription,
  dogParameters,
  dogPhotos,
  isUnderCare,
}: WAFPopupStoreModels.DogFormData) {
  const dogDTO = {
    ...defaultDogDto,
    birthDate: getDogBirthDate(dogParameters.age),
    dogDescriptionDtoSet: Object.values(LANGUAGES).map(language => ({
      language,
      description: dogDescription[language.languageValue],
    })),
    dogNameDtoSet: Object.values(LANGUAGES).map(language => ({
      language,
      name: dogName[language.languageValue],
    })),
    dogPhotoDtoList: buildDogPhotoList(dogPhotos),
    sizeId: dogParameters.size + 1,
    hasGuardianship: isUnderCare,
    male: !dogParameters.sex,
    sterilized: dogParameters.sterilized === null
      ? false
      : !!dogParameters.sterilized,
  }

  return dogDTO;
}

export function createDogDtoForUpdate(
  {
    dogName,
    dogDescription,
    dogParameters,
    dogPhotos,
    isUnderCare,
  }: WAFPopupStoreModels.DogFormData,
  dogBeforeUpdate: WAFModels.Pet,
) {
  const { dogDescriptionDtoSet, dogNameDtoSet, dogPhotoDtoList } = dogBeforeUpdate;
  const newDogDescriptionDtoSet = dogDescriptionDtoSet.map(item => ({
    ...item,
    description: dogDescription[item.language.languageValue],
  }));
  const newDogNameDtoSet = dogNameDtoSet.map(item => ({
    ...item,
    name: dogName[item.language.languageValue],
  }));

  const dogDTO = {
    ...defaultDogDto,
    birthDate: getDogBirthDate(dogParameters.age),
    dogDescriptionDtoSet: newDogDescriptionDtoSet,
    dogNameDtoSet: newDogNameDtoSet,
    dogPhotoDtoList: buildDogPhotoList(dogPhotos, dogPhotoDtoList),
    sizeId: dogParameters.size + 1,
    hasGuardianship: isUnderCare,
    male: !dogParameters.sex,
    sterilized: dogParameters.sterilized === null
      ? false
      : !!dogParameters.sterilized,
  }

  return dogDTO;
}
