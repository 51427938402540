import { WAFPopupStoreModels } from './WAFPopupFormStore.models'

const getFormDogData = (
  state: { adminDogPopupFormReducer: WAFPopupStoreModels.State },
) => ({
  formDogData: state.adminDogPopupFormReducer.formDog,
  dogBeforeUpdate: state.adminDogPopupFormReducer.dogBeforeUpdate,
})

export default getFormDogData;
