import { ServicesPageModels } from '../../../models/servicesPage.models';
import styles from './DogDescription.module.scss';

const defaultFoodItems: string[] = ['royalCanin', 'baskerville', 'optiMeal'];

const defaultDogServices: ServicesPageModels.ServiceInfoProps[] = [
  {
    position: ServicesPageModels.PositionTypes.Left,
    details: [
      {
        text: 'templateHeader',
        type: ServicesPageModels.ServiceDetailsTypes.H1,
        componentClassName: styles['services-section-header'],
      },
      {
        text: 'paragraphFirst',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
      {
        text: 'paragraphSecond',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
      {
        text: 'paragraphThird',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
    ],
  },
  {
    position: ServicesPageModels.PositionTypes.Right,
    details: [
      {
        text: 'templateHeader',
        type: ServicesPageModels.ServiceDetailsTypes.H1,
        componentClassName: styles['services-section-header'],
      },
      {
        text: 'paragraphFirst',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
      {
        text: 'paragraphSecond',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
      {
        text: 'paragraphThird',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
        needToTranslate: false,
      },
    ],
  },
  {
    position: ServicesPageModels.PositionTypes.Other,
    extraClassName: ServicesPageModels.ExtraClassNameTypes.SectionDogsNutrition,
    details: [
      {
        text: 'templateHeader',
        type: ServicesPageModels.ServiceDetailsTypes.H1,
        componentClassName: styles['services-section-header'],
      },
      {
        text: 'natural',
        type: ServicesPageModels.ServiceDetailsTypes.H4,
        componentClassName: styles['services-section-subtitle'],
      },
      {
        text: 'paragraphFirst',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
        needToTranslate: true,
      },
      {
        text: 'dryFood',
        type: ServicesPageModels.ServiceDetailsTypes.H4,
        componentClassName: styles['services-section-subtitle'],
      },
      {
        type: ServicesPageModels.ServiceDetailsTypes.UL,
        componentClassName: styles['services-section-subtitle-item'],
        listOfItems: defaultFoodItems,
      },
      {
        text: 'paragraphSecond',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
      {
        text: 'softFood',
        type: ServicesPageModels.ServiceDetailsTypes.H4,
        componentClassName: styles['services-section-subtitle'],
      },
      {
        type: ServicesPageModels.ServiceDetailsTypes.UL,
        componentClassName: styles['services-section-subtitle-item'],
        listOfItems: ['cannedBaskerville'],
      },
      {
        text: 'paragraphThird',
        type: ServicesPageModels.ServiceDetailsTypes.P,
        componentClassName: styles['services-section-paragraph'],
      },
    ],
  },
];

export default defaultDogServices;
