import React, { useState, useEffect, FC, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Location } from 'history';
import { AdminBlogActions } from '../../+store/actions';
import styles from './articleEditPage.module.scss';
import ArticleContentItem from './components/articleContentItem/articleContentItem';
import CreateArticleContent from './components/createArticleContent/createArticleContent';
import Story from './components/story/story';
import Button from '../../../../../shared-ui/button';
import Icon from '../../../../../shared-ui/icon';
import dotsLoader from '../../../../../shared-ui/dotsLoader/dotsLoader';
import PopupComponent from '../popupForm/popupForm';
import Dropdown from '../../../../../shared-ui/dropdown/Dropdown';
import { transformTabsToDto, updateBrowserPath, transformContentToDTO } from '../../+store/helpers';
import SettingsButton from '../articleButtons/settingsButton';
import PopupChangeMainArticle from '../popupForm/components/popupMessages/changeMainArticle';
import formatDefaultArticleData from './utils/formatDefaultArticleData';
import formatChangedArticleData from './utils/formatChangedArticleData';
import validateObjectEquals from './utils/validateObjectEquals';
import TRANSLATE_KEYS from '../../../../../../i18n_keys';
import { ArticleContentTypes,
  ArticleStatusIds,
  ArticleTransformedData,
  BlogPageModels } from '../../../../../../models/blogPage.models';
import { defaultContent, defaultIds, defaultTabs, defaultTranslation, LANGUAGE_TABS } from '../../constants';
import { CommonModels } from '../../../../../../models/common.models';
import { TranslateFn } from '../../../../../../models/translate-function.models';
import removeEmptyArticleBlocks from './utils/removeEmptyContentObjects';

const minTime = 0;
const maxTime = 30;
const timerDelay = 1000;

interface ArticleEditPageProps extends RouteComponentProps {
  isUpdateFormOpened: boolean;
  selectedLanguage: string;
  changeMainArticleMessageVisible: boolean;
  article: BlogPageModels.Article;
  mainArticle: BlogPageModels.Article;
  articleCategory: CommonModels.RecordInfo;
  articleTitle: CommonModels.RecordInfo;
  articleContent: ArticleTransformedData.Content;
  articleTabs: ArticleTransformedData.Tabs;
  articlesTitles: BlogPageModels.ArticleTitles[];
  match: CommonModels.DetailsPageMatch;
  location: Location;
  cookies: Cookies;
  getMainArticle: (cookies: Cookies) => void;
  createSnapshot: (DTO: BlogPageModels.Article, cookies: Cookies) => Promise<number>;
  updateSnapshot: (DTO: BlogPageModels.Article, cookies: Cookies) => void;
  getArticle: (id: number, cookies: Cookies) => void;
  getArticlesTitles: (cookies: Cookies) => void;
  clearArticlesFromDropdown: () => void;
  clearBlogPageArticles: () => void;
  onCreatePostHandler: () => void;
  onPublishArticle: (id: number, cookies: Cookies) => void;
  onHideArticle: (id: number, cookies: Cookies) => Promise<number>;
  t: TranslateFn;
}

const ArticleEditPage: FC<ArticleEditPageProps> = ({
  isUpdateFormOpened,
  cookies,
  article,
  mainArticle,
  articleCategory = defaultTranslation,
  articleTitle = defaultTranslation,
  articleContent,
  articleTabs,
  articlesTitles,
  selectedLanguage,
  changeMainArticleMessageVisible,
  match,
  location,
  getMainArticle,
  createSnapshot,
  updateSnapshot,
  getArticle,
  getArticlesTitles,
  clearArticlesFromDropdown,
  clearBlogPageArticles,
  onCreatePostHandler,
  onPublishArticle,
  onHideArticle,
  t: translate,
}) => {
  const [blocks, setBlocks] = useState(defaultContent);
  const [haveOriginal, setHaveOriginal] = useState(false);
  const [tabs, setTabs] = useState(defaultTabs);
  const [uniqueIds, setUniqueIds] = useState(defaultIds);
  const [isMenuOpened, setMenuOpenedState] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(selectedLanguage);
  const [articleContentChanged, setArticleContentChanged] = useState(false);
  const [seconds, setSeconds] = useState(maxTime);

  useEffect(() => {
    getArticle(Number(match.params.id), cookies);
    getArticlesTitles(cookies);
    if (!mainArticle) getMainArticle(cookies);

    return () => {
      clearArticlesFromDropdown();
      clearBlogPageArticles();
    };
  }, [match.params.id]);

  useEffect(() => {
    if (!articleContent || !articleTabs) return;
    setTabs(articleTabs);
    if (tabs) {
      setHaveOriginal(Object.keys(tabs).reduce((result, currentKey) => result || tabs[currentKey].isOriginal, false));
    }
    setBlocks(articleContent);
  }, [article]);

  useEffect(() => {
    if (!articleContent) return;
    const getLastArticleContentId = () => {
      let index = 0;
      Object.keys(articleContent).forEach(lang => articleContent[lang].forEach((content) => {
        if (index < content.id) {
          index = content.id;
        }
      }));
      return index + 1;
    };
    const lastArticleContentId = getLastArticleContentId();
    setUniqueIds({ uk: lastArticleContentId, en: lastArticleContentId + 1 });
  }, [articleContent]);

  useEffect(() => {
    if (articleContentChanged) {
      const defaultArticle = formatDefaultArticleData(article.articleContentDtoList);
      const changedArticle = formatChangedArticleData(blocks);

      if (!validateObjectEquals(defaultArticle, changedArticle, tabs)) {
        if (seconds > minTime) {
          setTimeout(() => setSeconds(seconds - 1), timerDelay);
        } else {
          updateArticleHandler();
          setSeconds(maxTime + 1);
        }
      }
    }
  });

  const addBox = (id: number, type: ArticleContentTypes) => {
    setUniqueIds({ ...uniqueIds, [activeLanguage]: uniqueIds[activeLanguage] + 3 });
    const newArray = [...blocks[activeLanguage]];
    let result;
    const newBlock = {
      id: uniqueIds[activeLanguage],
      type,
      content: '',
    };

    setBlocks(() => {
      if (id) {
        const blockPos = newArray.findIndex(el => el.id === id);
        newArray.splice(blockPos + 1, 0, newBlock);
        result = newArray;
      } else {
        result = [newBlock, ...blocks[activeLanguage]];
      }

      return { ...blocks, [activeLanguage]: result };
    });
  };

  const handleTextChange = (e, id: number) => {
    const newArray = [...blocks[activeLanguage]];
    const blockPos = newArray.findIndex(el => el.id === id);
    newArray[blockPos].content = e.target ? e.target.value : e;
    const currentTab = { ...tabs[activeLanguage], hasChanged: true };
    const allLanguages = ['uk', 'en'];
    const otherLanguages = allLanguages.filter(element => element !== activeLanguage);
    const checkEmptyBlock = (blockNumber) => {
      if (Object.keys(blocks[otherLanguages[blockNumber]])) {
        return blocks[otherLanguages[blockNumber]].length === 0 || blocks[otherLanguages[blockNumber]].length === 1;
      }
      return false;
    };
    const newBlocks = !tabs[activeLanguage].isOriginal && haveOriginal
      ? { ...blocks, [activeLanguage]: newArray }
      : Object.keys(blocks)
        .reduce((result, key) => (!tabs[key].hasChanged && checkEmptyBlock(0)
          ? { ...result, [key]: newArray.map(item => ({ ...item })) }
          : { ...result, [key]: [...blocks[key]] }), { uk: [], en: [] });

    setBlocks(newBlocks);

    if (!haveOriginal) {
      setHaveOriginal(true);
      currentTab.isOriginal = true;
    }
    setTabs({ ...tabs, [activeLanguage]: currentTab });
    setArticleContentChanged(true);
  };

  const handleImageChange = (url: string, id: number) => {
    const position = [...blocks[activeLanguage]].findIndex(el => el.id === id);
    const contentItem = { ...blocks[activeLanguage][position], content: url };
    const newBlocks = Object.keys(blocks).reduce((result, key) => {
      if (key === activeLanguage) {
        const item = [...blocks[key]];
        item[position] = { ...contentItem };
        return { ...result, [key]: item };
      }
      return {
        ...result,
        [key]: [...[...blocks[key]].slice(0, position), { ...contentItem }, ...[...blocks[key]].slice(position)],
      };
    }, { uk: [], en: [] });
    setBlocks(newBlocks);
    setArticleContentChanged(true);
  };

  const deleteBox = (id: number) => {
    setBlocks((prevBlocks) => {
      const newArray = [...prevBlocks[activeLanguage]];
      const blockPos = newArray.findIndex(el => el.id === id);
      newArray.splice(blockPos, 1);
      return { ...prevBlocks, [activeLanguage]: newArray };
    });
    setArticleContentChanged(true);
  };

  const burgerToggle = (e: MouseEvent) => {
    e.preventDefault();
    setMenuOpenedState(!isMenuOpened);
  };

  const publishArticleHandler = () => {
    onPublishArticle(article.id, cookies);
    updateBrowserPath(article.parentArticleId, location);
  };

  const hideArticleHandler = () => {
    onHideArticle(article.id, cookies).then((id) => updateBrowserPath(id, location));
  };

  const updateArticleHandler = async () => {
    const { parentArticleId, snapshotId } = article;
    const nonEmptyBlocks = removeEmptyArticleBlocks(blocks);
    let articleDto = transformContentToDTO(article, nonEmptyBlocks);
    articleDto = transformTabsToDto(articleDto, tabs);
    // parentArticleId exist ? that snapshot -> update it
    if (parentArticleId) {
      await updateSnapshot(articleDto, cookies);
      setArticleContentChanged(false);
    }
    // !parentArticleId && !snapshotId that article is published -> create snapshot fot it
    if (!parentArticleId && !snapshotId) {
      createSnapshot(articleDto, cookies)
        .then((id) => {
          updateBrowserPath(id, location);
        });
    }
    setArticleContentChanged(false);
  };

  const getButtonHandlerList = () => {
    const { statusId } = article;
    switch (true) {
      case statusId === ArticleStatusIds.PUBLISHED && !articleContentChanged:
        return (
          <Button
            content={translate(TRANSLATE_KEYS.hideBtn)}
            uiType="sun"
            onClick={hideArticleHandler}
          />
        );
      case statusId === ArticleStatusIds.HIDDEN && !articleContentChanged:
        return (
          <Button
            content={translate(TRANSLATE_KEYS.publishBtn)}
            uiType="sun"
            onClick={publishArticleHandler}
          />
        );
      case statusId === ArticleStatusIds.HAS_CHANGES && !articleContentChanged:
        return (
          <Button
            content={translate(TRANSLATE_KEYS.updateBtn)}
            uiType="sun"
            onClick={publishArticleHandler}
          />
        );
      default:
        return (
          <Button
            content={translate(TRANSLATE_KEYS.saveUpdate)}
            uiType="sun"
            onClick={updateArticleHandler}
          />
        );
    }
  };

  const buttonsHandlerList = getButtonHandlerList();

  const buildSortedArticlesTitlesForDropdown = (
    articlesOfTitles: BlogPageModels.ArticleTitles[],
    mainArticleData: BlogPageModels.Article,
  ) => {
    const isMainArticleTitleNotFirst = articlesOfTitles.length
      && mainArticleData
      && articlesOfTitles[0].id !== mainArticleData.id;
    if (isMainArticleTitleNotFirst) {
      const mainArticleTitle = articlesOfTitles.find(({ id }) => id === mainArticleData.id);

      return [mainArticleTitle, ...articlesOfTitles.filter(({ id }) => id !== mainArticleData.id)];
    }
    return articlesOfTitles;
  };

  return (
    <>
      {/* In the future should be merged with the header component */}
      <header className={styles.box}>
        <div className={styles.wrapper}>
          <div className={styles['articles-list-dropdown']}>
            <Link to="/admin/blog" className={styles['back-link']}>
              <Icon name="back" />
            </Link>
            <Dropdown
              content={(
                <ul>
                  <button onClick={onCreatePostHandler}>
                    {translate(TRANSLATE_KEYS.createArticleTitle)}
                    <Icon name="add" />
                  </button>
                  {buildSortedArticlesTitlesForDropdown(articlesTitles, mainArticle)
                    .filter(item => !!item)
                    .map(item => (
                      <li
                        key={item.id}
                        className={item.id === +match.params.id ? styles['is-active'] : ''}
                      >
                        <Link to={`/admin/blog/edit/article/${item.id}`}>
                          {item.titleDtoSet.find(title => title.language.languageValue === activeLanguage)?.titleValue}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
            >
              <span className={styles.title}>
                {articleTitle ? articleTitle[activeLanguage] : ''}
              </span>
            </Dropdown>
          </div>
          <nav
            className={
              isMenuOpened ? `${styles.navigation} ${styles['is-visible']}` : styles.navigation
            }
          >
            <ul className={styles.list}>
              {LANGUAGE_TABS.map(tab => (
                <button key={tab.value} onClick={() => setActiveLanguage(tab.value)}>
                  <span className={`${styles.link} ${activeLanguage === tab.value ? styles['is-active'] : ''}`}>
                    {tab.label}
                  </span>
                </button>
              ))}
            </ul>
          </nav>
          {/* todo */}
          <div
            className={
              isMenuOpened
                ? `${styles['is-visible']} ${styles['publication-process-wrapper']}`
                : `${styles['publication-process-wrapper']}`
            }
          >
            <div
              className={styles.articleChangeBlock}
              style={seconds > minTime && seconds < maxTime ? { opacity: 1 } : { opacity: 0 }}
            >
              <div className={styles.dotsLoader}>{dotsLoader()}</div>
              <span className={styles.progress}>{translate(TRANSLATE_KEYS.saving)}</span>
            </div>
            <SettingsButton id={article.id} size={40} position={{ bottom: '63px' }} />
            {buttonsHandlerList}
          </div>
          <div
            className={!isMenuOpened ? `${styles['menu-icon']}` : `${styles['menu-icon_close']}`}
            onClick={burgerToggle}
            role="presentation"
          />
        </div>
      </header>
      <Story
        article={article}
        articleTitle={articleTitle && articleTitle[activeLanguage]}
        articleCategory={articleCategory && articleCategory[activeLanguage]}
      />
      <main className={styles.box}>
        <div className={styles.wrapper}>
          <CreateArticleContent isHidden={false} addBox={addBox} />
          {blocks[activeLanguage].map(({ id, type, content }: ArticleTransformedData.TransformedContent) => (
            <ArticleContentItem
              key={id}
              id={id}
              type={type}
              content={content}
              addBox={addBox}
              autofilled={!tabs[activeLanguage].isOriginal && !tabs[activeLanguage].hasChanged}
              handleTextChange={handleTextChange}
              handleImageChange={handleImageChange}
              deleteBox={deleteBox}
              activeLanguage={activeLanguage}
            />
          ))}
        </div>
      </main>
      {isUpdateFormOpened && <PopupComponent browserPathCanUpdate />}
      {changeMainArticleMessageVisible && <PopupChangeMainArticle />}
    </>
  );
};

const mapStateToProps = state => ({
  article: state.articlesPageReducer.article,
  mainArticle: state.articlesPageReducer.mainArticle,
  articleCategory: state.articlesPageReducer.articleCategory,
  articleTitle: state.articlesPageReducer.articleTitle,
  articleContent: state.articlesPageReducer.articleContent,
  articleTabs: state.articlesPageReducer.articleTabs,
  articlesTitles: state.articlesPageReducer.articlesTitles,
  loading: state.articlesPageReducer.loading,
  isUpdateFormOpened: state.articlesPageReducer.openModalCreatingPost,
  selectedLanguage: state.languageReducer.selectedLanguage.languageValue,
  changeMainArticleMessageVisible: state.articlesPageReducer.changeMainArticleMessageVisible,
});

const mapDispatchToProps = dispatch => ({
  getArticle: (id: number, cookies: Cookies) => dispatch(AdminBlogActions.fetchArticle(id, cookies)),
  getArticlesTitles: (cookies: Cookies) => dispatch(AdminBlogActions.fetchAllArticlesTitles(cookies)),
  getMainArticle: (cookies: Cookies) => dispatch(AdminBlogActions.fetchMainArticle(cookies)),
  clearBlogPageArticles: () => dispatch(AdminBlogActions.clearArticles()),
  clearArticlesFromDropdown: () => dispatch(AdminBlogActions.clearAllArticles()),
  onCreatePostHandler: () => dispatch(AdminBlogActions.createNewPost()),
  onPublishArticle: (id: number, cookies: Cookies) => dispatch(AdminBlogActions.publishArticle(id, cookies)),
  onHideArticle: (id: number, cookies: Cookies) => dispatch(AdminBlogActions.hideArticle(id, cookies)),
  createSnapshot: (
    data: BlogPageModels.Article,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.createArticleSnapshot(data, cookies)),
  updateSnapshot: (
    data: BlogPageModels.Article,
    cookies: Cookies,
  ) => dispatch(AdminBlogActions.updateArticleSnapshot(data, cookies)),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(ArticleEditPage))),
);
